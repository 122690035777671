/* eslint-disable no-unused-expressions */
import { EWeekDay } from "./types"

export const mapWeekDay = (
	indexFromSunday: number,
	indexFirstWeekDayFromSunday: number,
): number => {
	let result = indexFromSunday + 1 - indexFirstWeekDayFromSunday
	result < 1 && (result = 7 + result)
	result > 7 && (result = 14 - result)
	return result
}

export const getWeekDay = (
	indexFromSunday: number,
	indexFirstWeekDayFromSunday: number,
): EWeekDay => {
	let result = indexFirstWeekDayFromSunday + indexFromSunday
	result > 6 && (result -= 7)
	return result
}

export const dateToStr = (date: Date, day = true): string => {
	if (!date) return ""
	const result = [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, "0")]
	day && result.push(date.getDate().toString().padStart(2, "0"))
	return result.join("-")
}

export const getWeekEdges = (
	date: Date | string,
	indexFirstWeekDayFromSunday: number,
): [string, string] => {
	typeof date === "string" && (date = new Date(date))
	const weekDay = mapWeekDay(date.getDay(), indexFirstWeekDayFromSunday)
	const first = new Date(date)
	first.setDate(date.getDate() - weekDay + 1)
	const last = new Date(date)
	last.setDate(date.getDate() - weekDay + 7)
	return [dateToStr(first), dateToStr(last)]
}

export const getMonthLastDate = (date: Date | string): Date => {
	typeof date === "string" && (date = new Date(date))
	return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export const getScrollbarWidth = (): number => {
	const outer = document.createElement("div")
	outer.style.visibility = "hidden"
	outer.style.overflow = "scroll"
	outer.className = "detect-scroll-width"
	document.body.appendChild(outer)
	const inner = document.createElement("div")
	outer.appendChild(inner)
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
	outer.parentNode?.removeChild(outer)
	return scrollbarWidth
}

export const parseColor = (color: string) => {
	const div = document.createElement("div")
	div.style.position = "absolute"
	div.style.visibility = "hidden"
	div.style.color = color
	document.body.append(div)
	const matches = getComputedStyle(div).color.match(
		/^rgba?\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*([\d.]+)\s*)?\)$/i,
	)
	document.body.removeChild(div)
	return {
		red: +(matches?.[1] || 0),
		green: +(matches?.[2] || 0),
		blue: +(matches?.[3] || 0),
		opacity: +(matches?.[4] || 1),
	}
}

export const minStr = (...strings: string[]): string => strings.sort()[0]
export const maxStr = (...strings: string[]): string => strings.sort().reverse()[0]
