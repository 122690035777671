import React, { useCallback, useEffect } from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
import debounceRender from "react-debounce-render"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import AutoSizer, { Size } from "react-virtualized-auto-sizer"
import { ListChildComponentProps, FixedSizeList as List } from "react-window"
import Skeleton from "react-loading-skeleton"

import { useMst } from "models/Root"
import exchangeMessages from "messages/exchange"
import Item from "./Item"
import styles from "./index.module.scss"
import Headline from "./Headline"
import { queryVars } from "../../../../constants/query"

const RecentTrades: React.FC = () => {
	const { pair: pairParams } = useParams<{ [queryVars.pair]: string }>()
	const { formatMessage } = useIntl()
	const {
		bybit: {
			recentTradesList,
			loadRecentTrades,
			isLoaded,
			pairAmountPrecision,
			pairPricePrecision,
			currentPair: { baseCoin, quoteCoin },
		},
	} = useMst()

	useEffect(() => {
		loadRecentTrades(pairParams || "").then(() => null)
	}, [pairParams])

	const Row = useCallback(
		({ index, style }: ListChildComponentProps) => (
			<div style={style}>
				<Item
					pricePrecision={pairPricePrecision}
					amountPrecision={pairAmountPrecision}
					price={recentTradesList[index].price}
					amount={recentTradesList[index].amount}
					time={recentTradesList[index].date}
					isBuy={recentTradesList[index].type === 2}
				/>
			</div>
		),
		[recentTradesList, pairPricePrecision, pairAmountPrecision],
	)

	const SkeletonRow = () => <Skeleton height={28} borderRadius={0} />

	return (
		<section className={styles.recentTrades}>
			<h2 className="visually-hidden">{formatMessage(exchangeMessages.recent_trades)}</h2>

			<div className={styles.recentTrades__header}>
				<button className={styles.recentTrades__headerTabButton} type="button">
					<span>{formatMessage(exchangeMessages.recent_trades)}</span>
				</button>
			</div>

			<div className={styles.recentTrades__container}>
				<Headline quoteCurrencyCode={quoteCoin} baseCurrencyCode={baseCoin} />

				<div className={styles.recentTrades__list}>
					<AutoSizer>
						{({ height, width }: Size) => (
							<List
								height={height}
								itemCount={isLoaded ? recentTradesList.length : 30}
								itemSize={28}
								width={width}
							>
								{isLoaded ? Row : SkeletonRow}
							</List>
						)}
					</AutoSizer>
				</div>
			</div>
		</section>
	)
}

export default debounceRender(observer(RecentTrades), 1000, { maxWait: 1000, trailing: true })
