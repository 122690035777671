import { toast } from "react-toastify"
import React from "react"
import { useIntl } from "react-intl"

import commonMessages from "messages/common"
import Informational from "../assets/icons/toast/Informational"

const useCopyClick = () => {
	const { formatMessage } = useIntl()

	return (text: string | number, message?: React.ReactNode) => {
		window.navigator.clipboard.writeText(text.toString())
		toast.info(
			<div>
				<div>Informational</div>
				<div className="Toastify__toast-subtext">
					{message || formatMessage(commonMessages.copied)}
				</div>
			</div>,
			{
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				icon: <Informational />,
			},
		)
	}
}

export default useCopyClick
