import WebSocket, { IMessageEvent } from "websocket"

interface IBybitWebSocketOptions {
	baseUrl: string
	apiKey?: string
	expires?: number
	signature?: string
}

export type TBybitWebSocketListeners = {
	open: () => void
	close: (code: number, desc: string) => void
	error: (error: Error) => void
	message: (data: any) => void
}

class BybitWebSocket {
	private readonly baseUrl: string

	private readonly apiKey: string

	private expires: number

	private signature: string

	private ws: WebSocket.w3cwebsocket | null = null

	private subscriptions: Set<string> = new Set()

	private listeners: Map<
		keyof TBybitWebSocketListeners,
		Set<TBybitWebSocketListeners[keyof TBybitWebSocketListeners]>
	> = new Map()

	private pingTimer?: ReturnType<typeof setInterval>

	constructor({ baseUrl, apiKey = "", expires = 0, signature = "" }: IBybitWebSocketOptions) {
		this.baseUrl = baseUrl
		this.apiKey = apiKey
		this.expires = expires
		this.signature = signature
	}

	private handleListeners<Name extends keyof TBybitWebSocketListeners>(
		name: Name,
		...args: Parameters<TBybitWebSocketListeners[Name]>
	): void {
		this.listeners.get(name)?.forEach((callback: any) => callback(...args))
	}

	public connect(): Promise<void> {
		return new Promise((resolve, reject) => {
			// eslint-disable-next-line new-cap
			this.ws = new WebSocket.w3cwebsocket(this.baseUrl)

			this.ws.onopen = () => {
				console.log("WebSocket connection opened")
				this.handleListeners("open")
				resolve()
			}

			this.ws.onclose = (event: WebSocket.ICloseEvent) => {
				console.log("WebSocket connection closed", event)
				this.handleListeners("close", event.code, event.reason)
				setTimeout(() => this.connect(), 5_000) // Reconnect on close
			}

			this.ws.onerror = (error: Error) => {
				console.error("WebSocket error", error)
				this.handleListeners("error", error)
				reject(error)
			}

			this.ws.onmessage = (message: WebSocket.IMessageEvent) => {
				this.handleListeners("message", JSON.parse(message.data as string))
			}
		})
	}

	public async authenticate() {
		const authPayload = {
			op: "auth",
			args: [this.apiKey, this.expires, this.signature],
		}

		this.ws?.send(JSON.stringify(authPayload))

		return new Promise<true | string>(resolve => {
			const handler = (data: any) => {
				const { op, ret_msg, success } = data
				if (op !== "auth") return
				this.off("message", handler)
				if (success) resolve(true)
				else resolve(ret_msg)
			}
			this.on("message", handler)
		})
	}

	public subscribe(...topics: string[]): void {
		if (!this.ws || this.ws.readyState !== WebSocket.w3cwebsocket.OPEN)
			throw new Error("WebSocket is not open")
		const args: string[] = []
		topics.forEach(topic => {
			if (this.subscriptions.has(topic)) return
			this.subscriptions.add(topic)
			args.push(topic)
		})
		if (!args.length) return
		const payload = {
			op: "subscribe",
			args,
		}
		this.ws?.send(JSON.stringify(payload))
	}

	public unsubscribe(...topics: string[]): void {
		topics.forEach(topic => {
			if (!this.subscriptions.has(topic)) return
			this.subscriptions.delete(topic)
		})
		const payload = {
			op: "unsubscribe",
			args: topics,
		}
		this.ws?.send(JSON.stringify(payload))
	}

	public on<Name extends keyof TBybitWebSocketListeners>(
		name: Name,
		callback: TBybitWebSocketListeners[Name],
	): void {
		if (!this.listeners.has(name)) this.listeners.set(name, new Set())
		this.listeners.get(name)?.add(callback)
	}

	public off<Name extends keyof TBybitWebSocketListeners>(
		name: Name,
		callback: TBybitWebSocketListeners[Name],
	): void {
		this.listeners.get(name)?.delete(callback)
		if (this.listeners.get(name)?.size === 0) this.listeners.delete(name)
	}

	public sendPing(): void {
		const pingPayload = {
			op: "ping",
		}
		this.ws?.send(JSON.stringify(pingPayload))
	}

	public startPingInterval(interval = 20_000): void {
		this.pingTimer = setInterval(this.sendPing, interval)
	}

	public close(): void {
		clearInterval(this.pingTimer)
		this.ws?.close()
	}
}

export default BybitWebSocket
