/* eslint-disable consistent-return,no-unused-expressions */
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react"
import cn from "classnames"

import styles from "./ellipser.module.scss"

type TProps = {
	value?: string
	middle?: number
	style?: CSSProperties
	className?: string
}

const Ellipser: React.FC<TProps> = ({ value = "", middle = 0, style, className }) => {
	const rootRef = useRef<HTMLDivElement>(null)

	const [ellipsis, setEllipsis] = useState<boolean>(false)

	const getEdges = () => {
		const center = value.length / 2
		const half = Math.max(middle, 0) / 2

		const start = center - half
		const end = center + half

		return { start, end }
	}

	const getNodes = () => {
		const nodeRoot = rootRef.current as HTMLDivElement
		return {
			nodeRoot,
			nodeFirst: nodeRoot.firstChild as HTMLDivElement,
			nodeLast: nodeRoot.lastChild as HTMLDivElement,
		}
	}

	useEffect(() => {
		const { start, end } = getEdges()

		const startStr = value.slice(0, start)
		const finishStr = value.slice(end)

		const { nodeFirst, nodeLast } = getNodes()

		startStr.split("").forEach(char => {
			const span = document.createElement("span")
			span.innerText = char
			nodeFirst.appendChild(span)
		})

		finishStr
			.split("")
			.reverse()
			.forEach(char => {
				const span = document.createElement("span")
				span.innerText = char
				nodeLast.appendChild(span)
			})

		return () => {
			nodeFirst.replaceChildren()
			nodeLast.replaceChildren()
		}
	}, [value, middle])

	const ellipsisText = useMemo(() => {
		const { start, end } = getEdges()

		let middleStr = middle > 0 ? value.slice(start, end) : ""

		if (ellipsis) {
			middleStr = `...${middleStr}`
			middle > 0 && (middleStr += "...")
		}

		return middleStr
	}, [value, middle, ellipsis])

	useEffect(() => {
		const { nodeRoot, nodeFirst, nodeLast } = getNodes()

		const updateEllipsis = () =>
			setEllipsis(Math.max(nodeFirst.offsetHeight, nodeLast.offsetHeight) > nodeRoot.offsetHeight)

		const resizeObserver = new ResizeObserver(updateEllipsis)

		updateEllipsis()

		resizeObserver.observe(nodeFirst)
		resizeObserver.observe(nodeLast)
		return () => {
			resizeObserver.unobserve(nodeFirst)
			resizeObserver.unobserve(nodeLast)
		}
	}, [])

	return (
		<div ref={rootRef} className={cn(styles.ellipser, className)} style={style}>
			<div />
			{!!ellipsisText && <div>{ellipsisText}</div>}
			<div />
		</div>
	)
}

export default Ellipser
