import { useEffect, useMemo } from "react"
import { useMst } from "models/Root"

const useActiveOrders = () => {
	const {
		history: { loadOpenedOrders, openedOrders, isOpenedOrdersLoading },
	} = useMst()

	const params = useMemo(
		() => ({
			category: "spot",
		}),
		[],
	)

	useEffect(() => {
		loadOpenedOrders(params)
	}, [loadOpenedOrders, params])

	return {
		openedOrders,
		isOpenedOrdersLoading,
	}
}

export default useActiveOrders
