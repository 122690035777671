/* eslint-disable consistent-return, no-proto,no-void,no-return-assign */
import { RefObject, useEffect, useRef } from "react"

/*
 * How to use
 *
 *
 *
 * const CustomInput = ({ name, defaultValue }) = {
 * 	const rootRef = useRef<HTMLDivElement>(null)
 *
 * 	// if you need to use custom input
 *  // but it is not necessary
 * 	const inputRef = useRef<HTMLInputElement>(null)
 *
 * 	const updateValue = (newValue) => {
 * 		...
 * 		setInputValue(inputValue)
 * 	}
 *
 * 	const resetValue = () => updateValue(defaultValue)
 *
 * 	const { setInputValue } = useFormField({
 *  	name,
 * 	  // if you need to use custom input
 * 		inputRef,
 * 		rootRef,
 * 		onReset: clearValue
 *  })
 *
 * 	return (
 * 		<div ref={rootRef}>
 * 			...
 * 	    // if you need to use custom input
 * 			<input ref={inputRef} name={name} />
 * 			...
 * 		</div>
 * 	)
 * }
 *
 *
 *
 * const formRef = useRef<HTMLFormElement>(null)
 *
 * const handleFormChange = ({ currentTarget }) => {
 * 	 const formData = new FormData(currentTarget)
 * 	 ...
 * }
 *
 * const handleFormSubmit = (event) => {
 * 	 event.preventDefault()
 * 	 const formData = new FormData(event.currentTarget)
 * 	 ...
 * }
 *
 * // you can subscribe to events or use "on..." props
 * formRef.current?.addEventListener("reset", handleFormChange)
 * formRef.current?.addEventListener("input", handleFormChange)
 * formRef.current?.addEventListener("submit", handleFormSubmit)
 *
 * // you can call methods or add buttons
 * formRef.current?.reset()
 * formRef.current?.submit()
 *
 * <form
 * 	 ref={formRef}
 * 	 // you can subscribe to events or use "on..." props
 * 	 onReset={handleFormChange}
 * 	 onInput={handleFormChange}
 * 	 onSubmit={handleFormSubmit}
 * >
 * 	 ...
 * 	 <CustomInput name="field" defaultValue="value" />
 * 	 ...
 * 	 // you can call methods or add buttons
 * 	 <button type="reset">Reset</button>
 * 	 <button type="submit">Submit</button>
 * </form>
 *
 */

type TParams = {
	name?: string
	rootRef?: RefObject<HTMLElement | undefined>
	inputRef?: RefObject<HTMLInputElement | undefined>
	onReset?: () => void
}

export default function useFormField({ name, rootRef, inputRef, onReset }: TParams = {}) {
	const internalInputRef = useRef<HTMLInputElement>()
	const resetRef = useRef<boolean>(false)

	const setInputValue = (value: string | number = "") => {
		const nodeInput = internalInputRef.current || inputRef?.current
		if (!nodeInput) return
		const setValue = Object.getOwnPropertyDescriptor((nodeInput as any).__proto__, "value")?.set
		setValue?.call(nodeInput, value.toString() || "")
		if (resetRef.current) return
		nodeInput.dispatchEvent(new Event("input", { bubbles: true }))
		nodeInput.dispatchEvent(new Event("change", { bubbles: true }))
	}

	const getForm = (): HTMLFormElement | undefined => rootRef?.current?.closest("form") || undefined

	useEffect(() => {
		const form = getForm()
		if (!form) return
		const handleFormReset = () => {
			resetRef.current = true
			onReset?.()
			setTimeout(() => (resetRef.current = false), 0)
		}
		form.addEventListener("reset", handleFormReset)
		return () => form.removeEventListener("reset", handleFormReset)
	}, [rootRef, onReset])

	useEffect(() => {
		if (!rootRef?.current || !name) return
		if (inputRef) return
		internalInputRef.current = document.createElement("input")
		internalInputRef.current.name = name
		internalInputRef.current.style.setProperty("display", "none", "important")
		rootRef.current.appendChild(internalInputRef.current)
		return () => void internalInputRef.current?.parentNode?.removeChild(internalInputRef.current)
	}, [rootRef, inputRef, name])

	return { setInputValue, getForm }
}
