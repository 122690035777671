import formMessages from "messages/form"
import { IOrder, IOrderBookOrder } from "models/Terminal"
import { MessageDescriptor } from "react-intl"
import { ICreateOrderBody } from "types/exchange"
import { MAX_ORDERBOOK_PRECISION_DIFF } from "utils/constants"
import { formatNumberNoRounding } from "utils/format"

export const onSubmitValidate = (
	order: ICreateOrderBody,
	minAmount: number,
	maxAmount: number,
	formatMessage: (descriptor: MessageDescriptor) => string,
	amountPrecision: number,
	pricePrecision: number,
	isMarketIsBuy = false,
): { errors: Record<string, unknown>; isValid: boolean } => {
	const errors: Record<string, unknown> = {}

	const validateField = (key: string, value: number | string, isAmount: boolean): boolean => {
		if ((!value || +value === 0) && key !== "is_leverage") {
			errors[key] = formatMessage(formMessages.required)
			return false
		}

		if ((key === "price" || key === "stop_price") && typeof value === "string") {
			const price = value.split(".")
			if (price[1] && price[1].length > pricePrecision) {
				errors[key] = `${formatMessage(formMessages.max_digits)} ${pricePrecision}`
				return false
			}
		}

		if (isAmount && !isMarketIsBuy) {
			if (+value < minAmount) {
				errors[key] = `${formatMessage(formMessages.min_value)} ${minAmount}`
				return false
			}

			if (+value > maxAmount) {
				errors[key] = `${formatMessage(formMessages.max_value)} ${maxAmount}`
				return false
			}

			const amount = value.toString().split(".")
			if (amount[1] && amount[1].length > amountPrecision) {
				errors[key] = `${formatMessage(formMessages.max_digits)} ${amountPrecision}`
				return false
			}
		}

		return true
	}

	const isValid = Object.entries(order).every(([key, value]) =>
		validateField(key, value, key === "amount" || key === "quote_amount"),
	)

	return { errors, isValid }
}

export const toDecimalFormat = (num: number): string => {
	const str = num.toString()

	// Если число уже в десятичном формате, просто вернем его
	if (!str.includes("e")) return str

	// Получаем значения из экспоненциальной нотации
	const [base, exponent] = str.split("e").map(Number)
	let result = ""

	if (exponent > 0) {
		const decimalIndex = base.toString().indexOf(".")
		if (decimalIndex === -1) {
			result = base.toString() + "0".repeat(exponent)
		} else {
			const decimals = base.toString().split(".")[1]
			if (exponent >= decimals.length) {
				result = base.toString().replace(".", "") + "0".repeat(exponent - decimals.length)
			} else {
				result = insertAt(base.toString().replace(".", ""), decimalIndex + exponent, ".")
			}
		}
	} else {
		const absExponent = Math.abs(exponent)
		result = `0.${"0".repeat(absExponent - 1)}${base.toString().replace(".", "")}`
	}

	return result
}

const insertAt = (original: string, index: number, subStr: string): string =>
	original.substr(0, index) + subStr + original.substr(index)

export const formatErrorFromServer = (
	err: Record<string, unknown>,
): { errors: Record<string, unknown>; serverErrors: Record<string, unknown> } => {
	const errors: Record<string, unknown> = {}
	const serverErrors: Record<string, unknown> = {}

	Object.keys(err).forEach(key => {
		errors[key] = "invalid"
		serverErrors[key] = err[key]
	})

	return { errors, serverErrors }
}

export const formatOrders = (orders: string[][], pricePrecision: number): IOrder[] => {
	if (!Array.isArray(orders)) return []
	let depth = 0
	let totalDepth = 0

	for (let i = 0; i < orders.length; i++) {
		totalDepth += +orders[i][1]
	}

	const nextOrders = orders.map((order: string[]) => {
		const price = +order[0]
		const amount = +order[1]
		depth += amount

		return {
			key: order[0],
			price: price,
			amount: amount,
			amount2: price * amount,
			orderDepth: +depth.toFixed(8),
			progress: `${(depth / totalDepth) * 100}%`,
			last_update: Date.now(),
			unique: false,
		}
	})

	const prices = nextOrders.map((order: IOrder) =>
		formatNumberNoRounding(order.price, pricePrecision),
	)

	const pricesSliced = prices.map((price: string) => price.slice(0, -2))
	for (let i = 0; i < nextOrders.length; i++) {
		nextOrders[i].unique = false
	}

	for (let i = 0; i < nextOrders.length; i++) {
		nextOrders[i].unique = true
		for (let k = i; k < prices.length; k++) {
			if (pricesSliced[i] !== pricesSliced[k]) {
				i = k - 1
				break
			}
			i = k
		}
	}

	return nextOrders
}

export const placeOrderInOrderbook = (
	orders: string[][],
	newOrder: string[],
	type: "sell" | "buy",
): string[][] => {
	// order: ["price", "amount"]
	if (!Array.isArray(orders)) return []
	if (!Array.isArray(newOrder)) return orders

	const newState = [...orders]
	const index = newState.findIndex(item => item[0] === newOrder[0])
	const newOrderAmount = +newOrder[1]

	if (index !== -1) {
		const amount = +newState[index][1] + newOrderAmount
		if (amount > 0.00000001 && amount !== 0) {
			newState[index] = [newState[index][0], `${amount}`]
		} else newState.splice(index, 1)
	} else {
		if (newOrderAmount < 0.00000001) return newState
		// eslint-disable-next-line no-unused-expressions
		type === "sell" ? binarySellInsert(newOrder, newState) : binaryBuyInsert(newOrder, newState)
	}

	return newState
}

const binarySellInsert = (
	value: string[],
	array: string[][],
	startVal = 0,
	endVal: number | null = null,
) => {
	const { length } = array
	const start = startVal
	const end = endVal ?? length - 1
	const m = start + Math.floor((end - start) / 2)
	const price = +value[0]

	if (length === 0) {
		array.push(value)
		return
	}

	if (array[end] && price > +array[end][0]) {
		array.splice(end + 1, 0, value)
		return
	}

	if (price < +array[start][0]) {
		array.splice(start, 0, value)
		return
	}

	if (start >= end) {
		return
	}

	if (price <= +array[m][0]) {
		binarySellInsert(value, array, start, m - 1)
		return
	}

	if (price > +array[m][0]) {
		binarySellInsert(value, array, m + 1, end)
	}
}

const binaryBuyInsert = (
	value: string[],
	array: string[][],
	startVal = 0,
	endVal: number | null = null,
) => {
	const { length } = array
	const end = startVal
	const start = endVal ?? length - 1
	const m = end + Math.floor((start - end) / 2)
	const price = +value[0]

	if (length === 0) {
		array.push(value)
		return
	}

	if (array[start] && price < +array[start][0]) {
		array.splice(start + 1, 0, value)
		return
	}

	if (price > +array[end][0]) {
		array.splice(end, 0, value)
		return
	}

	if (start <= end) {
		return
	}

	if (price >= +array[m][0]) {
		binaryBuyInsert(value, array, end, m - 1)
		return
	}

	if (price < +array[m][0]) {
		binaryBuyInsert(value, array, m + 1, start)
	}
}

export const formatOrdersToPrecision = (
	orders: IOrderBookOrder[],
	precision: number,
	initialPrecision: number,
): IOrderBookOrder[] => {
	const precisionOrders = new Map<string, IOrderBookOrder>()

	orders.forEach(o => {
		const nextOrder = { ...o }
		const multiplier = 10 ** Math.abs(precision)
		const price =
			precision >= 0
				? formatNumberNoRounding(o.price, precision)
				: (Math.trunc(Math.trunc(o.price) / multiplier) * multiplier).toString()
		const prevOrder = precisionOrders.get(price)
		nextOrder.price = +price

		if (precision !== initialPrecision) {
			nextOrder.unique = true
		}

		if (prevOrder) {
			nextOrder.key = prevOrder.key
			nextOrder.price = prevOrder.price
			nextOrder.amount += prevOrder.amount
			nextOrder.amount2 += prevOrder.amount2
			nextOrder.orderDepth += prevOrder.last_update
			nextOrder.progress += prevOrder.progress
		}

		precisionOrders.set(price, nextOrder)
	})

	return Array.from(precisionOrders.values())
}

/**
 * Converts a given precision value into its symbolic representation.
 *
 * @param precision - The precision value. Positive values represent decimal places,
 *                    while negative values (or zero) represent powers of ten.
 * @returns A string representation of the precision.
 */
export const toPrecisionSymbol = (precision: number): string => {
	// If the precision is positive, we want to represent it as a decimal.
	if (precision > 0) {
		// We use .toFixed() to ensure the correct number of decimal places.
		// For example, a precision of 2 would result in "0.01".
		return (10 ** -precision).toFixed(precision).toString()
	}

	// If the precision is zero or negative, we want to represent it as a power of ten.
	// For example, a precision of -2 would result in "100".
	return (10 ** Math.abs(precision)).toString()
}

export const getPrecisionMap = (pairPrecision: number, pairPrice: number): Map<number, string> => {
	const list: Map<number, string> = new Map()
	const priceIntMultiplier = Math.trunc(pairPrice).toString().length
	const diff = pairPrecision - MAX_ORDERBOOK_PRECISION_DIFF
	const left =
		diff < 0
			? Math.max(diff, -priceIntMultiplier + 1)
			: pairPrecision - MAX_ORDERBOOK_PRECISION_DIFF

	for (let i = pairPrecision; i > left; i--) {
		if (i > 0) {
			list.set(i, (10 ** -i).toFixed(i).toString())
		} else {
			list.set(i, (10 ** Math.abs(i)).toString())
		}
	}

	return list
}
