/* eslint-disable jsx-a11y/control-has-associated-label,no-multi-assign */
import React, {
	ChangeEventHandler,
	ClipboardEventHandler,
	FocusEventHandler,
	KeyboardEventHandler,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from "react"
import { useIntl } from "react-intl"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"
import useFormField from "hooks/useFormField"

import Loader from "assets/icons/Loader"

import styles from "./input.module.scss"

type TProps = {
	name?: string
	placeholder?: string
	icon?: ReactNode | TAlphaIcon
	value?: string
	defValue?: string
	disabled?: boolean
	readOnly?: boolean
	mini?: boolean
	error?: boolean
	number?: boolean
	secure?: boolean
	min?: number
	max?: number
	maxLength?: number
	action?: string
	actionClick?: () => void
	actionDisabled?: boolean
	actionLoading?: boolean
	onChange?: (value: string) => void
	onBlur?: () => void
	className?: string
}

const Input = ({
	name,
	placeholder,
	icon,
	value,
	defValue,
	disabled,
	readOnly,
	mini,
	error,
	number,
	secure,
	min,
	max,
	maxLength,
	action,
	actionClick,
	actionDisabled,
	actionLoading,
	onChange,
	onBlur,
	className,
}: TProps) => {
	const { formatNumber } = useIntl()

	const rootRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)

	const [empty, setEmpty] = useState(!defValue && !value)
	const updateEmpty = () => setEmpty(!inputRef.current?.value?.length)

	const clearValue = () => {
		setInputValue("")
		updateEmpty()
	}

	const { setInputValue } = useFormField({
		name,
		rootRef,
		inputRef,
		onReset: clearValue,
	})

	const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
		updateEmpty()
		onChange?.(target.value)
	}

	const formatAmount = (value: string) => {
		let parsedValue = parseFloat(value)
		if (Number.isNaN(parsedValue)) return ""
		if (min !== undefined && parsedValue < min) parsedValue = min
		if (max !== undefined && parsedValue > max) parsedValue = max
		return parsedValue.toFixed(8).replace(/\.?0+$/, "")
	}

	const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
		if (number) {
			const currentValue = inputRef.current?.value || ""
			const numberValue = formatAmount(currentValue)
			if (currentValue !== numberValue) setInputValue(numberValue)
		}
		onBlur?.()
	}

	const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = event => {
		const { ctrlKey, key, metaKey, currentTarget } = event
		if (!number || ctrlKey || metaKey) return
		if (["Backspace", "Delete"].includes(key)) return
		if (key === "." && (currentTarget.value.includes(".") || currentTarget.selectionStart === 0))
			event.preventDefault()
		if (key === "-" && (currentTarget.value.includes("-") || currentTarget.selectionStart !== 0))
			event.preventDefault()
		if (!"-1234567890.".includes(key)) event.preventDefault()
	}

	const handlePaste: ClipboardEventHandler<HTMLInputElement> = event => {
		if (!number || readOnly || disabled) return
		event.preventDefault()
		const { clipboardData, currentTarget } = event
		const { value: currentValue, selectionStart, selectionEnd } = currentTarget
		const pastedText = clipboardData.getData("text").replace(/[^-1234567890.]/g, "")
		if (!pastedText.length) return
		const [startText, endText] = [
			currentValue.slice(0, selectionStart ?? 0),
			currentValue.slice(selectionEnd ?? Math.max(currentValue.length - 1, 0)),
		]
		const compoundValue = startText + pastedText + endText
		const numberValue = formatAmount(compoundValue)
		setInputValue(numberValue)
		currentTarget.selectionStart = currentTarget.selectionEnd =
			currentTarget.value.length - endText.length
	}

	useEffect(updateEmpty, [defValue, value])

	return (
		<div
			ref={rootRef}
			className={cn(styles.input, { [styles.error]: error, [styles.mini]: mini }, className)}
		>
			{!!icon && (
				<div className={styles.icon}>
					{typeof icon === "string" ? <i className={cn("ai", icon)} /> : icon}
				</div>
			)}
			<input
				ref={inputRef}
				type={secure ? "password" : "text"}
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				readOnly={readOnly}
				defaultValue={defValue}
				value={value}
				maxLength={maxLength}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				onPaste={handlePaste}
			/>
			{!empty && !disabled && !readOnly && (
				<button type="button" className={styles.reset} onClick={clearValue}>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12 4L4 12M4 4L12 12"
							strokeWidth="1.25"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			)}
			{!!action?.length && !actionLoading && (
				<button
					type="button"
					disabled={actionDisabled}
					className={styles.action}
					onClick={actionClick}
				>
					{action}
				</button>
			)}
			{actionLoading && <Loader />}
		</div>
	)
}

export default Input
