/* eslint-disable no-unused-expressions */
import React, { CSSProperties, UIEventHandler, useEffect, useRef, useState } from "react"

import {
	ESelectionStatus,
	TColors,
	TFonts,
	TMonthNames,
	TOnHover,
	TOnSelect,
	TPaddings,
} from "../types"
import { getMonthLastDate, maxStr, minStr } from "../utils"

import Month from "./month"

import "./style.scss"

export type TMonthsData = {
	year: number
	top: number
	height: number
}

type TProps = {
	todayStr: string
	minDateStr: string
	maxDateStr: string
	data: TMonthsData[]
	monthNames: TMonthNames
	range: boolean
	monthHeight: number
	yearWidth: number
	monthsPadding: TPaddings
	selectedRadius: number
	colors: TColors
	fonts: TFonts
	valueFromStr: string
	valueToStr: string
	onSelect?: TOnSelect
	onHover?: TOnHover
}

const Months: React.FC<TProps> = ({
	todayStr,
	minDateStr,
	maxDateStr,
	data,
	monthNames,
	range,
	monthHeight,
	yearWidth,
	monthsPadding,
	selectedRadius,
	colors,
	fonts,
	valueFromStr,
	valueToStr,
	onSelect,
	onHover,
}) => {
	const currentMonthStr = todayStr.substr(0, 7)

	const { borders, monthsYearText, monthsYearBackground } = colors

	const monthsScrollRef = useRef<HTMLDivElement>(null)
	const [monthsScrollTop, setMonthsScrollTop] = useState(0)
	const onMonthsScroll: UIEventHandler<HTMLDivElement> = ({ target }) =>
		setMonthsScrollTop((target as HTMLDivElement).scrollTop)
	const monthsScrollHeight = monthsScrollRef.current?.offsetHeight

	const scrollToValue = () => {
		let monthToScroll = todayStr
		if (valueFromStr >= minDateStr && valueFromStr <= maxDateStr) {
			monthToScroll = valueFromStr
		}
		if (monthToScroll >= minDateStr && monthToScroll <= maxDateStr) {
			const index = data.findIndex(({ year }) => monthToScroll < year.toString())
			if (index > 1)
				(monthsScrollRef.current as HTMLDivElement).scrollTop = data[index - 1].top - monthHeight
		}
	}
	useEffect(() => {
		scrollToValue()
	}, [])

	const monthsRef = useRef<HTMLDivElement>(null)
	const [monthWidth, setMonthWidth] = useState(0)
	const updateMonthWidth = () =>
		setMonthWidth(((monthsRef.current as HTMLDivElement).clientWidth - yearWidth) / 6)
	useEffect(() => {
		updateMonthWidth()
		window.addEventListener("resize", updateMonthWidth)
		return () => window.removeEventListener("resize", updateMonthWidth)
	}, [])

	const [status, setStatus] = useState<ESelectionStatus>(ESelectionStatus.CLEAR)
	const [selectedMonths, setSelectedMonths] = useState<string[]>([])
	const [hoveredMonth, setHoveredMonth] = useState<string>("")
	const onMonthClick = () => {
		// eslint-disable-next-line one-var
		let newStatus,
			newSelectedMonths: string[] = []
		// eslint-disable-next-line default-case
		switch (status) {
			case ESelectionStatus.CLEAR:
			case ESelectionStatus.FULL:
				newStatus = range ? ESelectionStatus.FIRST : ESelectionStatus.FULL
				newSelectedMonths = [hoveredMonth, hoveredMonth]
				break
			case ESelectionStatus.FIRST:
				// eslint-disable-next-line no-case-declarations
				const dates = [hoveredMonth, ...selectedMonths]
				newStatus = ESelectionStatus.FULL
				newSelectedMonths = [minStr(...dates), maxStr(...dates)]
				break
		}
		setStatus(newStatus)
		setSelectedMonths(newSelectedMonths)
		if (!onSelect) return
		const firstDate = new Date(newSelectedMonths[0])
		const lastDate = getMonthLastDate(newSelectedMonths[1])
		onSelect({
			selected: newStatus === ESelectionStatus.FULL,
			value: [firstDate, lastDate],
		})
	}
	const onMonthHover = setHoveredMonth
	const onMonthsLeave = () => status !== ESelectionStatus.FIRST && setHoveredMonth("")

	useEffect(() => {
		if (!onHover) return
		if (hoveredMonth) {
			const firstDate = new Date(hoveredMonth)
			const lastDate = getMonthLastDate(firstDate)
			onHover([firstDate, lastDate])
		} else {
			onHover(null)
		}
	}, [hoveredMonth])

	useEffect(() => {
		if (!valueFromStr) {
			setSelectedMonths([])
			setStatus(ESelectionStatus.CLEAR)
		} else {
			setSelectedMonths([valueFromStr.slice(0, 7), valueToStr.slice(0, 7)])
			setStatus(ESelectionStatus.FULL)
		}
	}, [valueToStr, valueFromStr])

	const lastYear = data[data.length - 1]
	const fullHeight = lastYear ? lastYear.top + lastYear.height : 0

	return (
		<div ref={monthsScrollRef} className="months-scroll" onScroll={onMonthsScroll}>
			<div
				ref={monthsRef}
				className="months"
				style={{ height: fullHeight }}
				onMouseLeave={onMonthsLeave}
			>
				{data.map(({ year, top, height }) => {
					if (
						!monthsScrollHeight ||
						top < monthsScrollTop - height ||
						top > monthsScrollTop + monthsScrollHeight
					)
						return false
					const yearStyle = {
						top,
						height,
						borderColor: borders,
					}
					const yearNameStyle: CSSProperties = {
						color: monthsYearText,
						backgroundColor: monthsYearBackground,
						fontSize: fonts.monthsYear,
						minWidth: yearWidth,
						maxWidth: yearWidth,
						borderRightColor: borders,
					}
					const yearMonthsStyle: CSSProperties = {
						padding:
							typeof monthsPadding === "number"
								? monthsPadding
								: `${monthsPadding[0]}px ${monthsPadding[1]}px`,
					}
					return (
						<div key={year} className="year" style={yearStyle}>
							<div className="year-name" style={yearNameStyle}>
								{year}
							</div>
							<div className="year-months" style={yearMonthsStyle}>
								{Array.from({ length: 12 }, (x, i) => i + 1).map(month => (
									<Month
										key={year + month}
										month={month}
										minDateStr={minDateStr}
										maxDateStr={maxDateStr}
										monthName={monthNames[month]}
										year={year}
										monthHeight={monthHeight}
										monthWidth={monthWidth}
										selectedRadius={selectedRadius}
										hoveredMonth={hoveredMonth}
										selectedMonths={selectedMonths}
										selectionStatus={status}
										currentStr={currentMonthStr}
										colors={colors}
										fonts={fonts}
										onMonthClick={onMonthClick}
										onMonthHover={onMonthHover}
									/>
								))}
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default Months
