import React, { CSSProperties } from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useMst } from "models/Root"

interface IProps {
	to?: string
	className?: string
	blank?: boolean
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	state?: any
	name?: string
	children?: React.ReactNode
	ariaLabel?: string
	style?: CSSProperties
	noLocalePrefix?: boolean
}

const InternalLink: React.FC<IProps> = ({
	to,
	children,
	className,
	onClick,
	blank,
	state,
	name,
	ariaLabel,
	style,
	noLocalePrefix,
}) => {
	const { global } = useMst()
	const formattedTo = to && !noLocalePrefix ? `/${global.locale}${to}` : to || "/"
	return to ? (
		<Link
			// @ts-ignore
			onClick={onClick}
			to={formattedTo}
			data-name={name}
			className={className}
			target={blank ? "_blank" : "_self"}
			rel={blank ? "noopener noreferrer" : ""}
			state={state}
			aria-label={ariaLabel}
			style={style}
		>
			{children}
		</Link>
	) : (
		<span onClick={onClick} className={className}>
			{children}
		</span>
	)
}

export default observer(InternalLink)
