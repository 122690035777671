import { defineMessages } from "react-intl"

export default defineMessages({
	history: {
		id: "app.containers.history",
		defaultMessage: "History",
	},
	wallet: {
		id: "app.containers.history.wallet",
		defaultMessage: "Wallet",
	},
	orders: {
		id: "app.containers.history.orders",
		defaultMessage: "Orders",
	},
	trades: {
		id: "app.containers.history.trades",
		defaultMessage: "Trading history",
	},
	deposits: {
		id: "app.containers.history.deposits",
		defaultMessage: "Deposits",
	},
	deposits_history: {
		id: "app.containers.history.deposits_history",
		defaultMessage: "Deposits history",
	},
	withdraws: {
		id: "app.containers.history.withdraws",
		defaultMessage: "Withdraws",
	},
	withdraws_history: {
		id: "app.containers.history.withdraws_history",
		defaultMessage: "Withdraws history",
	},
	orders_table_date: {
		id: "app.containers.history.orders_table.date",
		defaultMessage: "Date",
	},
	orders_table_type: {
		id: "app.containers.history.orders_table.type",
		defaultMessage: "Type",
	},
	orders_table_type_1: {
		id: "app.containers.history.orders_table.type.1",
		defaultMessage: "Sell",
	},
	orders_table_type_2: {
		id: "app.containers.history.orders_table.type.2",
		defaultMessage: "Buy",
	},
	orders_table_pair: {
		id: "app.containers.history.orders_table.pair",
		defaultMessage: "Pair",
	},
	orders_table_amount: {
		id: "app.containers.history.orders_table.amount",
		defaultMessage: "Amount",
	},
	orders_table_price: {
		id: "app.containers.history.orders_table.price",
		defaultMessage: "Price",
	},
	orders_table_value: {
		id: "app.containers.history.orders_table.value",
		defaultMessage: "Value",
	},
	orders_table_status: {
		id: "app.containers.history.orders_table.status",
		defaultMessage: "Status",
	},
	orders_table_state: {
		id: "app.containers.history.orders_table.orders_table_state",
		defaultMessage: "State",
	},
	orders_table_status_1: {
		id: "app.containers.history.orders_table.status.1",
		defaultMessage: "Active",
	},
	orders_table_status_2: {
		id: "app.containers.history.orders_table.status.2",
		defaultMessage: "Canceled",
	},
	orders_table_status_3: {
		id: "app.containers.history.orders_table.status.3",
		defaultMessage: "Done",
	},
	orders_table_action: {
		id: "app.containers.history.orders_table_action",
		defaultMessage: "Action",
	},
	orders_table_action_cancel: {
		id: "app.containers.history.orders_table_action.cancel",
		defaultMessage: "Cancel",
	},
	trades_table_total: {
		id: "app.containers.history.trades_table.total",
		defaultMessage: "Total",
	},
	amount_filled: {
		id: "app.containers.history.amount_filled",
		defaultMessage: "Amount Filled",
	},
	avg_price: {
		id: "app.containers.history.avg_price",
		defaultMessage: "Avg Price",
	},
	value_filled: {
		id: "app.containers.history.value_filled",
		defaultMessage: "Value Filled",
	},
	fee_filled: {
		id: "app.containers.history.fee_filled",
		defaultMessage: "Fee Filled",
	},
	trades_table_side: {
		id: "app.containers.history.trades_table.side",
		defaultMessage: "Side",
	},
	trades_table_fee: {
		id: "app.containers.history.trades_table.fee",
		defaultMessage: "Fee",
	},
	deposits_table_currency: {
		id: "app.containers.history.deposits_table.currency",
		defaultMessage: "Currency",
	},
	deposits_table_transaction: {
		id: "app.containers.history.deposits_table.transaction_id",
		defaultMessage: "Transaction ID",
	},
	deposits_table_channel: {
		id: "app.containers.history.deposits_table.channel",
		defaultMessage: "Channel",
	},
	withdraws_table_action: {
		id: "app.containers.history.withdraws_table_action",
		defaultMessage: "Action",
	},
	withdraws_table_confirm_cancel: {
		id: "app.containers.history.withdraws_table_confirm_cancel",
		defaultMessage: "Cancel withdraw ?",
	},
	confirmation_status: {
		id: "app.containers.history.confirmation_status",
		defaultMessage: "confirmations {confirmations} of {required_confirmations}",
	},
	withdraw_comment: {
		id: "app.containers.history.withdraw_comment",
		defaultMessage: "Reason:",
	},
	trigger_orders: {
		id: "app.containers.history.trigger_orders",
		defaultMessage: "Trigger Orders",
	},
	active_orders: {
		id: "app.containers.orders.header",
		defaultMessage: "Open Orders",
	},
	active_orders_pair: {
		id: "app.containers.orders.pair",
		defaultMessage: "Pair",
	},
	active_orders_side: {
		id: "app.containers.orders.side",
		defaultMessage: "Side",
	},
	instrument: {
		id: "app.containers.orders.instrument",
		defaultMessage: "Instrument",
	},
	order_type: {
		id: "app.containers.orders.order_type",
		defaultMessage: "Order Type",
	},
	order_value: {
		id: "app.containers.orders.order_value",
		defaultMessage: "Order Value",
	},
	order_price: {
		id: "app.containers.orders.order_price",
		defaultMessage: "Order Price",
	},
	order_qty: {
		id: "app.containers.orders.order_qty",
		defaultMessage: "Order Q-ty",
	},
	filled_qty: {
		id: "app.containers.orders.filled_qty",
		defaultMessage: "Filled Q-ty",
	},
	unfilled_qty: {
		id: "app.containers.orders.unfilled_qty",
		defaultMessage: "Unfilled Q-ty",
	},
	order_time: {
		id: "app.containers.orders.order_time",
		defaultMessage: "Order Time",
	},
	no_open_orders: {
		id: "app.containers.orders.no_open_orders",
		defaultMessage: "You have no open orders.",
	},
	no_closed_orders: {
		id: "app.containers.orders.no_closed_orders",
		defaultMessage: "You have no closed orders.",
	},
	filled_value: {
		id: "app.containers.orders.filled_value",
		defaultMessage: "Filled Value",
	},
	filled_price: {
		id: "app.containers.orders.filled_price",
		defaultMessage: "Filled Price",
	},
	trading_fee: {
		id: "app.containers.orders.trading_fee",
		defaultMessage: "Trading Fee",
	},
	filled_time: {
		id: "app.containers.orders.filled_time",
		defaultMessage: "Filled Time",
	},
	currency: {
		id: "app.containers.orders.currency",
		defaultMessage: "Currency",
	},
	borrowed_amount: {
		id: "app.containers.orders.borrowed_amount",
		defaultMessage: "Borrowed Amount",
	},
	hourly_borrow_rate: {
		id: "app.containers.orders.hourly_borrow_rate",
		defaultMessage: "Hourly Borrow Rate",
	},
	repay: {
		id: "app.containers.orders.repay",
		defaultMessage: "Repay",
	},
	max_borrowed_amount: {
		id: "app.containers.orders.max_borrowed_amount",
		defaultMessage: "Max. Borrowing Amount",
	},
	no_borrows: {
		id: "app.containers.orders.no_borrows",
		defaultMessage: "You have no borrows.",
	},
	available: {
		id: "app.containers.orders.available",
		defaultMessage: "Available",
	},
	total: {
		id: "app.containers.orders.total",
		defaultMessage: "Total",
	},
	debt: {
		id: "app.containers.orders.debt",
		defaultMessage: "Debt",
	},
	index_price: {
		id: "app.containers.orders.index_price",
		defaultMessage: "Index Price",
	},
	index_sum: {
		id: "app.containers.orders.index_sum",
		defaultMessage: "Index Sum",
	},
	sell_order: {
		id: "app.containers.orders.sell_order",
		defaultMessage: "Sell Order",
	},
	buy_order: {
		id: "app.containers.orders.buy_order",
		defaultMessage: "Buy Order",
	},
	category: {
		id: "app.containers.orders.category",
		defaultMessage: "Category",
	},
	trigger_price: {
		id: "app.containers.orders.trigger_price",
		defaultMessage: "Trigger price",
	},
	no_funds: {
		id: "app.containers.orders.no_funds",
		defaultMessage: "You have no funds.",
	},
	active_orders_filled: {
		id: "app.containers.orders.filled",
		defaultMessage: "Filled",
	},
	active_orders_received: {
		id: "app.containers.orders.received",
		defaultMessage: "Received under the deal",
	},
	order_id: {
		id: "app.containers.orders.order_id",
		defaultMessage: "Order ID",
	},
	active_orders_type: {
		id: "app.containers.orders.type",
		defaultMessage: "Type",
	},
	active_orders_price: {
		id: "app.containers.orders.price",
		defaultMessage: "Price",
	},
	active_orders_trading_price: {
		id: "app.containers.orders.trading_price",
		defaultMessage: "Trading Price",
	},
	active_orders_transaction_fee: {
		id: "app.containers.orders.transaction_fee",
		defaultMessage: "Transaction Fee",
	},
	active_orders_amount: {
		id: "app.containers.orders.amount",
		defaultMessage: "Amount",
	},
	active_orders_original_amount: {
		id: "app.containers.orders.active_orders_original_amount",
		defaultMessage: "Original amount",
	},
	active_orders_value: {
		id: "app.containers.orders.value",
		defaultMessage: "Value",
	},
	active_orders_date: {
		id: "app.containers.orders.date",
		defaultMessage: "Date",
	},
	active_orders_total: {
		id: "app.containers.orders.active_orders_total",
		defaultMessage: "Order Value",
	},
	trigger_condition: {
		id: "app.containers.orders.trigger_condition",
		defaultMessage: "Trigger",
	},
	active_orders_action: {
		id: "app.containers.orders.action",
		defaultMessage: "Action",
	},
	active_orders_type_1: {
		id: "app.containers.orders.type_1",
		defaultMessage: "sell",
	},
	active_orders_type_2: {
		id: "app.containers.orders.type_2",
		defaultMessage: "buy",
	},
	active_orders_action_cancel: {
		id: "app.containers.history.orders_table_action.cancel",
		defaultMessage: "Cancel",
	},
	active_orders_action_cancel_tooltip: {
		id: "app.containers.history.orders_table_action.cancel.tooltip",
		defaultMessage: "Cancel order",
	},
	active_orders_button_cancel: {
		id: "app.containers.orders.active_orders_button_cancel",
		defaultMessage: "Cancel",
	},
	active_orders_button_confirm: {
		id: "app.containers.orders.button_confirm",
		defaultMessage: "Confirm",
	},
	active_orders_modal_confirm: {
		id: "app.containers.orders.modal_confirm",
		defaultMessage: "Cancel order?",
	},
	active_orders_modal_cancel_all: {
		id: "app.containers.orders.modal_cancel_all",
		defaultMessage: "Cancel all?",
	},
	active_orders_modal_cancel_body: {
		id: "app.containers.orders.modal_cancel_body",
		defaultMessage: "You are going to cancel an open order. Please confirm action.",
	},
	active_orders_modal_cancel_all_body: {
		id: "app.containers.orders.modal_cancel_all_body",
		defaultMessage: "You are about to cancel all open orders. Please confirm action",
	},
	active_orders_modal_cancel_all_current: {
		id: "app.containers.orders.modal_cancel_all_current",
		defaultMessage: "Cancel current orders?",
	},
	active_orders_modal_cancel_all_current_body: {
		id: "app.containers.orders.modal_cancel_all_current_body",
		defaultMessage: "You are going to cancel orders for all pairs. Please confirm the action.",
	},
	order_was_cancelled: {
		id: "app.containers.orders.order_was_cancelled",
		defaultMessage: "Order was cancelled",
	},
	orders_history_widget_login_msg: {
		id: "app.components.exchange.orders_history_widget_login_msg",
		defaultMessage: "If you want to see your orders history, please {ref1} or {ref2}",
	},
	positions_history_login_msg: {
		id: "app.components.exchange.positions_history_login_msg",
		defaultMessage: "If you want to see your positions, please {ref1} or {ref2}",
	},
	active_orders_widget_login_msg: {
		id: "app.components.exchange.active_orders_widget_login_msg",
		defaultMessage: "Please {ref1} or {ref2} first",
	},
	whole_history: {
		id: "app.components.exchange.whole_history",
		defaultMessage: "Whole History",
	},
	order_history: {
		id: "app.components.exchange.orders_history",
		defaultMessage: "Order History",
	},
	status: {
		id: "app.components.exchange.orders_history.status",
		defaultMessage: "Status",
	},
	state: {
		id: "app.components.exchange.orders_history.state",
		defaultMessage: "State",
	},
	stop_limit_order_warn_lower: {
		id: "app.components.exchange.orders_history.stop_limit_order_warn_lower",
		defaultMessage:
			"If the last price falls to or below {stop_price}, an order will be placed to {side} {amount} at {price}",
	},
	stop_limit_order_warn_higher: {
		id: "app.components.exchange.orders_history.stop_limit_order_warn_higher",
		defaultMessage:
			"If the last price is equal or higher {stop_price}, an order will be placed to {side} {amount} at {price}",
	},
	cancel_all: {
		id: "app.containers.orders.cancel_all",
		defaultMessage: "Close all",
	},
	cancel_all_orders: {
		id: "app.containers.orders.cancel_all_orders",
		defaultMessage: "Cancel all orders",
	},
	orders_are_cancelled: {
		id: "app.components.exchange.orders_history.orders_are_cancelled",
		defaultMessage: "Orders are cancelled",
	},
	all_orders_are_cancelled: {
		id: "app.components.exchange.orders_history.all_orders_are_cancelled",
		defaultMessage: "All orders are cancelled",
	},
	show_all: {
		id: "app.containers.orders.show_all",
		defaultMessage: "Show All",
	},
	all_orders_window_title: {
		id: "app.containers.orders.all_orders_window_title",
		defaultMessage: "All Orders",
	},
	operation_buy: {
		id: "app.containers.history.operation_buy",
		defaultMessage: "Buy",
	},
	operation_sell: {
		id: "app.containers.history.operation_sell",
		defaultMessage: "Sell",
	},
	borrows: {
		id: "app.containers.history.borrows",
		defaultMessage: "Borrows",
	},
	repayments: {
		id: "app.containers.history.repayments",
		defaultMessage: "Repayments",
	},
	interests: {
		id: "app.containers.history.interests",
		defaultMessage: "Interests",
	},
	transfers: {
		id: "app.containers.history.transfers",
		defaultMessage: "Margin Transfers",
	},
	margin_calls: {
		id: "app.containers.history.margin_calls",
		defaultMessage: "Margin Calls",
	},
	liquidations: {
		id: "app.containers.history.liquidations",
		defaultMessage: "Liquidations",
	},
	borrows_amount: {
		id: "app.containers.history.borrows.amount",
		defaultMessage: "Amount",
	},
	borrows_pair: {
		id: "app.containers.history.borrows.pair",
		defaultMessage: "Pair",
	},
	borrows_type: {
		id: "app.containers.history.borrows.type",
		defaultMessage: "Type",
	},
	borrows_repaid: {
		id: "app.containers.history.borrows.repaid",
		defaultMessage: "Repaid",
	},
	borrows_interest_paid: {
		id: "app.containers.history.borrows.interest_paid",
		defaultMessage: "Interest Paid",
	},
	borrows_closed_at: {
		id: "app.containers.history.borrows.closed_at",
		defaultMessage: "Closed At",
	},
	interests_amount: {
		id: "app.containers.history.interests.amount",
		defaultMessage: "Amount of interest",
	},
	interests_borrowed: {
		id: "app.containers.history.interests.borrowed",
		defaultMessage: "Borrowed",
	},
	interests_pair: {
		id: "app.containers.history.interests.pair",
		defaultMessage: "Pair",
	},
	interests_daily_rate: {
		id: "app.containers.history.interests.interests_daily_rate",
		defaultMessage: "Daily Interest Rate",
	},
	margin_calls_amount: {
		id: "app.containers.history.margin_calls.amount",
		defaultMessage: "Amount",
	},
	margin_calls_type: {
		id: "app.containers.history.margin_calls.type",
		defaultMessage: "Type",
	},
	margin_calls_level: {
		id: "app.containers.history.margin_calls.level",
		defaultMessage: "Margin Level",
	},
	margin_calls_sent_at: {
		id: "app.containers.history.margin_calls.sent_at",
		defaultMessage: "Send Status",
	},
	margin_calls_content: {
		id: "app.containers.history.margin_calls.content",
		defaultMessage: "Content",
	},
	repayments_principal_amount: {
		id: "app.containers.history.repayments.principal_amount",
		defaultMessage: "Principal Amount",
	},
	repayments_interest: {
		id: "app.containers.history.repayments.interest",
		defaultMessage: "Interest",
	},
	repayments_total: {
		id: "app.containers.history.repayments.total",
		defaultMessage: "Total",
	},
	repayments_type: {
		id: "app.containers.history.repayments.type",
		defaultMessage: "Type",
	},
	transfers_coin: {
		id: "app.containers.history.transfers.coin",
		defaultMessage: "Coin",
	},
	transfers_amount: {
		id: "app.containers.history.transfers.amount",
		defaultMessage: "Amount",
	},
	transfers_direction: {
		id: "app.containers.history.transfers.direction",
		defaultMessage: "Direction",
	},
	liquidations_currency: {
		id: "app.containers.history.liquidations.currency",
		defaultMessage: "Currency",
	},
	funds: {
		id: "app.containers.history.funds",
		defaultMessage: "Funds",
	},
	funds_currency: {
		id: "app.containers.history.funds.currency",
		defaultMessage: "Currency",
	},
	funds_available: {
		id: "app.containers.history.funds.available",
		defaultMessage: "Available",
	},
	funds_total: {
		id: "app.containers.history.funds.total",
		defaultMessage: "Total",
	},
	funds_debt: {
		id: "app.containers.history.funds.debt",
		defaultMessage: "Debt",
	},
	funds_position: {
		id: "app.containers.history.funds.position",
		defaultMessage: "Position",
	},
	funds_index_price: {
		id: "app.containers.history.funds.index_price",
		defaultMessage: "Index Price",
	},
	funds_liq_price: {
		id: "app.containers.history.funds.liq_price",
		defaultMessage: "Liq. Price",
	},
})
