export enum ETickersQuotedEnum {
	FIAT = "FIAT",
	FAVORITES = "Favorites",
}

export interface ITickerWS {
	symbol: string;
	close: number;
	base_volume: number;
	quote_volume: number;
	change_percent: number;
	high: number;
	low: number;
	bid: number;
	ask: number;
}
