import React from "react"
import cn from "classnames"
import Skeleton from "react-loading-skeleton"

import styles from "components/NewUI/Terminal/FunctionalPanel/MarginSelector/index.module.scss"

import MarginSelectorArrow from "assets/icons/terminal/MarginSelectorArrow"

type TProps = {
	isLoaded?: boolean
	disabled?: boolean
	value: number
	onClick?: () => void
}

const MarginSelector: React.FC<TProps> = ({ isLoaded, disabled, value, onClick }) => (
	<div
		className={cn(styles.marginSelector, {
			[styles.marginSelector__disabled]: isLoaded && disabled,
		})}
	>
		<div className={styles.marginSelector__selectorWrapper}>
			{isLoaded ? (
				<button
					onClick={onClick}
					type="button"
					disabled={disabled}
					className={styles.marginSelector__selector}
				>
					<div className={styles.marginSelector__selectorContent}>
						<p className={styles.marginSelector__selectorText}>Cross</p>

						<p className={styles.marginSelector__selectorValueText}>{value}x</p>
					</div>

					<MarginSelectorArrow />
				</button>
			) : (
				<div className={styles.marginSelector__selectorSkeleton}>
					<Skeleton height={40} borderRadius={8} />
				</div>
			)}
		</div>
	</div>
)

export default MarginSelector
