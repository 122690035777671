import { EOrderSide, EOrderType, TOrderStatus } from "./orders"

export type TBybitResponse<Result> = {
	result: Result
	retCode: number
	retExtInfo: any
	retMsg: string
	time: number
}

export enum EBybitMessageTopic {
	TICKERS = "tickers",
	ORDERBOOK = "orderbook",
	PUBLIC_TRADE = "publicTrade",
	KLINE = "kline",
	ORDER = "order",
	WALLET = "wallet",
}

export enum EBybitMessageType {
	SNAPSHOT = "snapshot",
	DELTA = "delta",
}

export type TBybitOrderbook = {
	a: [string, string][]
	b: [string, string][]
	s: string
	seq: number
	u: number
	ts?: number
	ctsts?: number
}

export type TBybitWebSocketTrade = {
	T: number // Timestamp
	s: string // Symbol
	S: "Buy" | "Sell" // Side
	v: string // Trade size
	p: string // Trade price
	L: string // Price change direction
	i: string // Trade ID
	BT: boolean // Block trade
}

export type TBybitWebSocketWalletCoin = {
	coin: string
	equity: string
	usdValue: string
	walletBalance: string
	availableToWithdraw: string
	availableToBorrow: string
	borrowAmount: string
	accruedInterest: string
	totalOrderIM: string
	totalPositionIM: string
	totalPositionMM: string
	unrealisedPnl: string
	cumRealisedPnl: string
	bonus: string
	collateralSwitch: boolean
	marginCollateral: boolean
	locked: string
	spotHedgingQty: string
}

export type TBybitWebSocketWallet = {
	accountIMRate: string
	accountMMRate: string
	totalEquity: string
	totalWalletBalance: string
	totalMarginBalance: string
	totalAvailableBalance: string
	totalPerpUPL: string
	totalInitialMargin: string
	totalMaintenanceMargin: string
	coin: TBybitWebSocketWalletCoin[]
	accountLTV: string
	accountType: "UNIFIED" | "CONTRACT" | "SPOT"
}

export type TBybitWebSocketOpenOrder = {
	symbol: string
	orderId: string
	side: EOrderSide
	orderType: EOrderType
	cancelType: string
	price: string
	qty: string
	orderIv: string
	timeInForce: string
	orderStatus: TOrderStatus
	orderLinkId: string
	lastPriceOnCreated: string
	reduceOnly: boolean
	leavesQty: string
	leavesValue: string
	cumExecQty: string
	cumExecValue: string
	avgPrice: string
	blockTradeId: string
	positionIdx: number
	cumExecFee: string
	createdTime: string
	updatedTime: string
	rejectReason: string
	stopOrderType: string
	tpslMode: string
	triggerPrice: string
	takeProfit: string
	stopLoss: string
	tpTriggerBy: string
	slTriggerBy: string
	tpLimitPrice: string
	slLimitPrice: string
	triggerDirection: number
	triggerBy: string
	closeOnTrigger: boolean
	category: string
	placeType: string
	smpType: string
	smpGroup: number
	smpOrderId: string
	feeCurrency: string
}

export type TBybitTrade = {
	execId: string
	symbol: string
	price: string
	size: string
	side: "Buy" | "Sell"
	time: string
	isBlockTrade: boolean
}

export type TBybitTicker = {
	symbol: string
	lastPrice: string
	indexPrice: string
	markPrice: string
	prevPrice24h: string
	price24hPcnt: string
	highPrice24h: string
	lowPrice24h: string
	prevPrice1h: string
	usdIndexPrice: string
	openInterest: string
	openInterestValue: string
	turnover24h: string
	volume24h: string
	fundingRate: string
	nextFundingTime: string
	predictedDeliveryPrice: string
	basisRate: string
	deliveryFeeRate: string
	deliveryTime: string
	ask1Size: string
	bid1Price: string
	ask1Price: string
	bid1Size: string
	basis: string
	preOpenPrice?: string
	preQty?: string
	curPreListingPhase?: string
}

export type TBybitCategory = "spot" | "linear" | "inverse" | "option"

export interface IBybitGetOrderbookQuery {
	category: TBybitCategory
	symbol: string
	limit?: number
}

export interface IBybitGetRecentTradesQuery {
	category: TBybitCategory
	symbol?: string
	baseCoin?: string
	optionType?: string
	limit?: number
}

export type TBybitGetRecentTradesResponse = {
	category: TBybitCategory
	list: TBybitTrade[]
}

export interface IBybitGetTickersQuery {
	category: TBybitCategory
	symbol?: string
	baseCoin?: string
	expDate?: string
}

export type TBybitGetTickersResponse = {
	category: TBybitCategory
	list: TBybitTicker[]
}

export type TBybitLeverageFilter = {
	minLeverage: string
	maxLeverage: string
	leverageStep: string
}

export type TBybitLotSizeFilter = {
	basePrecision: string
	quotePrecision: string
	minOrderQty: string
	maxOrderQty: string
	minOrderAmt: string
	maxOrderAmt: string
}

export type TBybitPriceFilter = {
	tickSize: string
}

export type TBybitRiskParameters = {
	limitParameter: string
	marketParameter: string
}

export interface TBybitInstrumentBase {
	symbol: string
	baseCoin: string
	quoteCoin: string
	status: string
	lotSizeFilter: TBybitLotSizeFilter
	priceFilter: TBybitPriceFilter
}

export interface TBybitInstrumentSpot extends TBybitInstrumentBase {
	innovation: string
	marginTrading: string
	riskParameters: TBybitRiskParameters
}

export interface TBybitInstrumentLinear extends TBybitInstrumentBase {
	contractType: string
	launchTime: string
	deliveryTime: string
	deliveryFeeRate: string
	priceScale: string
	leverageFilter: TBybitLeverageFilter
	unifiedMarginTrade: boolean
	fundingInterval: number
	settleCoin: string
	copyTrading: string
	upperFundingRate: string
	lowerFundingRate: string
	isPreListing?: boolean
	preListingInfo?: TBybitPreListingInfo | null
}

export type TBybitPreListingPhase = {
	phase: string
	startTime: string
	endTime: string
}

export type TBybitAuctionFeeInfo = {
	auctionFeeRate: string
	takerFeeRate: string
	makerFeeRate: string
}

export type TBybitPreListingInfo = {
	curAuctionPhase: string
	phases: TBybitPreListingPhase[]
	auctionFeeInfo: TBybitAuctionFeeInfo
}

export interface IBybitGetInstrumentsInfoQuery {
	category: TBybitCategory
	symbol?: string
	status?: string
	baseCoin?: string
	limit?: number
	cursor?: string
}

export type TBybitGetInstrumentsInfoResponse = {
	category: TBybitCategory
	nextPageCursor: string
	list: TBybitInstrumentLinear[] | TBybitInstrumentSpot[]
}

export type TBybitChartInterval =
	| "1"
	| "3"
	| "5"
	| "15"
	| "30"
	| "60"
	| "120"
	| "240"
	| "360"
	| "720"
	| "D"
	| "M"
	| "W"

export interface IBybitGetChartDataQuery {
	category?: TBybitCategory
	symbol: string
	interval: TBybitChartInterval
	start?: number
	end?: number
	limit?: number
}

export type TBybitChartData = {
	category: TBybitCategory
	symbol: string
	list: string[][]
}

export type TBybitGetInstrumentsInfoSpotResponse = {
	category: TBybitCategory
	nextPageCursor?: string
	list: TBybitInstrumentSpot[]
}

export type TBybitWsCredentials = {
	api_key: string
	expires: number
	signature: string
}
