import React from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { routes } from "constants/routing"
import financeMessages from "messages/finance"
import commonMessages from "messages/common"

import Switcher from "components/redesigned/Switcher"

import IconOverview from "assets/icons/menu/overview.svg"
import IconDatabase from "assets/icons/menu/database.svg"
import IconPercent from "assets/icons/menu/percent.svg"
import IconDeposit from "assets/icons/menu/deposit.svg"
import IconWithdrawal from "assets/icons/menu/withdrawal.svg"
import IconTransfer from "assets/icons/menu/transfer.svg"
import IconPieChart from "assets/icons/menu/pie-chart.svg"
import IconClockRewind from "assets/icons/menu/clock-rewind.svg"
import MenuSections from "../components/MenuSections"

const Wallet: React.FC = () => {
	const { formatMessage } = useIntl()
	const navigate = useNavigate()

	//TODO: translate
	return (
		<>
			<Switcher
				items={[
					{ key: "wallet", label: "Wallet" },
					{ key: "account", label: "Account" },
				]}
				selected="wallet"
				full
				onClick={() => navigate("")}
			/>
			<MenuSections
				sections={[
					{
						items: [
							{
								route: "",
								caption: "Overview",
								icon: IconOverview,
								disabled: true,
							},
						],
					},
					{
						title: "Accounts",
						items: [
							{
								route: routes.profile.wallets,
								query: { type: "FUND" },
								caption: "Funding Account",
								icon: IconDatabase,
								exact: true,
							},
							{
								route: routes.profile.wallets,
								query: { type: "UNIFIED" },
								caption: "Unified Trading",
								icon: IconPercent,
								exact: true,
							},
						],
					},
					{
						title: "Manage Crypto",
						items: [
							{
								route: routes.profile.getDepositCurrency("USDT"),
								template: routes.profile.createDepositCurrency,
								caption: formatMessage(financeMessages.deposit),
								icon: IconDeposit,
							},
							{
								route: routes.profile.getWithdrawCurrency("BTC"),
								template: routes.profile.createWithdrawCurrency,
								caption: formatMessage(commonMessages.withdraw),
								icon: IconWithdrawal,
							},
							// {
							// 	route: routes.transfers.root,
							// 	caption: formatMessage(financeMessages.transfer),
							// 	icon: IconTransfer,
							// },
							{
								route: routes.financeHistory.root,
								caption: formatMessage(commonMessages.history),
								icon: IconClockRewind,
							},
						],
					},
					{
						title: "Analysis",
						items: [
							{
								route: "/profile/wallets/test",
								caption: "UTA P&L",
								icon: IconPieChart,
								disabled: true,
							},
						],
					},
				]}
			/>
		</>
	)
}

export default Wallet
