/* eslint-disable react/jsx-no-useless-fragment,no-void */
import React, { PropsWithChildren, useEffect, useRef, useState } from "react"

type TProps = {
	destination?: number | string | Date
	delayMs?: number
	render?: (value: number) => string | React.ReactNode
	hideOnFinish?: boolean
	onFinish?: () => void
}

const Counter: React.FC<PropsWithChildren<TProps>> = ({
	destination,
	delayMs = 1000,
	render,
	hideOnFinish,
	children,
	onFinish,
}) => {
	const timerRef = useRef<ReturnType<typeof setInterval>>()
	const [value, setValue] = useState(0)

	useEffect(() => void (destination && value === 0 && onFinish?.()), [value, destination])
	useEffect(() => void (value < 1 && clearInterval(timerRef.current)), [value])

	useEffect(() => {
		let newValue = 0
		if (typeof destination === "number") {
			newValue = destination
		} else if (typeof destination === "string") {
			const date = new Date(destination)
			newValue = Math.floor((date.getTime() - Date.now()) / 1000)
		} else if (destination instanceof Date) {
			newValue = Math.floor((destination.getTime() - Date.now()) / 1000)
		}
		newValue = Math.max(newValue, 0)
		setValue(newValue)
		clearInterval(timerRef.current)
		if (newValue > 0)
			timerRef.current = setInterval(() => setValue(val => Math.max(val - 1, 0)), delayMs)
	}, [destination?.toString()])

	if (value < 1 && hideOnFinish) return null
	if (value < 1 && children) return <>{children}</>
	return <>{render ? render(value) : value}</>
}

export default Counter
