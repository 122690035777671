import {
	applySnapshot,
	cast,
	flow,
	getParent,
	getSnapshot,
	Instance,
	types as t,
} from "mobx-state-tree"

import config from "helpers/config"
import cache from "helpers/cache"
import ExchangeService from "services/ExchangeService"
import { FavoriteFilterTypeEnum, TradeActionsEnum, TradeTypeEnum } from "types/exchange"
import { ITickerWS, ETickersQuotedEnum } from "types/ticker"
import { FAVORITE_TICKERS_CACHE_KEY, TICKERS_LOW_LIQUIDITY_CACHE_KEY } from "utils/cacheKeys"
import { TickerModelNamesEnum } from "types/models"
import errorHandler from "utils/errorHandler"
import { SearchSymbolResultItem } from "charting_library/charting_library"
import { OrderTypeEnum } from "types/orders"
import { TBybitTicker } from "types/bybit";
import { IRootStore } from "./Root"

export const QUOTED_CURRENCIES_BASE = config.tickersQuotedCurrenciesBase
	.split(",")
	.filter(curr => !!curr)
export const QUOTED_CURRENCIES_FIAT = config.tickersQuotedCurrenciesFiat
	.split(",")
	.filter(curr => !!curr)
export const QUOTED_CURRENCIES_ALL = QUOTED_CURRENCIES_BASE.concat(QUOTED_CURRENCIES_FIAT).concat([
	ETickersQuotedEnum.FIAT,
	ETickersQuotedEnum.FAVORITES,
])

// const filterCross = (t: ITicker): boolean => (t.cross_margin_leverage ?? 0) > 0
// const filterIsolated = (t: ITicker): boolean => (t.isolated_margin_leverage ?? 0) > 0
// const mapQuotedCodes = (t: ITicker): string => t.quote_currency?.code ?? ""

const Currency = t.model({
	code: t.optional(t.string, ""),
	image_png: t.maybeNull(t.string),
	image_svg: t.maybeNull(t.string),
	name: t.optional(t.string, ""),
})
export type ICurrency = Instance<typeof Currency>

export const Ticker = t
	.model(TickerModelNamesEnum.TICKER, {
		symbol: t.identifier,
		price_precision: t.optional(t.number, 2),
		open: t.number,
		open_size: t.number,
		ask: t.number,
		ask_size: t.number,
		high: t.number,
		low: t.number,
		close: t.number,
		change: t.number,
		change_percent: t.number,
		turnover: t.number,
		volume: t.number,
		quote_coin: t.string,
		base_coin: t.string,
		is_favorite: t.maybe(t.boolean),

		amount_precision: t.optional(t.number, 0),
		base_currency: t.optional(Currency, {} as ICurrency),
		base_currency_code: t.optional(t.string, ""),
		base_volume: t.optional(t.number, 0),
		cross_margin_leverage: t.optional(t.number, 0),
		isolated_margin_leverage: t.optional(t.number, 0),
		is_defi: t.optional(t.boolean, false),
		is_enabled: t.optional(t.boolean, false),
		is_limit_enabled: t.optional(t.boolean, false),
		is_low: t.optional(t.boolean, false),
		is_market_enabled: t.optional(t.boolean, false),
		is_stop_limit_enabled: t.optional(t.boolean, false),
		label: t.optional(t.string, ""),
		maximum_order_size: t.optional(t.number, 0),
		minimum_order_size: t.optional(t.number, 0),
		minimum_order_value: t.optional(t.number, 0),
		quote_currency: t.optional(Currency, {} as ICurrency),
		quote_currency_code: t.optional(t.string, ""),
		quote_volume: t.optional(t.number, 0),
		updated_at: t.optional(t.string, ""),
	})
	.views(self => ({
		get changePercent() {
			const fixedPercent = +self.change_percent.toFixed(2)
			return fixedPercent < 0.01 && fixedPercent > -0.01 ? 0 : fixedPercent
		},
		get fullName() {
			return `${self.base_coin}/${self.quote_coin}`
		},
	}))
	.actions(self => ({
		setClose(nextClose: number) {
			self.close = nextClose
		},
		setIsFavorite(nextIsFavorite: boolean) {
			const cachedTickers = cache.getItem<string[]>(FAVORITE_TICKERS_CACHE_KEY, [])
			const favoriteSet = new Set(cachedTickers)

			if (nextIsFavorite) favoriteSet.add(self.symbol)
			else favoriteSet.delete(self.symbol)

			cache.setItem(FAVORITE_TICKERS_CACHE_KEY, Array.from(favoriteSet))
			self.is_favorite = nextIsFavorite
		},
	}))
export type ITicker = Instance<typeof Ticker>

const TradeActionsEnumType = t.enumeration("TradeActionsEnum", Object.values(TradeActionsEnum))
const OrderTypeEnumType = t.enumeration("OrderTypeEnum", Object.values(OrderTypeEnum))
const TradeTypeEnumType = t.enumeration("TradeTypeEnum", Object.values(TradeTypeEnum))

const TickersFilter = t
	.model("TickersFilter", {
		lowLiquidity: t.optional(t.boolean, cache.getItem(TICKERS_LOW_LIQUIDITY_CACHE_KEY, true)),
		tradeType: t.optional(TradeTypeEnumType, TradeTypeEnum.SPOT),
		quotedCurrency: t.optional(t.string, ""),
		quotedCurrencySpotHeader: t.optional(t.string, ""),
		quotedCurrencyMarginHeader: t.optional(t.string, ""),
		sort: t.optional(t.string, "symbol.asc"), // [name.asc|desc]
		search: t.optional(t.string, ""),
		tradeAction: t.optional(TradeActionsEnumType, TradeActionsEnum.BUY),
		tradeMode: t.optional(OrderTypeEnumType, OrderTypeEnum.LIMIT),
	})
	.actions(self => {
		const initialState = getSnapshot(self)
		return {
			resetState() {
				applySnapshot(self, initialState)
			},
		}
	})
	.actions(self => ({
		setLowLiquidity(nextLowLiquidity: boolean) {
			self.lowLiquidity = nextLowLiquidity
			cache.setItem(TICKERS_LOW_LIQUIDITY_CACHE_KEY, nextLowLiquidity)
		},
		setTradeType(nextTradeType: TradeTypeEnum) {
			self.tradeType = nextTradeType
		},
		setSort(nextSort: string) {
			self.sort = nextSort
		},
		setQuotedCurrency(nextQuotedCurrency: string) {
			self.quotedCurrency = QUOTED_CURRENCIES_ALL.includes(nextQuotedCurrency)
				? nextQuotedCurrency
				: QUOTED_CURRENCIES_BASE[0]
		},
		setQuotedCurrencySpotHeader(nextQuotedCurrency: string) {
			self.quotedCurrencySpotHeader = QUOTED_CURRENCIES_ALL.includes(nextQuotedCurrency)
				? nextQuotedCurrency
				: QUOTED_CURRENCIES_BASE[0]
		},
		setQuotedCurrencyMarginHeader(nextQuotedCurrency: string) {
			self.quotedCurrencyMarginHeader = QUOTED_CURRENCIES_ALL.includes(nextQuotedCurrency)
				? nextQuotedCurrency
				: QUOTED_CURRENCIES_BASE[0]
		},
		setSearch(nextSearch: string) {
			self.search = nextSearch
		},
		setTradeAction(nextTradeAction: TradeActionsEnum) {
			self.tradeAction = nextTradeAction
		},
		setTradeMode(nextTradeMode: OrderTypeEnum) {
			self.tradeMode = nextTradeMode
		},
	}))
const FavoriteFilterTypeEnumType = t.enumeration(
	"FavoriteFilterTypeEnum",
	Object.values(FavoriteFilterTypeEnum),
)

export const Tickers = t
	.model({
		list: t.optional(t.array(Ticker), []),
		isLoading: t.optional(t.boolean, false),
		favoriteFilterType: t.optional(FavoriteFilterTypeEnumType, FavoriteFilterTypeEnum.PERCENT),
		isLoaded: t.optional(t.boolean, false),
		filter: t.optional(TickersFilter, {}),
	})
	.actions(self => {
		const initialState = getSnapshot(self)
		return {
			resetState() {
				applySnapshot(self, initialState)
			},
		}
	})
	.actions(self => ({
		setFavoriteFilterType: (nextFavoriteFilterType: FavoriteFilterTypeEnum) => {
			self.favoriteFilterType = nextFavoriteFilterType
		},
	}))
	.views(self => ({
		get chartCompareSymbols(): SearchSymbolResultItem[] {
			return []
			// return self.list.map(t => ({
			// 	symbol: t.symbol.replace("_", "/"),
			// 	full_name: t.label,
			// 	description: `${t.base_currency.name}/${t.quote_currency.name}`,
			// 	exchange: config.department ?? "",
			// 	ticker: t.symbol,
			// 	type: "",
			// }))
		},
		get isMargin() {
			return self.filter.tradeType === TradeTypeEnum.CROSS
		},
		get hasLowLiquidity() {
			return false
			// return self.list.filter(t => t.is_low).length > 0
		},
		get favoriteList() {
			return self.list.filter(t => t.is_favorite)
		},
		get tickerList() {
			return self.list
		},
		get quoteList() {
			return Array.from(new Set(self.list.map(({ quote_coin }) => quote_coin)))
		},
		get formattedList(): ITicker[] {
			return []
			// const nextList = self.list.filter(t => {
			// 	const searchFilter = self.filter.search
			// 		? t.base_currency.code.toUpperCase().includes(self.filter.search.toUpperCase()) ||
			// 		  t.base_currency.name.toUpperCase().includes(self.filter.search.toUpperCase())
			// 		: true
			// 	const lowFilter = self.filter.lowLiquidity ? true : !t.is_low
			// 	const quoteFilter =
			// 		self.filter.quotedCurrency === ETickersQuotedEnum.FIAT
			// 			? QUOTED_CURRENCIES_FIAT.includes(t.quote_currency_code)
			// 			: self.filter.quotedCurrency === ETickersQuotedEnum.FAVORITES
			// 			? t.is_favorite
			// 			: t.quote_currency_code === self.filter.quotedCurrency
			//
			// 	return searchFilter && lowFilter && quoteFilter
			// })

			// const [sortName, sortValue] = self.filter.sort.split(".")
			// nextList.sort((ticker1: ITicker, ticker2: ITicker) => {
			// 	switch (sortName) {
			// 		case "symbol":
			// 			return sortValue === queryVars.asc
			// 				? ticker1.symbol.localeCompare(ticker2.symbol)
			// 				: ticker2.symbol.localeCompare(ticker1.symbol)
			// 		case "close":
			// 			return sortValue === queryVars.asc
			// 				? ticker1.close - ticker2.close
			// 				: ticker2.close - ticker1.close
			// 		case "quote_volume":
			// 			return sortValue === queryVars.asc
			// 				? ticker1.quote_volume - ticker2.quote_volume
			// 				: ticker2.quote_volume - ticker1.quote_volume
			// 		case "change_percent":
			// 			return sortValue === queryVars.asc
			// 				? ticker1.change_percent - ticker2.change_percent
			// 				: ticker2.change_percent - ticker1.change_percent
			// 		default:
			// 			return 0
			// 	}
			// })
			//
			// return nextList
		},
		get formattedListSpotHeader() {
			return []
			// const nextList = self.list.filter(t => {
			// 	const quoteFilter =
			// 		self.filter.quotedCurrencySpotHeader === ETickersQuotedEnum.FIAT
			// 			? QUOTED_CURRENCIES_FIAT.includes(t.quote_currency_code)
			// 			: t.quote_currency_code === self.filter.quotedCurrencySpotHeader
			//
			// 	return quoteFilter
			// })
			//
			// return nextList
		},
	}))
	.views(self => ({
		get quotedCurrencies(): Record<string, string[]> {
			return {
				[TradeTypeEnum.SPOT]: QUOTED_CURRENCIES_BASE,
				[TradeTypeEnum.CROSS]: [],
				[TradeTypeEnum.ISOLATED]: [],
			}
			// return {
			// 	[TradeTypeEnum.SPOT]: QUOTED_CURRENCIES_BASE,
			// 	[TradeTypeEnum.CROSS]: self.quotedCurrenciesCross,
			// 	[TradeTypeEnum.ISOLATED]: self.quotedCurrenciesIsolated,
			// }
		},
	}))
	.actions(self => ({
		updateTickersWS(tickers: ITickerWS[]) {
			const nextTickers = [...self.list]
			tickers.forEach(t => {
				const idx = nextTickers.findIndex(nt => nt.symbol === t.symbol)
				if (idx !== -1) {
					nextTickers[idx] = {
						...nextTickers[idx],
						...t,
					}
				}
			})
			self.list = cast(nextTickers)

			const account = getParent<IRootStore>(self).account
			account.updateBalancesValuations()
		},
		updateTickers(data: TBybitTicker) {
			const nextTickers: ITicker[] = self.list.map(ticker => {
				if (data.symbol === ticker.symbol) {
					return {
						...ticker,
						high: +data.highPrice24h,
						low: +data.lowPrice24h,
						turnover: +data.turnover24h,
						volume: +data.volume24h,
						close: +data.lastPrice,
						change: +data.prevPrice24h,
						change_percent: +data.price24hPcnt,
						// ??? prevPrice24h: +data.prevPrice24h,
						// ??? usdIndexPrice:
						// 	+data.usdIndexPrice !== 0 ? +data.usdIndexPrice : self.ticker?.usdIndexPrice || 0,
					}
				}
				return ticker
			})
			self.list = cast(nextTickers)
		},
	}))
	.actions(self => ({
		loadTickers: flow(function* () {
			try {
				self.isLoading = true
				const tickers: Awaited<ReturnType<typeof ExchangeService.getTickers>> =
					yield ExchangeService.getTickers("spot")

				const cachedTickers = cache.getItem<string[]>(FAVORITE_TICKERS_CACHE_KEY, [])
				self.list = cast(
					tickers
						.filter(({ base_coin, quote_coin }) => base_coin && quote_coin)
						.map(item => ({ ...item, is_favorite: cachedTickers.includes(item.symbol) })),
				)
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isLoading = false
				self.isLoaded = true
			}
		}),
		updateFavoritePair: flow(function* (symbol: string, isFavorite: boolean) {
			try {
				yield ExchangeService.updateFavoritePair(symbol, isFavorite)
			} catch (err) {
				errorHandler(err)
			}
		}),
	}))

export type ITickers = Instance<typeof Tickers>
