export enum RenderModuleEnum {
	MOBILE_APP = "mobile-app",
	PAYMENT_SERVICE = "payment-services",
	MARGIN = "margin",
	REFERRALS = "referrals",
	TRANSFERS = "transfers",
	NEWS = "news",
	CRYPTO_NEWS = "crypto-news",
	STORIES = "stories",
	HOME_STATS = "home-stats",
	SUPPORT_CENTER = "support-center",
	ALP_COIN = "alp-coin",
	GIVEAWAY = "giveaway",
}
