/* eslint-disable no-unused-expressions */
import React, { CSSProperties, UIEventHandler, useEffect, useRef, useState } from "react"

import {
	ESelectionStatus,
	ECalendarType,
	TColors,
	TFonts,
	TMonthNames,
	TOnHover,
	TPaddings,
	TOnSelect,
} from "../types"
import { getWeekEdges, maxStr, minStr } from "../utils"

import Day from "./day"

import "./style.scss"

export type TDaysData = {
	year: number
	month: number
	days: number
	lines: number
	firstDay: number
	lastDay: number
	top: number
	height: number
}

type TProps = {
	todayStr: string
	minDateStr: string
	maxDateStr: string
	data: TDaysData[]
	monthNames: TMonthNames
	range: boolean
	dayHeight: number
	daysPadding: TPaddings
	firstWeekDay: number
	selectedRadius: number
	calendarType: ECalendarType
	colors: TColors
	fonts: TFonts
	valueFromStr: string
	valueToStr: string
	onSelect?: TOnSelect
	onHover?: TOnHover
}

const Days: React.FC<TProps> = ({
	todayStr,
	minDateStr,
	maxDateStr,
	data,
	monthNames,
	range,
	dayHeight,
	daysPadding,
	firstWeekDay,
	selectedRadius,
	calendarType,
	colors,
	fonts,
	valueFromStr,
	valueToStr,
	onSelect,
	onHover,
}) => {
	const { monthName } = colors

	const daysScrollRef = useRef<HTMLDivElement>(null)
	const [daysScrollTop, setDaysScrollTop] = useState(0)
	const onDaysScroll: UIEventHandler<HTMLDivElement> = ({ target }) =>
		setDaysScrollTop((target as HTMLDivElement).scrollTop)
	const daysScrollHeight = daysScrollRef.current?.offsetHeight

	const scrollToValue = () => {
		let dayToScroll = todayStr
		if (valueFromStr >= minDateStr && valueFromStr <= maxDateStr) {
			dayToScroll = valueFromStr
		}
		if (dayToScroll >= minDateStr && dayToScroll <= maxDateStr) {
			const index = data.findIndex(
				({ year, month }) => new Date(dayToScroll) < new Date(year, month, 1),
			)
			if (index > 0)
				(daysScrollRef.current as HTMLDivElement).scrollTop = data[index].top - dayHeight
		}
	}
	useEffect(() => {
		scrollToValue()
	}, [])

	const daysRef = useRef<HTMLDivElement>(null)
	const [dayWidth, setDayWidth] = useState(0)
	const updateDayWidth = () => setDayWidth((daysRef.current as HTMLDivElement).clientWidth / 7)
	useEffect(() => {
		updateDayWidth()
		window.addEventListener("resize", updateDayWidth)
		return () => window.removeEventListener("resize", updateDayWidth)
	}, [])

	const [status, setStatus] = useState<ESelectionStatus>(ESelectionStatus.CLEAR)
	const [selectedDates, setSelectedDates] = useState<string[]>([])
	const [hoveredDates, setHoveredDates] = useState<string[]>([])
	const onDayClick = () => {
		// eslint-disable-next-line one-var
		let newStatus, newSelectedDates
		// eslint-disable-next-line default-case
		switch (status) {
			case ESelectionStatus.CLEAR:
			case ESelectionStatus.FULL:
				newStatus = range ? ESelectionStatus.FIRST : ESelectionStatus.FULL
				newSelectedDates = hoveredDates
				break
			case ESelectionStatus.FIRST:
				// eslint-disable-next-line no-case-declarations
				const dates = [...hoveredDates, ...selectedDates]
				newStatus = ESelectionStatus.FULL
				newSelectedDates = [minStr(...dates), maxStr(...dates)]
				break
		}
		setStatus(newStatus)
		setSelectedDates(newSelectedDates)
		onSelect?.({
			selected: newStatus === ESelectionStatus.FULL,
			value:
				range || calendarType !== ECalendarType.DAY
					? [new Date(newSelectedDates[0]), new Date(newSelectedDates[1])]
					: new Date(newSelectedDates[0]),
		})
	}
	const onDayHover = (date: string) => {
		let dates
		if (calendarType === ECalendarType.WEEK) {
			dates = getWeekEdges(date, firstWeekDay)
		} else {
			dates = [date, date]
		}
		setHoveredDates(dates)
	}
	const onDaysLeave = () => status !== ESelectionStatus.FIRST && setHoveredDates([])

	useEffect(() => {
		onHover?.(
			!hoveredDates.length
				? null
				: hoveredDates[0] !== hoveredDates[1]
				? [new Date(hoveredDates[0]), new Date(hoveredDates[1])]
				: new Date(hoveredDates[0]),
		)
	}, [hoveredDates])

	useEffect(() => {
		if (!valueFromStr) {
			setSelectedDates([])
			setStatus(ESelectionStatus.CLEAR)
		} else {
			setSelectedDates([valueFromStr, valueToStr])
			setStatus(ESelectionStatus.FULL)
		}
	}, [valueToStr, valueFromStr])

	const lastMonth = data[data.length - 1]
	const fullHeight = lastMonth ? lastMonth.top + lastMonth.height : 0

	const monthStyle: CSSProperties = {
		height: dayHeight,
		color: monthName,
		fontSize: fonts.daysMonth,
	}

	const daysStyle: CSSProperties = {
		height: fullHeight,
		margin:
			typeof daysPadding === "number" ? daysPadding : `${daysPadding[0]}px ${daysPadding[1]}px`,
	}

	return (
		<div ref={daysScrollRef} className="days-scroll" onScroll={onDaysScroll}>
			<div ref={daysRef} className="days" style={daysStyle} onMouseLeave={onDaysLeave}>
				{data.map((item, index) => {
					const { year, month, days, firstDay, lastDay, top, height } = item
					if (
						!daysScrollHeight ||
						top < daysScrollTop - height ||
						top > daysScrollTop + daysScrollHeight
					)
						return false
					const monthStr = `${year}-${month.toString().padStart(2, "0")}`
					return (
						<div key={monthStr} className="month" style={{ top }}>
							<div className="month-name" style={monthStyle}>
								{`${monthNames[month]}, ${year}`}
							</div>
							{Array.from({ length: days }, (x, i) => i + 1).map(day => (
								<Day
									key={monthStr + day}
									day={day}
									minDateStr={minDateStr}
									maxDateStr={maxDateStr}
									dayHeight={dayHeight}
									dayWidth={dayWidth}
									selectedRadius={selectedRadius}
									firstDay={firstDay}
									hoveredDates={hoveredDates}
									selectedDates={selectedDates}
									monthStr={monthStr}
									selectionStatus={status}
									todayStr={todayStr}
									colors={colors}
									fonts={fonts}
									onDayHover={onDayHover}
									onDayClick={onDayClick}
								/>
							))}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default Days
