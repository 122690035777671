import React, { CSSProperties, MouseEventHandler } from "react"

import { ESelectionStatus, TColors, TFonts } from "../types"
import { maxStr, minStr } from "../utils"

type TProps = {
	month: number
	monthName: string
	currentStr: string
	minDateStr: string
	maxDateStr: string
	hoveredMonth: string
	selectedMonths: string[]
	year: number
	selectionStatus: ESelectionStatus
	monthHeight: number
	monthWidth: number
	selectedRadius: number
	colors: TColors
	fonts: TFonts
	onMonthClick: MouseEventHandler<HTMLDivElement>
	onMonthHover: (monthStr: string) => void
}

const Month: React.FC<TProps> = ({
	month,
	monthName,
	currentStr,
	minDateStr,
	maxDateStr,
	hoveredMonth,
	selectedMonths,
	selectionStatus,
	year,
	monthHeight,
	monthWidth,
	selectedRadius,
	colors,
	fonts,
	onMonthClick,
	onMonthHover,
}) => {
	const {
		monthsMonthText,
		todayBorder,
		hoveredDayBackground,
		selectedHoveredDayBackground,
		selectedDayBackground,
		selectedDayText,
		betweenSelectedDaysBackground,
	} = colors

	const monthStr = `${year}-${month.toString().padStart(2, "0")}`
	const isActive =
		(minDateStr < monthStr || minDateStr.startsWith(monthStr)) &&
		(monthStr < maxDateStr || maxDateStr.startsWith(monthStr))
	const isCurrent = monthStr === currentStr
	const isHovered = selectionStatus !== ESelectionStatus.FIRST && monthStr === hoveredMonth
	const months =
		selectionStatus === ESelectionStatus.FIRST ? [hoveredMonth, ...selectedMonths] : selectedMonths
	const minMonth = minStr(...months)
	const maxMonth = maxStr(...months)
	const isBetween = monthStr >= minMonth && monthStr <= maxMonth
	const isFirstInRange = monthStr === minMonth
	const isLastInRange = monthStr === maxMonth

	const fitSize = Math.min(monthHeight, monthWidth)

	const monthStyle = {
		height: monthHeight,
	}

	const firstTextWrapperStyle: CSSProperties = {}
	if (isHovered) {
		firstTextWrapperStyle.backgroundColor = hoveredDayBackground
	} else if (isBetween) {
		firstTextWrapperStyle.backgroundColor = betweenSelectedDaysBackground
	}
	if (isFirstInRange || (!isBetween && isHovered)) {
		firstTextWrapperStyle.borderTopLeftRadius = selectedRadius
		firstTextWrapperStyle.borderBottomLeftRadius = selectedRadius
	}
	if (isLastInRange || (!isBetween && isHovered)) {
		firstTextWrapperStyle.borderTopRightRadius = selectedRadius
		firstTextWrapperStyle.borderBottomRightRadius = selectedRadius
	}

	const secondTextWrapperStyle: CSSProperties = {
		borderRadius: selectedRadius,
		fontSize: fonts.monthsMonth,
	}
	if (isFirstInRange || isLastInRange) {
		secondTextWrapperStyle.color = selectedDayText
		if (isHovered) {
			secondTextWrapperStyle.backgroundColor = selectedHoveredDayBackground
		} else {
			secondTextWrapperStyle.backgroundColor = selectedDayBackground
		}
	} else {
		secondTextWrapperStyle.color = monthsMonthText
	}

	const currentStyle: CSSProperties = {
		width: fitSize - 2,
		height: fitSize - 8,
		marginLeft: (fitSize - 2) / -2,
		marginTop: (fitSize - 8) / -2,
		borderColor: todayBorder,
		borderRadius: selectedRadius,
	}

	return (
		<div
			className={!isActive ? "inactive" : undefined}
			style={monthStyle}
			onClick={onMonthClick}
			onMouseEnter={() => onMonthHover(monthStr)}
		>
			<div style={firstTextWrapperStyle}>
				<div style={secondTextWrapperStyle}>{monthName}</div>
			</div>
			{isCurrent && <div className="current" style={currentStyle} />}
		</div>
	)
}

export default Month
