import React, { useCallback, useId, useMemo } from "react"
import { Range as ReactRange } from "react-range"
import { IRenderThumbParams, IRenderTrackParams } from "react-range/lib/types"
import cn from "classnames"

import styles from "./index.module.scss"

type TProps = {
	step?: number
	min?: number
	max?: number
	values: number[]
	setValues: (val: number[]) => void
	disabled?: boolean
	isPercent?: boolean
	isDarkerStyle?: boolean
	hideValue?: boolean
	eachStep?: boolean
	suffix?: string
	className?: string
}

const Range: React.FC<TProps> = ({
	step = 1,
	min = 0,
	max = 100,
	values = [0],
	setValues,
	disabled = false,
	isPercent = true,
	isDarkerStyle = false,
	hideValue = false,
	eachStep = false,
	suffix = "",
	className,
}) => {
	const id = useId()

	const currentValue = values[0]
	const currentPercentValue = ((currentValue - min) / (max - min)) * 100

	const stepsCount = Math.trunc((max - min) / step)

	const buttonsCount = eachStep ? stepsCount + 1 : isPercent ? 5 : 2
	const buttonInterval = (max - min) / (buttonsCount - 1)

	const labelsCount = eachStep ? stepsCount + 1 : 2
	const labelsInterval = (max - min) / (labelsCount - 1)

	const renderTrack = useCallback(
		({ props, children }: IRenderTrackParams) => (
			<div
				ref={props.ref}
				className={cn(styles.range__track, { [styles.range__track___disabled]: disabled })}
			>
				<div className={styles.range__trackList}>
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						className={styles.range__trackListRange}
					>
						<div
							className={styles.range__trackListRangeInner}
							style={{ width: `${currentPercentValue}%` }}
						/>
					</div>

					{Array(buttonsCount)
						.fill(null)
						.map((_, index) => {
							const buttonValue = buttonInterval * index + min
							const isChecked = buttonValue <= currentValue
							const label = isPercent
								? `${(100 / stepsCount) * (buttonValue - min)}%`
								: `${buttonValue}${suffix}`
							return (
								<button
									key={`range-${id}-dot-${label}`}
									type="button"
									className={cn(styles.range__trackButton, {
										[styles.range__trackButton___checked]: isChecked,
									})}
									disabled={disabled}
									aria-label={label}
									onClick={() => setValues([buttonValue])}
								/>
							)
						})}
				</div>

				{children}
			</div>
		),
		[currentValue, disabled],
	)

	// Function that renders the dot, we use useCallback for optimization.
	const renderThumb = useCallback(
		({ props }: IRenderThumbParams) => (
			<button type="button" {...props} key={props.key} className={styles.range__thumbWrapper}>
				{!hideValue && (
					<p className={styles.range__thumbText}>
						{Math.round(currentValue)}
						{isPercent ? "%" : suffix}
					</p>
				)}

				<div aria-label="Press to move" className={styles.range__thumb} />
			</button>
		),
		[currentValue],
	)

	return (
		<div className={cn(styles.range, { [styles.range___darker]: isDarkerStyle }, className)}>
			<ReactRange
				step={step}
				min={min}
				max={max}
				values={values}
				onChange={setValues}
				renderTrack={renderTrack}
				renderThumb={renderThumb}
			/>

			<div className={styles.range__percents}>
				{Array(labelsCount)
					.fill(null)
					.map((_, index) => {
						const labelValue = labelsInterval * index + min
						const label = isPercent
							? `${(100 / stepsCount) * (labelValue - min)}%`
							: `${labelValue}${suffix}`
						return (
							<span key={`range-${id}-label-${label}`} className={styles.range__percentText}>
								{label}
							</span>
						)
					})}
			</div>
		</div>
	)
}

export default Range
