import { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import { queryVars } from "constants/query"
import historyMessages from "messages/history"
import { useMst } from "models/Root"

const useTradeHistory = ({ isShowAll = true }) => {
	const { formatMessage } = useIntl()
	const {
		global: { isAuthenticated },
		history: {
			loadTradeHistory,
			isTradeHistoryLoading,
			tradeHistoryList,
			setFilterTypeTradeHistory,
			filterTypeTradeHistory,
		},
		bybit: {
			currentPair: { symbol },
			pairAmountPrecision,
			pairPricePrecision,
		},
	} = useMst()
	const params = useMemo(
		() => ({
			[queryVars.pair]: !isShowAll ? symbol : undefined,
			category: "spot",
		}),
		[symbol, isShowAll],
	)

	const listScheme = [
		{
			arrCount: 1,
			id: 123,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_pair),
					width: 110,
					asociate: "name",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 11,
			id: 223,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_side),
					width: 80,
					asociate: "direction",
				},
				// {
				// 	headline: "Instrument",
				// 	width: 100,
				// 	asociate: "isLeverage",
				// },
				{
					headline: formatMessage(historyMessages.order_type),
					width: 100,
					asociate: "orderType",
				},
				{
					headline: formatMessage(historyMessages.filled_value),
					width: 168,
					asociate: "filledValue",
				},
				{
					headline: formatMessage(historyMessages.filled_price),
					width: 168,
					asociate: "filledPrice",
				},
				{
					headline: formatMessage(historyMessages.filled_qty),
					width: 125,
					asociate: "filledQty",
				},
				{
					headline: formatMessage(historyMessages.trading_fee),
					width: 168,
					asociate: "tradingFees",
				},
				{ headline: formatMessage(historyMessages.order_qty), width: 168, asociate: "orderQty" },
				// { headline: formatMessage(historyMessages.state), width: 110, asociate: "orderStatus" },
				{ headline: formatMessage(historyMessages.filled_time), width: 168, asociate: "date" },
				{
					headline: formatMessage(historyMessages.deposits_table_transaction),
					width: 100,
					asociate: "tradeId",
				},
				{ headline: formatMessage(historyMessages.order_id), width: 168, asociate: "orderId" },
			],
		},
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	useEffect(() => {
		if (isAuthenticated && symbol !== "") {
			loadTradeHistory(params).then(() => null)
		}
	}, [isAuthenticated, symbol, params, isShowAll])

	return {
		listScheme,
		listHeadline,
		list: tradeHistoryList,
		isAuthenticated,
		pairAmountPrecision,
		pairPricePrecision,
		isTradeHistoryLoading,
		filterTypeTradeHistory,
		setFilterTypeTradeHistory,
	}
}

export default useTradeHistory
