import { makeAutoObservable } from "mobx"

import { TerminalStore } from "./Terminal"

class RootStore {
	terminalStore

	constructor() {
		this.terminalStore = new TerminalStore()

		makeAutoObservable(this)
	}
}

export const rootStore = new RootStore()
