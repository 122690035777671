import { useQuery } from "react-query"

import ApiClient from "helpers/ApiClient"
import { IBalance, IMarginStatus, IProfileStatus, IRateAlgo } from "models/Account"
import {
	IGetFinanceBalancesParams,
	IGetMarginStatusParams,
	IPostMarginTransferParams,
	TGetAggregatedBalanceResponse,
	TGetFinanceAccountTypesResponse,
	TGetFinanceBalancesResponse,
	TPostMarginTransferResponse,
	TGetBorrowQuotaResponse,
	ICollateralInfo,
	IBorrowHistoryResult,
	ICollateralSwitchParams,
	TPostCollateralSwitchResponse,
	TGetAccountInfoResponse,
	EMarginMode,
	TPostMarginModeResponse,
	IGetBorrowQuotaParams,
} from "types/account"

const AccountService = {
	getProfileStatus: (): Promise<IProfileStatus> => ApiClient.get("web/profile/status"),
	getAccountTypes: (): Promise<TGetFinanceAccountTypesResponse> =>
		ApiClient.get("web/finance/account-types"),
	getBalances: (params: IGetFinanceBalancesParams): Promise<TGetFinanceBalancesResponse> =>
		ApiClient.get("web/finance/balances", params),
	getBorrowQuota: (params: IGetBorrowQuotaParams): Promise<TGetBorrowQuotaResponse> =>
		ApiClient.get("web/margin/borrow-quota", params),
	getAggregatedBalance: (): Promise<TGetAggregatedBalanceResponse> =>
		ApiClient.get("web/finance/aggr-balances"),
	getBalancesCross: (): Promise<IBalance[]> => ApiClient.get("web/margin/cross-balance"),
	getBalancesIsolated: (): Promise<IBalance[]> => ApiClient.get("web/margin/isolated-balance"),
	getMarginStatus: (params: IGetMarginStatusParams): Promise<IMarginStatus> =>
		ApiClient.get("web/margin/account-status", params),
	getRates: (quoteCurrency: string): Promise<IRateAlgo[]> =>
		ApiClient.get("web/finance/rates/algo", { quote_currency: quoteCurrency }),
	marginTransfer: (body: IPostMarginTransferParams): Promise<TPostMarginTransferResponse> =>
		ApiClient.post("web/margin/transfer", body),
	getCollateralInfo: (params?: {
		currency?: string
		is_borrowed?: boolean
	}): Promise<ICollateralInfo[]> => ApiClient.get("web/margin/collateral-info", params),
	getBorrowHistory: (params?: {
		loan_currency?: string
		collateral_currency?: string
	}): Promise<IBorrowHistoryResult> => ApiClient.get("web/margin/borrow-history", params),
	postCollateralSwitch: (body: ICollateralSwitchParams): Promise<TPostCollateralSwitchResponse> =>
		ApiClient.post("web/margin/collateral-asset", body),

	getAccountInfo: (): Promise<TGetAccountInfoResponse> => ApiClient.get("web/margin/account-info"),

	getMigratorStatus: (): Promise<any> => ApiClient.get("web/migrator/get-status"),

	setMigratorStatus: (status: string): Promise<void> =>
		ApiClient.post("web/migrator/set-status", { status }),

	setMarginMode: (margin_mode: EMarginMode): Promise<TPostMarginModeResponse> =>
		ApiClient.post("web/margin/account-margin-mode", { margin_mode }),
}

export const useRatesUSDT = () =>
	useQuery(["ads"], async () => {
		const data = await AccountService.getRates("USDT")
		return data ?? null
	})

export default AccountService
