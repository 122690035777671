import { TDateInputValue } from "./types"

export const getFirstMonthDay = (date?: Date): Date => {
	const result = date || new Date()
	result.setDate(1)
	return result
}

export const getLastMonthDay = (date?: Date): Date => {
	const result = date || new Date()
	result.setMonth(result.getMonth() + 1)
	result.setDate(0)
	return result
}

const pad = (val: string | number) => val.toString().padStart(2, "0")

export const dateToStr = (date: Date): string =>
	`${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`

export const dateToValue = (date: Date): string =>
	`${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`

export const getMonthsBetween = (date1: Date, date2: Date): number => {
	let months = (date2.getFullYear() - date1.getFullYear()) * 12
	months -= date1.getMonth()
	months += date2.getMonth()
	if (date2.getDate() < date1.getDate()) months--
	return months + 1
}

export const getDaysBetween = (date1: Date, date2: Date): number => {
	const msPerDay = 24 * 60 * 60 * 1000
	const diffInMs = Math.abs(date2.getTime() - date1.getTime())
	return Math.floor(diffInMs / msPerDay) + 1
}

export function parseValue<Range extends boolean>(value: string): null | TDateInputValue<Range> {
	if (!value) return null
	if (value.includes(",")) {
		const values = value.split(",")
		return [new Date(values[0]), new Date(values[1])] as TDateInputValue<Range>
	}
	return new Date(value) as TDateInputValue<Range>
}
