import React, { useMemo, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import cn from "classnames"

import { useMst } from "models/Root"
import useScrollArrows from "hooks/newUI/terminal/useScrollArrows"
import { TradeTypeEnum } from "types/exchange"
import Menu from "assets/icons/terminal/Menu"
import commonMessages from "messages/common"
import ArrowRight from "assets/icons/terminal/ArrowRight"
import messages from "messages/exchange"

import Amount from "./Amount"

import styles from "../index.module.scss"

const Info: React.FC<{ openMenuAction: (ev: any) => void; isOpen: boolean }> = ({
	openMenuAction,
	isOpen,
}) => {
	const {
		ref,
		handleArrowLeftClick,
		showArrows,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	} = useScrollArrows(undefined, false, true)

	const { formatNumber, formatMessage } = useIntl()
	const location = useLocation()

	const {
		bybit: {
			ticker,
			currentPair: { quoteCoin, baseCoin },
			pairPricePrecision,
			pairAmountPrecision,
			isMarginAvailable,
			loadLoanConditions,
		},
		render: { margin: isMargin },
		global: { isAuthenticated },
	} = useMst()

	const [baseCoinRate, setBaseCoinRate] = useState<number | null>(null)
	const [quoteCoinRate, setQuoteCoinRate] = useState<number | null>(null)

	// Определяем tradeType из URL параметров
	const tradeType = useMemo(() => {
		const params = new URLSearchParams(location.search)
		return params.get("type") as TradeTypeEnum
	}, [location.search])

	const formatPriceNumberOptions = useMemo(
		() => ({
			useGrouping: true,
			maximumFractionDigits: pairPricePrecision,
			minimumFractionDigits: pairPricePrecision,
		}),
		[pairPricePrecision],
	)

	const formatAmountNumberOptions = useMemo(
		() => ({
			useGrouping: true,
			maximumFractionDigits: pairAmountPrecision,
			minimumFractionDigits: pairAmountPrecision,
		}),
		[pairAmountPrecision],
	)

	useEffect(() => {
		if (tradeType === TradeTypeEnum.CROSS && baseCoin && isAuthenticated) {
			loadLoanConditions(baseCoin)
				.then((data: any) => {
					if (data && Array.isArray(data)) {
						const rate =
							data.find((item: any) => item.currency === baseCoin)?.hourly_borrow_rate ?? null
						setBaseCoinRate(rate)
					} else {
						console.warn("No valid data returned for BaseCoin")
					}
				})
				.catch((error: any) => {
					console.error("Error fetching BaseCoin loan conditions:", error)
				})
		}
	}, [tradeType, baseCoin, loadLoanConditions, isAuthenticated])

	useEffect(() => {
		if (tradeType === TradeTypeEnum.CROSS && quoteCoin && isAuthenticated) {
			loadLoanConditions(quoteCoin)
				.then((data: any) => {
					if (data && Array.isArray(data)) {
						const rate =
							data.find((item: any) => item.currency === quoteCoin)?.hourly_borrow_rate ?? null
						setQuoteCoinRate(rate)
					} else {
						console.warn("No valid data returned for QuoteCoin")
					}
				})
				.catch((error: any) => {
					console.error("Error fetching QuoteCoin loan conditions:", error)
				})
		}
	}, [tradeType, quoteCoin, loadLoanConditions, isAuthenticated])

	// Для отображения процентных ставок
	const hourlyInterestsRate = useMemo(
		() =>
			baseCoinRate !== null && quoteCoinRate !== null
				? `${formatNumber(baseCoinRate, {
						useGrouping: false,
						minimumFractionDigits: 7,
						maximumFractionDigits: 10,
				  })}% | ${formatNumber(quoteCoinRate, {
						useGrouping: false,
						minimumFractionDigits: 7,
						maximumFractionDigits: 10,
				  })}%`
				: undefined,
		[baseCoinRate, quoteCoinRate, formatNumber],
	)

	return (
		<div className={styles.trading__header}>
			<button
				type="button"
				className={styles.trading__menuButton}
				aria-label="Toggle search open"
				onClick={openMenuAction}
				disabled={isOpen}
			>
				<Menu />
			</button>

			<div className={styles.trading__info}>
				<div className={styles.trading__infoLeft}>
					<div className={styles.trading__infoHeadline}>
						{baseCoin !== "" ? (
							baseCoin
						) : (
							<div className={styles.trading__skeleton}>
								<Skeleton width={30} height={19} borderRadius={4} />
							</div>
						)}
						/
						{quoteCoin !== "" ? (
							quoteCoin
						) : (
							<div className={styles.trading__skeleton}>
								<Skeleton width={40} height={19} borderRadius={4} />
							</div>
						)}
					</div>

					<div className={styles.trading__infoContent}>
						{isMarginAvailable && isMargin ? (
							<div className={styles.trading__infoIndicator}>10x</div>
						) : null}
					</div>
				</div>

				<div className={styles.trading__infoRight}>
					<div
						className={cn(styles.trading__infoPrice, {
							[styles.trading__infoPrice___minus]: (ticker?.price24hPcnt || 0) < 0,
						})}
					>
						<Amount
							value={ticker?.lastPrice}
							format={formatPriceNumberOptions}
							width={72}
							height={18}
							borderRadius={4}
						/>
					</div>

					{["USDT", "USDC"].includes(quoteCoin) && ticker?.usdIndexPrice !== 0 ? (
						<div className={styles.trading__infoPrice2}>
							≈{" "}
							<Amount
								value={ticker?.usdIndexPrice}
								format={formatPriceNumberOptions}
								unit=" USD"
								width={80}
								height={14}
								borderRadius={4}
							/>
						</div>
					) : null}
				</div>
			</div>

			<div className={styles.trading__listContainer}>
				<div ref={ref} className={styles.trading__listWrapper}>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.trading__listButtonLeft} ${
							showArrows && showLeftArrow ? styles.trading__listButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.trading__listButtonRight} ${
							showArrows && showRightArrow ? styles.trading__listButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<ul className={styles.trading__list}>
						<li className={styles.trading__item}>
							<p className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.change)}
							</p>

							<div
								className={cn(styles.trading__itemValue, {
									[styles.trading__itemValue___minus]: (ticker?.price24hPcnt || 0) < 0,
								})}
							>
								<Amount
									value={(ticker?.price24hPcnt || 0) * 100}
									format={{
										useGrouping: false,
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
									}}
									unit="%"
									width={37}
									height={15}
									borderRadius={4}
								/>
							</div>
						</li>

						<li className={styles.trading__item}>
							<p className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.high)}
							</p>

							<div className={styles.trading__itemValue}>
								<Amount
									value={ticker?.highPrice24h}
									format={formatPriceNumberOptions}
									width={54}
									height={13}
									borderRadius={4}
								/>
							</div>
						</li>

						<li className={styles.trading__item}>
							<p className={styles.trading__itemTopText}>24H {formatMessage(commonMessages.low)}</p>

							<div className={styles.trading__itemValue}>
								<Amount
									value={ticker?.lowPrice24h}
									format={formatPriceNumberOptions}
									width={54}
									height={13}
									borderRadius={4}
								/>
							</div>
						</li>

						<li className={styles.trading__item}>
							<div className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.turnover)} ({quoteCoin})
							</div>

							<div className={styles.trading__itemValue}>
								<Amount
									value={ticker?.turnover24h}
									format={formatPriceNumberOptions}
									width={84}
									height={13}
									borderRadius={4}
								/>
							</div>
						</li>

						<li className={styles.trading__item}>
							<div className={styles.trading__itemTopText}>
								24H {formatMessage(commonMessages.volume)} (
								{baseCoin !== "" ? (
									baseCoin
								) : (
									<div className={styles.trading__skeleton}>
										<Skeleton width={35} height={13} borderRadius={2} />
									</div>
								)}
								)
							</div>

							<div className={styles.trading__itemValue}>
								<Amount
									value={ticker?.volume24h}
									format={formatAmountNumberOptions}
									width={48}
									height={13}
									borderRadius={4}
								/>
							</div>
						</li>

						{isMarginAvailable &&
							isMargin &&
							isAuthenticated &&
							tradeType === TradeTypeEnum.CROSS && (
								<li className={styles.trading__item}>
									<div className={styles.trading__itemTopText}>
										{formatMessage(messages.borrow_hourly_rate)} ({quoteCoin}/{baseCoin})
									</div>
									<div className={styles.trading__itemValue}>
										{hourlyInterestsRate || <Skeleton width={130} height={13} borderRadius={4} />}
									</div>
								</li>
							)}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default observer(Info)
