import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import historyMessages from "messages/history"

import styles from "components/NewUI/Terminal/Bottom/Borrows/List/index.module.scss"
import Spinner from "components/UI/Spinner"

import ArrowRight from "assets/icons/terminal/ArrowRight"

const Row: React.FC<{
	item: {
		available_to_borrow: number
		borrow_amount: number
		borrow_usage_rate: number
		borrowable: boolean
		collateral_ratio: number
		collateral_switch: boolean
		currency: string
		free_borrow_amount: number
		free_borrowing_amount: string
		free_borrowing_limit: number
		hourly_borrow_rate: number
		margin_collateral: boolean
		max_borrowing_amount: number
	}
	id: number
	namesArr: { headline: string; width: number; asociate: string }[]
	handleArrowRightClick: () => void
	handleArrowLeftClick: () => void
	showArrows: boolean
	showLeftArrow: boolean
	showRightArrow: boolean
	isScroll?: boolean
	handleMarginRepay: (currency: string, amount: number) => void
}> = ({
	item,
	id,
	namesArr,
	handleArrowLeftClick,
	showArrows,
	showLeftArrow,
	showRightArrow,
	handleArrowRightClick,
	isScroll,
	handleMarginRepay,
}) => {
	const { formatMessage, formatNumber } = useIntl()

	const repay = useCallback(
		() => handleMarginRepay(item.currency, item.borrow_amount),
		[item.currency, item.borrow_amount, handleMarginRepay],
	)

	return (
		<li key={`${id}-${item.currency}-list-item`} className={styles.list__item}>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__itemButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__itemButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__itemButtonRight} ${
							showArrows && showRightArrow ? styles.list__itemButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>
				</>
			) : null}

			{namesArr.map(nameItem => (
				<div
					key={`${id}-${nameItem.asociate}-item`}
					className={`${styles.list__itemElem} ${
						nameItem.asociate === "direction" ? styles.list__itemElem___direction : ""
					} ${nameItem.asociate === "action" ? styles.list__itemElem___action : ""}`}
					style={{ minWidth: `${nameItem.width}px` }}
				>
					{nameItem.asociate === "currency" && (
						<span className={styles.list__currency}>
							<span className={styles.list__currencySymbol}>{item.currency}</span>
						</span>
					)}

					{nameItem.asociate === "borrow_amount" && (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.borrow_amount, {
									useGrouping: true,
									minimumFractionDigits: 2,
									maximumFractionDigits: 8,
								})}
							</span>
							<span className={styles.list__itemCurrency}>{item.currency}</span>
						</span>
					)}

					{nameItem.asociate === "hourly_borrow_rate" && (
						<span className={styles.list__itemWithCurrency}>
							<span>{item.hourly_borrow_rate}%</span>
						</span>
					)}

					{nameItem.asociate === "max_borrowing_amount" && (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.max_borrowing_amount, {
									useGrouping: true,
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
							</span>
							<span className={styles.list__itemCurrency}>{item.currency}</span>
						</span>
					)}

					{nameItem.asociate === "action" && (
						<button onClick={repay} type="button" className={styles.list__itemButton}>
							<div className={styles.list__itemButtonLoader}>
								<Spinner size={18} />
							</div>
							<span>{formatMessage(historyMessages.repay)}</span>
						</button>
					)}
				</div>
			))}
		</li>
	)
}

export default observer(Row)
