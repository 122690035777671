import { useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useMst } from "models/Root"

type TParams<Routes extends Record<string, unknown>> = {
	routes: Routes
}

export default function useRouting<Routes extends Record<string, unknown>>({
	routes,
}: TParams<Routes>) {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const {
		global: { locale },
	} = useMst()

	const route = useMemo<keyof Routes | undefined>(() => {
		if (!routes) return undefined
		const [key] =
			Object.entries(routes).find(([, route]) => {
				if (typeof route !== "string") return false
				const localeRoute = `/${locale}${route}`.replace(/\/+$/, "")
				return localeRoute === pathname.replace(/\/+$/, "")
			}) || []
		return key as keyof Routes
	}, [routes, pathname])

	const navigateRoute = (key: keyof Routes) => navigate(`/${locale}${routes[key]}`)

	useEffect(() => {
		if (!route && routes.root) navigateRoute("root")
	}, [route])

	return { route, navigateRoute }
}
