import React, { CSSProperties, useId } from "react"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"

import styles from "./textButton.module.scss"
import AlphaIcon from "../AlphaIcon"

type TProps = {
	caption: string
	icon?: TAlphaIcon | React.ReactNode
	active?: boolean
	disabled?: boolean
	style?: CSSProperties
	className?: string
	onClick?: () => void
}

const TextButton: React.FC<TProps> = ({
	caption,
	icon,
	active,
	disabled,
	style,
	className,
	onClick,
}) => {
	const id = useId()

	return (
		<button
			type="button"
			disabled={disabled}
			className={cn(styles.textButton, { [styles.active]: active }, className)}
			style={style}
			onClick={onClick}
		>
			{typeof icon === "string" ? <AlphaIcon name={icon as TAlphaIcon} /> : icon}
			<span>{caption}</span>
		</button>
	)
}

export default TextButton
