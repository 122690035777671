import React, { CSSProperties, useId } from "react"
import cn from "classnames"

import styles from "./billboard.module.scss"

type TProps = {
	text: string | string[]
	bordered?: boolean
	style?: CSSProperties
	className?: string
}

const Billboard: React.FC<TProps> = ({ text, bordered, style, className }) => {
	const id = useId()

	return (
		<div className={cn(styles.billboard, { [styles.bordered]: bordered }, className)} style={style}>
			{typeof text === "string" ? (
				<p>{text}</p>
			) : (
				<ol>
					{text.map((line, index) => (
						<li key={`billboard-${id}-line-${index.toString()}-${line.length}`}>{line}</li>
					))}
				</ol>
			)}
		</div>
	)
}

export default Billboard
