import { cast, flow, Instance, types as t } from "mobx-state-tree"

import {
	EWithdrawLoading,
	ICreateWithdrawRequestBody,
	TWithdrawalDetails,
	IHistoryWithdrawParams,
} from "types/withdrawal"
import { AwaitedReturn } from "types/general"
import errorHandler from "utils/errorHandler"
import WithdrawalService from "services/WithdrawalService"
import AccountService from "services/AccountService"

import { Balance, IBalance } from "./Account"

const WithdrawalLimit = t.model({
	quota: t.string,
	used: t.string,
	quota_converted: t.string,
	is_hard_limited: t.boolean,
	hard_limit_until: t.maybeNull(t.string),
	used_converted: t.string,
	unused_in_currency: t.string,
	currency: t.string,
	verification_level: t.optional(t.number, 0),
	extend_verification_level: t.number,
})

export type IWithdrawalLimit = Instance<typeof WithdrawalLimit>

const WithdrawalMethod = t.model("WithdrawalMethodModel", {
	id: t.string,
	name: t.string,
	is_withdraw_enabled: t.boolean,
	min_verification_level: t.number,
	min_withdraw: t.number,
	withdraw_fee_amount: t.number,
	withdraw_fee_rate: t.number,
})

export type IWithdrawalMethod = Instance<typeof WithdrawalMethod>

const WithdrawStatus = t.model({
	id: t.number,
	label: t.string,
})

const WithdrawAttributes = t.model({
	label: t.string,
	value: t.string,
})

export type IWithdrawAttributes = Instance<typeof WithdrawAttributes>

const WithdrawalCurrency = t.model({
	code: t.maybe(t.string),
	name: t.maybe(t.string),
	image_svg: t.maybe(t.string),
	image_png: t.maybe(t.string),
})

export type IWithdrawalCurrency = Instance<typeof WithdrawalCurrency>

export const WithdrawalDetails = t.model({
	slug: t.string,
	is_totp_required: t.boolean,
	totp_timeout: t.maybeNull(t.string),
	is_totp_confirmed: t.boolean,
	is_pincode_required: t.boolean,
	pincode_tries_left: t.number,
	pincode_timeout: t.maybeNull(t.string),
	pincode_generated_at: t.maybeNull(t.string),
	is_pincode_confirmed: t.boolean,
	deadline_at: t.string,
	is_totp_ok: t.boolean,
	is_pincode_ok: t.boolean,
	is_ok: t.boolean,
	amount: t.string,
	address: t.string,
	tag: t.maybeNull(t.string),
	note: t.maybeNull(t.string),
	attributes_labeled: t.optional(t.array(WithdrawAttributes), []),
	payment_method_name: t.string,
	// "withdraw": t.maybeNull(?),
	currency: WithdrawalCurrency,
	fee_amount: t.number,

	id: t.maybe(t.number),
	date: t.maybe(t.string),
	currency_id: t.maybe(t.string),
	status: t.maybe(WithdrawStatus),
	fee_currency_id: t.maybe(t.string),
	comment: t.maybe(t.string),
	txid: t.maybe(t.string),
	txid_url: t.maybe(t.string),
})

export type IWithdrawalDetails = Instance<typeof WithdrawalDetails>

export const HistoricalWithdrawal = t.model({
	user: t.string,
	id: t.string,
	created_at: t.string,
	amount: t.string,
	currency: t.string,
	chain: t.string,
	address: t.string,
	tag: t.maybeNull(t.string),
	status: t.string,
	type: t.number,
	fee_amount: t.string,
	fee_currency: t.string,
	withdraw_id: t.string,
	txid: t.maybeNull(t.string),
})

export type IHistoricalWithdrawal = Instance<typeof HistoricalWithdrawal>

export const Withdrawal = t
	.model({
		loadings: t.array(t.string),
		currencies: t.optional(t.array(Balance), []),
		currentCurrencyCode: t.maybe(t.string),
		methods: t.optional(t.array(WithdrawalMethod), []),
		currentMethodId: t.maybe(t.string),
		withdraw: t.maybeNull(WithdrawalDetails),
		previousWithdraws: t.optional(t.array(HistoricalWithdrawal), []),
		previousWithdrawsCount: t.optional(t.number, 0),

		withdraw_limit: t.maybeNull(WithdrawalLimit),
	})

	.actions(self => ({
		startLoading(kind: EWithdrawLoading) {
			if (self.loadings.includes(kind)) return
			self.loadings.push(kind)
		},
		finishLoading(kind: EWithdrawLoading) {
			if (!self.loadings.includes(kind)) return
			self.loadings = cast(self.loadings.filter(item => item !== kind))
		},

		setCurrentMethod(method?: string) {
			self.currentMethodId = cast(method)
		},
		setCurrentCurrency(currency?: string) {
			self.currentCurrencyCode = cast(currency)
		},

		setWithdraw(withdraw: IWithdrawalDetails | TWithdrawalDetails) {
			self.withdraw = cast({
				...withdraw,
				currency:
					typeof withdraw.currency === "string"
						? { name: withdraw.currency, code: withdraw.currency }
						: withdraw.currency,
			})
		},
	}))

	.views(self => ({
		getIsLoading(kind: EWithdrawLoading) {
			return self.loadings.includes(kind)
		},
		get currentCurrency() {
			if (!self.currentCurrencyCode?.length) return null
			return self.currencies.find(({ code }) => code === self.currentCurrencyCode) || null
		},
		get currentMethod() {
			if (!self.currentMethodId?.length) return null
			return self.methods.find(({ id }) => id.toString() === self.currentMethodId) || null
		},
	}))

	.actions(self => ({
		loadCurrencies: flow(function* () {
			try {
				const balances: AwaitedReturn<typeof AccountService.getBalances> =
					yield AccountService.getBalances({ account_type: "FUND" })
				self.currencies = cast(
					balances.map<IBalance>(balance =>
						cast({
							code: balance.code,
							balance: +balance.balance,
							reserve: +balance.reserve,
							available: +balance.available,
							converted: Object.fromEntries(
								Object.entries(balance.converted).map(([key, val]) => [key, +val]),
							),
							is_demo: false,
						}),
					),
				)
			} catch (err) {
				errorHandler(err)
			}
		}),

		loadMethods: flow(function* () {
			if (!self.currentCurrencyCode?.length) return
			try {
				self.startLoading(EWithdrawLoading.METHODS)
				self.setCurrentMethod(undefined)
				const methods: AwaitedReturn<typeof WithdrawalService.getMethods> =
					yield WithdrawalService.getMethods({ currency: self.currentCurrencyCode })
				self.methods = cast(
					methods.map<IWithdrawalMethod>(method =>
						cast({
							id: method.id,
							name: method.name,
							is_withdraw_enabled: method.is_withdraw_enabled,
							min_verification_level: method.min_verification_level,
							min_withdraw: +method.min_withdraw,
							withdraw_fee_amount: +method.withdraw_fee_amount,
							withdraw_fee_rate: +method.withdraw_fee_rate,
						}),
					),
				)
				self.currentMethodId = self.methods.find(
					({ is_withdraw_enabled }) => is_withdraw_enabled,
				)?.id
			} catch (err) {
				errorHandler(err)
			} finally {
				self.finishLoading(EWithdrawLoading.METHODS)
			}
		}),

		createWithdrawal: flow<string | null, [ICreateWithdrawRequestBody]>(function* (params) {
			try {
				self.startLoading(EWithdrawLoading.CREATING)
				const newWithdrawal: AwaitedReturn<typeof WithdrawalService.createWithdrawal> =
					yield WithdrawalService.createWithdrawal(params)
				return newWithdrawal.slug
			} catch (err) {
				errorHandler(err)
				return null
			} finally {
				self.finishLoading(EWithdrawLoading.CREATING)
			}
		}),

		loadWithdrawal: flow(function* (slug: string) {
			try {
				self.startLoading(EWithdrawLoading.DETAILS)
				const result: AwaitedReturn<typeof WithdrawalService.getWithdrawDetails> =
					yield WithdrawalService.getWithdrawDetails(slug)
				self.setWithdraw(result)
			} catch (err) {
				errorHandler(err)
			} finally {
				self.finishLoading(EWithdrawLoading.DETAILS)
			}
		}),

		loadPreviousWithdraws: flow(function* (params?: IHistoryWithdrawParams) {
			try {
				self.startLoading(EWithdrawLoading.HISTORY)
				const { results, count }: AwaitedReturn<typeof WithdrawalService.getPreviousWithdraws> =
					yield WithdrawalService.getPreviousWithdraws(params || {})
				self.previousWithdrawsCount = count
				self.previousWithdraws = cast(results)
			} catch (err) {
				errorHandler(err)
			} finally {
				self.finishLoading(EWithdrawLoading.HISTORY)
			}
		}),

		cancelWithdraw: flow(function* (slug: string) {
			try {
				yield WithdrawalService.cancelWithdraw(slug)
				return true
			} catch (err) {
				errorHandler(err)
				return false
			}
		}),

		loadWithdrawLimit: flow(function* (params: { currency: string }) {
			try {
				self.withdraw_limit = yield WithdrawalService.getWithdrawLimit(params)
			} catch (err) {
				errorHandler(err)
			}
		}),
	}))
