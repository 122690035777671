/* eslint-disable no-unreachable-loop */
// eslint-disable-next-line max-classes-per-file
import React from "react"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import cookies from "js-cookie"
import { toast } from "react-toastify"

import appConfig from "helpers/config"
import { LOCALE_CACHE_KEY } from "utils/cacheKeys"
import { TBybitResponse } from "types/bybit"
import { defaultLocale } from "providers/LanguageProvider/i18n"
import Error from "assets/icons/toast/Error"
import cache from "./cache"

const API_PREFIX = appConfig.apiPrefix
const API_BYBIT_PREFIX = appConfig.apiBybitPrefix
const CSRF_COOKIE_NAME = appConfig.csrfCookieName
const SESSION_COOKIE_NAME = appConfig.sessionCookieName

axios.defaults.withCredentials = true

type TResponseType = "json" | "arraybuffer" | "blob" | "document" | "text" | "stream" | undefined
type TRequestMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH"

interface IError {
	data: any
	status: number
	message: string
}

export type IApiError = IError

const getDetails = (obj: Record<string, unknown>): string => {
	const d = obj[Object.keys(obj)?.length ? Object.keys(obj)[0] : "details"]
	while (typeof d === "object") {
		return getDetails(d as Record<string, unknown>)
	}
	return (typeof d === "string" ? d : "Server error") as string
}

let isUnauthorized = false
const handleError = (err: AxiosError) => {
	const status = err.response?.status ?? 0
	if ([401].includes(status)) {
		if (!isUnauthorized) {
			isUnauthorized = true
			cookies.remove(appConfig.sessionCookieName)
			// cookies.remove(appConfig.csrfCookieName)

			toast.error(
				<div>
					<div>Error</div>
					<div className="Toastify__toast-subtext">
						{status === 401 ? "Unauthorized [401]" : "Access denied [403]"}
					</div>
				</div>,
				{
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					icon: <Error />,
				},
			)

			setTimeout(() => {
				const locale = cache.getItem(LOCALE_CACHE_KEY, defaultLocale)
				window.location.href = `/${locale}/login`
			}, 2000)
		}

		return Promise.resolve()
	}

	const originalError = (err as Record<string, any>)?.response.data || err
	const details = { ...originalError }
	const message =
		typeof details === "object"
			? getDetails(details as Record<string, unknown>)
			: err.message || "Server error"
	if (appConfig.isDebug) {
		console.error("API error: ", err.response)
	}
	const error = {
		data: originalError,
		status: status,
		message: message,
	}

	if (error.status === 0 || error.status >= 500) {
		toast.error(
			<div>
				<div>Error</div>
				<div className="Toastify__toast-subtext">Server error</div>
			</div>,
			{
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				icon: <Error />,
			},
		)
	} else if (error.status === 404) {
		toast.error(
			<div>
				<div>Error</div>
				<div className="Toastify__toast-subtext">Not Found</div>
			</div>,
			{
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				icon: <Error />,
			},
		)
	} else if (error.status === 401) {
		toast.error(
			<div>
				<div>Error</div>
				<div className="Toastify__toast-subtext">{message}</div>
			</div>,
			{
				position: "bottom-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				icon: <Error />,
			},
		)
	}
	return Promise.reject(error)
}

const handleResponse = (res: AxiosResponse) => res.data || res

const handleRequest = (config: AxiosRequestConfig) => {
	const sessionToken = cookies.get(SESSION_COOKIE_NAME)
	const csrfToken = cookies.get(CSRF_COOKIE_NAME)

	if (config.headers) {
		if (sessionToken) {
			config.headers.Authorization = `Bearer ${sessionToken}`
		}
		if (csrfToken) {
			config.headers["X-CSRFToken"] = csrfToken
		}
	}

	return config
}

// const csrfInit = () => request(CSRF_API_URL, "GET");

axios.interceptors.response.use(handleResponse, handleError)
axios.interceptors.request.use(handleRequest)

async function request<ResponseData>(
	url: string,
	method: TRequestMethod,
	data: any,
	params = null,
	headers = {},
	responseType: TResponseType = "json",
) {
	const config: AxiosRequestConfig = {
		method,
		params,
		url: `${API_PREFIX}/${url}`,
		data,
		responseType,
		headers: {
			accept: "application/json",
			"content-type": "application/json",
			"accept-language": cache.getItem(LOCALE_CACHE_KEY, defaultLocale),
			...headers,
		},
	}

	return (await axios(config)) as ResponseData
}

export default class ApiClient {
	static get = async function <ResponseData = any>(
		url: string,
		params: any = null,
		headers: Record<string, unknown> = {},
		responseType?: TResponseType,
	) {
		return request<ResponseData>(url, "GET", null, params, headers, responseType)
	}

	static post = async function <ResponseData = any>(
		url: string,
		data?: unknown,
		params?: any,
		headers: any = {},
	) {
		return request<ResponseData>(url, "POST", data, params, headers)
	}

	static put = async function <ResponseData>(url: string, data?: any, params: any = null) {
		return request<ResponseData>(url, "PUT", data, params)
	}

	static patch = async function <ResponseData = any>(
		url: string,
		data?: any,
		params: any = null,
		headers: any = {},
	) {
		return request<ResponseData>(url, "PATCH", data, params, headers)
	}

	static delete = async function <ResponseData = any>(url: string, headers: any = {}, data?: any) {
		return request<ResponseData>(url, "DELETE", data, null, headers)
	}
}

async function bybitRequest<ResponseData>(
	url: string,
	method: TRequestMethod,
	data: any,
	params = null,
	headers = {},
	responseType: TResponseType = "json",
): Promise<TBybitResponse<ResponseData>> {
	const config: AxiosRequestConfig = {
		method,
		params,
		url: `${API_BYBIT_PREFIX}/${url}`,
		data,
		responseType,
		headers: {
			accept: "application/json",
			"content-type": "application/json",
			"accept-language": cache.getItem(LOCALE_CACHE_KEY, defaultLocale),
			...headers,
		},
	}

	const result: TBybitResponse<ResponseData> = (await axios(config)) as never

	if (!["ok", "success", ""].includes(result.retMsg?.toLowerCase()))
		throw new (Error as unknown as { new (message?: string): Error })(
			`[${result.retCode}] ${result.retMsg}`,
		)

	return result
}

export class ApiBybitClient {
	static get = async function <ResponseData>(
		url: string,
		params: any = null,
		headers: Record<string, unknown> = {},
		responseType?: TResponseType,
	): Promise<ResponseData> {
		return (await bybitRequest<ResponseData>(url, "GET", null, params, headers, responseType))
			.result
	}

	static post = async function <ResponseData>(
		url: string,
		data?: unknown,
		params?: any,
		headers: any = {},
	) {
		return (await bybitRequest<ResponseData>(url, "POST", data, params, headers)).result
	}

	static put = async function <ResponseData>(url: string, data?: any, params: any = null) {
		return (await bybitRequest<ResponseData>(url, "PUT", data, params)).result
	}

	static patch = async function <ResponseData>(
		url: string,
		data?: any,
		params: any = null,
		headers: any = {},
	) {
		return (await bybitRequest<ResponseData>(url, "PATCH", data, params, headers)).result
	}

	static delete = async function <ResponseData>(url: string, headers: any = {}, data?: any) {
		return (await bybitRequest<ResponseData>(url, "DELETE", data, null, headers)).result
	}
}
