import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import useModal, { TDefaultModalProps } from "hooks/useModal"
import ExchangeService from "services/ExchangeService"
import messages from "messages/common"

import Input from "components/UI/Input"
import Range from "components/NewUI/Range"
import Button from "components/UI/Button"
import Error from "assets/icons/toast/Error"

import styles from "styles/components/Modals/AdjustLeverage.module.scss"

const MIN_LEVERAGE = 2
const MAX_LEVERAGE = 10

const Modal: React.FC<TDefaultModalProps> = ({ closeModal }) => {
	const {
		terminal: {
			tradeForm: { marginLvl, setMarginLvl },
		},
	} = useMst()

	const { formatMessage } = useIntl()

	const [values, setValues] = useState<number[]>([marginLvl ?? MIN_LEVERAGE])
	const [loading, setLoading] = useState<boolean>(false)

	const value = values[0]

	const handleSubmit = async () => {
		try {
			setLoading(true)
			const { leverage } = await ExchangeService.submitMarginLeverage(value)
			setMarginLvl(+leverage)
			closeModal()
		} catch (err) {
			const error = err as {
				data?: { message?: string }
				message?: string
			}
			const errorMessage = error.data?.message || error.message || "An unknown error occurred"

			toast.error(
				<div>
					<div>Error</div>
					<div className="Toastify__toast-subtext">{errorMessage}</div>
				</div>,
				{
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					icon: <Error />,
				},
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className={styles.adjustLeverageModal}>
			<div className={styles.header}>
				<span>{formatMessage(messages.adjust_leverage)}</span>
			</div>
			<div className={styles.content}>
				<Input
					labelValue={formatMessage(messages.leverage)}
					value={value}
					textAlign="align_right"
					disabled
				/>
				<Range
					min={MIN_LEVERAGE}
					max={MAX_LEVERAGE}
					step={1}
					hideValue
					eachStep
					isPercent={false}
					suffix="x"
					values={values}
					setValues={setValues}
					className={styles.range}
				/>
			</div>
			<div className={styles.footer}>
				<Button
					label={formatMessage(messages.cancel)}
					disabled={loading}
					variant="outlined"
					onClick={closeModal}
				/>
				<Button
					label={formatMessage(messages.confirm)}
					isLoading={loading}
					onClick={handleSubmit}
				/>
			</div>
		</div>
	)
}

const useAdjustLeverage = () => {
	const {
		terminal: {
			tradeForm: { marginLvl },
			loadLastMarginLvl,
		},
	} = useMst()
	const [open, close] = useModal(Modal)

	useEffect(() => {
		if (!marginLvl) loadLastMarginLvl()
	}, [marginLvl])

	return { marginLvl, openAdjustLeverageModal: open, closeAdjustLeverageModal: close }
}

export default useAdjustLeverage
