import React, { useCallback } from "react"
import { FormattedRelativeTime, useIntl } from "react-intl"
import cn from "classnames"

import messages from "messages/common"
import styles from "../PinCode.module.scss"

const DelayText: React.FC<{ delayTime: number; triesLeft: number; resendRequest: any }> = ({
	delayTime,
	triesLeft,
	resendRequest,
}) => {
	const { formatMessage } = useIntl()
	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60)
		const secs = seconds % 60
		return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`
	}
	const renderTriesLeft = useCallback(() => {
		if (triesLeft !== null && triesLeft > 0) {
			return (
				<div className={styles.pinCode__text}>
					{formatMessage(messages.pincode_input_with_tries, {
						tries: triesLeft,
						time_to_wait: formatTime(delayTime),
					})}
				</div>
			)
		}
		return null
	}, [triesLeft, delayTime])

	const renderReloadSuccess = useCallback(() => {
		if (delayTime > 0) {
			return (
				<div className={styles.pinCode__text}>
					{formatMessage(messages.pincode_input_get_new_pincode_success, {
						time_to_wait: (
							<FormattedRelativeTime value={delayTime} updateIntervalInSeconds={1} format="mm:ss" />
						),
					})}
				</div>
			)
		}
		return null
	}, [delayTime])

	const renderReloadButton = useCallback(() => {
		if (delayTime === 0 && triesLeft !== null && triesLeft >= 0 && resendRequest) {
			return (
				<div className={cn(styles.pinCode__text, styles.pinCode__textResend)}>
					I don&apos;t received a code.{" "}
					<button className={styles.pinCode__resendButton} type="button" onClick={resendRequest}>
						{formatMessage(messages.pincode_input_get_new_pincode)}
					</button>
				</div>
			)
		}
		return null
	}, [delayTime, triesLeft, resendRequest])

	const renderNoTries = useCallback(() => {
		if (triesLeft === 0) {
			return (
				<div className={cn(styles.pinCode__text, delayTime === 0 && styles.pinCode__textResend)}>
					{delayTime > 0 ? (
						<>
							{formatMessage(messages.pincode_input_with_no_tries, {
								time_to_wait: (
									<FormattedRelativeTime
										value={delayTime}
										updateIntervalInSeconds={1}
										format="mm:ss"
									/>
								),
							})}
						</>
					) : (
						<button className={styles.pinCode__resendButton} type="button" onClick={resendRequest}>
							{formatMessage(messages.pincode_input_with_no_tries, { time_to_wait: "" })}
						</button>
					)}
				</div>
			)
		}
		return null
	}, [triesLeft, delayTime, resendRequest])

	const getDelayText = useCallback((): JSX.Element | null => {
		if (triesLeft === 0) {
			return renderNoTries()
		}

		if (delayTime > 0) {
			if (triesLeft !== null && triesLeft > 0) {
				return renderTriesLeft()
			}
			return renderReloadSuccess()
		}

		if (delayTime === 0 && triesLeft !== null && triesLeft >= 0 && resendRequest) {
			return renderReloadButton()
		}

		return null
	}, [triesLeft, delayTime, resendRequest])

	return <div className={styles.pinCode__delayWrapper}>{getDelayText()}</div>
}

export default DelayText
