import React, { CSSProperties } from "react"
import { useIntl } from "react-intl"
import cn from "classnames"

import AlphaIcon from "components/redesigned/AlphaIcon"

import styles from "./progressButton.module.scss"

type TProps = {
	title?: string
	max: number
	value: number
	unit?: string
	disabled?: boolean
	style?: CSSProperties
	className?: string
	onClick?: () => void
}

const ProgressButton: React.FC<TProps> = ({
	title,
	max,
	value,
	unit,
	disabled,
	style,
	className,
	onClick,
}) => {
	const { formatNumber } = useIntl()
	return (
		<button
			type="button"
			disabled={disabled}
			className={cn(styles.progressButton, { [styles.clickable]: !!onClick }, className)}
			style={style}
			onClick={onClick}
		>
			<div className={styles.title}>
				<span>{title}</span>
				{!!onClick && (
					<AlphaIcon name="ai-chevron_right" color="--redesigned-color-text-primary" size={14} />
				)}
			</div>
			<div className={styles.bar}>
				<div style={{ flexGrow: value }} />
				<hr />
				<div style={{ flexGrow: max - value }} />
			</div>
			<div className={styles.labels}>
				<span>{formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
				<span>
					{formatNumber(max, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {unit}
				</span>
			</div>
		</button>
	)
}

export default ProgressButton
