import React, { useEffect } from "react"
import { useMst } from "models/Root"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet"
import { observer } from "mobx-react-lite"
import ProfileLayout from "layouts/ProfileLayout"
import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"
import { Header } from "../../components/History"
import historyStyles from "../../styles/pages/History/History.module.scss"
import commonMessages from "../../messages/common"
import MarginDataTable from "./MarginDataTable"

const MarginData: React.FC = () => {
	const { formatMessage } = useIntl()
	const { account } = useMst()

	return (
		<ProfileLayout sidebarMenuLevel={SidebarMenuLevelsEnum.Transfer}>
			<Helmet title={formatMessage(commonMessages.margin_data)} />
			<div className={historyStyles.container}>
				<Header label={formatMessage(commonMessages.margin_data)} />
				<MarginDataTable />
			</div>
		</ProfileLayout>
	)
}

export default observer(MarginData)
