import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import dayjs from "dayjs"
import { useIntl } from "react-intl"

import FooterArrow from "assets/icons/footer/FooterArrow"
import { routes } from "constants/routing"
import messages from "messages/common"
import feesMessages from "messages/fees_trading"
import newsMessages from "messages/news"
import support_messages from "messages/support"
import { useMst } from "models/Root"
import config from "helpers/config"
import { socialNetworks } from "constants/socialNetworks"
import Logo from "assets/icons/Logo"
import InternalLink from "components/InternalLink"
import styles from "./index.module.scss"

interface IListItem {
	label: string | React.ReactNode
	link: string
	permission: boolean
}

const FooterLarge: React.FC<{ isHomePage?: boolean }> = ({ isHomePage = false }) => {
	const {
		global: { locale },
		render,
	} = useMst()
	const [currentOpen, setCurrentOpen] = useState(0)
	const currentYear = dayjs().year()
	const { formatMessage } = useIntl()

	const productsList: IListItem[] = [
		{
			link: routes.trade.root,
			label: formatMessage(messages.exchange),
			permission: true,
		},
		{
			link: routes.stories.root,
			label: formatMessage(messages.alpha_stories),
			permission: render.stories,
		},
	]

	const informationList: IListItem[] = [
		{
			link: "/#",
			label: formatMessage(messages.home),
			permission: true,
		},
		// {
		// 	link: "/halving",
		// 	label: formatMessage(messages.halving),
		// 	permission: true,
		// },
		{
			link: routes.aboutUs.root,
			label: formatMessage(messages.about_us),
			permission: true,
		},
		{
			link: routes.fees,
			label: formatMessage(messages.fee),
			permission: true,
		},
		{
			link: routes.tradingFees,
			label: formatMessage(feesMessages.trading_fees),
			permission: true,
		},
		{
			link: routes.news.root,
			label: formatMessage(newsMessages.news),
			permission: render.news,
		},
		{
			link: routes.support.root,
			label: formatMessage(support_messages.support_center),
			permission: render.supportCenter,
		},
	]

	const companyList: IListItem[] = [
		{
			link: routes.termsOfUse,
			label: formatMessage(messages.confirm_term_of_use),
			permission: true,
		},
		{
			link: routes.privacyPolicy,
			label: formatMessage(messages.privacy_policy_context),
			permission: true,
		},
		{
			link: routes.riskWarning,
			label: formatMessage(messages.risk_warning),
			permission: true,
		},
		{
			link: routes.amlKycPolicy,
			label: formatMessage(messages.kyc_policy),
			permission: true,
		},
		{
			link: routes.marginAgreement,
			label: formatMessage(messages.margin_agreement),
			permission: true,
		},
	]

	return (
		<footer className={styles.footer}>
			<div className={`${isHomePage ? styles.footer__container___home : styles.footer__container}`}>
				<div className={styles.footer__content}>
					<div className={styles.footer__data}>
						<div className={styles.footer__logoWrapper}>
							<InternalLink className={styles.footer__link} to="/">
								<Logo />
							</InternalLink>
						</div>

						<div className={styles.footer__copy_license_wrapper}>
							<p>Copyright {currentYear} © BTC-Alpha company. All right reserved</p>
							<div className={styles.footer__license}>
								<p>ALPHACOM Spzoo</p>
								<p>KRS: 0001124473,</p>
								<p>UL. HOŻA 86 / 210, 00-682 Warsaw, Poland</p>
							</div>
						</div>

						<ul className={styles.footer__socialList}>
							{socialNetworks(locale).map(({ link, icon: Icon, label }) => (
								<li key={`${link}-social`} className={styles.footer__socialItem}>
									<a
										key={label}
										className={styles.item}
										href={link}
										target="_blank"
										rel="noopener noreferrer"
										aria-label={`${label} ${config.department}`}
									>
										<Icon />
									</a>
								</li>
							))}
						</ul>
					</div>

					<ul className={styles.footer__columns}>
						<li
							className={`${styles.footer__column} ${
								currentOpen === 0 ? styles.footer__column___active : ""
							}`}
						>
							<button
								className={styles.footer__columnHeadlineButton}
								onClick={() => setCurrentOpen(0)}
								type="button"
							>
								<p className={styles.footer__columnHeadline}>{formatMessage(messages.products)}</p>

								<FooterArrow />
							</button>

							<ul className={styles.footer__columnList}>
								{productsList.map(({ link, permission, label }) =>
									permission ? (
										<li key={link + label} className={styles.footer__columnItem}>
											<InternalLink className={styles.footer__link} to={link}>
												{label}
											</InternalLink>
										</li>
									) : null,
								)}
							</ul>
						</li>

						<li
							className={`${styles.footer__column} ${
								currentOpen === 1 ? styles.footer__column___active : ""
							}`}
						>
							<button
								className={styles.footer__columnHeadlineButton}
								onClick={() => setCurrentOpen(1)}
								type="button"
							>
								<p className={styles.footer__columnHeadline}>
									{formatMessage(messages.information)}
								</p>

								<FooterArrow />
							</button>

							<ul className={styles.footer__columnList}>
								{informationList.map(({ link, permission, label }) =>
									permission ? (
										<li className={styles.footer__columnItem} key={link + label}>
											<InternalLink className={styles.footer__link} to={link}>
												{label}
											</InternalLink>
										</li>
									) : null,
								)}
								<li className={styles.footer__columnItem}>
									<a
										href={config.publicApiPath}
										className={styles.footer__link}
										target="_blank"
										rel="noopener noreferrer"
									>
										{formatMessage(messages.api)}
									</a>
								</li>
							</ul>
						</li>

						<li
							className={`${styles.footer__column} ${
								currentOpen === 2 ? styles.footer__column___active : ""
							}`}
						>
							<button
								className={styles.footer__columnHeadlineButton}
								onClick={() => setCurrentOpen(2)}
								type="button"
							>
								<p className={styles.footer__columnHeadline}>{formatMessage(messages.company)}</p>

								<FooterArrow />
							</button>

							<ul className={styles.footer__columnList}>
								{companyList.map(({ link, permission, label }) =>
									permission ? (
										<li className={styles.footer__columnItem} key={link + label}>
											<InternalLink className={styles.footer__link} to={link}>
												{label}
											</InternalLink>
										</li>
									) : null,
								)}
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	)
}

export default observer(FooterLarge)
