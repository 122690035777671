/* eslint-disable no-unused-expressions */
import React, { CSSProperties, useMemo } from "react"
import cn from "classnames"

import {
	ECalendarType,
	TColors,
	TDayNames,
	TEdgeDay,
	TFonts,
	TMonthNames,
	TOnHover,
	TOnSelect,
	TPaddings,
} from "./types"
import { dateToStr, getMonthLastDate, getScrollbarWidth, getWeekDay, mapWeekDay } from "./utils"
import {
	defaultColors,
	defaultDayNames,
	defaultFonts,
	defaultMonthNames,
	defaultMonthShortNames,
	defaultProps,
} from "./constants"

import Days, { TDaysData } from "./days"
import Months, { TMonthsData } from "./months"

import "./style.scss"

type TProps = {
	minDate: TEdgeDay
	maxDate: TEdgeDay
	range?: boolean
	firstWeekDay?: number
	weekHeight?: number
	dayHeight?: number
	daysPadding?: TPaddings
	monthHeight?: number
	yearWidth?: number
	monthsPadding?: TPaddings
	selectedRadius?: number
	calendarType?: ECalendarType
	colors?: Partial<TColors>
	fonts?: Partial<TFonts>
	dayNames?: TDayNames
	monthNames?: TMonthNames
	monthShortNames?: TMonthNames
	value?: null | Date | [Date, Date]
	className?: string
	onSelect?: TOnSelect
	onHover?: TOnHover
}

const Calendar: React.FC<TProps> = ({
	minDate,
	maxDate,
	range = false,
	firstWeekDay = defaultProps.firstWeekDay,
	weekHeight = defaultProps.weekHeight,
	dayHeight = defaultProps.dayHeight,
	daysPadding = defaultProps.daysPadding as TPaddings,
	monthHeight = defaultProps.monthHeight,
	yearWidth = defaultProps.yearWidth,
	monthsPadding = defaultProps.monthsPadding as TPaddings,
	selectedRadius = defaultProps.selectedRadius,
	calendarType = defaultProps.calendarType,
	colors = {},
	fonts = {},
	dayNames = {},
	monthNames = {},
	monthShortNames = {},
	value,
	className,
	onSelect,
	onHover,
}) => {
	colors = { ...defaultColors, ...colors } as TColors
	fonts = { ...defaultFonts, ...fonts } as TFonts
	dayNames = { ...defaultDayNames, ...dayNames }
	monthNames = { ...defaultMonthNames, ...monthNames }
	monthShortNames = { ...defaultMonthShortNames, ...monthShortNames }

	let valueFromStr = ""
	let valueToStr = ""
	if (value instanceof Date) {
		valueFromStr = dateToStr(value)
		valueToStr = dateToStr(value)
	} else if (value instanceof Array && value.length === 2) {
		valueFromStr = dateToStr(value[0])
		valueToStr = dateToStr(value[1])
	}

	minDate = new Date(minDate)
	if (Number.isNaN(minDate.getTime())) {
		minDate = new Date()
		minDate.setMonth(0)
		minDate.setDate(1)
	}
	maxDate = new Date(maxDate)
	if (Number.isNaN(maxDate.getTime())) {
		maxDate = new Date()
		maxDate.setMonth(0)
		maxDate.setDate(1)
	}

	const minDateStr = dateToStr(minDate)
	const maxDateStr = dateToStr(maxDate)

	const monthsData = useMemo(() => {
		if (calendarType !== ECalendarType.MONTH) return []
		const result: TMonthsData[] = []
		const minYear = (minDate as Date).getFullYear()
		const maxYear = (maxDate as Date).getFullYear()
		const verticalPadding =
			(typeof monthsPadding === "number" ? monthsPadding : monthsPadding[0]) * 2
		const height = monthHeight * 2 + verticalPadding
		let top = 0
		for (let year = minYear; year <= maxYear; year++) {
			result.push({
				year,
				top,
				height,
			})
			top += height
		}
		return result
	}, [calendarType, minDateStr, maxDateStr, monthHeight])

	const daysData = useMemo(() => {
		if (![ECalendarType.DAY, ECalendarType.WEEK].includes(calendarType)) return []
		const result: TDaysData[] = []
		const currentMonth = new Date(minDate)
		currentMonth.setDate(1)
		let fullHeight = 0
		while (currentMonth <= maxDate) {
			const lastDate = getMonthLastDate(currentMonth)
			const firstDay = mapWeekDay(currentMonth.getDay(), firstWeekDay)
			const lastDay = mapWeekDay(lastDate.getDay(), firstWeekDay)
			const days = lastDate.getDate()
			const lines = Math.ceil((days + firstDay - 1) / 7)
			result.push({
				year: currentMonth.getFullYear(),
				month: currentMonth.getMonth() + 1,
				days,
				lines,
				firstDay,
				lastDay,
				top: fullHeight,
				height: lines * dayHeight + dayHeight,
			})
			fullHeight += lines * dayHeight + dayHeight
			currentMonth.setMonth(currentMonth.getMonth() + 1)
		}
		return result
	}, [calendarType, minDateStr, maxDateStr, firstWeekDay, dayHeight])

	const today = new Date()
	const todayStr = dateToStr(today)

	const rootStyle: CSSProperties = {
		fontFamily: fonts.family,
		backgroundColor: colors.background,
	}

	const daysHorizontalPadding = typeof monthsPadding === "number" ? monthsPadding : monthsPadding[1]
	const scrollBarWidth = useMemo(() => getScrollbarWidth(), [])
	const weekNamesStyle: CSSProperties = {
		height: weekHeight,
		paddingLeft: daysHorizontalPadding,
		paddingRight: scrollBarWidth + daysHorizontalPadding,
		backgroundColor: colors.weekBackground,
		color: colors.weekText,
		fontSize: fonts.daysWeek,
		borderBottomColor: colors.borders,
	}

	return (
		<div className={cn("calendar", className)} style={rootStyle}>
			<div className="header">
				{[ECalendarType.DAY, ECalendarType.WEEK].includes(calendarType) && (
					<div className="week" style={weekNamesStyle}>
						{Array.from(Array(7).keys()).map(day => (
							<div key={day}>{dayNames[getWeekDay(day, firstWeekDay)]}</div>
						))}
					</div>
				)}
			</div>
			{calendarType === ECalendarType.MONTH ? (
				<Months
					todayStr={todayStr}
					minDateStr={minDateStr}
					maxDateStr={maxDateStr}
					data={monthsData}
					monthNames={monthShortNames}
					range={range}
					monthHeight={monthHeight}
					yearWidth={yearWidth}
					monthsPadding={monthsPadding}
					selectedRadius={selectedRadius}
					colors={colors as TColors}
					fonts={fonts as TFonts}
					valueFromStr={valueFromStr}
					valueToStr={valueToStr}
					onSelect={onSelect}
					onHover={onHover}
				/>
			) : (
				<Days
					todayStr={todayStr}
					minDateStr={minDateStr}
					maxDateStr={maxDateStr}
					data={daysData}
					monthNames={monthNames}
					range={range}
					dayHeight={dayHeight}
					daysPadding={daysPadding}
					selectedRadius={selectedRadius}
					firstWeekDay={firstWeekDay}
					calendarType={calendarType}
					colors={colors as TColors}
					fonts={fonts as TFonts}
					valueFromStr={valueFromStr}
					valueToStr={valueToStr}
					onSelect={onSelect}
					onHover={onHover}
				/>
			)}
		</div>
	)
}

export default Calendar
