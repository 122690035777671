import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"

import styles from "../index.module.scss"

const Tab: React.FC<{
	quotedCurrency: string
	name: string
	label: string
	setQuotedCurrency: (name: string) => void
}> = ({ quotedCurrency, name, label, setQuotedCurrency }) => {
	const onChange = useCallback(() => setQuotedCurrency(name), [name])

	return (
		<li>
			<input
				className={`visually-hidden ${styles.search__tabRadio}`}
				type="radio"
				name="searchTab"
				id={`${label}SearchTab`}
				value={`${label}SearchTab`}
				onChange={onChange}
				checked={quotedCurrency === name}
			/>

			<label className={styles.search__tabButton} htmlFor={`${label}SearchTab`}>
				<span>{label}</span>
			</label>
		</li>
	)
}

export default observer(Tab)
