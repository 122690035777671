import React, { useMemo, useState, useEffect } from "react"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import AccountService from "services/AccountService"
import useConfirmModal from "hooks/useConfirmModal"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"

import RepayModal from "components/NewUI/Terminal/RepayModal"

interface BorrowData {
	available_to_borrow: number
	borrow_amount: number
	borrow_usage_rate: number
	borrowable: boolean
	collateral_ratio: number
	collateral_switch: boolean
	currency: string
	free_borrow_amount: number
	free_borrowing_amount: string
	free_borrowing_limit: number
	hourly_borrow_rate: number
	margin_collateral: boolean
	max_borrowing_amount: number
}

const useBorrows = () => {
	const { formatMessage } = useIntl()
	const { openConfirmModal } = useConfirmModal()
	const {
		global: { isAuthenticated },
	} = useMst()

	const [borrowsData, setBorrowsData] = useState<BorrowData[]>([])

	const handleMarginRepay = (coin: string, amount: number) =>
		openConfirmModal({
			title: formatMessage(exchangeMessages.repay),
			content: <RepayModal coin={coin} amount={amount} />,
			width: 500,
			onConfirm: async () => {
				await new Promise(r => {
					setTimeout(r, 2000)
				})
				return false
			},
		})

	useEffect(() => {
		const fetchCollateralInfo = async () => {
			try {
				const data = await AccountService.getCollateralInfo({ is_borrowed: true })

				if (data && Array.isArray(data)) {
					setBorrowsData(data as BorrowData[])
				} else {
					console.error("Received data is not in expected format:", data)
				}
			} catch (error) {
				console.error("Error fetching collateral info:", error)
			}
		}

		if (isAuthenticated) fetchCollateralInfo()
	}, [isAuthenticated])

	const listScheme = [
		{
			arrCount: 1,
			id: 123,
			arrNames: [
				{
					headline: formatMessage(historyMessages.currency),
					width: 240,
					asociate: "currency",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 11,
			id: 223,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.borrowed_amount),
					width: 148,
					asociate: "borrow_amount",
				},
				{
					headline: formatMessage(historyMessages.hourly_borrow_rate),
					width: 168,
					asociate: "hourly_borrow_rate",
				},
				{
					headline: formatMessage(historyMessages.max_borrowed_amount),
					width: 168,
					asociate: "max_borrowing_amount",
				},
				{
					headline: formatMessage(historyMessages.active_orders_action),
					width: 150,
					asociate: "action",
				},
			],
		},
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	const formattedBorrowsData = useMemo(
		() =>
			Array.isArray(borrowsData)
				? borrowsData.map(borrow => ({
						...borrow,
						max_borrowing_amount: borrow.max_borrowing_amount || "-",
				  }))
				: [],
		[borrowsData],
	)

	return {
		isAuthenticated,
		listScheme,
		listHeadline,
		list: formattedBorrowsData,
		handleMarginRepay,
	}
}

export default useBorrows
