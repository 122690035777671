/* eslint-disable no-unused-expressions */
import React, { CSSProperties, MouseEventHandler } from "react"

import { ESelectionStatus, TColors, TFonts } from "../types"
import { maxStr, minStr } from "../utils"

type TProps = {
	day: number
	firstDay: number
	monthStr: string
	todayStr: string
	minDateStr: string
	maxDateStr: string
	hoveredDates: string[]
	selectedDates: string[]
	selectionStatus: ESelectionStatus
	dayHeight: number
	dayWidth: number
	selectedRadius: number
	colors: TColors
	fonts: TFonts
	onDayClick: MouseEventHandler<HTMLDivElement>
	onDayHover: (dateStr: string) => void
}

const Day: React.FC<TProps> = ({
	day,
	firstDay,
	monthStr,
	todayStr,
	minDateStr,
	maxDateStr,
	hoveredDates,
	selectedDates,
	selectionStatus,
	dayHeight,
	dayWidth,
	selectedRadius,
	colors,
	fonts,
	onDayClick,
	onDayHover,
}) => {
	const {
		daysDayText,
		todayBorder,
		hoveredDayBackground,
		selectedHoveredDayBackground,
		selectedDayBackground,
		selectedDayText,
		betweenSelectedDaysBackground,
	} = colors

	const isFirstDay = day === 1
	const dateStr = `${monthStr}-${day.toString().padStart(2, "0")}`
	const isToday = dateStr === todayStr
	const isActive = minDateStr <= dateStr && dateStr <= maxDateStr
	const isHovered =
		hoveredDates.length &&
		selectionStatus !== ESelectionStatus.FIRST &&
		dateStr >= hoveredDates[0] &&
		dateStr <= hoveredDates[1]
	const isFirstHovered = hoveredDates.length && dateStr === hoveredDates[0]
	const isLastHovered = hoveredDates.length && dateStr === hoveredDates[1]
	const dates =
		selectionStatus === ESelectionStatus.FIRST ? [...hoveredDates, ...selectedDates] : selectedDates
	const minDate = minStr(...dates)
	const maxDate = maxStr(...dates)
	const isBetween = dateStr >= minDate && dateStr <= maxDate
	const isFirstInRange = dateStr === minDate
	const isLastInRange = dateStr === maxDate
	const className = [`day`]
	isFirstDay && className.push(`week-day-${firstDay}`)
	!isActive && className.push(`inactive`)

	const fitSize = Math.min(dayHeight, dayWidth)

	const dayStyle = {
		height: dayHeight,
	}

	const secondTextWrapperStyle: CSSProperties = {}
	if (isHovered) {
		secondTextWrapperStyle.backgroundColor = hoveredDayBackground
	} else if (isBetween) {
		secondTextWrapperStyle.backgroundColor = betweenSelectedDaysBackground
	}
	if (isFirstInRange || (!isBetween && isFirstHovered)) {
		secondTextWrapperStyle.borderTopLeftRadius = selectedRadius
		secondTextWrapperStyle.borderBottomLeftRadius = selectedRadius
	}
	if (isLastInRange || (!isBetween && isLastHovered)) {
		secondTextWrapperStyle.borderTopRightRadius = selectedRadius
		secondTextWrapperStyle.borderBottomRightRadius = selectedRadius
	}

	const thirdTextWrapperStyle: CSSProperties = {
		borderRadius: selectedRadius,
		fontSize: fonts.daysDay,
	}
	if (isFirstInRange || isLastInRange) {
		thirdTextWrapperStyle.color = selectedDayText
		if (isHovered) {
			thirdTextWrapperStyle.backgroundColor = selectedHoveredDayBackground
		} else {
			thirdTextWrapperStyle.backgroundColor = selectedDayBackground
		}
	} else {
		thirdTextWrapperStyle.color = daysDayText
	}

	const todayStyle: CSSProperties = {
		width: fitSize - 4,
		height: fitSize - 4,
		marginLeft: (fitSize - 4) / -2,
		marginTop: (fitSize - 4) / -2,
		borderColor: todayBorder,
		borderRadius: selectedRadius,
	}

	return (
		<div className={className.join(" ")} style={dayStyle}>
			<div onClick={onDayClick} onMouseEnter={() => onDayHover(dateStr)}>
				<div style={secondTextWrapperStyle}>
					<div style={thirdTextWrapperStyle}>{day}</div>
				</div>
			</div>
			{isToday && <div className="today" style={todayStyle} />}
		</div>
	)
}

export default Day
