import React from "react"
import { observer } from "mobx-react-lite"

import { IDeposit } from "models/Deposit"
import DepositService from "services/DepositService"
import { formatDate } from "utils/formatDate"

import Table, { TTableOnFetch } from "components/redesigned/Table"

import { useColumns, useFilters } from "./hooks"

const Deposits = () => {
	const columns = useColumns()
	const filters = useFilters()

	const handleFetch: TTableOnFetch<IDeposit> = async ({ cursor, limit, filters }) => {
		const dates = filters?.date?.split(",")
		const { results, cursor: next } = await DepositService.getDepositHistory({
			cursor,
			limit,
			currency: filters.currency as string,
			start_time: dates && formatDate(dates[0], "MM/dd/yyyy"),
			end_time: dates && formatDate(dates[1], "MM/dd/yyyy"),
		})
		return { data: results, next }
	}

	return (
		<Table<IDeposit>
			id="date"
			columns={columns}
			filters={filters}
			//TODO: translate
			empty="No orders to show."
			lazyLoad="cursor"
			resetFilters
			defFetching
			footer="pagination"
			onFetch={handleFetch}
		/>
	)
}

export default observer(Deposits)
