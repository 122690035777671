import React, { CSSProperties, ReactNode } from "react"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"
import { rem } from "utils/styles"

import AlphaIcon from "components/redesigned/AlphaIcon"

import styles from "./miniButton.module.scss"

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	caption?: string | number
	icon?: ReactNode | TAlphaIcon
	iconSize?: number
	bordered?: boolean
	disabled?: boolean
}

const MiniButton: React.FC<IProps> = ({
	caption,
	icon,
	iconSize,
	bordered,
	className,
	...props
}) => {
	const style: Record<string, string> = {}
	if (iconSize) style["--icon-size"] = rem(iconSize)

	return (
		<button
			type="button"
			style={Object.keys(style).length ? (style as CSSProperties) : undefined}
			className={cn(styles.miniButton, { [styles.bordered]: bordered }, className)}
			{...props}
		>
			{typeof icon === "string" ? <AlphaIcon name={icon as TAlphaIcon} /> : icon}
			{!!caption && <span>{caption}</span>}
		</button>
	)
}

export default MiniButton
