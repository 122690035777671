import { makeAutoObservable, reaction } from "mobx"

import BybitService from "services/BybitService"
import errorHandler from "utils/errorHandler"
import { TBybitInstrumentSpot } from "types/bybit"

export class CurrentPairStore {
	// is currentPair loaded
	isLoaded = false

	symbol = ""

	constructor() {
		makeAutoObservable(this)

		reaction(
			() => this.symbol,
			symbol => {
				this.loadCurrentPair(symbol).then(() => null)
			},
		)
	}

	setSymbol(symbol: string) {
		this.symbol = symbol
	}

	async loadCurrentPair(symbol: string) {
		try {
			const response = await BybitService.getInstrumentsInfo({
				category: "spot",
				symbol,
			})

			const list = response.list

			if (Array.isArray(list) && list.length > 0) {
				const list = response.list

				this.setSpotCurrentPair(list[0] as TBybitInstrumentSpot)
			} else {
				console.error("Invalid pairs data format", response.list)
			}
		} catch (err) {
			errorHandler(err)
		}
	}

	setSpotCurrentPair(data: TBybitInstrumentSpot) {
		console.log(data)
		this.isLoaded = true
		// self.currentPair = cast(data)
		// self.setOrderBookPrecision(getPrecision(self.pairPricePrecision))
	}
}
