import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Tabs from "components/UI/Tabs"
import { useMst } from "models/Root"
import { AccountTypeEnum, EBybitWalletType, getEBybitWalletTypeLabel } from "types/account"
import styles from "styles/pages/Wallets.module.scss"
import Tab from "components/UI/Tab"
import useParamQuery from "hooks/useSearchQuery"
import { queryVars } from "constants/query"

const PREFERRED_CURRENCIES = ["USD", "EUR"]
const PREFERRED_COIN = "BTC"

const WalletsTabs: React.FC = () => {
	const {
		finance: { walletsFilter, setWalletsFilter },
		account: { getTypes, selectedType, loadBalances },
		buyCrypto: { rates },
	} = useMst()
	const { formatMessage } = useIntl()
	const navigate = useNavigate()
	const query = useParamQuery()

	useEffect(() => {
		// if (!rates.length) loadFiatRates({ direction: 1 })
	}, [])

	useEffect(() => {
		const type = query.get(queryVars.type) as AccountTypeEnum
		if (
			type &&
			[AccountTypeEnum.SPOT, AccountTypeEnum.CROSS, AccountTypeEnum.ISOLATED].includes(type)
		) {
			walletsFilter.setAccountType(type)
		}
	}, [])

	const handleTabChange = (name: string) => {
		let accountType = name

		if (name === EBybitWalletType.FUND) {
			accountType = "FUND"
		} else if (name === EBybitWalletType.UNIFIED) {
			accountType = "UNIFIED"
		}

		setWalletsFilter({
			...walletsFilter,
			accountType: accountType,
			sort:
				name === AccountTypeEnum.CROSS
					? "code.asc"
					: name === AccountTypeEnum.ISOLATED
					? "pair.asc"
					: "",
		})

		loadBalances(accountType)

		navigate({ [queryVars.search]: `?${queryVars.type}=${accountType}` })
	}

	return (
		<Tabs className={styles.tabs}>
			{getTypes().map(({ name, label }) => (
				<Tab
					key={`wallet-tab-${name}`}
					name={name}
					isActive={selectedType === name}
					className={styles.tab}
					onClick={() => handleTabChange(name)}
					label={getEBybitWalletTypeLabel(name as EBybitWalletType, formatMessage) || label}
				/>
			))}
		</Tabs>
	)
}

export default observer(WalletsTabs)
