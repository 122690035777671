import { Instance, types as t } from "mobx-state-tree"
import config from "helpers/config"
import { RenderModuleEnum } from "types/render"

export const Render = t.model({
	mobileApp: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.MOBILE_APP)),
	paymentServices: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.PAYMENT_SERVICE)),
	margin: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.MARGIN)),
	referrals: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.REFERRALS)),
	transfers: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.TRANSFERS)),
	news: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.NEWS)),
	cryptoNews: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.CRYPTO_NEWS)),
	stories: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.STORIES)),
	homeStats: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.HOME_STATS)),
	supportCenter: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.SUPPORT_CENTER)),
	alpCoin: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.ALP_COIN)),
	giveaway: t.optional(t.boolean, config.isModuleOn(RenderModuleEnum.GIVEAWAY)),
})

export type IRender = Instance<typeof Render>
