import React, { ChangeEventHandler, PropsWithChildren } from "react"
import cn from "classnames"

import styles from "./checkbox.module.scss"

type TProps = {
	name?: string
	label?: string
	checked?: boolean
	disabled?: boolean
	required?: boolean
	errors?: string
	className?: string
	onChange?: ChangeEventHandler<HTMLInputElement>
}

const CheckBox: React.FC<PropsWithChildren<TProps>> = ({
	name,
	label,
	disabled = false,
	checked,
	required = false,
	errors,
	className,
	children,
	onChange,
}) => (
	<label className={cn(styles.checkbox, className)}>
		<input
			type="checkbox"
			name={name}
			onChange={onChange}
			required={required}
			disabled={disabled}
			checked={checked}
		/>

		<div className={cn(styles.checkmark, { [styles.error]: errors })} />

		{(children || label) && <span className={styles.content}>{children || label}</span>}
	</label>
)

export default CheckBox
