import acaIcon from "assets/icons/coins/aca.svg"
import achIcon from "assets/icons/coins/ach.svg"
import acsIcon from "assets/icons/coins/acs.svg"
import adaIcon from "assets/icons/coins/ada.svg"
import afcIcon from "assets/icons/coins/afc.svg"
import agiIcon from "assets/icons/coins/agi.svg"
import agixIcon from "assets/icons/coins/agix.svg"
import aglaIcon from "assets/icons/coins/agla.svg"
import agldIcon from "assets/icons/coins/agld.svg"
import aiozIcon from "assets/icons/coins/aioz.svg"
import algoIcon from "assets/icons/coins/algo.svg"
import ankrIcon from "assets/icons/coins/ankr.svg"
import apeIcon from "assets/icons/coins/ape.svg"
import apexIcon from "assets/icons/coins/apex.svg"
import aptIcon from "assets/icons/coins/apt.svg"
import arIcon from "assets/icons/coins/ar.svg"
import arbiIcon from "assets/icons/coins/arbi.svg"
import arkmIcon from "assets/icons/coins/arkm.svg"
import athIcon from "assets/icons/coins/ath.svg"
import atomIcon from "assets/icons/coins/atom.svg"
import auroraIcon from "assets/icons/coins/aurora.svg"
import auryIcon from "assets/icons/coins/aury.svg"
import avaIcon from "assets/icons/coins/ava.svg"
import avaxIcon from "assets/icons/coins/avax.svg"
import axlIcon from "assets/icons/coins/axl.svg"
import axsIcon from "assets/icons/coins/axs.svg"
import babydogeIcon from "assets/icons/coins/babydoge.svg"
import batIcon from "assets/icons/coins/bat.svg"
import bblIcon from "assets/icons/coins/bbl.svg"
import bchIcon from "assets/icons/coins/bch.svg"
import beamIcon from "assets/icons/coins/beam.svg"
import belIcon from "assets/icons/coins/bel.svg"
import bicoIcon from "assets/icons/coins/bico.svg"
import bitIcon from "assets/icons/coins/bit.svg"
import blastIcon from "assets/icons/coins/blast.svg"
import blockIcon from "assets/icons/coins/block.svg"
import blurIcon from "assets/icons/coins/blur.svg"
import bnbIcon from "assets/icons/coins/bnb.svg"
import bntIcon from "assets/icons/coins/bnt.svg"
import bobIcon from "assets/icons/coins/bob.svg"
import bobaIcon from "assets/icons/coins/boba.svg"
import bonkIcon from "assets/icons/coins/bonk.svg"
import btcIcon from "assets/icons/coins/btc.svg"
import btgIcon from "assets/icons/coins/btg.svg"
import bttIcon from "assets/icons/coins/btt.svg"
import c98Icon from "assets/icons/coins/c98.svg"
import cakeIcon from "assets/icons/coins/cake.svg"
import capsIcon from "assets/icons/coins/caps.svg"
import catIcon from "assets/icons/coins/cat.svg"
import cbxIcon from "assets/icons/coins/cbx.svg"
import celIcon from "assets/icons/coins/cel.svg"
import celoIcon from "assets/icons/coins/celo.svg"
import cgptIcon from "assets/icons/coins/cgpt.svg"
import chrpIcon from "assets/icons/coins/chrp.svg"
import chzIcon from "assets/icons/coins/chz.svg"
import compIcon from "assets/icons/coins/comp.svg"
import coreIcon from "assets/icons/coins/core.svg"
import cpoolIcon from "assets/icons/coins/cpool.svg"
import crvIcon from "assets/icons/coins/crv.svg"
import csprIcon from "assets/icons/coins/cspr.svg"
import ctcIcon from "assets/icons/coins/ctc.svg"
import cultIcon from "assets/icons/coins/cult.svg"
import cusdIcon from "assets/icons/coins/cusd.svg"
import cwarIcon from "assets/icons/coins/cwar.svg"
import cyberIcon from "assets/icons/coins/cyber.svg"
import daiIcon from "assets/icons/coins/dai.svg"
import dcrIcon from "assets/icons/coins/dcr.svg"
import defaultIcon from "assets/icons/coins/default.svg"
import defiIcon from "assets/icons/coins/defi.svg"
import degenIcon from "assets/icons/coins/degen.svg"
import devtIcon from "assets/icons/coins/devt.svg"
import dfiIcon from "assets/icons/coins/dfi.svg"
import dgbIcon from "assets/icons/coins/dgb.svg"
import dlcIcon from "assets/icons/coins/dlc.svg"
import dogeIcon from "assets/icons/coins/doge.svg"
import domeIcon from "assets/icons/coins/dome.svg"
import dotIcon from "assets/icons/coins/dot.svg"
import dpxIcon from "assets/icons/coins/dpx.svg"
import dsrunIcon from "assets/icons/coins/dsrun.svg"
import dydxIcon from "assets/icons/coins/dydx.svg"
import dzooIcon from "assets/icons/coins/dzoo.svg"
import ecoxIcon from "assets/icons/coins/ecox.svg"
import egldIcon from "assets/icons/coins/egld.svg"
import egoIcon from "assets/icons/coins/ego.svg"
import enjIcon from "assets/icons/coins/enj.svg"
import ensIcon from "assets/icons/coins/ens.svg"
import eosIcon from "assets/icons/coins/eos.svg"
import erthaIcon from "assets/icons/coins/ertha.svg"
import etcIcon from "assets/icons/coins/etc.svg"
import ethIcon from "assets/icons/coins/eth.svg"
import ethwIcon from "assets/icons/coins/ethw.svg"
import everIcon from "assets/icons/coins/ever.svg"
import fetIcon from "assets/icons/coins/fet.svg"
import fidaIcon from "assets/icons/coins/fida.svg"
import filIcon from "assets/icons/coins/fil.svg"
import fitfiIcon from "assets/icons/coins/fitfi.svg"
import flowIcon from "assets/icons/coins/flow.svg"
import flrIcon from "assets/icons/coins/flr.svg"
import fortIcon from "assets/icons/coins/fort.svg"
import ftmIcon from "assets/icons/coins/ftm.svg"
import fttIcon from "assets/icons/coins/ftt.svg"
import fxsIcon from "assets/icons/coins/fxs.svg"
import galIcon from "assets/icons/coins/gal.svg"
import galaIcon from "assets/icons/coins/gala.svg"
import geneIcon from "assets/icons/coins/gene.svg"
import ggIcon from "assets/icons/coins/gg.svg"
import ggmIcon from "assets/icons/coins/ggm.svg"
import glmrIcon from "assets/icons/coins/glmr.svg"
import gmIcon from "assets/icons/coins/gm.svg"
import gmtIcon from "assets/icons/coins/gmt.svg"
import gmxIcon from "assets/icons/coins/gmx.svg"
import gnsIcon from "assets/icons/coins/gns.svg"
import godsIcon from "assets/icons/coins/gods.svg"
import grtIcon from "assets/icons/coins/grt.svg"
import gstsIcon from "assets/icons/coins/gsts.svg"
import gswiftIcon from "assets/icons/coins/gswift.svg"
import hbarIcon from "assets/icons/coins/hbar.svg"
import heroIcon from "assets/icons/coins/hero.svg"
import hftIcon from "assets/icons/coins/hft.svg"
import hntIcon from "assets/icons/coins/hnt.svg"
import honIcon from "assets/icons/coins/hon.svg"
import hookIcon from "assets/icons/coins/hook.svg"
import hotIcon from "assets/icons/coins/hot.svg"
import hvhIcon from "assets/icons/coins/hvh.svg"
import icpIcon from "assets/icons/coins/icp.svg"
import icxIcon from "assets/icons/coins/icx.svg"
import idIcon from "assets/icons/coins/id.svg"
import imxIcon from "assets/icons/coins/imx.svg"
import injIcon from "assets/icons/coins/inj.svg"
import iziIcon from "assets/icons/coins/izi.svg"
import jasmyIcon from "assets/icons/coins/jasmy.svg"
import jstIcon from "assets/icons/coins/jst.svg"
import jupIcon from "assets/icons/coins/jup.svg"
import karateIcon from "assets/icons/coins/karate.svg"
import kasIcon from "assets/icons/coins/kas.svg"
import kastaIcon from "assets/icons/coins/kasta.svg"
import kavaIcon from "assets/icons/coins/kava.svg"
import kcsIcon from "assets/icons/coins/kcs.svg"
import kdaIcon from "assets/icons/coins/kda.svg"
import klayIcon from "assets/icons/coins/klay.svg"
import kmonIcon from "assets/icons/coins/kmon.svg"
import krlIcon from "assets/icons/coins/krl.svg"
import ksmIcon from "assets/icons/coins/ksm.svg"
import kubIcon from "assets/icons/coins/kub.svg"
import kunciIcon from "assets/icons/coins/kunci.svg"
import ladysIcon from "assets/icons/coins/ladys.svg"
import laiIcon from "assets/icons/coins/lai.svg"
import ldoIcon from "assets/icons/coins/ldo.svg"
import leverIcon from "assets/icons/coins/lever.svg"
import lfwIcon from "assets/icons/coins/lfw.svg"
import lingIcon from "assets/icons/coins/ling.svg"
import linkIcon from "assets/icons/coins/link.svg"
import lmwrIcon from "assets/icons/coins/lmwr.svg"
import looksIcon from "assets/icons/coins/looks.svg"
import lrcIcon from "assets/icons/coins/lrc.svg"
import ltcIcon from "assets/icons/coins/ltc.svg"
import lunaIcon from "assets/icons/coins/luna.svg"
import luncIcon from "assets/icons/coins/lunc.svg"
import manaIcon from "assets/icons/coins/mana.svg"
import mantleIcon from "assets/icons/coins/mantle.svg"
import maskIcon from "assets/icons/coins/mask.svg"
import maticIcon from "assets/icons/coins/matic.svg"
import mboxIcon from "assets/icons/coins/mbox.svg"
import mbxIcon from "assets/icons/coins/mbx.svg"
import mcrtIcon from "assets/icons/coins/mcrt.svg"
import mdaoIcon from "assets/icons/coins/mdao.svg"
import memeIcon from "assets/icons/coins/meme.svg"
import minaIcon from "assets/icons/coins/mina.svg"
import minuIcon from "assets/icons/coins/minu.svg"
import mixIcon from "assets/icons/coins/mix.svg"
import mkrIcon from "assets/icons/coins/mkr.svg"
import mlkIcon from "assets/icons/coins/mlk.svg"
import mogIcon from "assets/icons/coins/mog.svg"
import movrIcon from "assets/icons/coins/movr.svg"
import mplxIcon from "assets/icons/coins/mplx.svg"
import musdIcon from "assets/icons/coins/musd.svg"
import mvIcon from "assets/icons/coins/mv.svg"
import mvlIcon from "assets/icons/coins/mvl.svg"
import mxIcon from "assets/icons/coins/mx.svg"
import mxmIcon from "assets/icons/coins/mxm.svg"
import myriaIcon from "assets/icons/coins/myria.svg"
import nakaIcon from "assets/icons/coins/naka.svg"
import nearIcon from "assets/icons/coins/near.svg"
import neonIcon from "assets/icons/coins/neon.svg"
import nexoIcon from "assets/icons/coins/nexo.svg"
import nftIcon from "assets/icons/coins/nft.svg"
import nglIcon from "assets/icons/coins/ngl.svg"
import nymIcon from "assets/icons/coins/nym.svg"
import oasIcon from "assets/icons/coins/oas.svg"
import omgIcon from "assets/icons/coins/omg.svg"
import omniIcon from "assets/icons/coins/omni.svg"
import oneIcon from "assets/icons/coins/one.svg"
import opIcon from "assets/icons/coins/op.svg"
import ordiIcon from "assets/icons/coins/ordi.svg"
import ortIcon from "assets/icons/coins/ort.svg"
import paxgIcon from "assets/icons/coins/paxg.svg"
import pendleIcon from "assets/icons/coins/pendle.svg"
import peopleIcon from "assets/icons/coins/people.svg"
import pepeIcon from "assets/icons/coins/pepe.svg"
import perpIcon from "assets/icons/coins/perp.svg"
import pipIcon from "assets/icons/coins/pip.svg"
import pltIcon from "assets/icons/coins/plt.svg"
import plyIcon from "assets/icons/coins/ply.svg"
import poktIcon from "assets/icons/coins/pokt.svg"
import polIcon from "assets/icons/coins/pol.svg"
import popcatIcon from "assets/icons/coins/popcat.svg"
import portalIcon from "assets/icons/coins/portal.svg"
import pptIcon from "assets/icons/coins/ppt.svg"
import primalIcon from "assets/icons/coins/primal.svg"
import primeIcon from "assets/icons/coins/prime.svg"
import pspIcon from "assets/icons/coins/psp.svg"
import pstakeIcon from "assets/icons/coins/pstake.svg"
import ptuIcon from "assets/icons/coins/ptu.svg"
import pumlxIcon from "assets/icons/coins/pumlx.svg"
import purseIcon from "assets/icons/coins/purse.svg"
import pythIcon from "assets/icons/coins/pyth.svg"
import pyusdIcon from "assets/icons/coins/pyusd.svg"
import qmallIcon from "assets/icons/coins/qmall.svg"
import qntIcon from "assets/icons/coins/qnt.svg"
import qtumIcon from "assets/icons/coins/qtum.svg"
import racaIcon from "assets/icons/coins/raca.svg"
import rainIcon from "assets/icons/coins/rain.svg"
import rdntIcon from "assets/icons/coins/rdnt.svg"
import realIcon from "assets/icons/coins/real.svg"
import renIcon from "assets/icons/coins/ren.svg"
import roseIcon from "assets/icons/coins/rose.svg"
import routeIcon from "assets/icons/coins/route.svg"
import rplIcon from "assets/icons/coins/rpl.svg"
import rss3Icon from "assets/icons/coins/rss3.svg"
import runeIcon from "assets/icons/coins/rune.svg"
import rvnIcon from "assets/icons/coins/rvn.svg"
import safeIcon from "assets/icons/coins/safe.svg"
import sailIcon from "assets/icons/coins/sail.svg"
import sandIcon from "assets/icons/coins/sand.svg"
import satsIcon from "assets/icons/coins/sats.svg"
import scIcon from "assets/icons/coins/sc.svg"
import scrtIcon from "assets/icons/coins/scrt.svg"
import seiIcon from "assets/icons/coins/sei.svg"
import seilorIcon from "assets/icons/coins/seilor.svg"
import sfundIcon from "assets/icons/coins/sfund.svg"
import shibIcon from "assets/icons/coins/shib.svg"
import shrapIcon from "assets/icons/coins/shrap.svg"
import sidusIcon from "assets/icons/coins/sidus.svg"
import sisIcon from "assets/icons/coins/sis.svg"
import slgIcon from "assets/icons/coins/slg.svg"
import slpIcon from "assets/icons/coins/slp.svg"
import snxIcon from "assets/icons/coins/snx.svg"
import solIcon from "assets/icons/coins/sol.svg"
import soloIcon from "assets/icons/coins/solo.svg"
import sosIcon from "assets/icons/coins/sos.svg"
import spartaIcon from "assets/icons/coins/sparta.svg"
import spellIcon from "assets/icons/coins/spell.svg"
import srmIcon from "assets/icons/coins/srm.svg"
import ssvIcon from "assets/icons/coins/ssv.svg"
import statIcon from "assets/icons/coins/stat.svg"
import stgIcon from "assets/icons/coins/stg.svg"
import strkIcon from "assets/icons/coins/strk.svg"
import stxIcon from "assets/icons/coins/stx.svg"
import suiIcon from "assets/icons/coins/sui.svg"
import sunIcon from "assets/icons/coins/sun.svg"
import sushiIcon from "assets/icons/coins/sushi.svg"
import sweatIcon from "assets/icons/coins/sweat.svg"
import tIcon from "assets/icons/coins/t.svg"
import tamaIcon from "assets/icons/coins/tama.svg"
import telIcon from "assets/icons/coins/tel.svg"
import tenetIcon from "assets/icons/coins/tenet.svg"
import thetaIcon from "assets/icons/coins/theta.svg"
import tiaIcon from "assets/icons/coins/tia.svg"
import timeIcon from "assets/icons/coins/time.svg"
import tokenIcon from "assets/icons/coins/token.svg"
import tomiIcon from "assets/icons/coins/tomi.svg"
import tonIcon from "assets/icons/coins/ton.svg"
import trcIcon from "assets/icons/coins/trc.svg"
import tribeIcon from "assets/icons/coins/tribe.svg"
import trvlIcon from "assets/icons/coins/trvl.svg"
import trxIcon from "assets/icons/coins/trx.svg"
import tusdIcon from "assets/icons/coins/tusd.svg"
import tvkIcon from "assets/icons/coins/tvk.svg"
import twtIcon from "assets/icons/coins/twt.svg"
import umaIcon from "assets/icons/coins/uma.svg"
import uniIcon from "assets/icons/coins/uni.svg"
import usdcIcon from "assets/icons/coins/usdc.svg"
import usddIcon from "assets/icons/coins/usdd.svg"
import usdtIcon from "assets/icons/coins/usdt.svg"
import ustcIcon from "assets/icons/coins/ustc.svg"
import vegaIcon from "assets/icons/coins/vega.svg"
import velaIcon from "assets/icons/coins/vela.svg"
import veloIcon from "assets/icons/coins/velo.svg"
import vextIcon from "assets/icons/coins/vext.svg"
import vinuIcon from "assets/icons/coins/vinu.svg"
import vpadIcon from "assets/icons/coins/vpad.svg"
import vraIcon from "assets/icons/coins/vra.svg"
import vvIcon from "assets/icons/coins/vv.svg"
import wavesIcon from "assets/icons/coins/waves.svg"
import waxpIcon from "assets/icons/coins/waxp.svg"
import wbtcIcon from "assets/icons/coins/wbtc.svg"
import wellIcon from "assets/icons/coins/well.svg"
import wemixIcon from "assets/icons/coins/wemix.svg"
import wlknIcon from "assets/icons/coins/wlkn.svg"
import wojakIcon from "assets/icons/coins/wojak.svg"
import wooIcon from "assets/icons/coins/woo.svg"
import wwyIcon from "assets/icons/coins/wwy.svg"
import xavaIcon from "assets/icons/coins/xava.svg"
import xcadIcon from "assets/icons/coins/xcad.svg"
import xdcIcon from "assets/icons/coins/xdc.svg"
import xecIcon from "assets/icons/coins/xec.svg"
import xemIcon from "assets/icons/coins/xem.svg"
import xetaIcon from "assets/icons/coins/xeta.svg"
import xlmIcon from "assets/icons/coins/xlm.svg"
import xrpIcon from "assets/icons/coins/xrp.svg"
import xtzIcon from "assets/icons/coins/xtz.svg"
import xwgIcon from "assets/icons/coins/xwg.svg"
import xymIcon from "assets/icons/coins/xym.svg"
import yfiIcon from "assets/icons/coins/yfi.svg"
import zenIcon from "assets/icons/coins/zen.svg"
import zeroIcon from "assets/icons/coins/zero.svg"
import zetaIcon from "assets/icons/coins/zeta.svg"
import zigIcon from "assets/icons/coins/zig.svg"
import zilIcon from "assets/icons/coins/zil.svg"
import zrxIcon from "assets/icons/coins/zrx.svg"
import inchIcon from "assets/icons/coins/1inch.svg"
import pIcon from "assets/icons/coins/3p.svg"
import aaveIcon from "assets/icons/coins/aave.svg"

const currencies: Record<string, string> = {
	aca: acaIcon,
	ach: achIcon,
	acs: acsIcon,
	ada: adaIcon,
	afc: afcIcon,
	agi: agiIcon,
	agix: agixIcon,
	agla: aglaIcon,
	agld: agldIcon,
	aioz: aiozIcon,
	algo: algoIcon,
	ankr: ankrIcon,
	ape: apeIcon,
	apex: apexIcon,
	apt: aptIcon,
	ar: arIcon,
	arbi: arbiIcon,
	arkm: arkmIcon,
	ath: athIcon,
	atom: atomIcon,
	aurora: auroraIcon,
	aury: auryIcon,
	ava: avaIcon,
	avax: avaxIcon,
	axl: axlIcon,
	axs: axsIcon,
	babydoge: babydogeIcon,
	bat: batIcon,
	bbl: bblIcon,
	bch: bchIcon,
	beam: beamIcon,
	bel: belIcon,
	bico: bicoIcon,
	bit: bitIcon,
	blast: blastIcon,
	block: blockIcon,
	blur: blurIcon,
	bnb: bnbIcon,
	bnt: bntIcon,
	bob: bobIcon,
	boba: bobaIcon,
	bonk: bonkIcon,
	btc: btcIcon,
	btg: btgIcon,
	btt: bttIcon,
	c98: c98Icon,
	cake: cakeIcon,
	caps: capsIcon,
	cat: catIcon,
	cbx: cbxIcon,
	cel: celIcon,
	celo: celoIcon,
	cgpt: cgptIcon,
	chrp: chrpIcon,
	chz: chzIcon,
	comp: compIcon,
	core: coreIcon,
	cpool: cpoolIcon,
	crv: crvIcon,
	cspr: csprIcon,
	ctc: ctcIcon,
	cult: cultIcon,
	cusd: cusdIcon,
	cwar: cwarIcon,
	cyber: cyberIcon,
	dai: daiIcon,
	dcr: dcrIcon,
	default: defaultIcon,
	defi: defiIcon,
	degen: degenIcon,
	devt: devtIcon,
	dfi: dfiIcon,
	dgb: dgbIcon,
	dlc: dlcIcon,
	doge: dogeIcon,
	dome: domeIcon,
	dot: dotIcon,
	dpx: dpxIcon,
	dsrun: dsrunIcon,
	dydx: dydxIcon,
	dzoo: dzooIcon,
	ecox: ecoxIcon,
	egld: egldIcon,
	ego: egoIcon,
	enj: enjIcon,
	ens: ensIcon,
	eos: eosIcon,
	ertha: erthaIcon,
	etc: etcIcon,
	eth: ethIcon,
	ethw: ethwIcon,
	ever: everIcon,
	fet: fetIcon,
	fida: fidaIcon,
	fil: filIcon,
	fitfi: fitfiIcon,
	flow: flowIcon,
	flr: flrIcon,
	fort: fortIcon,
	ftm: ftmIcon,
	ftt: fttIcon,
	fxs: fxsIcon,
	gal: galIcon,
	gala: galaIcon,
	gene: geneIcon,
	gg: ggIcon,
	ggm: ggmIcon,
	glmr: glmrIcon,
	gm: gmIcon,
	gmt: gmtIcon,
	gmx: gmxIcon,
	gns: gnsIcon,
	gods: godsIcon,
	grt: grtIcon,
	gsts: gstsIcon,
	gswift: gswiftIcon,
	hbar: hbarIcon,
	hero: heroIcon,
	hft: hftIcon,
	hnt: hntIcon,
	hon: honIcon,
	hook: hookIcon,
	hot: hotIcon,
	hvh: hvhIcon,
	icp: icpIcon,
	icx: icxIcon,
	id: idIcon,
	imx: imxIcon,
	inj: injIcon,
	izi: iziIcon,
	jasmy: jasmyIcon,
	jst: jstIcon,
	jup: jupIcon,
	karate: karateIcon,
	kas: kasIcon,
	kasta: kastaIcon,
	kava: kavaIcon,
	kcs: kcsIcon,
	kda: kdaIcon,
	klay: klayIcon,
	kmon: kmonIcon,
	krl: krlIcon,
	ksm: ksmIcon,
	kub: kubIcon,
	kunci: kunciIcon,
	ladys: ladysIcon,
	lai: laiIcon,
	ldo: ldoIcon,
	lever: leverIcon,
	lfw: lfwIcon,
	ling: lingIcon,
	link: linkIcon,
	lmwr: lmwrIcon,
	looks: looksIcon,
	lrc: lrcIcon,
	ltc: ltcIcon,
	luna: lunaIcon,
	lunc: luncIcon,
	mana: manaIcon,
	mantle: mantleIcon,
	mask: maskIcon,
	matic: maticIcon,
	mbox: mboxIcon,
	mbx: mbxIcon,
	mcrt: mcrtIcon,
	mdao: mdaoIcon,
	meme: memeIcon,
	mina: minaIcon,
	minu: minuIcon,
	mix: mixIcon,
	mkr: mkrIcon,
	mlk: mlkIcon,
	mog: mogIcon,
	movr: movrIcon,
	mplx: mplxIcon,
	musd: musdIcon,
	mv: mvIcon,
	mvl: mvlIcon,
	mx: mxIcon,
	mxm: mxmIcon,
	myria: myriaIcon,
	naka: nakaIcon,
	near: nearIcon,
	neon: neonIcon,
	nexo: nexoIcon,
	nft: nftIcon,
	ngl: nglIcon,
	nym: nymIcon,
	oas: oasIcon,
	omg: omgIcon,
	omni: omniIcon,
	one: oneIcon,
	op: opIcon,
	ordi: ordiIcon,
	ort: ortIcon,
	paxg: paxgIcon,
	pendle: pendleIcon,
	people: peopleIcon,
	pepe: pepeIcon,
	perp: perpIcon,
	pip: pipIcon,
	plt: pltIcon,
	ply: plyIcon,
	pokt: poktIcon,
	pol: polIcon,
	popcat: popcatIcon,
	portal: portalIcon,
	ppt: pptIcon,
	primal: primalIcon,
	prime: primeIcon,
	psp: pspIcon,
	pstake: pstakeIcon,
	ptu: ptuIcon,
	pumlx: pumlxIcon,
	purse: purseIcon,
	pyth: pythIcon,
	pyusd: pyusdIcon,
	qmall: qmallIcon,
	qnt: qntIcon,
	qtum: qtumIcon,
	raca: racaIcon,
	rain: rainIcon,
	rdnt: rdntIcon,
	real: realIcon,
	ren: renIcon,
	rose: roseIcon,
	route: routeIcon,
	rpl: rplIcon,
	rss3: rss3Icon,
	rune: runeIcon,
	rvn: rvnIcon,
	safe: safeIcon,
	sail: sailIcon,
	sand: sandIcon,
	sats: satsIcon,
	sc: scIcon,
	scrt: scrtIcon,
	sei: seiIcon,
	seilor: seilorIcon,
	sfund: sfundIcon,
	shib: shibIcon,
	shrap: shrapIcon,
	sidus: sidusIcon,
	sis: sisIcon,
	slg: slgIcon,
	slp: slpIcon,
	snx: snxIcon,
	sol: solIcon,
	solo: soloIcon,
	sos: sosIcon,
	sparta: spartaIcon,
	spell: spellIcon,
	srm: srmIcon,
	ssv: ssvIcon,
	stat: statIcon,
	stg: stgIcon,
	strk: strkIcon,
	stx: stxIcon,
	sui: suiIcon,
	sun: sunIcon,
	sushi: sushiIcon,
	sweat: sweatIcon,
	t: tIcon,
	tama: tamaIcon,
	tel: telIcon,
	tenet: tenetIcon,
	theta: thetaIcon,
	tia: tiaIcon,
	time: timeIcon,
	token: tokenIcon,
	tomi: tomiIcon,
	ton: tonIcon,
	trc: trcIcon,
	tribe: tribeIcon,
	trvl: trvlIcon,
	trx: trxIcon,
	tusd: tusdIcon,
	tvk: tvkIcon,
	twt: twtIcon,
	uma: umaIcon,
	uni: uniIcon,
	usdc: usdcIcon,
	usdd: usddIcon,
	usdt: usdtIcon,
	ustc: ustcIcon,
	vega: vegaIcon,
	vela: velaIcon,
	velo: veloIcon,
	vext: vextIcon,
	vinu: vinuIcon,
	vpad: vpadIcon,
	vra: vraIcon,
	vv: vvIcon,
	waves: wavesIcon,
	waxp: waxpIcon,
	wbtc: wbtcIcon,
	well: wellIcon,
	wemix: wemixIcon,
	wlkn: wlknIcon,
	wojak: wojakIcon,
	woo: wooIcon,
	wwy: wwyIcon,
	xava: xavaIcon,
	xcad: xcadIcon,
	xdc: xdcIcon,
	xec: xecIcon,
	xem: xemIcon,
	xeta: xetaIcon,
	xlm: xlmIcon,
	xrp: xrpIcon,
	xtz: xtzIcon,
	xwg: xwgIcon,
	xym: xymIcon,
	yfi: yfiIcon,
	zen: zenIcon,
	zero: zeroIcon,
	zeta: zetaIcon,
	zig: zigIcon,
	zil: zilIcon,
	zrx: zrxIcon,
	inch: inchIcon,
	p: pIcon,
	aave: aaveIcon,
}

export default currencies
