/* eslint-disable jsx-a11y/control-has-associated-label,no-multi-assign */
import React, { ChangeEventHandler, RefObject, useEffect, useRef, useState } from "react"
import cn from "classnames"

import useFormField from "hooks/useFormField"

import styles from "./textarea.module.scss"

type TProps = {
	name?: string
	rows?: number
	placeholder?: string
	value?: string
	defValue?: string
	disabled?: boolean
	readOnly?: boolean
	error?: boolean
	maxLength?: number
	onChange?: (value: string) => void
	onBlur?: () => void
	className?: string
}

const Textarea = ({
	name,
	rows = 4,
	placeholder,
	value,
	defValue,
	disabled,
	readOnly,
	error,
	maxLength,
	onChange,
	onBlur,
	className,
}: TProps) => {
	const rootRef = useRef<HTMLDivElement>(null)
	const textareaRef = useRef<HTMLTextAreaElement>(null)

	const [empty, setEmpty] = useState(!defValue && !value)
	const updateEmpty = () => setEmpty(!textareaRef.current?.value?.length)

	const clearValue = () => {
		setInputValue("")
		updateEmpty()
	}

	const { setInputValue } = useFormField({
		name,
		rootRef,
		inputRef: textareaRef as never as RefObject<HTMLInputElement>,
		onReset: clearValue,
	})

	const handleChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
		updateEmpty()
		onChange?.(target.value)
	}

	useEffect(updateEmpty, [defValue, value])

	return (
		<div ref={rootRef} className={cn(styles.textarea, { [styles.error]: error }, className)}>
			<textarea
				ref={textareaRef}
				name={name}
				rows={rows}
				placeholder={placeholder}
				disabled={disabled}
				readOnly={readOnly}
				defaultValue={defValue}
				value={value}
				maxLength={maxLength}
				onChange={handleChange}
				onBlur={onBlur}
			/>
			{!empty && !disabled && !readOnly && (
				<button type="button" className={styles.reset} onClick={clearValue}>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12 4L4 12M4 4L12 12"
							strokeWidth="1.25"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			)}
		</div>
	)
}

export default Textarea
