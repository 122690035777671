import ApiClient from "helpers/ApiClient"
import {
	ICreateWithdrawRequestBody,
	TWithdrawalDetails,
	IGetWithdrawLimitParams,
	IGetWithdrawMethodsParams,
	IHistoryWithdrawParams,
	IHistoryWithdrawResponse,
	TGetWithdrawMethodsResponse,
	IConfirmWithdrawRequestBody,
	TWithdrawDailyLimit,
} from "types/withdrawal"

const WithdrawalService = {
	getMethods: (params: IGetWithdrawMethodsParams): Promise<TGetWithdrawMethodsResponse> =>
		ApiClient.get("web/withdraw/methods", params),

	createWithdrawal: (data: ICreateWithdrawRequestBody): Promise<TWithdrawalDetails> =>
		ApiClient.post("web/withdraw/create/request", data),

	getWithdrawDetails: (slug: string): Promise<TWithdrawalDetails> =>
		ApiClient.get(`web/withdraw/create/${slug}/details`),

	cancelWithdraw: (slug: string): Promise<void> =>
		ApiClient.post(`web/withdraw/create/${slug}/cancel`),

	resendWithdrawPincode: (slug: string): Promise<TWithdrawalDetails> =>
		ApiClient.post(`web/withdraw/create/${slug}/resend`),

	confirmWithdraw: (
		slug: string,
		data?: IConfirmWithdrawRequestBody,
	): Promise<TWithdrawalDetails> => ApiClient.post(`web/withdraw/create/${slug}/confirm`, data),

	getWithdrawLimit: (params: IGetWithdrawLimitParams): Promise<TWithdrawDailyLimit> =>
		ApiClient.get(`web/withdraw/withdraw-daily-limit`, params),

	getPreviousWithdraws: (params: IHistoryWithdrawParams): Promise<IHistoryWithdrawResponse> =>
		ApiClient.get("web/withdraw/history", params),
}

export default WithdrawalService
