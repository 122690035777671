import { defaultColors, defaultFonts } from "./constants"

export enum ECalendarType {
	DAY = "day",
	WEEK = "week",
	MONTH = "month",
}

export enum EWeekDay {
	SUN = 0,
	MON = 1,
	TUE = 2,
	WED = 3,
	THU = 4,
	FRI = 5,
	SAT = 6,
}

export enum ESelectionStatus {
	CLEAR = 0,
	FIRST = 1,
	FULL = 2,
}

export type TColors = Record<keyof typeof defaultColors, string>
export type TFonts = Record<keyof typeof defaultFonts, string>
export type TMonthNames = Record<number, string>
export type TDayNames = Record<EWeekDay, string>

export type TEdgeDay = Date | string | number
export type TPaddings = number | [number, number]
export type TOnHover = (value: null | Date | [Date, Date]) => void
export type TOnSelect = (value: { selected: boolean; value: Date | [Date, Date] }) => void
