import React, { CSSProperties, useId } from "react"
import cn from "classnames"

import MiniButton from "components/redesigned/MiniButton"

import styles from "./infoPanel.module.scss"

type TProps = {
	title: string
	text: string | string[]
	img: string
	style?: CSSProperties
	className?: string
	onClick?: () => void
}

const InfoPanel: React.FC<TProps> = ({ title, text, img, style, className, onClick }) => {
	const id = useId()
	const lines = typeof text === "string" ? [text] : text
	return (
		<div className={cn(styles.infoPanel, className)} style={style}>
			<div className={styles.title}>{title}</div>
			<div className={styles.text}>
				{lines.map((line, index) => (
					<p key={`info-panel-${id}-line-${index.toString()}`}>{line}</p>
				))}
			</div>
			<img src={img} alt={title} className={styles.image} />
			{onClick && (
				<MiniButton
					icon="ai-mini_down"
					iconSize={24}
					bordered
					className={styles.button}
					onClick={onClick}
				/>
			)}
		</div>
	)
}

export default InfoPanel
