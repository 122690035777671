/* eslint-disable consistent-return,jsx-a11y/control-has-associated-label,no-unused-expressions,no-void,no-proto,@typescript-eslint/no-inferrable-types */
import React, { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from "react"
import cn from "classnames"

import styles from "./collapser.module.scss"

interface IProps extends PropsWithChildren {
	label?: string
	disabled?: boolean
	opened?: boolean
	className?: string
}

const Collapser: React.FC<IProps> = ({ label, children, disabled, opened, className }) => {
	const rootRef = useRef<HTMLDivElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)

	const [closed, setClosed] = useState<boolean>(!opened)
	const [visibility, setVisibility] = useState<boolean>(!!opened)
	const [height, setHeight] = useState<number>(0)

	useEffect(() => {
		setClosed(!opened)
	}, [opened])

	const handleClick = () => closed !== visibility && setClosed(val => !val)

	const handleTransitionEnd = () => closed && setVisibility(false)

	useEffect(() => {
		if (closed) setHeight(0)
		else setVisibility(true)
	}, [closed])

	useEffect(() => {
		if (!visibility) return
		const updateHeight = () => setHeight(contentRef.current?.offsetHeight || 0)
		updateHeight()
		window.addEventListener("resize", updateHeight)
		return () => window.removeEventListener("resize", updateHeight)
	}, [visibility])

	const style = { "--content-height": `${height}px` } as CSSProperties

	return (
		<div
			ref={rootRef}
			className={cn(styles.collapser, { [styles.opened]: !closed }, className)}
			style={style}
			onTransitionEnd={handleTransitionEnd}
		>
			<button type="button" disabled={disabled} onClick={handleClick}>
				<span>{label}</span>
				<i className={cn("ai", !closed ? "ai-minus" : "ai-plus")} />
			</button>
			{visibility && (
				<div ref={contentRef} className={styles.content}>
					{children}
				</div>
			)}
		</div>
	)
}

export default Collapser
