const cache = {
	setItem: function <Value = unknown>(key: string, value: Value) {
		window.localStorage.setItem(key, JSON.stringify(value))
	},
	getItem: function <Value = any>(key: string, initialValue: Value): Value {
		try {
			const item = window.localStorage.getItem(key)
			if (item) return JSON.parse(item)
			if (typeof initialValue === "string") return JSON.parse(initialValue)
			return initialValue
		} catch (error) {
			return initialValue
		}
	},
}

export default cache
