/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unreachable-loop */
import React from "react"
import { toast } from "react-toastify"
import ErrorIcon from "assets/icons/toast/Error"

const getDetails = (obj: any): string | string[] => {
	if (typeof obj === "string") return obj

	if (obj && typeof obj === "object" && Object.keys(obj).length) {
		const details = obj.error_message || obj.error || obj.details || obj[Object.keys(obj)[0]]
		return getDetails(details)
	}

	if (obj instanceof Array) return obj.map(getDetails).flat()

	return "It looks like something went wrong. Please refresh the page and try again."
}

const errorHandler = (err: unknown, throwError = true) => {
	console.error("Error", err)
	if (typeof err === "object") {
		if ((err as Record<string, unknown>).status === 403) {
			toast.error(
				<div>
					<div>Error</div>
					<div className="Toastify__toast-subtext">Access denied [403]</div>
				</div>,
				{
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					icon: <ErrorIcon />,
				},
			)

			return
		}
		if ((err as Record<string, unknown>).status === 401) {
			toast.error(
				<div>
					<div>Error</div>
					<div className="Toastify__toast-subtext">Unauthorized [401]</div>
				</div>,
				{
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					icon: <ErrorIcon />,
				},
			)

			return
		}
	}

	const details = (err as Record<string, unknown>)?.data || err

	const message =
		typeof details === "object"
			? getDetails(details as Record<string, unknown>)
			: "It looks like something went wrong. Please, refresh the page."

	toast.error(
		<div>
			<div>Error</div>
			<div className="Toastify__toast-subtext">
				{!Array.isArray(message)
					? message
					: message.map(m => (
							<React.Fragment key={Math.random()}>
								{m} <br />
							</React.Fragment>
					  ))}
			</div>
		</div>,
		{
			position: "bottom-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
			icon: <ErrorIcon />,
		},
	)

	if (throwError) {
		throw details
	}
}

export default errorHandler
