import React, { useMemo } from "react"
import dayjs from "dayjs"
import { useIntl } from "react-intl"

import { formatNumberNoRounding } from "utils/format"
import styles from "../index.module.scss"

const Item: React.FC<{
	price: number
	amount: number
	time: number
	pricePrecision: number
	amountPrecision: number
	isBuy: boolean
}> = ({ price, amount, time, pricePrecision, amountPrecision, isBuy }) => {
	const { formatNumber } = useIntl()
	const formatAmount = (value: number, fractionDigits: number) => {
		const isLarge = value >= 100_000
		return formatNumber(value, {
			maximumFractionDigits: isLarge ? 3 : fractionDigits,
			minimumFractionDigits: isLarge ? 3 : fractionDigits,
			notation: isLarge ? "compact" : "standard",
		})
	}
	const date = new Date(time)
	const diff: number = dayjs(dayjs()).diff(date, "h")
	const format: string = diff > 24 ? "DD/MM/YY" : "HH:mm:ss"
	const priceValue = formatNumberNoRounding(price, pricePrecision)
	const timeValue = useMemo(() => dayjs(date).utc().format(format), [format, date])
	const amountValue = useMemo(
		() => formatAmount(amount, amountPrecision),
		[amountPrecision, amount],
	)

	return (
		<div className={styles.recentTrades__item}>
			<span
				className={`${styles.recentTrades__price} ${isBuy ? styles.recentTrades__price___buy : ""}`}
			>
				{priceValue}
			</span>

			<span className={styles.recentTrades__amount}>{amountValue}</span>

			<span className={styles.recentTrades__time}>{timeValue}</span>
		</div>
	)
}

export default Item
