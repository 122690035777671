import React from "react"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"

import { useMst } from "models/Root"
import OrderAll from "assets/icons/terminal/OrderAll"
import OrderRed from "assets/icons/terminal/OrderRed"
import OrderGreen from "assets/icons/terminal/OrderGreen"
import Precision from "./Precision"
import styles from "../index.module.scss"

const filterList = [
	{
		id: "AllOrders",
		label: "Order books for buys and sells",
		icon: OrderAll,
	},
	{
		id: "RedOrders",
		label: "Sales order book",
		icon: OrderRed,
	},
	{
		id: "GreenOrders",
		label: "Buy order book",
		icon: OrderGreen,
	},
]

const Filter: React.FC<{
	setCurrentListType: (value: string) => void
	currentListType: string
}> = ({ setCurrentListType, currentListType }) => {
	const { isLoaded } = useMst().bybit

	const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCurrentListType(e.target.value)
	}

	return (
		<>
			{/*
					The positions of the elements are crucial,
					as they are styled in a hierarchical order.
					If you change the HTML, also adjust the CSS.
			*/}
			{filterList.map(({ id, label }) => (
				<input
					key={`${id}-radio`}
					className={`visually-hidden ${styles.orders__filterRadio}`}
					type="radio"
					name="Order"
					id={id}
					value={id}
					onChange={handleFilterChange}
					checked={currentListType === id}
					aria-label={label}
				/>
			))}

			<div className={styles.orders__filters}>
				{filterList.map(({ id, label, icon: Icon }) => (
					<label
						key={`${id}-label`}
						className={styles.orders__filterLabel}
						htmlFor={id}
						aria-label={label}
					>
						<Icon />
					</label>
				))}

				{isLoaded ? (
					<Precision />
				) : (
					<div className={styles.orders__precisionSkeleton}>
						<Skeleton height={32} borderRadius={8} />
					</div>
				)}
			</div>
		</>
	)
}

export default observer(Filter)
