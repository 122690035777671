export enum OrderStatusEnum {
	OPEN = "OPEN",
	PARTIAL_CANCELED = "PARTIAL_CANCELED",
	PARTIAL_CANCELLED = "PARTIAL_CANCELLED",
	PARTIAL_FILLED = "PARTIAL_FILLED",
	PENDING = "PENDING",
	CANCELLED = "CANCELLED",
	EXPIRED = "EXPIRED",
	FAILED = "FAILED",
	FILLED = "FILLED",
}

export enum OrderSideEnum {
	BUY = "BUY",
	SELL = "SELL",
}

export enum TradeSideEnum {
	BUY = "1",
	SELL = "2",
}

export enum OrderTypeEnum {
	LIMIT = "LIMIT",
	MARKET = "MARKET",
	STOP_LIMIT = "STOP_LIMIT",
	STOP_ORDER = "STOP_ORDER",
	STOP_MARKET = "STOP_MARKET",
}

export enum EOrderSide {
	BUY = "Buy",
	SELL = "Sell",
}

export enum EOrderType {
	MARKET = "Market",
	LIMIT = "Limit",
	UNKNOWN = "UNKNOWN",
}

export enum EOrderOpenStatus {
	NEW = "New",
	PARTIALLY_FILLED = "PartiallyFilled",
	UNTRIGGERED = "Untriggered",
}

export enum EOrderCloseStatus {
	REJECTED = "Rejected",
	PARTIALLY_FILLED_CANCELED = "PartiallyFilledCanceled",
	FILLED = "Filled",
	CANCELLED = "Cancelled",
	TRIGGERED = "Triggered",
	DEACTIVATED = "Deactivated",
}

export type TOrderStatus = EOrderOpenStatus | EOrderCloseStatus
