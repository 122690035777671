export const getScrollbarIsOverlay = () => {
	const testDiv = document.createElement("div")
	testDiv.style.width = "100px"
	testDiv.style.height = "100px"
	testDiv.style.overflowY = "scroll"
	testDiv.style.position = "absolute"
	testDiv.style.top = "-9999px"
	document.body.appendChild(testDiv)
	const hasOverlayScrollbar = testDiv.offsetWidth === testDiv.clientWidth
	document.body.removeChild(testDiv)
	return hasOverlayScrollbar
}
