import React, { ComponentProps, useId } from "react"
import cn from "classnames"

import MenuButton from "../MenuButton"

import styles from "./menuSections.module.scss"

export type TMenuSection = {
	title?: string
	items: Array<ComponentProps<typeof MenuButton> & { visible?: boolean }>
}

type TProps = {
	sections: TMenuSection[]
	className?: string
}

const MenuSections: React.FC<TProps> = ({ sections, className }) => {
	const id = useId()
	return (
		<div className={cn(styles.menuSections, className)}>
			{sections
				.filter(({ items }) => items.some(({ visible }) => visible !== false))
				.map(({ title, items }, sectionIndex) => (
					<div key={`sections-${id}-section-${sectionIndex.toString()}`}>
						{!!title && <label>{title}</label>}
						{items
							.filter(({ visible }) => visible !== false)
							.map((props, itemIndex) => (
								<MenuButton
									key={`sections-${id}-section-${sectionIndex.toString()}-item-${itemIndex.toString()}`}
									{...props}
								/>
							))}
					</div>
				))}
		</div>
	)
}

export default MenuSections
