import useModal from "hooks/useModal"
import MigrationNotification from "components/NewUI/Terminal/FunctionalPanel/MigrationNotification"

const useMigrationNotificationModal = () => {
	const [open, close, preventClosing] = useModal(MigrationNotification)

	const openMigrationNotificationModal = () => open({ preventClosing })

	return { openMigrationNotificationModal, closeMigrationNotificationModal: close }
}

export default useMigrationNotificationModal
