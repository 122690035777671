import React from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import historyMessages from "messages/history"
import { OrderTypeEnum } from "types/orders"
import { ICreateOrderBody, TradeActionsEnum } from "types/exchange"

import styles from "./index.module.scss"

type TProps = {
	base: string
	quote: string
	order: ICreateOrderBody
}

const valToText = (value?: string) =>
	(value || "")
		.replaceAll("_", " ")
		.toLowerCase()
		.replace(/(^(\w)|\s+(\w))/g, val => val.toUpperCase())

const OrderConfirmation: React.FC<TProps> = ({ base, quote, order }) => {
	const { formatMessage } = useIntl()

	const { category, type, side, price, amount, stop_price } = order

	const amountCurrency =
		type.toLowerCase() === OrderTypeEnum.MARKET.toLowerCase() &&
		side.toLowerCase() === TradeActionsEnum.BUY.toLowerCase()
			? quote
			: base

	return (
		<div className={styles.orderConfirmation}>
			<div>
				<span>{formatMessage(historyMessages.active_orders_pair)}</span>
				<span>
					{base} / {quote}
				</span>
			</div>
			<div>
				<span>{formatMessage(historyMessages.category)}</span>
				<span>{valToText(category)}</span>
			</div>
			<div>
				<span>{formatMessage(historyMessages.active_orders_type)}</span>
				<span>{valToText(type)}</span>
			</div>
			{stop_price && (
				<div>
					<span>{formatMessage(historyMessages.trigger_price)}</span>
					<span>
						{stop_price} {quote}
					</span>
				</div>
			)}
			{price && (
				<div>
					<span>{formatMessage(historyMessages.order_price)}</span>
					<span>
						{price} {quote}
					</span>
				</div>
			)}
			<div>
				<span>{formatMessage(historyMessages.active_orders_amount)}</span>
				<span>
					{amount} {amountCurrency}
				</span>
			</div>
		</div>
	)
}

export default observer(OrderConfirmation)
