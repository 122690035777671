import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import * as yup from "yup"

import { AccountTypeEnum, EMarginTransferStatus } from "types/account"
import { formatNumberNoRounding } from "utils/format"
import errorHandler from "utils/errorHandler"
import { errorsFromSchema, validateSchema } from "utils/yup"
import AccountService from "services/AccountService"
import { useMst } from "models/Root"
import messages from "messages/exchange"
import commonMessages from "messages/common"
import formMessages from "messages/form"

import Modal, {
	ActionGroup,
	BodyContainer,
	ContentForm,
	Footer,
	SuccessScreen,
} from "components/UI/Modal"
import CustomSelect, { ICSelectOption as ICustomSelectOption } from "components/UI/CustomSelect"
import CurrencySelect, { IOption as ICurrencySelectOption } from "components/UI/CurrencySelect"
import Input, { Appender, AppenderButton, AppenderDivider } from "components/UI/Input"
import Button from "components/UI/Button"

import marginModalStyles from "styles/components/MarginModal.module.scss"

interface ITransferCurrency {
	code: string
	available: number
	usdt: number
}

interface IFormBody {
	from: string
	to: string
	currency: string
	amount: string
}

const formBodyDefault: IFormBody = {
	from: "",
	to: "",
	currency: "",
	amount: "",
}

interface IFormBodyErrors {
	currency: string
	amount: string
}

const extractUsdt = (converted: Record<string, string | number>) =>
	+(converted?.USDT || converted?.USDC || converted?.USD || 0)

const mapCurrency = ({
	code,
	available,
	converted,
}: {
	code: string
	available: string | number
	converted: Record<string, string | number>
}): ITransferCurrency => ({
	code,
	available: +available,
	usdt: extractUsdt(converted),
})

const modalStyles: ReactModal.Styles = { content: { overflow: "visible" } }

interface IProps {
	isOpen: boolean
	onClose: () => void
	onSuccess?: () => void
}

const BybitTransferModal: React.FC<IProps> = ({ isOpen, onClose, onSuccess }) => {
	const {
		account: { types, getTypes, selectedType, balances, loadBalances },
	} = useMst()
	const { formatMessage } = useIntl()

	const [isBalancesLoading, setIsBalancesLoading] = useState<boolean>(false)
	const [transferBalances, setTransferBalances] = useState<Record<string, ITransferCurrency[]>>({})

	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
	const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

	const [formBody, setFormBody] = useState<IFormBody>({ ...formBodyDefault })
	const updateFormBody = (values: Partial<IFormBody>) =>
		setFormBody(current => ({ ...current, ...values }))
	const currency = transferBalances[formBody.from]?.find(({ code }) => code === formBody.currency)

	useEffect(() => {
		if (!isOpen) return
		updateFormBody({ currency: "", amount: "" })
		if (transferBalances[formBody.from]?.length) return
		;(async () => {
			try {
				let currencies: ITransferCurrency[] = []
				if (selectedType === formBody.from && balances.length) {
					currencies = balances.map(mapCurrency)
				} else {
					setIsBalancesLoading(true)
					const result = await AccountService.getBalances({ account_type: formBody.from })
					currencies = result.map(mapCurrency)
				}
				setTransferBalances(items => ({ ...items, [formBody.from]: currencies }))
			} catch (e) {
				errorHandler(e)
			} finally {
				setIsBalancesLoading(false)
			}
		})()
	}, [formBody.from])

	const [formErrors, setFormErrors] = useState<IFormBodyErrors>({
		amount: "",
		currency: "",
	})
	const updateErrors = (values: Partial<IFormBodyErrors>) =>
		setFormErrors(current => ({ ...current, ...values }))

	const typeFromOptions = useMemo(
		() =>
			getTypes().map<ICustomSelectOption>(({ name, label }) => ({
				label: { code: label },
				value: name,
			})),
		[types.join()],
	)

	const typeToOptions = useMemo(
		() =>
			getTypes()
				.filter(({ name }) => name !== formBody.from)
				.map<ICustomSelectOption>(({ name, label }) => ({
					label: { code: label },
					value: name,
				})),
		[types.join(), formBody.from],
	)

	useEffect(() => {
		if (!isOpen) {
			setTransferBalances({})
			setIsSuccessful(false)
			setFormBody(formBodyDefault)
		} else setFormBody({ ...formBodyDefault, from: selectedType })
	}, [isOpen])

	useEffect(() => updateFormBody({ to: typeToOptions[0]?.value }), [typeToOptions])

	const coinsOptions = useMemo(
		() =>
			Array.from(transferBalances[formBody.from] || [])
				.sort((a, b) => {
					if (!a.available !== !b.available) return b.available - a.available
					return a.code.localeCompare(b.code)
				})
				.map<ICurrencySelectOption>(balance => ({
					label: {
						code: balance.code,
						available: formatNumberNoRounding(balance.available, 8, true),
						withoutPrecision: true,
					},
					value: balance.code,
				})),
		[transferBalances, formBody.from],
	)

	const handleFromChange = ({ value }: ICustomSelectOption) => updateFormBody({ from: value })

	const handleToChange = ({ value }: ICustomSelectOption) => updateFormBody({ to: value })

	const handleCoinChange = ({ value }: ICurrencySelectOption) =>
		updateFormBody({ currency: value, amount: "" })

	const handleAmountChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
		updateFormBody({ amount: value })

	const handleMaxAmountClick = () =>
		updateFormBody({ amount: formatNumberNoRounding(currency?.available || 0, 4) })

	const handleSwapFromTo = () =>
		updateFormBody({
			from: formBody.to,
			to: formBody.from,
		})

	useEffect(() => updateErrors({ currency: "" }), [formBody.currency])
	useEffect(() => updateErrors({ amount: "" }), [formBody.amount])

	const handleAmountBlur = () => {
		if (!formBody.amount) return "0"
		const formatedAmount = (+(+formBody.amount).toFixed(4)).toString()
		updateFormBody({ amount: formatedAmount })
		return formatedAmount
	}

	const validate = async (fields: Partial<IFormBody>): Promise<boolean> => {
		try {
			await validateSchema({
				currency: [fields.currency, yup.string().required(formatMessage(formMessages.required))],
				amount: [
					fields.amount,
					yup
						.string()
						.required(formatMessage(formMessages.required))
						.test(
							"Min checking",
							`${formatMessage(formMessages.min_value)} 0.0001`,
							value => +(value || 0) >= 0.0001,
						)
						.test(
							"Max checking",
							`${formatMessage(formMessages.max_value)} ${currency?.available}`,
							value => +(value || 0) <= (currency?.available || 0),
						),
				],
			})
			return true
		} catch (err) {
			setFormErrors(errorsFromSchema<IFormBody>(err as any) as IFormBody)
			return false
		}
	}

	const handleSubmit = async () => {
		const amount = handleAmountBlur()
		if (!(await validate({ currency: formBody.currency, amount }))) return
		try {
			setIsSubmitLoading(true)
			const result = await AccountService.marginTransfer({
				from_account_type: formBody.from,
				to_account_type: formBody.to,
				currency: formBody.currency,
				amount,
			})
			if (result.status !== EMarginTransferStatus.SUCCESS) throw new Error("Something went wrong")
			await loadBalances()
			setIsSuccessful(true)
			onSuccess?.()
		} catch (err) {
			errorHandler(err)
		} finally {
			setIsSubmitLoading(false)
		}
	}

	const isValidAmount = +formBody.amount >= 0.0001 && +formBody.amount <= (currency?.available || 0)

	return (
		<Modal
			label={formatMessage(messages.action_margin_transfer)}
			isOpen={isOpen}
			onClose={onClose}
			headerDisable
			style={modalStyles}
		>
			{isSuccessful ? (
				<>
					<SuccessScreen>
						<b>
							{formatNumberNoRounding(+formBody.amount.replace(",", "."), 4)}
							&nbsp;
							{/*{isIsolated ? formBody.currency : formBody.asset}*/}
						</b>
						<span>{formatMessage(messages.amount_successfuly_submitted)}</span>
						<span>
							{formatMessage(
								formBody.to === AccountTypeEnum.SPOT
									? messages.transfer_to_spot_success
									: messages.transfer_to_margin_success,
							)}
						</span>
					</SuccessScreen>
					<Footer>
						<ActionGroup>
							<Button
								fullWidth
								defaultTerminalBtn
								variant="filled"
								color="darkness"
								onClick={onClose}
								label={formatMessage(commonMessages.close)}
							/>
						</ActionGroup>
					</Footer>
				</>
			) : (
				<>
					<BodyContainer>
						<ContentForm>
							<div className={marginModalStyles.transfer_title}>
								{formatMessage(messages.action_margin_transfer)}
							</div>
							<div className={marginModalStyles.swap_select_action_block}>
								<CustomSelect
									options={typeFromOptions}
									onChange={handleFromChange}
									label={formatMessage(messages.from)}
									value={typeFromOptions.find(({ value }) => value === formBody.from)}
									hideIcons
									codeUpperCase={false}
								/>
								<div className={marginModalStyles.swap_btn_block}>
									<button type="button" aria-label="swap-to" onClick={handleSwapFromTo}>
										<i className="ai ai-change_arrows" />
									</button>
								</div>
								<div className={marginModalStyles.transfer_to_container}>
									<CustomSelect
										options={typeToOptions}
										onChange={handleToChange}
										label={formatMessage(messages.to)}
										value={typeToOptions.find(({ value }) => value === formBody.to)}
										hideIcons
										codeUpperCase={false}
									/>
								</div>
							</div>
							<div className={marginModalStyles.divider} />
							<CurrencySelect
								options={coinsOptions}
								onSelectChange={handleCoinChange}
								label={formatMessage(messages.currency)}
								value={coinsOptions.find(({ value }) => value === formBody.currency)}
								error={formErrors.currency}
								hideIcons
								autoFocus
								isLoading={isBalancesLoading}
								disabled={isBalancesLoading}
							/>
							<div className={marginModalStyles.amount_container}>
								<Input
									value={formBody.amount}
									error={formErrors.amount}
									onChange={handleAmountChange}
									onEnter={handleSubmit}
									onBlur={handleAmountBlur}
									helpText={
										<div className={marginModalStyles.available_amount}>
											{!currency ? (
												" "
											) : (
												<>
													MAX&nbsp;{formatNumberNoRounding(currency?.available || 0, 4)}
													&nbsp;{currency?.code}
												</>
											)}
										</div>
									}
									type="number"
									labelValue={formatMessage(messages.amount)}
									appender={
										<Appender>
											{currency?.code}
											<AppenderDivider />
											<AppenderButton
												onClick={handleMaxAmountClick}
												disabled={!formBody.to || !currency}
											>
												MAX
											</AppenderButton>
										</Appender>
									}
									disabled={!formBody.to || !currency}
								/>
							</div>
						</ContentForm>
					</BodyContainer>
					<Footer>
						<ActionGroup>
							<Button
								fullWidth
								defaultTerminalBtn
								variant="filled"
								color="darkness"
								onClick={onClose}
								label={formatMessage(commonMessages.cancel)}
								disabled={isSubmitLoading}
							/>
							<Button
								fullWidth
								defaultTerminalBtn
								variant="filled"
								color="primary"
								disabled={!isValidAmount}
								onClick={handleSubmit}
								isLoading={isSubmitLoading}
								label={`${formatMessage(commonMessages.confirm)} ${formatMessage(
									messages.action_margin_transfer,
								)}`}
							/>
						</ActionGroup>
					</Footer>
				</>
			)}
		</Modal>
	)
}

export default observer(BybitTransferModal)
