import React, { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import cn from "classnames"

import { ETickersQuotedEnum } from "types/ticker"
import { URL_VARS } from "constants/routing"
import { queryVars } from "constants/query"
import useScrollArrows from "hooks/newUI/terminal/useScrollArrows"
import { useMst } from "models/Root"
import { ITicker } from "models/Ticker"
import commonMessages from "messages/common"

import Input from "components/NewUI/Input"
import SearchIcon from "assets/icons/terminal/Search"
import ArrowRight from "assets/icons/terminal/ArrowRight"

import Tab from "./Tab"
import Item from "./Item"
import CoinsHeader, { TSort, TSortDirection } from "./CoinsHeader"
import styles from "./index.module.scss"

const FAVORITE_COINS = ["USDT", "USDC", "BTC", "ETH"]

const Search: React.FC = () => {
	const {
		tickers: {
			quoteList,
			loadTickers,
			tickerList,
			filter: { tradeType },
		},
		global: { locale, isAuthenticated },
		render: { margin: isMargin },
	} = useMst()
	const navigate = useNavigate()
	const { formatMessage } = useIntl()

	const tabList = useMemo(() => {
		const list: Array<{ name: string; label: string }> = [
			{
				name: ETickersQuotedEnum.FAVORITES,
				label: formatMessage(commonMessages.favorites),
			},
		]
		FAVORITE_COINS.forEach(name => quoteList.includes(name) && list.push({ name, label: name }))
		list.push(
			...quoteList
				.filter(coin => !FAVORITE_COINS.includes(coin))
				.sort()
				.map(name => ({
					name,
					label: name,
				})),
		)
		return list
	}, [formatMessage, quoteList])

	const intervalRef = useRef<NodeJS.Timer>()

	useEffect(() => {
		loadTickers()
		intervalRef.current = setInterval(loadTickers, 60_000)
		return () => clearInterval(intervalRef.current)
	}, [])

	const [quotedCurrency, setQuotedCurrency] = useState<string>("")
	useEffect(() => {
		if (!tabList.find(({ name }) => name === quotedCurrency))
			setQuotedCurrency(tabList[1]?.name || "")
	}, [tabList])

	const [searchString, setSearchString] = useState<string>("")
	const [sort, setSort] = useState<TSort>("symbol.asc")

	const list = useMemo(() => {
		const preparedSearchString = searchString.trim().toLowerCase()
		const [field, direction] = sort.split(".") as [keyof ITicker, TSortDirection]
		return tickerList
			.filter(({ quote_coin, base_coin, is_favorite }) => {
				const isQuoted =
					quotedCurrency === ETickersQuotedEnum.FAVORITES
						? is_favorite
						: quote_coin === quotedCurrency
				const isSerched = base_coin.toLowerCase().startsWith(preparedSearchString)
				return isQuoted && isSerched
			})
			.sort(({ [field]: a }, { [field]: b }) => {
				if (typeof a === "string")
					return direction === "asc" ? a.localeCompare(b) : b.localeCompare(a)
				if (typeof a === "number") return direction === "asc" ? a - b : b - a
				return 0
			})
	}, [quotedCurrency, searchString, sort])

	// const handleTickerFavoriteClick = (symbol: string, isFavorite: boolean) => {
	// 	if (isAuthenticated) {
	// 		updateFavoritePair(symbol, isFavorite).then(() => null)
	// 	}
	// }

	const handleLinkClick = (symbol: string) => {
		navigate(`/${locale}/${URL_VARS.TRADE}/${symbol}?${queryVars.type}=${tradeType}`)
		window.location.reload()
	}

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setSearchString(e.target.value)

	const {
		ref: tabsRef,
		handleArrowLeftClick,
		showLeftArrow,
		handleArrowRightClick,
		showRightArrow,
	} = useScrollArrows<HTMLUListElement>([tabList], false, true)

	return (
		<div className={styles.search}>
			<div className={styles.search__inputWrapper}>
				<Input
					prefixIcon={<SearchIcon />}
					value={searchString}
					placeholder={formatMessage(commonMessages.search)}
					onChange={handleSearchChange}
					type="text"
				/>
			</div>

			<div className={styles.search__topListWrapper}>
				<button
					type="button"
					aria-label="to left"
					className={cn(styles.search__topScrollButton, {
						[styles.search__topScrollButtonVisible]: showLeftArrow,
					})}
					onClick={handleArrowLeftClick}
				>
					<ArrowRight />
				</button>
				<ul ref={tabsRef} className={styles.search__topList}>
					{tabList.map(({ name, label }) => (
						<Tab
							key={`${name}-radioLabel`}
							name={name}
							label={label}
							quotedCurrency={quotedCurrency}
							setQuotedCurrency={setQuotedCurrency}
						/>
					))}
				</ul>
				<button
					type="button"
					aria-label="to right"
					className={cn(styles.search__topScrollButton, {
						[styles.search__topScrollButtonVisible]: showRightArrow,
					})}
					onClick={handleArrowRightClick}
				>
					<ArrowRight />
				</button>
			</div>

			{/* <ul className={styles.search__optionsList}>
				<li className={styles.search__optionsItem}>
					<button
						className={`${styles.search__optionsItemButton} ${styles.search__optionsItemButton___active}`}
						type="button"
					>
						All
					</button>
				</li>

				<li className={styles.search__optionsItem}>
					<button className={styles.search__optionsItemButton} type="button">
						0 Fees
					</button>
				</li>

				<li className={styles.search__optionsItem}>
					<button className={styles.search__optionsItemButton} type="button">
						Arbitrum
					</button>
				</li>
			</ul> */}

			<div className={styles.search__coins}>
				<CoinsHeader sort={sort} setSort={setSort} />

				{list.length > 0 ? (
					<ul className={styles.search__coinsList}>
						{list.map(item => (
							<Item
								key={`${item.symbol}-coinsList`}
								item={item}
								handleLinkClick={handleLinkClick}
								// handleTickerFavoriteClick={handleTickerFavoriteClick}
								isMargin={isMargin}
								isFiat={false}
							/>
						))}
					</ul>
				) : (
					<p style={{ textAlign: "center", marginTop: "30px" }}>This list is empty</p>
				)}
			</div>
		</div>
	)
}

export default observer(Search)
