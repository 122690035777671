/* eslint-disable no-return-assign,no-void */
import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import OtpInput from "react-otp-input"

import styles from "./codeInput.module.scss"

type TProps = {
	length?: number
	error?: boolean
	disabled?: boolean
	secure?: boolean
	autoFocus?: boolean
	numeric?: boolean
	value?: string
	className?: string
	onChange?: (value: string, filled: boolean) => void
}

const CodeInput: React.FC<TProps> = ({
	length = 4,
	error,
	disabled,
	secure,
	autoFocus,
	numeric,
	value,
	className,
	onChange,
}) => {
	const rootRef = useRef<HTMLDivElement>(null)
	const [currentValue, setCurrentValue] = useState<any>(value || "")

	const handleChange = (newValue: string) => {
		if (value === undefined) setCurrentValue(newValue)
		onChange?.(newValue, newValue.length === length)
	}

	useEffect(() => setCurrentValue(value), [value])

	useEffect(() => {
		rootRef.current?.querySelectorAll("input").forEach(input => (input.placeholder = "-"))
	}, [])

	return (
		<div ref={rootRef} className={cn(styles.codeInput, { [styles.error]: error }, className)}>
			<OtpInput
				numInputs={length}
				isDisabled={disabled}
				isInputNum={numeric}
				isInputSecure={secure}
				shouldAutoFocus={autoFocus}
				value={currentValue}
				onChange={handleChange}
			/>
		</div>
	)
}

export default CodeInput
