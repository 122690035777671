import React, { useEffect, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { useNavigate, useLocation } from "react-router-dom"

import { TradeTypeEnum } from "types/exchange"
import { routes } from "constants/routing"
import { queryVars } from "constants/query"
import exchangeMessages from "messages/exchange"
import { useMst } from "models/Root"
import useAccountType from "hooks/useAccountType"
import useAdjustLeverage from "hooks/useAdjustLeverage"

import Dots from "assets/icons/terminal/Dots"
import AccountService from "services/AccountService"

import styles from "components/NewUI/Terminal/FunctionalPanel/index.module.scss"
import commonMessages from "../../../../../messages/common"

import Item from "./Item"

interface HeaderProps {
	tradeType: TradeTypeEnum
	setTradeType: (nextTradeType: TradeTypeEnum) => void
}

const Header: React.FC<HeaderProps> = observer(({ tradeType, setTradeType }) => {
	const { formatMessage } = useIntl()
	const navigate = useNavigate()
	const location = useLocation()
	const accountType = useAccountType()

	const {
		account: { profileStatus },
		terminal: { marginLeverage },
		bybit: { isMarginAvailable },
		history: { setCurrentOpenTab, currentOpenTab },
		render: { margin },
		global: { isAuthenticated },
	} = useMst()
	const { marginLvl } = useAdjustLeverage()

	// State for toggling dropdown
	const [isDropdownOpen, setDropdownOpen] = useState(false)
	const dropdownRef = useRef<HTMLDivElement>(null)

	const dotsButtonAction = () => {
		// Toggle dropdown visibility
		setDropdownOpen(prev => !prev)
	}

	useEffect(() => {
		setTradeType(accountType as unknown as TradeTypeEnum)
		if (currentOpenTab !== "OpenOrdersTab") {
			setCurrentOpenTab("OpenOrdersTab")
		}
	}, [accountType])

	useEffect(() => {
		if (marginLeverage === 0 && location.search !== `?${queryVars.type}=${TradeTypeEnum.SPOT}`) {
			setTradeType(TradeTypeEnum.SPOT)
			navigate(`${location.pathname}?${queryVars.type}=${TradeTypeEnum.SPOT}`)
		}
	}, [marginLeverage])

	const handleTradeTypeChange = async (nextType: TradeTypeEnum): Promise<void> => {
		navigate(`${location.pathname}?${queryVars.type}=${nextType}`)
		if (nextType === TradeTypeEnum.CROSS && isAuthenticated) {
			await AccountService.getCollateralInfo()
		}
	}

	// Close dropdown when clicking outside
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setDropdownOpen(false)
			}
		}
		if (isDropdownOpen) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [isDropdownOpen])

	useEffect(() => {
		if (tradeType === TradeTypeEnum.CROSS && isAuthenticated) {
			AccountService.getCollateralInfo()
		}
	}, [tradeType, isAuthenticated])

	return (
		<div className={styles.functionalPanel__header}>
			<ul className={styles.functionalPanel__headerList}>
				<Item
					label={`${formatMessage(exchangeMessages.spot)}`}
					onClick={() => handleTradeTypeChange(TradeTypeEnum.SPOT)}
					isActive={tradeType === TradeTypeEnum.SPOT}
					name={TradeTypeEnum.SPOT}
				/>

				{isMarginAvailable && margin && !profileStatus?.is_margin_restricted && (
					<Item
						label={`${formatMessage(exchangeMessages.cross_margin)} ${marginLvl}X`}
						onClick={() => handleTradeTypeChange(TradeTypeEnum.CROSS)}
						isActive={tradeType === TradeTypeEnum.CROSS}
						name={TradeTypeEnum.CROSS}
					/>
				)}
			</ul>

			{isMarginAvailable && margin && !profileStatus?.is_margin_restricted && (
				<div ref={dropdownRef} className={styles.functionalPanel__dotsWrapper}>
					<button
						type="button"
						onClick={dotsButtonAction}
						aria-label="Functional panel dots button"
						className={styles.functionalPanel__dots}
					>
						<Dots />
					</button>
					{isDropdownOpen && (
						<div className={styles.functionalPanel__dropdown}>
							<button
								type="button"
								className={styles.functionalPanel__dropdownItem}
								onClick={() => navigate(routes.marginData.root)}
							>
								{formatMessage(commonMessages.margin_data)}
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	)
})

export default Header
