import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import Helmet from "react-helmet"
import { observer } from "mobx-react-lite"
import CurrencySelect, { IOption } from "components/UI/CurrencySelect"
import LoadingSpinner from "components/UI/LoadingSpinner"
import NoRowsMessage from "components/Table/NoRowsMessage"
import { Table } from "components/UI/Table"
import { IHeaderColumn } from "components/UI/Table/Table"
import { useMst } from "models/Root"
import pageStyles from "styles/pages/Page.module.scss"
import MarginDataTableRow from "./MarginDataTableRow"
import messages from "../../messages/history"
import commonMessages from "../../messages/common"

const MarginDataTable: React.FC = () => {
	const {
		account: { collateralInfo, isCollateralInfoLoaded, loadCollateralInfo },
		deposit: { currencies, loadCurrencies },
	} = useMst()

	const { formatMessage } = useIntl()
	const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>(undefined)

	const currenciesOptions: IOption[] =
		currencies?.map(
			(currency): IOption => ({
				label: {
					code: currency.code,
					name: currency.name,
					image_png: currency.image_png ?? "",
					image_svg: currency.image_svg ?? "",
				},
				value: currency.code,
			}),
		) || []

	useEffect(() => {
		loadCurrencies()
		loadCollateralInfo(selectedCurrency)
	}, [loadCurrencies, loadCollateralInfo, selectedCurrency])

	const handleCurrencySelect = useCallback(
		(e: IOption | null) => {
			const currency = e?.value || undefined
			setSelectedCurrency(currency)
			loadCollateralInfo(currency)
		},
		[loadCollateralInfo],
	)

	const columns: IHeaderColumn[] = [
		{ label: formatMessage(commonMessages.coins), width: "100px", name: "currency" },
		{
			label: formatMessage(commonMessages.margin),
			width: "120px",
			name: "marginCollateral",
			align: "center",
		},
		{
			label: formatMessage(commonMessages.borrowable),
			width: "120px",
			name: "borrowable",
			align: "center",
		},
		{
			label: formatMessage(commonMessages.d_interest_rate),
			width: "150px",
			name: "hourlyBorrowRate",
			align: "right",
		},
		{
			label: formatMessage(commonMessages.y_interest_rate),
			width: "150px",
			name: "hourlyBorrowRate",
			align: "right",
		},
		{
			label: formatMessage(commonMessages.max_borrow_amount),
			width: "150px",
			name: "maxBorrowingAmount",
			align: "right",
		},
	]

	return (
		<div className={pageStyles.table_container}>
			<Helmet title={formatMessage(commonMessages.margin_data)} />
			<div className={pageStyles.filters}>
				<div className={pageStyles.filter_select}>
					<CurrencySelect
						onSelectChange={handleCurrencySelect}
						options={currenciesOptions}
						value={currenciesOptions.find(option => option.value === selectedCurrency)}
						placeholder={formatMessage(messages.deposits_table_currency)}
						autoFocus
						withoutLabel
						mini
						isClearable
					/>
				</div>
			</div>
			{!isCollateralInfoLoaded ? (
				<LoadingSpinner />
			) : collateralInfo.length > 0 ? (
				<Table stripped header={{ columns }}>
					{collateralInfo.map((info: any, idx: number) => (
						<MarginDataTableRow key={idx} collateral={info} />
					))}
				</Table>
			) : (
				<NoRowsMessage />
			)}
		</div>
	)
}

export default observer(MarginDataTable)
