import React from "react"
import { TableData, TableRow } from "components/UI/Table"
import { IBorrowHistoryItem } from "types/account"
import dayjs from "utils/dayjs"
import styles from "../../styles/components/FinanceHistory.module.scss"

interface Props {
	borrow: IBorrowHistoryItem
}

const BorrowHistoryRow: React.FC<Props> = ({ borrow }) => (
	<TableRow>
		<TableData width="150px" dateMode>
			{dayjs(+borrow.borrow_time).format("DD/MM/YYYY")}&nbsp;
			<span className={styles.table_data_grey_colored}>
				{dayjs(+borrow.borrow_time).format("HH:mm:ss")}
			</span>
		</TableData>
		<TableData width="150px">{borrow.initial_collateral_amount}</TableData>
		<TableData width="150px">{borrow.initial_loan_amount}</TableData>
		<TableData width="100px">{borrow.collateral_currency}</TableData>
		<TableData width="100px">{borrow.loan_currency}</TableData>
		<TableData width="150px">{borrow.repaid_interest}</TableData>
		<TableData width="150px">{borrow.repaid_penalty_interest}</TableData>
		<TableData width="150px">{borrow.hourly_interest_rate}</TableData>
	</TableRow>
)

export default BorrowHistoryRow
