/* eslint-disable prefer-const */
import { useNavigate } from "react-router-dom"

import { useMst } from "models/Root"

type TNavigationParams = {
	path?: string
	search?: Record<string, string>
}

const useLocaleNavigate = () => {
	const navigate = useNavigate()
	const {
		global: { locale },
	} = useMst()

	return (to: string | TNavigationParams) => {
		if (typeof to === "string") {
			navigate(`/${locale}${to}`)
			return
		}
		const { path, search } = to
		if (!path && !search) return
		const nextPath = path ? `/${locale}${path}` : window.location.pathname
		let query = new URLSearchParams(search).toString()
		if (query.length) query = `?${query}`
		navigate(`${nextPath}${query}`)
	}
}

export default useLocaleNavigate
