import React from "react"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet"
import { observer } from "mobx-react-lite"

import { useMst } from "models/Root"
import useAccountType from "hooks/useAccountType"
import { AccountTypeEnum } from "types/account"
import commonMessages from "messages/common"
import { getPageTitle } from "helpers/global"

const Terminal: React.FC = () => {
	const {
		ticker,
		pairPricePrecision,
		currentPair: { baseCoin, quoteCoin, symbol },
	} = useMst().bybit
	const { formatMessage, formatNumber } = useIntl()
	const type = useAccountType()
	const isMargin = [AccountTypeEnum.CROSS, AccountTypeEnum.ISOLATED].includes(type)
	const price = ticker?.lastPrice
		? formatNumber(ticker?.lastPrice, {
				useGrouping: true,
				maximumFractionDigits: pairPricePrecision ?? 8,
		  })
		: 0
	const title = getPageTitle(
		symbol !== ""
			? `${price} | ${symbol} | ${formatMessage(commonMessages.exchange)}`
			: `${formatMessage(commonMessages.exchange)}`,
	)
	const desc = getPageTitle(
		isMargin
			? formatMessage(commonMessages.exchange_page_description_margin)
			: baseCoin !== "" && quoteCoin !== ""
			? formatMessage(commonMessages.exchange_page_description, {
					base_currency: baseCoin,
					quote_currency: quoteCoin,
			  })
			: `${formatMessage(commonMessages.exchange)}`,
	)

	return (
		<Helmet
			title={title}
			meta={[
				{ name: "description", content: desc },
				{ name: "viewport", content: "width=device-width, initial-scale=1.0" },
				{ property: "og:title", content: title },
				{ property: "twitter:title", content: title },
				{ property: "og:description", content: desc },
				{ name: "twitter:description", content: desc },
			]}
		/>
	)
}

export default observer(Terminal)
