import React from "react"

import { rem } from "utils/styles"

import currencies from "./currencies"

type TProps = {
	name: string
	size?: number
	className?: string
}

const CurrencyIcon: React.FC<TProps> = ({ name, size = 24, className }) => {
	const src = currencies[name.toLowerCase()]
	if (!src) return null

	return (
		<img
			src={src}
			alt={name.toUpperCase()}
			className={className}
			style={{ width: rem(size), height: rem(size) }}
		/>
	)
}

export default CurrencyIcon
