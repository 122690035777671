import React, { FC, useEffect } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Header/Navigation/index.module.scss"
import notificationsMessages from "messages/notifications"
import NotificationArrow from "assets/icons/header/NotificationArrow"
import { INotification } from "models/Notifications"
import { routes } from "constants/routing"
import InternalLink from "components/InternalLink"
import { useMst } from "models/Root"
import Item from "./Item"
import NotificationsService from "../../../../../services/NotificationsService"
import useAutoFetch from "../../../../../hooks/useAutoFetch"
import commonMessages from "../../../../../messages/common"

const Notifications: FC = () => {
	const { formatMessage } = useIntl()
	const {
		notifications: { latest, unread_count, loadLatestNotifications },
	} = useMst()

	const handleMarkAllRead = async () => {
		try {
			await NotificationsService.markAllRead()
			await loadLatestNotifications()
		} catch (e) {
			console.log(e)
		}
	}

	useAutoFetch(loadLatestNotifications, true, 60000)

	return (
		<div className={`${styles.nav__firstList} ${styles.nav__firstList___notifications}`}>
			<div className={styles.nav__notificationHeader}>
				<h3 className={styles.nav__notificationTitle}>
					{formatMessage(commonMessages.notifications)}
				</h3>

				{unread_count > 0 ? (
					<button
						onClick={handleMarkAllRead}
						type="button"
						className={styles.nav__notificationReadAll}
					>
						Read All
					</button>
				) : null}
			</div>

			{latest.length > 0 ? (
				<ul className={styles.nav__firstListWrap}>
					{latest.slice(0, 5).map((n: INotification) => (
						<Item
							key={n.uuid}
							title={n.title}
							readAt={n.read_at}
							created_at={n.created_at}
							uuid={n.uuid}
						/>
					))}
				</ul>
			) : (
				<div className={styles.nav__notificationNoNewWrapper}>
					<p className={styles.nav__notificationNoNew}>{formatMessage(commonMessages.yay)}</p>
				</div>
			)}

			<div className={styles.nav__notificationButtonWrapper}>
				<InternalLink to={routes.profile.notification} className={styles.nav__notificationButton}>
					<p className={styles.nav__notificationButtonText}>
						<span>{formatMessage(notificationsMessages.all_notifications)}</span>
					</p>

					<div className={styles.nav__notificationExplore}>
						<p>{formatMessage(commonMessages.explore)}</p>

						<NotificationArrow />
					</div>
				</InternalLink>
			</div>
		</div>
	)
}

export default observer(Notifications)
