/* eslint-disable jsx-a11y/control-has-associated-label,consistent-return,no-return-assign */
import React, {
	createElement,
	CSSProperties,
	forwardRef,
	PropsWithChildren,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react"
import { observer } from "mobx-react-lite"
import { createRoot, Root } from "react-dom/client"
import cn from "classnames"

import { Provider, rootStore } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"

import LanguageProvider from "providers/LanguageProvider"

import styles from "styles/components/ModalHook.module.scss"

export type TModalParams = {
	title?: string | JSX.Element
	closeButton?: boolean
	closeBack?: boolean
	closeEsc?: boolean
	width?: number
	className?: string
	onOpen?: () => void
	onClose?: (...args: any[]) => void
}

export type TDefaultModalProps = {
	localeNavigate: ReturnType<typeof useLocaleNavigate>
	updateModal: (params?: TModalParams) => void
	closeModal: (...args: any[]) => void
}

const DEFAULT_WIDTH = 425

type TContentRef = {
	updateModal?: (params?: TModalParams) => void
}

const Content = forwardRef<
	TContentRef,
	PropsWithChildren<{ params?: TModalParams; closeModal: () => void }>
>(({ params, closeModal, children }, ref) => {
	const isParams = !!params

	const [
		{ title = "", closeButton = true, width = DEFAULT_WIDTH, closeEsc = true, className = "" } = {},
		setParams,
	] = useState(params)

	useImperativeHandle(ref, () => ({
		updateModal: newParams => setParams(current => ({ ...current, ...newParams })),
	}))

	useEffect(() => {
		if (!closeEsc) return
		const handleKeyDown = ({ key }: KeyboardEvent) => key === "Escape" && closeModal()
		window.addEventListener("keydown", handleKeyDown)
		return () => window.removeEventListener("keydown", handleKeyDown)
	}, [closeEsc])

	return (
		<Provider value={rootStore}>
			<LanguageProvider>
				{isParams ? (
					<div
						className={cn(styles.modal, className)}
						style={{ "--width": `${width}px` } as CSSProperties}
					>
						{(!!title || closeButton) && (
							<div className={styles.header}>
								{typeof title === "string" ? <span>{title}</span> : title}
								{closeButton && (
									<button type="button" onClick={closeModal}>
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M12 4L4 12M4 4L12 12"
												strokeWidth="1.25"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								)}
							</div>
						)}
						<div className={styles.content}>{children}</div>
					</div>
				) : (
					children
				)}
			</LanguageProvider>
		</Provider>
	)
})

function useModal<Props extends TDefaultModalProps = TDefaultModalProps>(
	Modal: React.FC<Props>,
	params?: TModalParams,
) {
	const localeNavigate = useLocaleNavigate()

	const closableRef = useRef<boolean>(true)
	const preventClosing = useCallback((prevent: boolean) => (closableRef.current = !prevent), [])

	const contentRef = useRef<TContentRef>(null)
	const backCloseRef = useRef<boolean | undefined>(params?.closeBack)
	const updateModal = (newParams?: TModalParams) => {
		if (newParams?.closeBack !== undefined) backCloseRef.current = newParams.closeBack
		contentRef.current?.updateModal?.(newParams)
	}

	const reactRootRef = useRef<Root>()
	const wrapperRef = useRef<HTMLDivElement>()

	const open = useCallback(
		(props: Omit<Props, "closeModal" | "updateModal" | "localeNavigate"> = {} as Props) => {
			backCloseRef.current = params?.closeBack

			const wrapper = document.createElement("div")
			wrapperRef.current = wrapper
			wrapper.classList.add(styles.modalWrapper)
			wrapper.addEventListener(
				"click",
				({ target }) => backCloseRef.current !== false && target === wrapper && close(),
			)
			document.body.appendChild(wrapper)

			reactRootRef.current = createRoot(wrapper)
			reactRootRef.current?.render(
				<Content ref={contentRef} params={params} closeModal={close}>
					{createElement(observer(Modal), {
						...props,
						closeModal: close,
						updateModal,
						localeNavigate,
					} as Props)}
				</Content>,
			)

			setTimeout(() => wrapper.classList.add(styles.opened), 0)
			params?.onOpen?.()
		},
		[],
	)

	const close = useCallback((...args: any[]) => {
		if (!closableRef.current) return
		const wrapper = wrapperRef.current as HTMLDivElement
		if (!wrapper) return
		wrapper.classList.remove(styles.opened)
		wrapper.ontransitionend = ({ target }) => {
			if (target !== wrapper) return
			reactRootRef.current?.unmount()
			wrapper.parentNode?.removeChild(wrapper)
			wrapperRef.current = undefined
			reactRootRef.current = undefined
		}
		params?.onClose?.(...args)
	}, [])

	const getOpened = useCallback(() => !!wrapperRef.current, [])

	return [open, close, preventClosing, getOpened] as [
		typeof open,
		typeof close,
		typeof preventClosing,
		typeof getOpened,
	]
}

export default useModal
