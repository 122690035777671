import React from "react"
import { useIntl } from "react-intl"

import styles from "styles/components/Profile/Dashboard/FriendsActivity.module.scss"
import referralsMessages from "messages/referrals"
import commonMessages from "messages/common"
import accountMessages from "messages/account"
import InternalLink from "components/InternalLink"
import useWindowSize from "hooks/useWindowSize"
import Button from "components/UI/Button"
import { routes } from "constants/routing"
import { DashboardCardHeader } from "../DashboardCard"

const FriendsActivity: React.FC = () => {
	const { formatMessage } = useIntl()
	const { mobile } = useWindowSize()

	return (
		<>
			<div className={styles.card}>
				<DashboardCardHeader link={routes.referrals.root}>
					<div className={styles.card_title}>
						<i className="ai ai-users" />
						{formatMessage(referralsMessages.friends_activity_for_30_days)}
					</div>
					<span>
						{!mobile && formatMessage(commonMessages.more)}
						<i className="ai ai-chevron_right" />
					</span>
				</DashboardCardHeader>
				{!mobile && (
					<div className={styles.stat_info_sack}>
						<span>{formatMessage(accountMessages.invite_friends_earn_up_to)}</span>
						<InternalLink to={routes.referrals.root}>
							<i className="ai ai-chevron_right" />
						</InternalLink>
					</div>
				)}
			</div>
			{mobile && (
				<InternalLink className={styles.stats_link_button} to={routes.referrals.root}>
					<Button
						variant="filled"
						color="quaternary"
						iconAlign="left"
						iconCode="referral"
						mini
						label={formatMessage(commonMessages.referrals)}
						fullWidth
					/>
				</InternalLink>
			)}
		</>
	)
}

export default FriendsActivity
