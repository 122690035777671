/* eslint-disable prefer-const */
import React, { useId } from "react"
import cn from "classnames"

import styles from "./keyValues.module.scss"

type TValue = number | string | boolean

type TFullItem = {
	key: string
	value?: TValue
	isVisible?: boolean
	separated?: boolean
}

type TShortItem = [string, TValue | undefined]

type TProps = {
	items: (TFullItem | TShortItem)[]
	className?: string
}

const KeyValues: React.FC<TProps> = ({ items, className }) => {
	const id = useId()

	return (
		<div className={cn(styles.keyValues, className)}>
			{items.map(item => {
				let {
					key,
					value,
					separated,
					isVisible = true,
				}: TFullItem = item instanceof Array
					? { key: (item as TShortItem)[0], value: (item as TShortItem)[1] }
					: (item as TFullItem)

				if (!isVisible) return null

				if (typeof value === "boolean") value = value ? "True" : "False"

				return (
					<div
						key={`key-values-${id}-item-${key}`}
						className={cn({ [styles.separated]: separated })}
					>
						<span>{key}</span>
						<span>{value}</span>
					</div>
				)
			})}
		</div>
	)
}

export default KeyValues
