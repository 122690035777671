import React from "react"
import { observer } from "mobx-react-lite"

import { IHistoricalWithdrawal } from "models/Withdrawal"
import WithdrawalService from "services/WithdrawalService"

import Table, { TTableOnFetch } from "components/redesigned/Table"

import { useColumns, useFilters } from "./hooks"

const Withdrawals = () => {
	const columns = useColumns()
	const filters = useFilters()

	const handleFetch: TTableOnFetch<IHistoricalWithdrawal> = async ({ page, limit, filters }) => {
		const { results, count } = await WithdrawalService.getPreviousWithdraws({
			page,
			"page-size": limit,
			currency: filters.currency as string,
			created_at: filters.created_at,
		})
		return { data: results, count }
	}

	return (
		<Table
			columns={columns}
			filters={filters}
			//TODO: translate
			empty="No orders to show."
			lazyLoad
			resetFilters
			defFetching
			footer="pagination"
			onFetch={handleFetch}
		/>
	)
}

export default observer(Withdrawals)
