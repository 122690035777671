/* eslint-disable no-unused-expressions */
import React from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { queryVars } from "constants/query"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import { IDeposit } from "models/Deposit"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import DepositService from "services/DepositService"

import RedesignedLayout from "layouts/RedesignedLayout"
import InfoPanel from "components/redesigned/InfoPanel"
import Table, { TTableOnFetch } from "components/redesigned/Table"

import InfoImg from "assets/images/withdraws/info.png"

import { useColumns } from "pages/FinanceHistory/Deposits/hooks"

import DepositForm from "./DepositForm"
import styles from "./deposit.module.scss"

const HISTORY_SIZE = 10

const Deposit: React.FC = () => {
	const { formatMessage } = useIntl()
	const { currency = "" } = useParams<{ [queryVars.currency]: string }>()
	const localeNavigate = useLocaleNavigate()

	const columns = useColumns()

	const handleFetch: TTableOnFetch<IDeposit> = async () => {
		const { results } = await DepositService.getDepositHistory({
			limit: HISTORY_SIZE,
		})
		return { data: results }
	}

	const handleAllClick = () => localeNavigate(routes.financeHistory.root)

	return (
		<RedesignedLayout title={formatMessage(commonMessages.deposit)} className={styles.deposit}>
			<div className={styles.main}>
				<DepositForm currency={currency} />
				<div className={styles.info}>
					<InfoPanel
						//TODO: translate
						title="How to Deposit?"
						text={[
							"We will ask you for some personal information.",
							"You will need to upload an ID and take a selfie",
							"to start trading on BTC-Alpha Lite",
						]}
						img={InfoImg}
					/>
				</div>
			</div>
			<div className={styles.history}>
				{/*TODO: translate*/}
				<h2>Deposit History</h2>
				<Table
					columns={columns}
					//TODO: translate
					empty="No orders to show."
					lazyLoad="cursor"
					defFetching
					footer="all"
					defLimit={HISTORY_SIZE}
					onFetch={handleFetch}
					onAllClick={handleAllClick}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(Deposit)
