import React, { useMemo } from "react"
import { Route, Routes } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { routes, URL_VARS } from "constants/routing"
import historyMessages from "messages/history"
import financeMessages from "messages/finance"
import useRouting from "hooks/useRouting"

import RedesignedLayout from "layouts/RedesignedLayout"
import Tabs, { TTabsItem } from "components/redesigned/Tabs"

import Deposits from "./Deposits"
import Withdrawals from "./Withdrawals"
import Transfers from "./Transfers"

import styles from "./financeHistory.module.scss"

const FinanceHistory: React.FC = () => {
	const { formatMessage } = useIntl()
	const { route, navigateRoute } = useRouting({ routes: routes.financeHistory })

	const tabs = useMemo<
		Array<TTabsItem<NonNullable<typeof route>> & { path?: string; Component: React.FC }>
	>(
		() => [
			// {
			// 	key: "root",
			// 	//TODO: translate
			// 	label: "All Transactions",
			// 	separated: true,
			// },
			{
				key: "root",
				label: formatMessage(historyMessages.deposits),
				path: URL_VARS.ROOT,
				Component: Deposits,
			},
			{
				key: "withdraws",
				label: formatMessage(historyMessages.withdraws),
				Component: Withdrawals,
			},
			// {
			// 	key: "transfers",
			// 	label: formatMessage(financeMessages.transfer),
			// 	Component: Transfers,
			// },
		],
		[formatMessage],
	)

	return (
		<RedesignedLayout title={formatMessage(historyMessages.history)} className={styles.financeHistory}>
			<Tabs items={tabs} selected={route} onClick={navigateRoute} />
			<div className={styles.table}>
				<Routes>
					{tabs.map(({ key, path, Component }) => (
						<Route key={`history-${key}`} path={path || key} element={<Component />} />
					))}
				</Routes>
			</div>
		</RedesignedLayout>
	)
}

export default observer(FinanceHistory)
