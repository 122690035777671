import { useEffect, useMemo } from "react"
import { useMst } from "models/Root"

const useTradesHistory = () => {
	const {
		history: { loadTradeHistory, tradeHistory, isTradeHistoryLoading },
	} = useMst()

	const params = useMemo(
		() => ({
			category: "spot",
		}),
		[],
	)

	useEffect(() => {
		loadTradeHistory(params)
	}, [loadTradeHistory, params])

	return {
		tradeHistory,
		isTradeHistoryLoading,
	}
}

export default useTradesHistory
