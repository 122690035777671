import React from "react"
import { observer } from "mobx-react-lite"

import useBorrows from "hooks/newUI/terminal/useBorrows"

import styles from "components/NewUI/Terminal/Bottom/Positions/index.module.scss"

import SignInSignUp from "../SignInSignUp"
import ListHeadline from "../ListHeadline"
import List from "./List"

const Borrows: React.FC = () => {
	const { isAuthenticated, listHeadline, listScheme, list, handleMarginRepay } = useBorrows()

	return (
		<div className={styles.positions}>
			{isAuthenticated ? (
				<List list={list} listScheme={listScheme} handleMarginRepay={handleMarginRepay} />
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />
					<SignInSignUp />
				</>
			)}
		</div>
	)
}

export default observer(Borrows)
