import React from "react"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"
import { rem } from "utils/styles"

type TProps = {
	name: TAlphaIcon
	size?: number
	color?: string
	className?: string
}

const AlphaIcon: React.FC<TProps> = ({ name, size, color, className }) => (
	<i
		className={cn("ai", name, className)}
		style={{
			fontSize: size !== undefined ? rem(size) : undefined,
			color: color?.startsWith("--") ? `var(${color})` : color,
		}}
	/>
)

export default AlphaIcon
