import React from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"
import { observer } from "mobx-react-lite"
import messages from "messages/history"
import { transformDate } from "utils/dayjs"
import { TableData, TableRow } from "components/UI/Table"
import ButtonMicro from "components/UI/ButtonMicro"
import Tooltip from "components/UI/Tooltip"
import Badge from "components/UI/Badge"
import historyStyles from "styles/pages/History/History.module.scss"
import { IHistoryOrder } from "models/History"
import errorHandler from "utils/errorHandler"
import Spinner from "components/UI/Spinner"
import Success from "assets/icons/toast/Success"

interface Props {
	order: IHistoryOrder
	type: string
	onCancelOrder: (id: string, pair: string) => Promise<void>
}

const ActiveOrdersRow: React.FC<Props> = ({ order, onCancelOrder }) => {
	const { formatMessage } = useIntl()
	const date = transformDate(order.date || 0)
	const amountPrecision = order.pair?.amount_precision ?? 8
	const pricePrecision = order.pair?.price_precision ?? 8
	const formatPrice = (price: number, precision: number) => {
		let formattedPrice = price.toFixed(precision)

		if (parseFloat(formattedPrice) === 0) {
			return "0.00"
		}

		const regex = /(\d+\.\d+?)0+$/
		formattedPrice = formattedPrice.replace(regex, "$1")

		if (formattedPrice.includes(".") && formattedPrice.split(".")[1].length < 2) {
			formattedPrice = parseFloat(formattedPrice).toFixed(2)
		}

		return formattedPrice
	}

	const handleCancelClick = async () => {
		if (!order.isCancelLoading) {
			order.setIsCancelLoading(true)
			try {
				await onCancelOrder(order.id, order.pair?.symbol || "")
				toast.success(
					<div>
						<div>Success</div>
						<div className="Toastify__toast-subtext">
							{formatMessage(messages.order_was_cancelled)}
						</div>
					</div>,
					{
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
						icon: <Success />,
					},
				)
			} catch (err) {
				errorHandler(err, false)
			} finally {
				order.setIsCancelLoading(false)
			}
		}
	}

	return (
		<TableRow common className={historyStyles.table_row}>
			<TableData width="150px" minWidth="150px">
				{date.format("DD/MM/YYYY")}&nbsp;
				<span>{date.format("HH:mm:ss")}</span>
			</TableData>
			<TableData width="75px">{order.pair?.symbol.replace(/\/$/, "")}</TableData>
			<TableData align="center" width="65px">
				{order.type || "--"}
			</TableData>
			<TableData align="center" width="70px">
				<Badge alpha color={order.side.toUpperCase() === "SELL" ? "red" : "green"}>
					{order.side.toUpperCase() === "SELL"
						? formatMessage(messages.orders_table_type_1)
						: formatMessage(messages.orders_table_type_2)}
				</Badge>
			</TableData>
			<TableData align="right" width="120px">
				{formatPrice(order.price || 0, pricePrecision)}
				&nbsp;{order.pair?.baseCoin}
			</TableData>
			<TableData align="right" width="120px">
				{formatPrice(order.amount_original || 0, amountPrecision)}
				&nbsp;{order.pair?.quoteCoin}
			</TableData>
			<TableData align="center" width="70px">
				{formatPrice(order.filled_percent || 0, 1)}%
			</TableData>
			<TableData align="right" width="120px">
				{formatPrice((order.price || 0) * (order.amount_original || 0), 3)}
				&nbsp;{order.pair?.quoteCoin}
			</TableData>
			<TableData align="right" width="120px">
				{order.amount_unfilled}
			</TableData>
			<TableData align="right" width="200px" minWidth="200px">
				{order.id}
			</TableData>
			<TableData align="right" width="80px">
				<Tooltip
					id={`tooltip_${order.id}`}
					opener={
						order.isCancelLoading ? (
							<Spinner />
						) : (
							<ButtonMicro onClick={handleCancelClick}>
								<i className="ai ai-cancel_mini" style={{ fontSize: "14px" }} />
							</ButtonMicro>
						)
					}
					text={formatMessage(messages.active_orders_action_cancel_tooltip)}
				/>
			</TableData>
		</TableRow>
	)
}

export default observer(ActiveOrdersRow)
