import { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"

import { ACCOUNT_TYPE } from "constants/exchange"
import useAccountType from "hooks/useAccountType"
import { useMst } from "models/Root"
import { queryVars } from "constants/query"
import historyMessages from "../../../messages/history"

const useOpenOrder = ({ isShowAll = false }) => {
	const { formatMessage } = useIntl()
	const {
		global: { isAuthenticated },
		history: {
			loadOpenedOrders,
			setFilteringSymbol,
			openedOrdersList,
			setFilterTypeOpenedOrders,
			filterTypeOpenedOrders,
			isOpenedOrdersLoading,
		},
		terminal: { setShowAllOpenedOrders },
		bybit: {
			currentPair: { symbol },
			pairAmountPrecision,
			pairPricePrecision,
		},
	} = useMst()
	const params = useMemo(
		() => ({
			symbol: !isShowAll ? symbol : undefined,
			category: "spot",
		}),
		[symbol, isShowAll],
	)

	const listScheme = [
		{
			arrCount: 1,
			id: 1,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_pair),
					width: 120,
					asociate: "name",
				},
			],
			isScroll: false,
		},
		{
			arrCount: 10,
			id: 2,
			isScroll: true,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_side),
					width: 100,
					asociate: "direction",
				},
				{ headline: formatMessage(historyMessages.instrument), width: 100, asociate: "isLeverage" },
				{ headline: formatMessage(historyMessages.order_type), width: 100, asociate: "orderType" },
				{
					headline: formatMessage(historyMessages.order_value),
					width: 150,
					asociate: "orderValue",
				},
				{
					headline: formatMessage(historyMessages.order_price),
					width: 140,
					asociate: "orderPriceOpenOrder",
				},
				{
					headline: formatMessage(historyMessages.order_qty),
					width: 168,
					asociate: "orderQtyOpenOrder",
				},
				{ headline: formatMessage(historyMessages.filled_qty), width: 168, asociate: "filledQty" },
				{
					headline: formatMessage(historyMessages.unfilled_qty),
					width: 168,
					asociate: "unFilledQty",
				},
				{ headline: formatMessage(historyMessages.order_time), width: 168, asociate: "orderTime" },
				{ headline: formatMessage(historyMessages.order_id), width: 168, asociate: "orderId" },
			],
		},
		{
			arrCount: 1,
			id: 3,
			isScroll: false,
			arrNames: [
				{
					headline: formatMessage(historyMessages.active_orders_action),
					width: 80,
					asociate: "action",
				},
			],
		},
	]

	const listHeadline = listScheme.flatMap(item => item.arrNames)

	useEffect(() => {
		if (isAuthenticated && symbol !== "") {
			setShowAllOpenedOrders(false)
			setFilteringSymbol("")
			loadOpenedOrders(params).then(() => null)
		}
	}, [isAuthenticated, symbol, isShowAll])

	return {
		isAuthenticated,
		listScheme,
		list: openedOrdersList,
		listHeadline,
		pairAmountPrecision,
		pairPricePrecision,
		setFilterTypeOpenedOrders,
		filterTypeOpenedOrders,
		isOpenedOrdersLoading,
	}
}

export default useOpenOrder
