import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { queryVars } from "constants/query"
import commonMessages from "messages/common"
import messages from "messages/exchange"

import styles from "../index.module.scss"
import Item from "./Item"

export type TSortDirection = typeof queryVars.asc | typeof queryVars.desc
export type TSort<Field extends string = string> = `${Field}.${TSortDirection}`

type TProps = {
	sort: TSort
	setSort: (value: TSort) => void
}

const CoinsHeader: React.FC<TProps> = ({ sort, setSort }) => {
	const [sortName, sortValue] = sort.split(".") as [string, TSortDirection]
	const { formatMessage } = useIntl()

	const columns = [
		{
			label: formatMessage(commonMessages.coin),
			name: "symbol",
		},
		{
			label: formatMessage(messages.order_price),
			name: "close",
		},
		{
			label: `24H ${formatMessage(commonMessages.change)}`,
			name: "change_percent",
		},
	]

	return (
		<div className={styles.search__coinsHeader}>
			{columns.map(({ name, label }) => (
				<Item
					key={`${name}-coin-header`}
					name={name}
					label={label}
					handleFilterChange={setSort}
					sortValue={sortValue}
					sortName={sortName}
				/>
			))}
		</div>
	)
}

export default observer(CoinsHeader)
