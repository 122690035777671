import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { EBybitMessageTopic } from "types/bybit"
import { useMst } from "models/Root"
import { queryVars } from "constants/query"
import messages from "messages/common"
import exchangeMessages from "messages/exchange"
import useBybitWebSocket from "hooks/newUI/useBybitWebSocket"

import Helmet from "components/NewUI/Helmets/Terminal"
import Cookies from "components/Cookies"
import Header from "components/NewUI/Header"
import Trading from "components/NewUI/Terminal/Trading"
import Orders from "components/NewUI/Terminal/Orders"
import RecentTrades from "components/NewUI/Terminal/RecentTrades"
import Footer from "components/NewUI/Footer"
import Bottom from "components/NewUI/Terminal/Bottom"
import FunctionalPanel from "components/NewUI/Terminal/FunctionalPanel"
import StopOrderModal from "components/NewUI/Terminal/StopOrderModal"
import AdjustLeverage from "components/NewUI/Terminal/AdjustLeverage"
import RiskMarginModal from "components/NewUI/Terminal/RiskMarginModal"

import styles from "./index.module.scss"
import { useMobxStore } from "../../store"

const Terminal: React.FC = () => {
	const {
		bybit: { loadTicker, loadPair, loadWsCredentials, wsCredentials },
		global: { isAuthenticated },
		tickers: { isMargin },
	} = useMst()
	// const { loadOrderBook } = useMobxStore().terminalStore.orderBook
	const currentPairStore = useMobxStore().terminalStore.currentPair
	const { pair: pairParams } = useParams<{ [queryVars.pair]: string }>()
	const { formatMessage } = useIntl()

	useEffect(() => {
		loadTicker(pairParams || "").then(() => null)
		loadPair(pairParams || "").then(() => null)

		if (pairParams) {
			currentPairStore.setSymbol(pairParams || "")
		}
	}, [pairParams])

	useEffect(() => {
		if (isAuthenticated && !wsCredentials) loadWsCredentials()
	}, [isAuthenticated, wsCredentials])

	useBybitWebSocket({
		baseUrl: "wss://stream.bybit.com/v5/public/spot",
		channels: [
			`${EBybitMessageTopic.ORDERBOOK}.200.${pairParams}`,
			`${EBybitMessageTopic.TICKERS}.${pairParams}`,
			`${EBybitMessageTopic.PUBLIC_TRADE}.${pairParams}`,
		],
	})

	useBybitWebSocket({
		baseUrl: "wss://stream.bybit.com/v5/private",
		apiKey: wsCredentials?.api_key,
		expires: wsCredentials?.expires,
		signature: wsCredentials?.signature,
		channels: [EBybitMessageTopic.ORDER, EBybitMessageTopic.WALLET],
		disabled: !wsCredentials,
	})

	return (
		<>
			<Helmet />

			<Header />

			<main className={styles.main}>
				<div className={styles.main__tradingView}>
					<Trading />
				</div>

				{/*  THE ORDER OF ELEMENTS IN THIS COMPONENT IS CRUCIAL.
				IF YOU'RE INSERTING SOMETHING NEW, MAKE SURE TO REFER TO THE STYLES AND UPDATE THE SEQUENCE OF TAGS ACCORDINGLY. */}
				<div className={styles.main__orderTrades}>
					{/* These tabs are written in pure HTML5 and CSS. This is done to ensure instant responsiveness of the components,
					as well as to avoid interrupting the JavaScript thread with something as simple as tab switching.
					This approach allows us to achieve smooth animations and fast rendering without any delays. */}
					<input
						className={`visually-hidden ${styles.main__tabRadio}`}
						type="radio"
						name="OrdersRecentTradesHeader"
						id="OrdersHeader"
						value="OrdersHeader"
						defaultChecked
					/>

					<input
						className={`visually-hidden ${styles.main__tabRadio}`}
						type="radio"
						name="OrdersRecentTradesHeader"
						id="RecentTradesHeader"
						value="RecentTradesHeader"
					/>

					<div className={styles.main__ordersHeader}>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className={styles.main__ordersHeaderTabButton} htmlFor="OrdersHeader">
							<span>{formatMessage(messages.order_book)}</span>
						</label>

						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className={styles.main__ordersHeaderTabButton} htmlFor="RecentTradesHeader">
							<span>{formatMessage(exchangeMessages.recent_trades)}</span>
						</label>
					</div>

					<div className={styles.main__orders}>
						<Orders />
					</div>

					<div className={styles.main__trades}>
						<RecentTrades />
					</div>
				</div>

				<div className={styles.main__terminal}>
					<FunctionalPanel />
				</div>

				<div className={styles.main__history}>
					<Bottom />
				</div>
			</main>

			<StopOrderModal />

			{isMargin ? <AdjustLeverage /> : null}

			{isMargin && isAuthenticated ? <RiskMarginModal /> : null}

			<Footer />

			<div className={styles.main__portalRoot} id="portalRoot" />

			<Cookies />
		</>
	)
}

export default observer(Terminal)
