import React, { useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import messages from "messages/history"
import { transformDate } from "utils/dayjs"
import { Table, TableData, TableRow, TableRowAdvancedContainer } from "components/UI/Table"
import { IHeaderColumn } from "components/UI/Table/Table"
import Badge, { BadgeColorEnum } from "components/UI/Badge"
import { OrderSideEnum, OrderStatusEnum, OrderTypeEnum } from "types/orders"
import styles from "styles/pages/History/History.module.scss"
import LoadingSpinner from "components/UI/LoadingSpinner"
import { IHistoryOrder, IHistoryOrderTrade } from "models/History"
import { ORDER_STATUS_TEXT } from "constants/orders"
import exchangeMessages from "messages/exchange"

const getColorFromStatus = (status: string): BadgeColorEnum => {
	switch (status) {
		case "PartialCancelled":
		case "PartialCanceled":
			return BadgeColorEnum.GREY_DARK
		case "Cancelled":
			return BadgeColorEnum.GREY
		default:
			return BadgeColorEnum.GREEN
	}
}

interface Props {
	order: IHistoryOrder
}

const OrdersHistoryRow: React.FC<Props> = ({ order }) => {
	const { formatMessage, formatNumber } = useIntl()
	const [isExpanded, setIsExpanded] = useState(false)
	const pricePrecision = order.pair?.price_precision ?? 8
	const amountPrecision = order.pair?.amount_precision ?? 8

	const date = transformDate(order.date)

	const hasTrades = ["Filled", "PartialFilled", "PartialCancelled", "PartialCanceled"].includes(
		order.status,
	)

	const disabled = ["Cancelled", "Expired"].includes(order.status)

	const toggleExpand = () => {
		if (hasTrades) {
			if (!order.trades.length && !isExpanded) {
				order.loadTrades()
			}
			setIsExpanded(prevState => !prevState)
		}
	}

	const tradesColumns: IHeaderColumn[] = [
		{
			name: "date",
			label: formatMessage(messages.active_orders_date),
			width: "85px",
			maxWidth: "120px",
		},
		{
			name: "price",
			label: formatMessage(messages.active_orders_trading_price),
			align: "right",
		},
		{
			name: "amount",
			label: formatMessage(messages.active_orders_amount),
			align: "right",
		},
		{
			name: "total",
			label: formatMessage(messages.active_orders_total),
			align: "right",
		},
		{
			name: "fee",
			label: formatMessage(messages.active_orders_transaction_fee),
			align: "right",
		},
	]

	// Function to get status text with "Unknown" fallback
	const getStatusText = (status: string) => ORDER_STATUS_TEXT[status] || "Unknown"
	const formatPrice = (price: number, precision: number) => {
		let formattedPrice = price.toFixed(precision)
		if (parseFloat(formattedPrice) === 0) {
			return "0.00"
		}
		const regex = /(\d+\.\d+?)0+$/
		formattedPrice = formattedPrice.replace(regex, "$1")
		if (formattedPrice.includes(".") && formattedPrice.split(".")[1].length < 2) {
			formattedPrice = parseFloat(formattedPrice).toFixed(2)
		}

		return formattedPrice
	}

	return (
		<TableRowAdvancedContainer active={isExpanded} disabled={disabled}>
			<TableRow active={isExpanded} isExpandActive={hasTrades} className={styles.closed_orders_row}>
				<TableData width="150px" minWidth="150px">
					{date.format("DD/MM/YYYY")}&nbsp;
					<span>{date.format("HH:mm:ss")}</span>
				</TableData>
				<TableData width="100px" minWidth="100px">
					{order.pair?.symbol ?? "--"}
				</TableData>
				<TableData align="center" width="65px" minWidth="65px">
					{order.type ?? "--"}
				</TableData>
				<TableData align="center" width="70px" minWidth="70px">
					<Badge
						alpha
						disabled={disabled}
						color={
							order.side.toUpperCase() === OrderSideEnum.SELL
								? BadgeColorEnum.RED
								: BadgeColorEnum.GREEN
						}
					>
						{formatMessage(
							order.side.toUpperCase() === OrderSideEnum.SELL
								? messages.orders_table_type_1
								: messages.orders_table_type_2,
						)}
					</Badge>
				</TableData>
				<TableData align="right" width="120px" minWidth="120px">
					{order.price
						? `${formatPrice(order.price ?? 0, pricePrecision)} ${order.pair?.quoteCoin ?? ""}`
						: "--"}
				</TableData>
				<TableData align="right" width="120px" minWidth="120px">
					{order.price_avg
						? `${formatPrice(order.price_avg ?? 0, pricePrecision)} ${order.pair?.quoteCoin ?? ""}`
						: "--"}
				</TableData>
				<TableData align="center" width="90px" minWidth="90px">
					{order.filled_percent}%
				</TableData>
				<TableData align="right" width="120px" minWidth="120px">
					{formatPrice(order.amount_original ?? 0, pricePrecision)} {order.pair?.baseCoin ?? ""}
				</TableData>
				<TableData align="right" width="140px" minWidth="140px">
					{formatPrice(order.value_filled ?? 0, pricePrecision)} {order.pair?.quoteCoin ?? ""}
				</TableData>
				<TableData align="right" width="120px" minWidth="120px">
					<Badge alpha disabled={disabled} color={getColorFromStatus(order.status)}>
						{getStatusText(order.status)}
					</Badge>
				</TableData>
			</TableRow>
		</TableRowAdvancedContainer>
	)
}

export default observer(OrdersHistoryRow)
