import React from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import messages from "messages/home"

const HomePage: React.FC = () => {
	const { formatMessage } = useIntl()
	const title = formatMessage(messages.page_title)
	const description = formatMessage(messages.page_description)

	return (
		<Helmet
			title={title}
			meta={[
				{ property: "og:title", content: title },
				{ name: "description", content: description },
				{ property: "twitter:title", content: title },
				{ name: "twitter:description", content: description },
				{ property: "og:description", content: description },
			]}
		/>
	)
}

export default observer(HomePage)
