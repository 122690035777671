import React, { CSSProperties, useId } from "react"
import QrCodeComponent from "qrcode.react"
import { useIntl } from "react-intl"
import cn from "classnames"

import commonMessages from "messages/common"
import useCopyClick from "hooks/useCopyClick"

import AlphaIcon from "components/redesigned/AlphaIcon"

import styles from "./qrPanel.module.scss"

type TProps = {
	title: string
	code: string
	style?: CSSProperties
	className?: string
	onClick?: () => void
}

const QRPanel: React.FC<TProps> = ({ title, code, style, className, onClick }) => {
	const { formatMessage } = useIntl()
	const copy = useCopyClick()

	const handleCopyClick = () => copy(code)

	return (
		<div className={cn(styles.qrPanel, className)} style={style}>
			<QrCodeComponent value={code} className={styles.qr} />
			<div className={styles.data}>
				<div className={styles.title}>{title}</div>
				<div className={styles.code}>
					<span>{code}</span>
					<hr />
					<button type="button" onClick={handleCopyClick}>
						<AlphaIcon name="ai-copy_new" size={16} />
						<span>{formatMessage(commonMessages.copy)}</span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default QRPanel
