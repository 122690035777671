import React, { MouseEventHandler, useId } from "react"
import cn from "classnames"

import styles from "./tabs.module.scss"

export type TTabsItem<Key extends string> = {
	key: Key
	label: string
	separated?: boolean
	disabled?: boolean
	visible?: boolean
}

export type TTabsItems<Key extends string = string> = Array<TTabsItem<Key>>

export type TTabsClick<Key extends string = string> = (key: Key) => void

type TProps<Key extends string> = {
	items?: TTabsItems<Key>
	selected?: Key
	compact?: boolean
	className?: string
	onClick?: TTabsClick<Key>
}

function Tabs<Key extends string>({
	items = [],
	selected,
	compact,
	className,
	onClick,
}: TProps<Key>) {
	const id = useId()

	const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
		event.preventDefault()
		const { key } = event.currentTarget.dataset
		onClick?.(key as Key)
	}

	return (
		<div className={cn(styles.tabs, { [styles.compact]: compact }, className)}>
			{items
				.filter(({ visible }) => visible !== false)
				.map(({ key, label, disabled, separated }, index, array) => (
					<React.Fragment key={`tabs-${id}-item-${key}`}>
						<button
							type="button"
							data-key={key}
							className={cn({ [styles.selected]: selected === key })}
							disabled={disabled}
							onClick={handleClick}
						>
							<span>{label}</span>
						</button>
						{separated && index < array.length - 1 && <hr />}
					</React.Fragment>
				))}
		</div>
	)
}

export default Tabs
