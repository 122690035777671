import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import errorHandler from "utils/errorHandler"
import styles from "components/NewUI/Terminal/Bottom/OpenOrders/List/index.module.scss"
import ArrowRight from "assets/icons/terminal/ArrowRight"
import { OrderSideEnum, OrderTypeEnum } from "types/orders"
import messages from "messages/history"
import messagesExchange from "messages/exchange"
import ExchangeService from "services/ExchangeService"
import Spinner from "components/UI/Spinner"
import { ORDER_STATUS_TEXT } from "constants/orders"
import ArrowSort from "assets/icons/terminal/ArrowSort"
import { toDecimalFormat } from "helpers/exchange"
import Success from "assets/icons/toast/Success"

const Row: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	id: number
	namesArr: { headline: string; width: number; asociate: string }[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowRightClick: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowLeftClick: any
	showArrows: boolean
	showLeftArrow: boolean
	showRightArrow: boolean
	isScroll?: boolean
	pairPricePrecision: number
	pairAmountPrecision: number
}> = ({
	item,
	id,
	namesArr,
	handleArrowLeftClick,
	showArrows,
	showLeftArrow,
	showRightArrow,
	handleArrowRightClick,
	isScroll,
	pairPricePrecision,
	pairAmountPrecision,
}) => {
	const { formatMessage, formatNumber } = useIntl()
	const [isCancelLoading, setIsCancelLoading] = useState(false)
	// const [baseCurrencyCode, quoteCurrencyCode] = item.name.split("/")
	const baseCurrencyCode = ""
	const quoteCurrencyCode = ""

	const cancelOrder = useCallback(async () => {
		if (!isCancelLoading) {
			try {
				setIsCancelLoading(true)

				await ExchangeService.cancelOrder(item.orderId)

				toast.success(
					<div>
						<div>Success</div>
						<div className="Toastify__toast-subtext">
							{formatMessage(messages.order_was_cancelled)}
						</div>
					</div>,
					{
						position: "bottom-left",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
						icon: <Success />,
					},
				)
			} catch (err) {
				errorHandler(err, false)
				setIsCancelLoading(false)
			}
		}
	}, [isCancelLoading, item.id])

	return (
		<li
			key={`${id}${item.orderId}-list-item`}
			className={`${styles.list__item} ${
				item.direction.toLowerCase() === "buy" ? "" : styles.list__item___sell
			}`}
		>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__itemButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__itemButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__itemButtonRight} ${
							showArrows && showRightArrow ? styles.list__itemButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>
				</>
			) : null}

			{namesArr.map(nameItem => (
				<div
					key={`${id}${nameItem.asociate}-item`}
					className={`${styles.list__itemElem} ${
						nameItem.asociate === "direction" && item.direction.toLowerCase() === "sell"
							? styles.list__itemElem___direction
							: ""
					} ${
						nameItem.asociate === "direction" && item.direction.toLowerCase() === "buy"
							? styles.list__itemElem___directionBuy
							: ""
					} ${nameItem.asociate === "action" ? styles.list__itemElem___action : ""}`}
					style={{ minWidth: `${nameItem.width}px` }}
				>
					{nameItem.asociate === "orderValue" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.orderValue, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "orderPrice" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.orderPrice, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "avgFilledPrice" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.avgFilledPrice, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "filledPrice" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.filledPrice, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "filledValue" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.filledValue, {
									useGrouping: false,
									minimumFractionDigits: pairPricePrecision,
									maximumFractionDigits: pairPricePrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{quoteCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "orderQty" ? (
						<span className={styles.list__itemWithCurrency}>
							{item.orderQty === 0 ? (
								"--"
							) : (
								<>
									<span>
										{formatNumber(item.orderQty, {
											useGrouping: false,
											minimumFractionDigits: pairAmountPrecision,
											maximumFractionDigits: pairAmountPrecision,
										})}
									</span>

									<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
								</>
							)}
						</span>
					) : null}

					{nameItem.asociate === "filledQty" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.filledQty, {
									useGrouping: false,
									minimumFractionDigits: pairAmountPrecision,
									maximumFractionDigits: pairAmountPrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "tradingFees" ? (
						<span className={styles.list__itemWithCurrency}>
							{item.tradingFees === 0 ? (
								"--"
							) : (
								<>
									<span>
										{formatNumber(item.tradingFees, {
											useGrouping: false,
											minimumFractionDigits: 8,
											maximumFractionDigits: 8,
										})}
									</span>

									<span className={styles.list__itemCurrency}>{item.feeCurrency}</span>
								</>
							)}
						</span>
					) : null}

					{nameItem.asociate === "unFilledQty" ? (
						<span className={styles.list__itemWithCurrency}>
							<span>
								{formatNumber(item.unFilledQty, {
									useGrouping: false,
									minimumFractionDigits: pairAmountPrecision,
									maximumFractionDigits: pairAmountPrecision,
								})}
							</span>

							<span className={styles.list__itemCurrency}>{baseCurrencyCode}</span>
						</span>
					) : null}

					{nameItem.asociate === "name" ? <span>{item.name}</span> : null}

					{nameItem.asociate === "direction" ? (
						<span>
							{/*{item.direction.toUpperCase() === OrderSideEnum.SELL*/}
							{/*	? formatMessage(*/}
							{/*			item.direction.toUpperCase() === OrderSideEnum.SELL && item.walletType === 1*/}
							{/*				? messagesExchange.sell*/}
							{/*				: messagesExchange.short,*/}
							{/*	  )*/}
							{/*	: formatMessage(*/}
							{/*			item.direction.toUpperCase() !== OrderSideEnum.SELL && item.walletType === 1*/}
							{/*				? messagesExchange.buy*/}
							{/*				: messagesExchange.long,*/}
							{/*	  )}*/}
							{item.direction.toLowerCase() === "buy"
								? formatMessage(messagesExchange.buy)
								: formatMessage(messagesExchange.sell)}
						</span>
					) : null}

					{nameItem.asociate === "isLeverage" ? (
						<span className={styles.list__itemOrderType}>
							{item.isLeverage ? "Margin" : "Spot"}
						</span>
					) : null}

					{nameItem.asociate === "orderType" ? (
						<span className={styles.list__itemOrderType}>
							{item.orderType.toLowerCase() === OrderTypeEnum.LIMIT.toLowerCase()
								? formatMessage(messagesExchange.order_type_limit)
								: ""}

							{item.orderType.toLowerCase() === OrderTypeEnum.MARKET.toLowerCase()
								? formatMessage(messagesExchange.order_type_market)
								: ""}

							{item.orderType === OrderTypeEnum.STOP_ORDER ||
							item.orderType === OrderTypeEnum.STOP_LIMIT
								? formatMessage(messagesExchange.order_type_stop_order)
								: ""}
						</span>
					) : null}

					{nameItem.asociate === "date" ? <span>{item.date}</span> : null}

					{nameItem.asociate === "orderStatus" ? (
						<span className={styles.list__itemState}>{ORDER_STATUS_TEXT[item.orderStatus]}</span>
					) : null}

					{nameItem.asociate === "orderId" ? <span>{item.orderId}</span> : null}

					{nameItem.asociate === "tradeId" ? <span>{item.id}</span> : null}

					{nameItem.asociate === "action" ? (
						<button
							onClick={cancelOrder}
							disabled={isCancelLoading}
							type="button"
							className={styles.list__itemButton}
						>
							<div className={styles.list__itemButtonLoader}>
								<Spinner size={18} />
							</div>

							<span>{item.action}</span>
						</button>
					) : null}
				</div>
			))}
		</li>
	)
}

export default observer(Row)
