import useAccountType from "hooks/useAccountType"
import { useMst } from "models/Root"
import { useIntl } from "react-intl"
import historyMessages from "messages/history"
import { AccountTypeEnum, EBybitWalletType } from "types/account"
import { useState, useEffect } from "react"

const useFunds = () => {
	const {
		global: { isAuthenticated },
		account: { balancesCrossFunds, balancesSpotFunds, loadBalances },
		history: { isTradeHistoryLoading, setFilterTypeTradeHistory, filterTypeTradeHistory },
		bybit: { pairAmountPrecision, pairPricePrecision, funds },
	} = useMst()
	const { formatMessage } = useIntl()

	const [isLoadingBalances, setIsLoadingBalances] = useState(true)

	useEffect(() => {
		const load = async () => {
			setIsLoadingBalances(true) // Устанавливаем загрузку
			await loadBalances(EBybitWalletType.UNIFIED) // Загружаем балансы
			setIsLoadingBalances(false) // Убираем загрузку после завершения
		}
		load()
	}, [])

	const type = useAccountType()

	// Применяем уникальные числовые ID
	const listScheme =
		type === AccountTypeEnum.SPOT
			? [
					{
						arrCount: 1,
						id: 1001, // Уникальный ID для SPOT
						arrNames: [
							{
								headline: formatMessage(historyMessages.currency),
								width: 240,
								asociate: "currency",
							},
						],
						isScroll: false,
					},
					{
						arrCount: 11,
						id: 1002, // Уникальный ID для SPOT
						isScroll: true,
						arrNames: [
							{
								headline: formatMessage(historyMessages.available),
								width: 214,
								asociate: "available",
							},
						],
					},
			  ]
			: [
					{
						arrCount: 1,
						id: 2001, // Уникальный ID для CROSS
						arrNames: [
							{
								headline: formatMessage(historyMessages.currency),
								width: 240,
								asociate: "currency",
							},
						],
						isScroll: false,
					},
					{
						arrCount: 11,
						id: 2002, // Уникальный ID для CROSS
						isScroll: true,
						arrNames: [
							{
								headline: formatMessage(historyMessages.available),
								width: 214,
								asociate: "available",
							},
							{
								headline: formatMessage(historyMessages.total),
								width: 168,
								asociate: "total",
							},
							{
								headline: formatMessage(historyMessages.debt),
								width: 168,
								asociate: "loan",
								modalWidth: 168,
								modalContent: "Borrowed + Fees",
								isModal: true,
							},
							{
								headline: formatMessage(historyMessages.index_price),
								width: 150,
								asociate: "indexPrice",
							},
							{
								headline: formatMessage(historyMessages.index_sum),
								width: 200,
								asociate: "indexSum",
							},
						],
					},
			  ]

	const listHeadline = listScheme.flatMap(item =>
		item.arrNames.map((arrName, index) => ({
			...arrName,
			uniqueId: `${item.id}-${arrName.asociate}-${index}`, // Уникальный ключ для каждого элемента
		})),
	)

	return {
		listScheme,
		listHeadline,
		list: type === AccountTypeEnum.SPOT ? balancesSpotFunds : balancesCrossFunds,
		isAuthenticated,
		isLoadingBalances,
		isTradeHistoryLoading,
		filterTypeTradeHistory,
		setFilterTypeTradeHistory,
	}
}

export default useFunds
