/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react"
import { useIntl } from "react-intl"

import { EWithdrawalStatuses } from "types/withdrawal"
import { useMst } from "models/Root"
import { IHistoricalWithdrawal } from "models/Withdrawal"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import useShare from "hooks/useShare"
import useCopyClick from "hooks/useCopyClick"
import { formatDate } from "utils/formatDate"
import { splitWords } from "utils/format"
import { capitalizeFirstLetter } from "utils/reportUtils"

import { ETableFilterType, TTableColumns, TTableFilters } from "components/redesigned/Table"
import Ellipser from "components/redesigned/Ellipser"
import Badge, { BadgeColorEnum } from "components/UI/Badge"
import CurrencyIcon from "components/redesigned/CurrencyIcon"

import styles from "./withdrawals.module.scss"

const statusColor: Record<EWithdrawalStatuses, BadgeColorEnum> = {
	[EWithdrawalStatuses.NEW]: BadgeColorEnum.BLUE,
	[EWithdrawalStatuses.PENDING]: BadgeColorEnum.ORANGE,
	[EWithdrawalStatuses.SUCCESS]: BadgeColorEnum.GREEN,
	[EWithdrawalStatuses.CANCEL_BY_USER]: BadgeColorEnum.GREY,
	[EWithdrawalStatuses.REJECT]: BadgeColorEnum.RED,
	[EWithdrawalStatuses.FAIL]: BadgeColorEnum.RED,
	[EWithdrawalStatuses.BLOCKCHAIN_CONFIRMED]: BadgeColorEnum.ORANGE,
	[EWithdrawalStatuses.MORE_INFORMATION_REQUIRED]: BadgeColorEnum.VIOLET,
	[EWithdrawalStatuses.UNKNOWN]: BadgeColorEnum.RED,
}

export const useColumns = (): TTableColumns<IHistoricalWithdrawal> => {
	const { formatNumber, formatMessage } = useIntl()
	const share = useShare()
	const copy = useCopyClick()

	return [
		{
			key: "created_at",
			//TODO: translate
			caption: "Date & Time",
			width: 140,
			render: ({ created_at }) => formatDate(created_at),
		},
		{
			key: "currency",
			//TODO: translate
			caption: "Coin",
			width: 74,
			className: styles.currency,
			render: ({ currency }) => (
				<>
					<CurrencyIcon name={currency} size={20} />
					<span>{currency}</span>
				</>
			),
		},
		//TODO: translate
		{ key: "chain", caption: "Chain Type", width: 150 },
		{
			key: "amount",
			//TODO: translate
			caption: "Quantity",
			width: 120,
			render: ({ amount, currency }) =>
				`${formatNumber(+amount, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 8,
				})} ${currency}`,
		},
		{
			key: "fee_amount",
			//TODO: translate
			caption: "Fee",
			width: 120,
			render: ({ fee_amount }) =>
				formatNumber(+fee_amount, { minimumFractionDigits: 2, maximumFractionDigits: 8 }),
		},
		{
			key: "address",
			//TODO: translate
			caption: "Address",
			render: ({ address }) => <Ellipser value={address} middle={4} style={{ width: 150 }} />,
			actions: () => [
				{
					icon: "ai-share_outline",
					visible: !!share,
					onClick: ({ address }) => share?.({ text: address }),
				},
				{
					icon: "ai-copy_new",
					onClick: ({ address }) => copy(address),
				},
			],
		},
		{
			key: "txid",
			//TODO: translate
			caption: "Txid",
			render: ({ txid }) => (txid?.length ? <Ellipser value={txid} style={{ width: 90 }} /> : "–"),
			actions: ({ txid }) =>
				!!txid && [
					{
						icon: "ai-share_outline",
						visible: !!share,
						onClick: ({ txid }) => share?.({ text: txid || "" }),
					},
					{
						icon: "ai-copy_new",
						onClick: ({ txid }) => copy(txid || ""),
					},
				],
		},
		{
			key: "status",
			width: 50,
			caption: formatMessage(historyMessages.orders_table_status),
			render: ({ status }) => (
				<Badge color={statusColor[capitalizeFirstLetter(status) as EWithdrawalStatuses]} alpha>
					{splitWords(capitalizeFirstLetter(status?.toLowerCase()))}
				</Badge>
			),
		},
	]
}

export const useFilters = (): TTableFilters<IHistoricalWithdrawal> => {
	const { formatMessage } = useIntl()
	const {
		withdrawal: { currencies, loadCurrencies },
	} = useMst()

	useEffect(() => {
		!currencies?.length && loadCurrencies()
	}, [currencies])

	const today = new Date()
	const minDate = new Date(today)
	minDate.setFullYear(today.getFullYear() - 1)

	return [
		{
			key: "currency",
			type: ETableFilterType.SELECT,
			items: currencies?.map(({ code }) => ({ value: code, label: code })),
			caption: formatMessage(exchangeMessages.coin),
		},
		{
			key: "created_at",
			type: ETableFilterType.DATE,
			minDate: minDate,
			maxDate: today,
			//TODO: translate
			caption: "Order Time",
		},
	]
}
