import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import ArrowRight from "assets/icons/terminal/ArrowRight"
import styles from "../index.module.scss"
import ExternalImage from "../../../../../ExternalImage"

const Row: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any
	id: number
	namesArr: { headline: string; width: number; asociate: string }[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowRightClick: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleArrowLeftClick: any
	showArrows: boolean
	showLeftArrow: boolean
	showRightArrow: boolean
	isScroll?: boolean
	pairPricePrecision: number
	pairAmountPrecision: number
}> = ({
	item,
	id,
	namesArr,
	handleArrowLeftClick,
	showArrows,
	showLeftArrow,
	showRightArrow,
	handleArrowRightClick,
	isScroll,
	pairPricePrecision,
	pairAmountPrecision,
}) => {
	const { formatNumber } = useIntl()

	// Функция для форматирования значений
	const formatValue = (value: number | string, precision: number): string => {
		if (typeof value === "number") {
			if (value < 0.000001) {
				// Если значение очень маленькое, показываем с полной точностью
				return value.toFixed(precision)
			}
			const formatted = value.toFixed(precision)
			// Убираем лишние нули в конце
			return parseFloat(formatted).toString()
		}
		if (typeof value === "string") {
			const numValue = parseFloat(value)
			if (!Number.isNaN(numValue)) {
				return formatValue(numValue, precision)
			}
		}
		return value as string // Если не число, возвращаем как есть
	}

	return (
		<li className={styles.list__item}>
			{isScroll ? (
				<>
					<button
						type="button"
						aria-label="to left"
						onClick={handleArrowLeftClick}
						className={`${styles.list__itemButtonLeft} ${
							showArrows && showLeftArrow ? styles.list__itemButtonLeft___show : ""
						}`}
					>
						<ArrowRight />
					</button>

					<button
						type="button"
						aria-label="to right"
						onClick={handleArrowRightClick}
						className={`${styles.list__itemButtonRight} ${
							showArrows && showRightArrow ? styles.list__itemButtonRight___show : ""
						}`}
					>
						<ArrowRight />
					</button>
				</>
			) : null}

			{namesArr.map(nameItem => (
				<div
					key={`${id}${nameItem.asociate}-item`}
					className={styles.list__itemElem}
					style={{ minWidth: `${nameItem.width}px` }}
				>
					{nameItem.asociate === "currency" ? (
						<span className={styles.list__currency}>
							<span className={styles.list__currencyContent}>
								<span className={styles.list__currencyName}>{item.currency.name}</span>
								<span className={styles.list__currencySymbol}>{item.currency.symbol}</span>
							</span>
						</span>
					) : null}

					{nameItem.asociate === "available" ? (
						<span className={styles.list__available}>
							{formatValue(item.available, 8)}{" "}
							<span className={styles.list__availableCurrency}>{item.currency.symbol}</span>
						</span>
					) : null}

					{nameItem.asociate === "total" ? <span>{formatValue(item.total, 8)}</span> : null}

					{nameItem.asociate === "loan" ? <span>{formatValue(item.loan, 8)}</span> : null}

					{nameItem.asociate === "indexPrice" ? (
						<span className={styles.list__available}>
							{item.indexPrice ? (
								<>
									{formatValue(item.indexPrice, pairPricePrecision)}{" "}
									<span className={styles.list__availableCurrency}>USDT</span>
								</>
							) : (
								"--"
							)}
						</span>
					) : null}

					{nameItem.asociate === "indexSum" ? (
						<span className={styles.list__available}>
							{item.indexSum ? (
								<>
									{formatValue(item.indexSum, 8)}{" "}
									<span className={styles.list__availableCurrency}>USDT</span>
								</>
							) : (
								"--"
							)}
						</span>
					) : null}
				</div>
			))}
		</li>
	)
}

export default observer(Row)
