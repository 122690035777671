import React from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import financeMessages from "messages/finance"
import useConfirmModal from "hooks/useConfirmModal"
import AccountService from "services/AccountService"
import errorHandler from "utils/errorHandler"
import { useMst } from "models/Root"
import Success from "assets/icons/toast/Success"

const useCollateralConfirmModal = () => {
	const { openConfirmModal } = useConfirmModal()
	const { formatMessage } = useIntl()
	const {
		bybit: {
			currentPair: { baseCoin },
		},
	} = useMst()

	const openCollateralConfirmModal = () =>
		openConfirmModal({
			title: formatMessage(financeMessages.notification),
			text: `${baseCoin} ${formatMessage(financeMessages.cannot_collateral)}`,
			onConfirmCaption: formatMessage(financeMessages.open),
			onCancelCaption: formatMessage(financeMessages.cancel),
			onConfirm: async () => {
				try {
					await AccountService.postCollateralSwitch({
						asset: baseCoin,
						collateral_switch: "ON",
					})
					toast.success(
						<div>
							<div>Success</div>
							<div className="Toastify__toast-subtext">Collateral usage approved.</div>
						</div>,
						{
							position: "bottom-left",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: "dark",
							icon: <Success />,
						},
					)
					return true
				} catch (error) {
					errorHandler(error)
					return false
				}
			},
		})

	return { openCollateralConfirmModal }
}

export default useCollateralConfirmModal
