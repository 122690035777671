/* eslint-disable no-unused-expressions */
import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { TGetRepayOptionsResponse } from "types/exchange"
import errorHandler from "utils/errorHandler"
import ExchangeService from "services/ExchangeService"
import { useMst } from "models/Root"

import KeyValues from "components/redesigned/KeyValues/keyValues"
import Table from "components/redesigned/Table"
import CheckBox from "components/redesigned/CheckBox"

import styles from "./repayModal.module.scss"

type TProps = {
	coin: string
	amount: number
}

const RepayModal: React.FC<TProps> = ({ coin, amount }) => {
	// const { formatMessage } = useIntl()
	// const {
	// 	global: { isAuthenticated },
	// } = useMst()

	const [loading, setLoading] = useState<boolean>(false)
	const [options, setOptions] = useState<TGetRepayOptionsResponse>([])
	const [selected, setSelected] = useState(new Set<string>())

	const loadRepayOptions = async () => {
		try {
			setLoading(true)
			const result = await ExchangeService.getRepayOptions("USDT")
			setOptions(result)
		} catch (error) {
			errorHandler(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		loadRepayOptions()
	}, [])

	const checkHandler =
		(coin: string) =>
		({ target }: ChangeEvent<HTMLInputElement>) => {
			const newSelected = new Set(selected)
			target.checked ? newSelected.add(coin) : newSelected.delete(coin)
			setSelected(newSelected)
		}

	const isCheckAll = options.every(({ eqv }) => selected.has(eqv))
	const handleCheckAll = () =>
		isCheckAll ? setSelected(new Set()) : setSelected(new Set(options.map(({ eqv }) => eqv)))

	return (
		<div className={styles.repayModal}>
			<KeyValues
				items={[
					["Coin", coin],
					["Borrowed Amount", amount],
					["Equivalent (USDT)", "0000"],
					["Total Available", "0000"],
				]}
			/>
			<hr />
			<Table
				loading={loading}
				columns={[
					{
						key: "checkbox",
						caption: (
							<CheckBox
								label="All"
								checked={isCheckAll}
								onChange={handleCheckAll}
								className={styles.all}
							/>
						),
						render: ({ eqv }) => (
							<CheckBox checked={selected.has(eqv)} onChange={checkHandler(eqv)} />
						),
					},
					{ key: "eqv", caption: "Coin" },
					{ key: "balance", caption: "Available Balance" },
				]}
				data={options}
			/>
			<div className={styles.footer}>
				<p>
					Please note that BTC-Alpha will charge a 0.1% repayment handling fee on the total repayment
					amount.
				</p>
				<p>Hedged portions of liabilities will not be subject to auto-repayment</p>
			</div>
		</div>
	)
}

export default RepayModal
