import React, { FC } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import NavArrowRight from "assets/icons/header/NavArrowRight"
import { useMst } from "models/Root"
import { routes } from "constants/routing"
import historyMessages from "messages/history"
import InternalLink from "components/InternalLink"
import styles from "components/NewUI/Header/Navigation/index.module.scss"
import OpenOrders from "assets/icons/header/OpenOrders"
import OrderHistory from "assets/icons/header/OrderHistory"
import TradingHistory from "assets/icons/header/TradingHistory"
import Borrows from "assets/icons/header/Borrows"
import Repayments from "assets/icons/header/Repayments"
/*import Interests from "assets/icons/header/Interests"*/
import MagrinTransfers from "assets/icons/header/MagrinTransfers"
import MarginCalls from "assets/icons/header/MarginCalls"
import Liquidations from "assets/icons/header/Liquidations"
import commonMessages from "../../../../../messages/common"

const Orders: FC = () => {
	const { formatMessage } = useIntl()
	const { render } = useMst()

	return (
		<div className={`${styles.nav__firstList} ${styles.nav__firstList___orders}`}>
			<ul className={styles.nav__userList}>
				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.history.activeOrders} className={styles.nav__ordersButton}>
						<OpenOrders />

						<span className={styles.nav__ordersButtonWrapper}>
							<span>{formatMessage(historyMessages.active_orders)}</span>

							<NavArrowRight />
						</span>
					</InternalLink>
				</li>

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.history.closedOrders} className={styles.nav__ordersButton}>
						<OrderHistory />

						<span className={styles.nav__ordersButtonWrapper}>
							<span>{formatMessage(historyMessages.order_history)}</span>

							<NavArrowRight />
						</span>
					</InternalLink>
				</li>

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.history.tradesHistory} className={styles.nav__ordersButton}>
						<TradingHistory />

						<span className={styles.nav__ordersButtonWrapper}>
							<span>{formatMessage(historyMessages.trades)}</span>

							<NavArrowRight />
						</span>
					</InternalLink>
				</li>

				{render.margin ? (
					<>
						<li className={styles.nav__itemFirst}>
							<InternalLink to={routes.borrowHistory.root} className={styles.nav__ordersButton}>
								<Borrows />

								<span className={styles.nav__ordersButtonWrapper}>
									<span>{formatMessage(commonMessages.borrows)}</span>

									<NavArrowRight />
								</span>
							</InternalLink>
						</li>

						{/*<li className={styles.nav__itemFirst}>*/}
						{/*	<InternalLink to={routes.history.repayments} className={styles.nav__ordersButton}>*/}
						{/*		<Repayments />*/}

						{/*		<span className={styles.nav__ordersButtonWrapper}>*/}
						{/*			<span>{formatMessage(historyMessages.repayments)}</span>*/}

						{/*			<NavArrowRight />*/}
						{/*		</span>*/}
						{/*	</InternalLink>*/}
						{/*</li>*/}

						{/*<li className={styles.nav__itemFirst}>
							<InternalLink to={routes.history.interests} className={styles.nav__ordersButton}>
								<Interests />

								<span className={styles.nav__ordersButtonWrapper}>
									<span>{formatMessage(historyMessages.interests)}</span>

									<NavArrowRight />
								</span>
							</InternalLink>
						</li>*/}

						{/*<li className={styles.nav__itemFirst}>*/}
						{/*	<InternalLink*/}
						{/*		to={routes.history.marginTransfers}*/}
						{/*		className={styles.nav__ordersButton}*/}
						{/*	>*/}
						{/*		<MagrinTransfers />*/}

						{/*		<span className={styles.nav__ordersButtonWrapper}>*/}
						{/*			<span>{formatMessage(historyMessages.transfers)}</span>*/}

						{/*			<NavArrowRight />*/}
						{/*		</span>*/}
						{/*	</InternalLink>*/}
						{/*</li>*/}

						{/*<li className={styles.nav__itemFirst}>*/}
						{/*	<InternalLink to={routes.history.marginCalls} className={styles.nav__ordersButton}>*/}
						{/*		<MarginCalls />*/}

						{/*		<span className={styles.nav__ordersButtonWrapper}>*/}
						{/*			<span>{formatMessage(historyMessages.margin_calls)}</span>*/}

						{/*			<NavArrowRight />*/}
						{/*		</span>*/}
						{/*	</InternalLink>*/}
						{/*</li>*/}

						{/*<li className={styles.nav__itemFirst}>*/}
						{/*	<InternalLink to={routes.history.liquidations} className={styles.nav__ordersButton}>*/}
						{/*		<Liquidations />*/}

						{/*		<span className={styles.nav__ordersButtonWrapper}>*/}
						{/*			<span>{formatMessage(historyMessages.liquidations)}</span>*/}

						{/*			<NavArrowRight />*/}
						{/*		</span>*/}
						{/*	</InternalLink>*/}
						{/*</li>*/}
					</>
				) : null}
			</ul>
		</div>
	)
}

export default observer(Orders)
