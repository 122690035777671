import React, { useEffect, useRef } from "react"
import debounceRender from "react-debounce-render"

const DEFAULT_WAIT_MS = 500

type TValue = string | number | undefined

type TProps = {
	value: TValue
	wait?: number
}

const Component: React.FC<{ value: TValue }> = ({ value }) => value as never as JSX.Element

const makeDebounced = (wait: number) =>
	debounceRender(Component, wait, { maxWait: wait, trailing: true })

const DebouncedValue: React.FC<TProps> = ({ value, wait = DEFAULT_WAIT_MS }) => {
	const debouncedComponent = useRef(makeDebounced(wait))
	useEffect(() => {
		debouncedComponent.current = makeDebounced(wait)
	}, [wait])

	return React.createElement(debouncedComponent.current || "", { value })
}

export default DebouncedValue
