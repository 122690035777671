import React from "react"
import { observer } from "mobx-react-lite"
import { TableRow, TableData } from "components/UI/Table"
import styles from "styles/pages/Wallets.module.scss"

interface IMarginDataTableRowProps {
	collateral: {
		currency: string
		margin_collateral: boolean
		borrowable: boolean
		hourly_borrow_rate: number
		max_borrowing_amount: number
	}
}

const MarginDataTableRow: React.FC<IMarginDataTableRowProps> = ({ collateral }) => (
	<TableRow className={styles.table_row}>
		<TableData width="100px">{collateral.currency}</TableData>
		<TableData align="center" width="120px">
			<input type="checkbox" checked={collateral.margin_collateral} readOnly />
		</TableData>
		<TableData align="center" width="120px">
			<input type="checkbox" checked={collateral.borrowable} readOnly />
		</TableData>
		<TableData align="right" width="150px">
			{(collateral.hourly_borrow_rate * 2400).toFixed(6)}%
		</TableData>
		<TableData align="right" width="150px">
			{(collateral.hourly_borrow_rate * 876000).toFixed(2)}%
		</TableData>
		<TableData align="right" width="150px">
			{collateral.max_borrowing_amount.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			})}
		</TableData>
	</TableRow>
)

export default observer(MarginDataTableRow)
