import React, { useEffect, useState, useCallback } from "react"
import { useMst } from "models/Root"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet"
import { observer } from "mobx-react-lite"
import ProfileLayout from "layouts/ProfileLayout"
import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"
import { Table } from "components/UI/Table"
import NoRowsMessage from "components/Table/NoRowsMessage"
import LoadingSpinner from "components/UI/LoadingSpinner"
import Pagination from "components/UI/Pagination"
import CurrencySelect, { IOption } from "components/UI/CurrencySelect"
import pageStyles from "styles/pages/Page.module.scss"
import { Header } from "../../components/History"
import BorrowHistoryRow from "./BorrowHistoryRow"
import styles from "../../styles/pages/History/History.module.scss"
import commonMessages from "../../messages/common"
import messages from "../../messages/exchange"

const PAGE_SIZE = 15

const BorrowHistory: React.FC = () => {
	const { formatMessage } = useIntl()
	const { account, deposit } = useMst()
	const [page, setPage] = useState(1)

	const [selectedLoanCurrency, setSelectedLoanCurrency] = useState<string | undefined>(undefined)
	const [selectedCollateralCurrency, setSelectedCollateralCurrency] = useState<string | undefined>(
		undefined,
	)

	const { borrowHistory, isLoading, borrowHistoryCount } = account
	const { currencies, loadCurrencies } = deposit

	const loanCurrencyOptions: IOption[] =
		currencies?.map(currency => ({
			label: {
				code: currency.code,
				name: currency.name,
				image_png: currency.image_png ?? "",
				image_svg: currency.image_svg ?? "",
			},
			value: currency.code,
		})) || []

	const collateralCurrencyOptions: IOption[] =
		currencies?.map(currency => ({
			label: {
				code: currency.code,
				name: currency.name,
				image_png: currency.image_png ?? "",
				image_svg: currency.image_svg ?? "",
			},
			value: currency.code,
		})) || []

	useEffect(() => {
		account.loadBorrowHistory()
		loadCurrencies()
	}, [account, loadCurrencies])

	const handlePaginationClick = (newPage: number) => {
		if (newPage === page) return
		setPage(newPage)
		account.loadBorrowHistory()
	}

	const handleLoanCurrencySelect = useCallback(
		(e: IOption | null) => {
			const currency = e?.value || undefined
			setSelectedLoanCurrency(currency)
			account.loadBorrowHistory({
				loan_currency: currency,
				collateral_currency: selectedCollateralCurrency,
			})
		},
		[account, selectedCollateralCurrency],
	)

	const handleCollateralCurrencySelect = useCallback(
		(e: IOption | null) => {
			const currency = e?.value || undefined
			setSelectedCollateralCurrency(currency)
			account.loadBorrowHistory({
				loan_currency: selectedLoanCurrency,
				collateral_currency: currency,
			})
		},
		[account, selectedLoanCurrency],
	)

	const columns = [
		{ name: "time", label: formatMessage(commonMessages.time), width: "150px" },
		{
			name: "initial_collateral_amount",
			label: formatMessage(commonMessages.initial_collateral),
			width: "150px",
		},
		{
			name: "initial_loan_amount",
			label: formatMessage(commonMessages.initial_loan),
			width: "150px",
		},
		{
			name: "collateral_currency",
			label: formatMessage(commonMessages.collateral_currency),
			width: "100px",
		},
		{ name: "loan_currency", label: formatMessage(commonMessages.loan_currency), width: "100px" },
		{
			name: "repaid_interest",
			label: formatMessage(commonMessages.repaid_interest),
			width: "150px",
		},
		{
			name: "repaid_penalty_interest",
			label: formatMessage(commonMessages.repaid_penalty),
			width: "150px",
		},
		{
			name: "hourly_interest_rate",
			label: formatMessage(messages.borrow_hourly_rate),
			width: "150px",
		},
	]

	return (
		<ProfileLayout sidebarMenuLevel={SidebarMenuLevelsEnum.Transfer}>
			<Helmet title={formatMessage(commonMessages.borrow_history)} />
			<div className={styles.container}>
				<Header label={formatMessage(commonMessages.borrow_history)} />
				<div className={pageStyles.filters}>
					<div className={pageStyles.filter_select}>
						<CurrencySelect
							onSelectChange={handleCollateralCurrencySelect}
							options={collateralCurrencyOptions}
							value={collateralCurrencyOptions.find(
								option => option.value === selectedCollateralCurrency,
							)}
							placeholder={formatMessage(commonMessages.collateral_currency)}
							withoutLabel
							autoFocus
							isClearable
						/>
					</div>
					<div className={pageStyles.filter_select}>
						<CurrencySelect
							onSelectChange={handleLoanCurrencySelect}
							options={loanCurrencyOptions}
							value={loanCurrencyOptions.find(option => option.value === selectedLoanCurrency)}
							placeholder={formatMessage(commonMessages.loan_currency)}
							withoutLabel
							isClearable
							autoFocus
						/>
					</div>
				</div>
				{isLoading ? (
					<LoadingSpinner />
				) : borrowHistory.length > 0 ? (
					<Table stripped header={{ columns }}>
						{borrowHistory.map(borrow => (
							<BorrowHistoryRow key={borrow.order_id} borrow={borrow} />
						))}
					</Table>
				) : (
					<NoRowsMessage />
				)}
				<Pagination
					count={Math.ceil(borrowHistoryCount / PAGE_SIZE)}
					page={page}
					onChange={handlePaginationClick}
				/>
			</div>
		</ProfileLayout>
	)
}

export default observer(BorrowHistory)
