import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { getPageTitle } from "helpers/global"
import { EBybitMessageTopic } from "types/bybit"
import { SidebarMenuLevelsEnum } from "types/sidebarMenuLevels"
import messages from "messages/finance"
import { useMst } from "models/Root"
import useWindowSize from "hooks/useWindowSize"
import useAutoFetch from "hooks/useAutoFetch"
import useBybitWebSocket from "hooks/newUI/useBybitWebSocket"

import ProfileLayout from "layouts/ProfileLayout"
import { WalletsTable, WalletsBalance, WalletsMobile } from "components/Wallets"

import styles from "styles/pages/Wallets.module.scss"

const Wallets: React.FC = () => {
	const {
		account: { loadRates, selectedType },
		finance: { loadMarginOptions },
		tickers: { loadTickers },
		global: { isWSDown, isAuthenticated },
		bybit: { loadWsCredentials, wsCredentials },
	} = useMst()
	const { formatMessage } = useIntl()
	const { tablet } = useWindowSize()
	const title = getPageTitle(formatMessage(messages.my_wallet))

	useEffect(() => {
		loadTickers()
		loadRates()
		loadMarginOptions()
	}, [])

	useAutoFetch(loadTickers, isWSDown)

	useEffect(() => {
		if (isAuthenticated && !wsCredentials) loadWsCredentials()
	}, [isAuthenticated, wsCredentials])

	useBybitWebSocket({
		baseUrl: "wss://stream.bybit.com/v5/private",
		apiKey: wsCredentials?.api_key,
		expires: wsCredentials?.expires,
		signature: wsCredentials?.signature,
		channels: [EBybitMessageTopic.WALLET],
		disabled: !wsCredentials && selectedType === "UNIFIED",
	})

	return (
		<ProfileLayout sidebarMenuLevel={SidebarMenuLevelsEnum.Wallets}>
			<Helmet
				title={title}
				meta={[
					{ name: "description", content: title },
					{ property: "og:title", content: title },
					{ property: "twitter:title", content: title },
					{ property: "og:description", content: title },
					{ name: "twitter:description", content: title },
				]}
			/>
			<div className={styles.container}>
				<WalletsBalance />
				{tablet ? <WalletsMobile /> : <WalletsTable />}
			</div>
			{/*<WebSocket events={[WSListenEventEnum.TICKERS, WSListenEventEnum.WALLETS]} />*/}
		</ProfileLayout>
	)
}

export default observer(Wallets)
