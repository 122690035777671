import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { matchPath, Navigate, Route, Routes, useLocation, useParams } from "react-router-dom"
import cookies from "js-cookie"

import { routes, URL_VARS } from "constants/routing"
import { useMst } from "models/Root"
import useLocalStorage from "hooks/useLocalStorage"
import { availableLocales, defaultLocale } from "providers/LanguageProvider/i18n"
import { LOCALE_CACHE_KEY } from "utils/cacheKeys"
import useParamQuery from "hooks/useSearchQuery"
import config from "helpers/config"
import { RenderModuleEnum } from "types/render"
import AuthenticatedRoute from "components/AuthenticatedRoute/AuthenticatedRoute"
import { HOST, REFERRAL_CODE_KEY, SHARING_LINK_PREFIX } from "utils/constants"
import { INewsCategoryEnum } from "types/news"

import MarginFAQ from "pages/MarginFAQ"
import News from "pages/News"
import AMLKYCPolicy from "pages/AMLKYCPolicy"
import RiskWarning from "pages/RiskWarning"
import PrivacyPolicy from "pages/PrivacyPolicy"
import TermsOfUse from "pages/TermsOfUse"
import Login from "pages/Login"
import ResetPassword from "pages/ResetPassword"
import Stories from "pages/Stories"
import StoryDetails from "pages/StoryDetails"
import NewsDetails from "pages/NewsDetails"
import VerificationDiiaSuccess from "pages/VerificationDiiaSuccess"
import Register from "pages/Register/Register"
import Wallets from "pages/Wallets"
import TradingFees from "pages/TradingFees"
import ProfileVerification from "pages/ProfileVerification"
import SupportCenter from "pages/SupportCenter"
import Notifications from "pages/Notifications"
import NotificationDetails from "pages/NotificationDetails"
import ProfileSecurity from "pages/ProfileSecurity"
import ProfileSettings from "pages/ProfileSettings"
import ProfileDashboard from "pages/ProfileDashboard"
import FinanceHistory from "pages/FinanceHistory"
import PageNotFound from "pages/PageNotFound"
import WalletDetails from "pages/WalletDetails"
import Deposit from "pages/Deposit"
import Withdrawal from "pages/Withdrawal"
import WithdrawVerification from "pages/WithdrawVerification"
import MultiCharts from "pages/MultiCharts"
import Transfers from "pages/Transfers"
import History from "pages/History/History"
import Fees from "pages/Fees"
import EmailConfirmation from "pages/EmailConfirmation"
import MobileChart from "pages/MobileChart"
import MarginAgreement from "pages/MarginAgreement"
import HomePage from "pages/HomePage"
import FundingWallet from "pages/FundingWallet"
import Terminal from "pages/Terminal"
import AboutUs from "./pages/AboutUs"
import MarginData from "./pages/MarginData/MarginData"
import BorrowHistory from "./pages/BorrowHistory/BorrowHistory"
import TestTable from "./pages/_RedesignedComponents"

interface IRoute {
	path: string
	component: React.ReactNode
	authenticatedRoute?: boolean
	isParent?: boolean
}

export const routesProps: IRoute[] = [
	{
		path: "/",
		component: <HomePage />,
	},
	{
		path: routes.profile.root,
		component: <Navigate to={routes.profile.dashboard} />,
		authenticatedRoute: true,
	},
	{
		path: routes.verification.root,
		component: <ProfileVerification />,
		authenticatedRoute: true,
		isParent: true,
	},
	{
		path: routes.dashboard.root,
		component: <ProfileDashboard />,
		authenticatedRoute: true,
		isParent: true,
	},
	{
		path: routes.settings.root,
		component: <ProfileSettings />,
		authenticatedRoute: true,
		isParent: true,
	},
	// {
	// 	path: routes.api.root,
	// 	component: <ProfileAPI />,
	// 	authenticatedRoute: true,
	// 	isParent: true,
	// },
	{
		path: routes.security.root,
		component: <ProfileSecurity />,
		authenticatedRoute: true,
		isParent: true,
	},
	{
		path: routes.profile.notification,
		component: <Notifications />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.notificationDetails,
		component: <NotificationDetails />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.wallets,
		component: <Wallets />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.fundingWallet,
		component: <FundingWallet />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.walletDetails,
		component: <WalletDetails />,
		authenticatedRoute: true,
	},
	{
		path: routes.history.root,
		component: <History />,
		authenticatedRoute: true,
		isParent: true,
	},
	{
		path: routes.trade.root,
		component: <Navigate to={routes.trade.getPair(config.defaultTerminalPair)} />,
	},
	{
		path: routes.trade.pair,
		component: <Terminal />,
	},
	{
		path: routes.amlKycPolicy,
		component: <AMLKYCPolicy />,
	},
	{
		path: routes.riskWarning,
		component: <RiskWarning />,
	},
	{
		path: routes.privacyPolicy,
		component: <PrivacyPolicy />,
	},
	{
		path: routes.verification.identityDiiaSuccess,
		component: <VerificationDiiaSuccess />,
	},
	{
		path: routes.marginAgreement,
		component: <MarginAgreement />,
	},
	{
		path: routes.termsOfUse,
		component: <TermsOfUse />,
	},
	{
		path: routes.login.root,
		component: <Login />,
	},
	{
		path: routes.register.root,
		component: <Register />,
	},
	{
		path: routes.resetPassword,
		component: <ResetPassword />,
	},
	{
		path: routes.tradingFees,
		component: <TradingFees />,
	},
	{
		path: routes.support.root,
		component: <SupportCenter />,
		isParent: true,
	},
	{
		path: routes.charting,
		component: <MobileChart />,
	},
	{
		path: routes.multiCharts,
		component: <MultiCharts />,
	},
	{
		path: routes.financeHistory.root,
		component: <FinanceHistory />,
		authenticatedRoute: true,
		isParent: true,
	},

	//TODO: remove this
	{
		path: `${routes.profile.wallets}/test`,
		component: <TestTable />,
		authenticatedRoute: true,
		isParent: true,
	},

	{
		path: routes.aboutUs.root,
		component: <AboutUs />,
	},
	{
		path: routes.profile.createDeposit,
		component: <Navigate to={routes.profile.getDepositCurrency("USDT")} />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.createDepositCurrency,
		component: <Deposit />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.createWithdraw,
		component: <Navigate to={routes.profile.getWithdrawCurrency("BTC")} />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.createWithdrawCurrency,
		component: <Withdrawal />,
		authenticatedRoute: true,
	},
	{
		path: routes.profile.verifyWithdraw,
		component: <WithdrawVerification />,
		authenticatedRoute: true,
	},
	{
		path: routes.confirm.email,
		component: <EmailConfirmation />,
		isParent: true,
	},
	{
		path: routes.fees,
		component: <Fees />,
	},
	{
		path: routes.borrowHistory.root,
		component: <BorrowHistory />,
		authenticatedRoute: true,
		isParent: true,
	},
]

if (config.isModuleOn(RenderModuleEnum.TRANSFERS)) {
	routesProps.push({
		path: routes.transfers.root,
		component: <Transfers />,
		authenticatedRoute: true,
		isParent: true,
	})
}

if (config.isModuleOn(RenderModuleEnum.STORIES)) {
	routesProps.push(
		{
			path: routes.stories.root,
			component: <Stories />,
		},
		{
			path: routes.stories.category,
			component: <Stories />,
		},
		{
			path: routes.stories.story,
			component: <StoryDetails />,
		},
	)
}

if (config.isModuleOn(RenderModuleEnum.NEWS)) {
	routesProps.push(
		{
			path: routes.news.root,
			component: <News category={INewsCategoryEnum.EXCHANGE} />,
		},
		{
			path: routes.news.news,
			component: <NewsDetails category={INewsCategoryEnum.EXCHANGE} />,
		},
	)
}

if (config.isModuleOn(RenderModuleEnum.MARGIN)) {
	routesProps.push({
		path: routes.marginTradingFaq,
		component: <MarginFAQ />,
	})
	routesProps.push({
		path: routes.marginData.root,
		component: <MarginData />,
		authenticatedRoute: true,
		isParent: true,
	})
}

interface ILocaleProviderProps {
	children?: React.ReactNode
}

const LocaleProvider: React.FC<ILocaleProviderProps & any> = ({ children }) => {
	const { locale } = useParams<{ locale: string }>()
	const { pathname, search } = useLocation()
	const [cachedLocale] = useLocalStorage(LOCALE_CACHE_KEY, defaultLocale)
	const { global } = useMst()

	const isValidLocale = locale && availableLocales.includes(locale)
	const nextCachedLocale = availableLocales.includes(cachedLocale) ? cachedLocale : defaultLocale
	const nextLocale = isValidLocale ? locale : nextCachedLocale
	const splittedPath = pathname.split("/")

	let nextPath = nextLocale

	if (splittedPath.length > 1 && !isValidLocale) {
		const formattedPath = splittedPath.slice(splittedPath.length > 2 ? 2 : 1).join("/")
		for (let i = 0; i < routesProps.length; i++) {
			const route = routesProps[i]
			if (route.path.includes(`/${formattedPath}`)) {
				nextPath = `/${nextLocale}/${formattedPath}${search}`
				break
			}
		}
	}

	useEffect(() => {
		global.setLocale(nextLocale)
	}, [])

	return isValidLocale ? children : <Navigate to={nextPath} />
}

const ReferralRedirect = () => {
	const query = useParamQuery()
	const referralCode = query.get(REFERRAL_CODE_KEY)
	if (referralCode) {
		cookies.set(REFERRAL_CODE_KEY, referralCode)
	}

	window.location.replace(
		`https://alpcom.page.link/?link=${SHARING_LINK_PREFIX}${referralCode}&apn=com.alpcom.exchange&amv=1.1.0&isi=1642501716&ibi=com.alpcom&imv=1.9.15&ofl=${HOST}/en/register?${REFERRAL_CODE_KEY}=${referralCode}`,
	)

	return <div />
}

const CachedLocaleRedirect = () => {
	const [cachedLocale] = useLocalStorage(LOCALE_CACHE_KEY, defaultLocale)
	const { pathname, search } = useLocation()
	const nextLocale = availableLocales.includes(cachedLocale) ? cachedLocale : defaultLocale

	let path = null
	for (let i = 0; i < routesProps.length; i++) {
		const route = routesProps[i]
		path = matchPath(route.isParent ? `${route.path}/*` : route.path, pathname)
		if (path) {
			break
		}
	}

	return path ? (
		<Navigate to={`/${nextLocale}${pathname === "/" ? "" : pathname}${search}`} />
	) : (
		<PageNotFound />
	)
}

const RouterURL: React.FC = () => {
	const {
		account: { profileStatus },
	} = useMst()

	return (
		<Routes>
			{routesProps
				.filter(({ path }) => {
					switch (path) {
						case routes.transfers.root:
						case routes.verification.root:
						case routes.alphaCodes.root:
							return !profileStatus?.is_sub_account
						default:
							return true
					}
				})
				.map(({ path, component, authenticatedRoute, isParent }) => (
					<Route
						key={path}
						path={`/:${URL_VARS.LOCALE}${path}${isParent ? "/*" : ""}`}
						element={
							authenticatedRoute ? (
								<AuthenticatedRoute>
									<LocaleProvider path={path}>{component}</LocaleProvider>
								</AuthenticatedRoute>
							) : (
								<LocaleProvider path={path}>{component}</LocaleProvider>
							)
						}
					/>
				))}
			<Route path={routes.invite} element={<ReferralRedirect />} />
			<Route path={URL_VARS.DEAD_END_ROUTE} element={<CachedLocaleRedirect />} />
		</Routes>
	)
}

export default observer(RouterURL)
