import React, { PropsWithChildren, useId } from "react"
import cn from "classnames"

import styles from "./stepper.module.scss"

interface IItemProps extends PropsWithChildren {
	title: string
	subTitle?: string
	className?: string
}

const StepperItem: React.FC<IItemProps> = ({ title, subTitle, children, className }) => (
	<div>
		<div className={styles.title}>
			<span>{title}</span>
			{subTitle && <span>{subTitle}</span>}
		</div>
		<div className={cn(styles.content, className)}>{children}</div>
	</div>
)

interface IFooterProps extends PropsWithChildren {
	className?: string
}

const StepperFooter: React.FC<IFooterProps> = ({ children, className }) => (
	<div className={cn(styles.footer, className)}>{children}</div>
)

interface IProps extends PropsWithChildren {
	step?: number
	className?: string
}

interface IStepper extends React.FC<IProps> {
	Item: React.FC<IItemProps>
	Footer: React.FC<IFooterProps>
}

const Stepper: IStepper = ({ step = 0, className, children }) => {
	const id = useId()

	const arrayChildren = React.Children.toArray(children)

	const items = arrayChildren.filter(
		child => React.isValidElement(child) && child.type === StepperItem,
	)
	const footer = arrayChildren.find(
		child => React.isValidElement(child) && child.type === StepperFooter,
	)

	return (
		<div className={cn(styles.stepper, className)}>
			{items.map((item, index) => (
				<div
					key={`stepper-${id}-item-${index.toString()}`}
					className={cn(styles.item, {
						[styles.passed]: index < step,
						[styles.active]: index === step,
						[styles.last]: index === items.length - 1,
					})}
				>
					<div>
						<div>{index < step && <i className="ai ai-check" />}</div>
					</div>
					{item}
				</div>
			))}
			{footer}
		</div>
	)
}

Stepper.Item = StepperItem
Stepper.Footer = StepperFooter

export default Stepper
