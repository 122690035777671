import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { useMst } from "models/Root"
import messages from "messages/common"
import useBodyOverflowHidden from "hooks/newUI/useOverflowHidden"
import Cross from "assets/icons/terminal/Cross"
import Range from "components/NewUI/Range"
import styles from "./index.module.scss"
import ExchangeService from "../../../../services/ExchangeService"
import errorHandler from "../../../../utils/errorHandler"

const AdjustLeverage: React.FC = () => {
	const { formatMessage } = useIntl()
	const {
		terminal: {
			tradeForm: { marginLvl, isOpenMarginChangeModal, setOpenMarginChangeModal, setMarginLvl },
			isLoaded,
			maxMarginLeverage,
		},
		account: { balancesCrossFunds, marginStatus },
		global: { isAuthenticated },
	} = useMst()
	const [value, setValue] = useState(marginLvl)

	const onChange = useCallback((val: number[]) => {
		const [newValue] = val

		setValue(newValue)
	}, [])

	useBodyOverflowHidden(isOpenMarginChangeModal)

	const closeModal = () => setOpenMarginChangeModal(false)

	const cancelAction = useCallback(() => {
		setValue(marginLvl)
		closeModal()
	}, [marginLvl])

	const saveAction = useCallback(() => {
		const date = new Date()
		const totalIndexSum = balancesCrossFunds.reduce((total, item) => total + +item.indexSum, 0)

		// ExchangeService.submitMarginLeverage({
		// 	leverage: `${value}`,
		// 	date: date.toISOString(),
		// 	funds_details: balancesCrossFunds,
		// 	total_funds: `${totalIndexSum.toFixed(4)}`,
		// })
		// 	.then(res => {
		// 		setMarginLvl(res.leverage)
		// 	})
		// 	.catch(err => {
		// 		errorHandler(err)
		// 	})
		// 	.finally(closeModal)
	}, [value, balancesCrossFunds])

	useEffect(() => {
		setValue(marginLvl)
	}, [marginLvl])

	return (
		<div
			className={`${styles.adjustLeverage} ${
				isOpenMarginChangeModal ? styles.adjustLeverage___open : ""
			}`}
		>
			<button
				type="button"
				className={styles.adjustLeverage__closeButton}
				aria-label="close modal"
				onClick={closeModal}
			/>

			<div className={styles.adjustLeverage__content}>
				<div className={styles.adjustLeverage__header}>
					<p className={styles.adjustLeverage__headerText}>
						{formatMessage(messages.adjust_leverage)}
					</p>

					<button
						type="button"
						className={styles.adjustLeverage__headerCloseButton}
						aria-label="close modal"
						onClick={closeModal}
					>
						<Cross />
					</button>
				</div>

				<div className={styles.adjustLeverage__container}>
					<p className={styles.adjustLeverage__containerText}>Leverage</p>

					<div className={styles.adjustLeverage__containerInput}>{value}X</div>
				</div>

				<Range
					values={[value]}
					setValues={onChange}
					step={1}
					min={2}
					max={marginStatus?.leverage ? marginStatus?.leverage : maxMarginLeverage}
					isPercent={false}
					disabled={!isAuthenticated || !isLoaded}
					isDarkerStyle
				/>

				<div className={styles.adjustLeverage__buttons}>
					<button className={styles.adjustLeverage__button} type="button" onClick={cancelAction}>
						Cancel
					</button>

					<button
						className={`${styles.adjustLeverage__button} ${styles.adjustLeverage__button___save}`}
						type="button"
						onClick={saveAction}
					>
						Confirm
					</button>
				</div>
			</div>
		</div>
	)
}

export default observer(AdjustLeverage)
