/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { TWithdrawDailyLimit } from "types/withdrawal"
import { queryVars } from "constants/query"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import { IHistoricalWithdrawal } from "models/Withdrawal"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import WithdrawalService from "services/WithdrawalService"

import RedesignedLayout from "layouts/RedesignedLayout"
import ProgressButton from "components/redesigned/ProgressButton"
import InfoPanel from "components/redesigned/InfoPanel"
import Table, { TTableOnFetch, TTableRef } from "components/redesigned/Table"

import InfoImg from "assets/images/withdraws/info.png"

import { useColumns } from "pages/FinanceHistory/Withdrawals/hooks"

import WithdrawalForm from "./WithdrawalForm"
import styles from "./withdrawal.module.scss"

const HISTORY_SIZE = 10

const Withdrawal: React.FC = () => {
	const { formatMessage } = useIntl()
	const { currency = "" } = useParams<{ [queryVars.currency]: string }>()
	const localeNavigate = useLocaleNavigate()

	const [
		{
			is_hard_limited: isLimited,
			quota: limitQuota = 0,
			used: limitUsed = 0,
			currency: limitCurrency,
		},
		setLimit,
	] = useState<TWithdrawDailyLimit>({
		hard_limit_until: null,
		is_hard_limited: false,
	})
	useEffect(() => {
		WithdrawalService.getWithdrawLimit({ currency })
			.then(setLimit)
			.catch(() => setLimit({ hard_limit_until: null, is_hard_limited: false }))
	}, [])

	const tableRef = useRef<TTableRef>(null)

	const columns = useColumns()

	const handleFetch: TTableOnFetch<IHistoricalWithdrawal> = async () => {
		const { results, count } = await WithdrawalService.getPreviousWithdraws({
			page: 1,
			"page-size": HISTORY_SIZE,
		})
		return { data: results, count }
	}

	const handleAllClick = () => localeNavigate(routes.financeHistory.withdraws)

	return (
		<RedesignedLayout title={formatMessage(commonMessages.withdraw)} className={styles.withdrawal}>
			<div className={styles.main}>
				<WithdrawalForm currency={currency} updateHistory={tableRef.current?.refetch} />
				<div className={styles.info}>
					<ProgressButton
						//TODO: translate
						title="Daily Remaining Limit"
						max={+limitQuota}
						value={+limitUsed}
						unit={limitCurrency}
						disabled={!isLimited}
					/>
					<InfoPanel
						//TODO: translate
						title="How to Withdraw?"
						text={[
							"We will ask you for some personal information.",
							"You will need to upload an ID and take a selfie",
							"to start trading on BTC-Alpha Lite",
						]}
						img={InfoImg}
					/>
				</div>
			</div>
			<div className={styles.history}>
				{/*TODO: translate*/}
				<h2>Withdraw History</h2>
				<Table
					ref={tableRef}
					columns={columns}
					//TODO: translate
					empty="No orders to show."
					lazyLoad
					defFetching
					footer="all"
					defLimit={HISTORY_SIZE}
					onFetch={handleFetch}
					onAllClick={handleAllClick}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(Withdrawal)
