/* eslint-disable no-unused-expressions,no-void,consistent-return,no-proto,jsx-a11y/no-autofocus,no-return-assign */
import React, {
	createElement,
	JSX,
	MouseEventHandler,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import ReactSelect, { ControlProps, OptionProps, SelectInstance } from "react-select"
import { OnChangeValue } from "react-select/dist/declarations/src/types"
import { useIntl } from "react-intl"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"
import useFormField from "hooks/useFormField"
import commonMessages from "messages/common"

import Loader from "assets/icons/Loader"

import { TSelectChangeEvent, TSelectOption } from "./types"

import styles from "./select.module.scss"

function Control<Value>({
	selectProps,
	hasValue,
	getValue,
	menuIsOpen,
	placeholder,
	placeholderIcon,
	loading,
	className,
	searchInput,
}: ControlProps<TSelectOption<Value>> & {
	loading?: boolean
	placeholder?: string
	placeholderIcon?: ReactNode | TAlphaIcon
	className?: string
	searchInput?: JSX.Element
}) {
	const option = hasValue && getValue()[0]
	const isSearch = !!searchInput

	const icon = loading ? <Loader /> : option ? option.icon : placeholderIcon
	const label = option ? option.label : placeholder
	const description = !loading && option && option?.description
	const amount = !loading && option && option?.amount

	const handleClick = () => (menuIsOpen ? selectProps.onMenuClose() : selectProps.onMenuOpen())

	return (
		<button
			className={cn(styles.control, { [styles.selected]: !!option }, className)}
			type="button"
			onClick={handleClick}
			onBlur={selectProps.onMenuClose}
		>
			{menuIsOpen && isSearch ? (
				searchInput
			) : (
				<div className={styles.value}>
					{!!icon && typeof icon === "string" ? <i className={cn("ai", icon)} /> : icon}
					<span className={styles.label}>{label}</span>
					{!!description && <span className={styles.description}>{description}</span>}
					{!!amount && <span className={styles.amount}>{amount}</span>}
				</div>
			)}
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.6665 10.0003L7.99984 13.3337L11.3332 10.0003M4.6665 6.00033L7.99984 2.66699L11.3332 6.00033"
					stroke="white"
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</button>
	)
}

function Option<Value>({ data, isSelected, selectOption }: OptionProps<TSelectOption<Value>>) {
	const { icon, label, description, amount, disabled } = data
	const handleClick = () => selectOption(data)
	return (
		<button
			type="button"
			className={cn(styles.option, { [styles.selected]: isSelected })}
			disabled={disabled}
			onClick={handleClick}
		>
			{!!icon && typeof icon === "string" ? <i className={cn("ai", icon)} /> : icon}
			<span className={styles.label}>{label}</span>
			{!!description && <span className={styles.description}>{description}</span>}
			{!!amount && <span className={styles.amount}>{amount}</span>}
		</button>
	)
}

type TProps<Value> = {
	name?: string
	placeholder?: string
	placeholderIcon?: ReactNode | TAlphaIcon
	options?: TSelectOption<Value>[]
	value?: Value
	defValue?: Value
	loading?: boolean
	disabled?: boolean
	mini?: boolean
	search?: boolean
	onChange?: TSelectChangeEvent<Value>
	className?: string
	controlClassName?: string
}

export default function Select<Value = string>({
	name,
	placeholder,
	placeholderIcon,
	options = [],
	value,
	defValue,
	loading,
	disabled,
	mini,
	search,
	onChange,
	className,
	controlClassName,
}: TProps<Value>) {
	const { formatMessage } = useIntl()

	const rootRef = useRef<HTMLDivElement>(null)
	const selectRef = useRef<SelectInstance<TSelectOption<Value>>>(null)
	const inputRef = useRef<HTMLInputElement>()

	const [opened, setOpened] = useState<boolean>(false)
	const handleOpen = () => setOpened(true)
	const handleClose = () => {
		if (
			window.document.activeElement?.nodeName === "INPUT" &&
			rootRef.current?.contains(window.document.activeElement)
		)
			return
		setOpened(false)
	}

	useEffect(() => {
		if (!opened) return
		const handleBodyClick = ({ target }: MouseEvent) =>
			!rootRef.current?.contains(target as HTMLElement) && setOpened(false)
		window.addEventListener("click", handleBodyClick, true)
		return () => window.removeEventListener("click", handleBodyClick, true)
	}, [opened])

	const handleChange = (option: OnChangeValue<TSelectOption<Value>, any>) => {
		setOpened(false)
		const newValue = (option as TSelectOption<Value>)?.value
		setTimeout(() => onChange?.(newValue || null), 0)
		setInputValue(newValue?.toString() || "")
	}

	const clearValue = () => selectRef.current?.clearValue()
	const resetValue = () => setValue(value !== undefined ? value : defValue)

	const setValue = (newValue?: Value) => {
		const option =
			newValue === undefined ? undefined : options.find(option => option.value === newValue)
		option ? selectRef.current?.selectOption(option) : clearValue()
	}

	const { setInputValue } = useFormField({ name, rootRef, inputRef, onReset: resetValue })

	useEffect(() => setValue(value), [value])
	useEffect(() => void (value === undefined && setValue(defValue)), [defValue])
	useEffect(
		() =>
			void (value !== undefined ? setValue(value) : defValue !== undefined && setValue(defValue)),
		[options.length],
	)

	useEffect(() => {
		const nodeInput = rootRef.current?.querySelector(`input[name="${name}"]`) as HTMLInputElement
		inputRef.current = nodeInput
		if (!nodeInput) return
		nodeInput.style.setProperty("display", "none")
		nodeInput.type = "text"
	}, [name])

	const [searchString, setSearchString] = useState<string>("")
	useEffect(() => setSearchString(""), [opened])
	const filteredOptions = !search
		? options
		: options.filter(({ label }) =>
				label.toString().toLowerCase().includes(searchString.trim().toLowerCase()),
		  )

	const searchInputRef = useRef<HTMLInputElement>(null)
	const searchInputSelectionRef = useRef<[number, number]>([0, 0])
	const searchInput = useMemo(
		() =>
			!search ? undefined : (
				<input
					ref={searchInputRef}
					autoFocus
					className={styles.search}
					value={searchString}
					onFocus={({ currentTarget }) =>
						currentTarget.setSelectionRange(
							searchInputSelectionRef.current[0],
							searchInputSelectionRef.current[1],
						)
					}
					onChange={({ target }) => {
						searchInputSelectionRef.current = [
							target.selectionStart || 0,
							target.selectionEnd || target.selectionStart || 0,
						]
						setSearchString(target.value)
					}}
					onKeyDown={({ key, currentTarget }) => {
						if (key === "Escape") setSearchString("")
						const selectionStart = currentTarget.selectionStart || 0
						const selectionEnd = currentTarget.selectionEnd || selectionStart
						if ((key === "Backspace" || key === "Delete") && selectionStart !== selectionEnd) {
							searchInputSelectionRef.current = [selectionStart, selectionStart]
							setSearchString(val => val.slice(0, selectionStart) + val.slice(selectionEnd))
						} else if (key === "Backspace" && selectionStart > 0) {
							searchInputSelectionRef.current = [selectionStart - 1, selectionStart - 1]
							setSearchString(val => val.slice(0, selectionStart - 1) + val.slice(selectionStart))
						} else if (key === "Delete" && selectionStart < currentTarget.value.length) {
							searchInputSelectionRef.current = [selectionStart, selectionStart]
							setSearchString(val => val.slice(0, selectionStart) + val.slice(selectionStart + 1))
						}
					}}
				/>
			),
		[search, searchString],
	)

	const iconed = useMemo(() => options?.some(({ icon }) => !!icon), [options])

	return (
		<div
			ref={rootRef}
			className={cn(
				styles.select,
				{ [styles.mini]: mini, [styles.disabled]: disabled, [styles.iconed]: iconed },
				className,
			)}
		>
			<ReactSelect
				ref={selectRef}
				name={name}
				isLoading={loading}
				classNamePrefix="react-select"
				options={filteredOptions}
				menuIsOpen={opened}
				onMenuOpen={handleOpen}
				onMenuClose={handleClose}
				isDisabled={disabled || loading}
				menuPlacement="auto"
				components={{
					Option: Option<Value>,
					Control: props =>
						createElement(Control<Value>, {
							...props,
							loading,
							placeholder: placeholder || formatMessage(commonMessages.select),
							placeholderIcon,
							className: controlClassName,
							searchInput,
						} as ControlProps<TSelectOption<Value>>),
				}}
				onChange={handleChange}
			/>
		</div>
	)
}
