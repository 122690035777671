import React, { useState, useRef } from "react"
import { useIntl } from "react-intl"
import { ReactComponent as ArrowDownIcon } from "assets/icons/ai-hint-down.svg"
import styles from "styles/components/UI/CustomSelect.module.scss"
import { useOnClickOutside } from "hooks/useOnClickOutside"
import cn from "classnames"

export interface ILabel {
	code: string
	name?: string
	available?: string
	precision?: number
	disabled?: boolean
	image_png?: string
	image_svg?: string
}

export interface ICSelectOption {
	label: ILabel
	value: string
}

type CustomSelectProps = {
	label?: string
	value?: ICSelectOption
	options: ICSelectOption[]
	hideIcons?: boolean
	codeUpperCase?: boolean
	error?: string | string[] | React.ReactNode
	helpText?: string | JSX.Element | React.ReactNode
	onChange: (value: any) => void
}

const CustomSelect = ({
	label: cLabel,
	value: cValue,
	options: cOptions,
	hideIcons,
	codeUpperCase = true,
	error,
	helpText,
	onChange: cOnChange,
}: CustomSelectProps) => {
	const customSelectRef = useRef(null)

	const precision = cValue?.label?.available
		? parseFloat(cValue?.label?.available) > 0
			? cValue?.label?.precision
			: 2
		: 0

	const { formatNumber } = useIntl()

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

	const handleOptionClick = (value: ICSelectOption): void => {
		setIsMenuOpen(!isMenuOpen)
		cOnChange(value)
	}

	const handleMenuOpen = () => {
		setIsMenuOpen(true)
	}

	const handleMenuClose = () => {
		setIsMenuOpen(false)
	}

	useOnClickOutside(customSelectRef, handleMenuClose)

	return (
		<div className={cn(styles.custom_select, { [styles.custom_select_error]: error })}>
			<div className={styles.custom_select_label}>{cLabel}</div>
			<div className={styles.custom_select_options}>
				<div
					className={cn(
						styles.custom_select_selected_label,
						isMenuOpen && styles.custom_select_selected_opened,
					)}
					onClick={handleMenuOpen}
				>
					<span>
						{cValue?.label?.code ? (
							<div className={styles.row}>
								<div className={styles.currency_code}>
									{!hideIcons &&
										(cValue?.label?.image_svg || cValue?.label?.image_png ? (
											<img
												src={cValue?.label?.image_svg || cValue?.label?.image_png}
												alt={cValue?.label?.code}
											/>
										) : (
											<i className={`ai ai-${cValue?.label?.code.toLowerCase()}`} />
										))}
									{codeUpperCase ? cValue?.label?.code.toUpperCase() : cValue?.label?.code}
								</div>
								{!!cValue?.label?.name && (
									<div className={styles.currency_name}>({cValue?.label?.name})</div>
								)}
							</div>
						) : (
							cValue?.label?.name
						)}
					</span>
					<span>
						<ArrowDownIcon />
					</span>
				</div>
				{isMenuOpen && (
					<div className={styles.custom_select_option_menus} ref={customSelectRef}>
						{cOptions &&
							cOptions.length > 0 &&
							cOptions.map(option => (
								<button key={option.value} type="button" onClick={() => handleOptionClick(option)}>
									{option?.label?.code ? (
										<div className={styles.coin_block}>
											<div className={styles.row}>
												<div className={styles.currency_code}>
													{!hideIcons &&
														(option?.label?.image_svg || option?.label?.image_png ? (
															<img
																src={option?.label?.image_svg || option?.label?.image_png}
																alt={option?.label?.code}
															/>
														) : (
															<i className={`ai ai-${option?.label?.code.toLowerCase()}`} />
														))}
													{codeUpperCase ? option?.label?.code.toUpperCase() : option?.label?.code}
												</div>
												{!!option?.label?.name && (
													<div className={styles.currency_name}>({option?.label?.name})</div>
												)}
											</div>
											<div className={styles.row}>
												{option?.label?.available ? (
													<div
														className={cn(styles.currency_available, {
															[styles.secondary]: parseFloat(option?.label?.available) <= 0,
														})}
													>
														{formatNumber(parseFloat(option?.label?.available), {
															useGrouping: false,
															minimumFractionDigits: precision,
															maximumFractionDigits: precision,
														})}
													</div>
												) : null}
											</div>
										</div>
									) : (
										option?.label?.name
									)}
								</button>
							))}
					</div>
				)}
			</div>
			{error ? (
				<div className={cn(styles.help_text, styles.error)}>
					{Array.isArray(error) ? error.join(" ") : error}
				</div>
			) : null}
			{helpText && !error ? <div className={styles.help_text}>{helpText}</div> : null}
		</div>
	)
}

export default CustomSelect
