import React, { PropsWithChildren } from "react"

import useWindowSize from "hooks/useWindowSize"

import Cookies from "components/Cookies"
import Header from "components/NewUI/Header"

import Wallet from "./menus/Wallet"

import styles from "./redesignedLayout.module.scss"

interface IProps extends PropsWithChildren {
	title?: string
	className?: string
}

const RedesignedLayout: React.FC<IProps> = ({ title, children, className }) => {
	const { desktop } = useWindowSize()

	return (
		<>
			<Header className={styles.header} />
			<div className={styles.layout}>
				{desktop && (
					<div className={styles.sideBar}>
						<Wallet />
					</div>
				)}
				<div className={styles.content}>
					{title && <h1>{title}</h1>}
					<div className={className}>{children}</div>
					<footer>
						{/*TODO: translate*/}
						<span>Alphacom UAB, J. Savickio g. 4-7, LT-01108 Vilnius</span>
						<span>© 2024 BTC-Alpha</span>
					</footer>
				</div>
			</div>
			<Cookies />
		</>
	)
}

export default RedesignedLayout
