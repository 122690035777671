import React, { useState, useCallback, useEffect } from "react"
import { useIntl } from "react-intl"
import cn from "classnames"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import commonMessages from "messages/common"
import historyMessages from "messages/history"
import { IHeader } from "components/UI/Table/Table"
import historyStyles from "styles/pages/History/History.module.scss"
import { AccountTypeEnum } from "types/account"
import { useMst } from "models/Root"
import useTradesHistory from "hooks/useTradesHistory"
import { getUrlParams } from "utils/filter"
import CurrencySelect, { IOption as ICurrencyOption } from "components/UI/CurrencySelect"
import { ISelectOption } from "components/UI/Select"
import { queryVars } from "constants/query"
import Tab from "components/UI/Tab"
import Button from "components/UI/Button"
import { RowSkeleton, Table } from "components/UI/Table"
import Pagination from "components/UI/Pagination"
import pageStyles from "styles/pages/Page.module.scss"
import noResultsStyles from "styles/components/Table/NoResultMessage.module.scss"
import config from "helpers/config"
import { RenderModuleEnum } from "types/render"
import { IHistoryParams } from "types/history"
import TradesHistoryTableRow from "./TradesHistoryTableRow"
import { ACCOUNT_TYPE } from "../../../constants/exchange"

const PAGE_SIZE = 15

interface IFilter {
	[queryVars.pair]: string
	[queryVars.sort]: {
		name: string
		value: "asc" | "desc"
	} | null
}

const TradesHistoryTable: React.FC = () => {
	const navigate = useNavigate()
	const { formatMessage } = useIntl()
	const {
		history: { loadTradeHistory },
		tickers: { loadTickers, list: tickerList },
		bybit: { loadPairs, spotPairs },
	} = useMst()
	const { tradeHistory, isTradeHistoryLoading } = useTradesHistory()

	useEffect(() => {
		console.log("Trades History:", tradeHistory) // Вывод данных в консоль
	}, [tradeHistory])

	const [activeTab, setActiveTab] = useState<AccountTypeEnum>(AccountTypeEnum.SPOT)
	const [page, setPage] = useState<number>(1)
	const [filter, setFilter] = useState<IFilter>({
		pair: "",
		sort: null,
	})

	const [loadParams, setLoadParams] = useState<IHistoryParams>({
		[queryVars.category]: AccountTypeEnum.SPOT,
	})

	useEffect(() => {
		loadPairs()
	}, [loadPairs])

	const filterCurrenciesOptions: ICurrencyOption[] = (spotPairs || []).map(
		(pair: any): ICurrencyOption => ({
			label: {
				code: `${pair.baseCoin}${pair.quoteCoin}`,
			},
			value: `${pair.baseCoin}${pair.quoteCoin}`,
		}),
	)

	const filterPairOption: ICurrencyOption | undefined =
		filterCurrenciesOptions.find(o => o.value === filter.pair) ?? undefined

	const handlePairSelectChange = useCallback(
		(e: ICurrencyOption | ISelectOption): void => {
			const { value } = e
			setFilter(prevState => ({
				...prevState,
				pair: value,
			}))

			const updatedParams = {
				...loadParams,
				symbol: value,
			}

			setLoadParams(updatedParams)
			loadTradeHistory(updatedParams)
		},
		[loadTradeHistory, loadParams],
	)

	const handleChangeOrdering = (name: string): void => {
		const nextSort: IFilter["sort"] = {
			name: name,
			value: filter.sort?.value === queryVars.asc ? queryVars.desc : queryVars.asc,
		}
		const nextFilter = {
			...filter,
			sort: nextSort,
		}
		setFilter(nextFilter)
		navigate({
			[queryVars.search]: getUrlParams({ ...nextFilter, [queryVars.type]: activeTab, page }),
		})
		setLoadParams({
			[queryVars.category]: activeTab,
		})
	}

	const handleSearch = (): void => {
		setPage(1)
		navigate({
			[queryVars.search]: getUrlParams({
				...filter,
				[queryVars.type]: activeTab,
				[queryVars.page]: 1,
			}),
		})
		setLoadParams({
			[queryVars.category]: activeTab,
		})
	}

	const handlePageChange = (page: number): void => {
		setPage(page)
		navigate({
			[queryVars.search]: getUrlParams({ ...filter, [queryVars.type]: activeTab, page }),
		})
		setLoadParams({
			[queryVars.category]: activeTab,
		})
	}

	const handleTabChange = (tab: string): void => {
		setActiveTab(tab as AccountTypeEnum)
		setPage(1)
		setLoadParams({
			[queryVars.category]: tab,
		})
		handleReset()
		navigate({ [queryVars.search]: `?${queryVars.type}=${tab}` })
	}

	const handleReset = () => {
		setFilter({
			pair: "",
			sort: {
				name: "",
				value: queryVars.desc,
			},
		})

		const resetParams = {
			category: "spot",
			[queryVars.page]: 1,
			[queryVars.wallet_type]: ACCOUNT_TYPE[activeTab],
			[queryVars.page_size]: PAGE_SIZE,
		}

		setLoadParams(resetParams)
		loadTradeHistory(resetParams)
	}

	const headerOptions: IHeader = {
		primary: true,
		className: historyStyles.table_row,
		columns: [
			{
				name: "date",
				label: formatMessage(historyMessages.active_orders_date),
				width: "150px",
				sort: filter.sort?.name === queryVars.date ? filter.sort?.value : "default",
				onSortChange: handleChangeOrdering,
			},
			{
				name: "pair_id",
				label: formatMessage(historyMessages.active_orders_pair),
				width: "100px",
			},
			{
				name: "type",
				label: formatMessage(historyMessages.active_orders_side),
				align: "center",
				width: "70px",
				minWidth: "70px",
			},
			{
				name: "price",
				label: formatMessage(historyMessages.active_orders_trading_price),
				align: "right",
				width: "120px",
				sort: filter.sort?.name === "price" ? filter.sort?.value : "default",
				onSortChange: handleChangeOrdering,
			},
			{
				name: "amount",
				label: formatMessage(historyMessages.active_orders_amount),
				width: "120px",
				align: "right",
			},
			{
				name: "total",
				label: formatMessage(historyMessages.active_orders_total),
				width: "120px",
				align: "right",
			},
			{
				name: "transaction_fee",
				label: formatMessage(historyMessages.active_orders_transaction_fee),
				width: "120px",
				align: "right",
			},
		],
	}

	const tabs = [
		{
			name: AccountTypeEnum.SPOT,
			label: "Spot",
		},
		{
			name: AccountTypeEnum.CROSS,
			label: "Cross",
		},
	]

	useEffect(() => {
		loadTickers()
	}, [])

	useEffect(() => {
		loadTradeHistory(loadParams)
	}, [loadParams])

	return (
		<div className={pageStyles.table_container}>
			{config.isModuleOn(RenderModuleEnum.MARGIN) ? (
				<div className={cn(historyStyles.tabs, pageStyles.tabs, pageStyles.table_head)}>
					{tabs.map(({ label, name }) => (
						<Tab
							key={name}
							name={name}
							isActive={name === activeTab}
							onClick={() => handleTabChange(name)}
						>
							{label}
						</Tab>
					))}
				</div>
			) : null}
			<div className={cn(historyStyles.filters, pageStyles.filters)}>
				<div className={pageStyles.filter_select}>
					<CurrencySelect
						mini
						onSelectChange={handlePairSelectChange}
						options={filterCurrenciesOptions}
						value={filterPairOption}
						autoFocus
						label={formatMessage(historyMessages.active_orders_pair)}
						placeholder={formatMessage(commonMessages.all)}
					/>
				</div>
				<div className={pageStyles.filters_buttons}>
					<Button
						variant="text"
						color="primary"
						label={formatMessage(commonMessages.reset)}
						mini
						onClick={handleReset}
					/>
					<Button
						variant="filled"
						color="primary"
						label={formatMessage(commonMessages.search)}
						mini
						onClick={handleSearch}
						isLoading={isTradeHistoryLoading}
					/>
				</div>
			</div>
			<Table header={headerOptions}>
				{isTradeHistoryLoading ? (
					[...new Array(PAGE_SIZE)].map((_, i: number) => (
						<RowSkeleton cells={headerOptions.columns} key={i} />
					))
				) : tradeHistory && tradeHistory.length > 0 ? (
					tradeHistory.map((trade, idx) => (
						<TradesHistoryTableRow key={trade.id + idx} trade={trade} />
					))
				) : (
					<div className={noResultsStyles.no_rows_message_container}>
						<i className="ai ai-dok_empty" />
						{formatMessage(commonMessages.table_no_data)}
					</div>
				)}
			</Table>
			{tradeHistory && tradeHistory.length ? (
				<div className={pageStyles.pagination_container}>
					<Pagination
						count={Math.ceil(tradeHistory.length / PAGE_SIZE)}
						page={page}
						onChange={handlePageChange}
					/>
				</div>
			) : null}
		</div>
	)
}

export default observer(TradesHistoryTable)
