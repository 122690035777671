import React from "react"
import { observer } from "mobx-react-lite"

import useFunds from "hooks/newUI/terminal/useFunds"
import styles from "components/NewUI/Terminal/Bottom/Positions/index.module.scss"
import Spinner from "components/UI/Spinner"
import SignInSignUp from "../SignInSignUp"
import ListHeadline from "../ListHeadline"
import List from "./List"

const Funds: React.FC = () => {
	const {
		isAuthenticated,
		listHeadline,
		listScheme,
		list,
		isLoadingBalances,
		isTradeHistoryLoading,
	} = useFunds()

	return (
		<div className={styles.positions}>
			{isAuthenticated ? (
				isLoadingBalances ? ( // Отображаем спиннер, пока идет загрузка
					<div className={styles.loading}>
						<Spinner size={24} />
					</div>
				) : (
					<List
						list={list}
						listScheme={listScheme}
						pairAmountPrecision={2}
						pairPricePrecision={2}
						isTradeHistoryLoading={isTradeHistoryLoading}
					/>
				)
			) : (
				<>
					<ListHeadline list={listHeadline} listScheme={listScheme} />

					<SignInSignUp />
				</>
			)}
		</div>
	)
}

export default observer(Funds)
