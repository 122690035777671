import ApiClient from "helpers/ApiClient"
import errorHandler from "utils/errorHandler"
import {
	IGetDepositAddressParams,
	IGetDepositAddressResponse,
	IGetDepositHistoryResponse,
	IGetDepositMethodsParams,
	TGetDepositMethodsResponse,
	IGetDepositHistoryParams,
} from "types/deposit"

const DepositService = {
	getMethods: (params: IGetDepositMethodsParams): Promise<TGetDepositMethodsResponse> =>
		ApiClient.get("web/deposit/methods", params),
	getDepositAddress: async (
		params: IGetDepositAddressParams,
	): Promise<IGetDepositAddressResponse> => ApiClient.get("web/deposit/address", params),
	getDepositHistory: (params?: IGetDepositHistoryParams): Promise<IGetDepositHistoryResponse> =>
		ApiClient.get("web/deposit/history", params),
}

export default DepositService
