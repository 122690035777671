import React from "react"
import { observer } from "mobx-react-lite"
// eslint-disable-next-line import/no-extraneous-dependencies
import Confetti from "react-confetti"

import { useMst } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import { routes } from "constants/routing"
import Header from "../Header"
import Button from "../Button"
import styles from "./RegWelcome.module.scss"

const RegWelcome: React.FC = () => {
	const {
		register: { isLoading, onSubmitFinishStep },
	} = useMst()
	const localeNavigate = useLocaleNavigate()

	const submitFinishStep = async () => {
		await onSubmitFinishStep()
		localeNavigate(routes.trade.root)
	}

	return (
		<div className={styles.regWelcome}>
			<Header
				headline="Welcome to BTC-Alpha"
				description="Embark on your cryptocurrency journey with BTC-Alpha, your trusted gateway to the world of digital finance."
			/>

			<div className={styles.regWelcome__wrapper}>
				<Confetti
					width={window.innerWidth}
					height={window.innerHeight}
					numberOfPieces={100}
					gravity={0.03}
				/>

				<div className={styles.regWelcome__buttonWrapper}>
					<Button isLoading={isLoading} onClick={submitFinishStep} type="button">
						Start Trading
					</Button>
				</div>
			</div>
		</div>
	)
}

export default observer(RegWelcome)
