export const alphaIcons = [
	"ai-1inch",
	"ai-2fa",
	"ai-aave",
	"ai-about_us",
	"ai-ac3",
	"ai-act",
	"ai-activity",
	"ai-add_filled",
	"ai-adres_book_filled",
	"ai-adres_book_outline",
	"ai-aglt",
	"ai-agltold",
	"ai-akn",
	"ai-akro",
	"ai-alc",
	"ai-ale",
	"ai-alp",
	"ai-alpha_stories",
	"ai-alpha",
	"ai-amd",
	"ai-android",
	"ai-announcement-02",
	"ai-api",
	"ai-app",
	"ai-apple",
	"ai-arcee",
	"ai-arrow_down",
	"ai-arrow_left",
	"ai-arrow_right",
	"ai-arrow_up",
	"ai-arrow-left",
	"ai-at_sign",
	"ai-atb",
	"ai-attachment",
	"ai-aud",
	"ai-aunit",
	"ai-avatar_new",
	"ai-avatar",
	"ai-bac",
	"ai-back",
	"ai-bal",
	"ai-balance_outline",
	"ai-balance",
	"ai-band",
	"ai-bar_chart_2",
	"ai-bar_chart_3",
	"ai-bar_chart_mini",
	"ai-bar-chart-10",
	"ai-bat",
	"ai-bbr",
	"ai-bch",
	"ai-bcz",
	"ai-bdx",
	"ai-beer",
	"ai-bell-02",
	"ai-bell",
	"ai-blz",
	"ai-book_open",
	"ai-book",
	"ai-bookmark",
	"ai-box",
	"ai-bq",
	"ai-bria",
	"ai-brl",
	"ai-bst",
	"ai-btc",
	"ai-btcd",
	"ai-btcz",
	"ai-bts",
	"ai-button_android_apk",
	"ai-buy_crypto",
	"ai-buz",
	"ai-byn",
	"ai-calendar",
	"ai-camera_off",
	"ai-camera_on",
	"ai-cancel_filled",
	"ai-cancel_mini",
	"ai-care",
	"ai-cars",
	"ai-cast",
	"ai-cbc",
	"ai-ccrb",
	"ai-cdy",
	"ai-change_arrows",
	"ai-change",
	"ai-chart_outline_2",
	"ai-chart_outline",
	"ai-chat",
	"ai-check_filled",
	"ai-check_mini",
	"ai-check_outline",
	"ai-check_outlined",
	"ai-check",
	"ai-checkbox_checked",
	"ai-checkbox_Indeterminate",
	"ai-checkbox_unchecked",
	"ai-chevron_double_left",
	"ai-chevron_double_right",
	"ai-chevron_down_mini",
	"ai-chevron_down",
	"ai-chevron_left_mini",
	"ai-chevron_left",
	"ai-chevron_right_mini",
	"ai-chevron_right",
	"ai-chevron_up_mini",
	"ai-chevron_up",
	"ai-circle_minus",
	"ai-circle_plus",
	"ai-circle_x",
	"ai-clipboard",
	"ai-clock-rewind",
	"ai-clock",
	"ai-close",
	"ai-clr",
	"ai-code-02",
	"ai-coin_new",
	"ai-coin_outline",
	"ai-coin",
	"ai-coins-hand",
	"ai-coins-swap-01",
	"ai-col",
	"ai-comp",
	"ai-copy_filled",
	"ai-copy_new",
	"ai-copy_outlined",
	"ai-credit_card",
	"ai-credit-card-download",
	"ai-credit-card-upload",
	"ai-crv-231",
	"ai-crv",
	"ai-cscj",
	"ai-ctg",
	"ai-cup",
	"ai-currency",
	"ai-cvn",
	"ai-cvp",
	"ai-cxp",
	"ai-dao",
	"ai-dash",
	"ai-dcy",
	"ai-deex",
	"ai-delete_outline",
	"ai-development",
	"ai-dim",
	"ai-discuss",
	"ai-dislike_filled",
	"ai-dislike_outline",
	"ai-doc_check_outline",
	"ai-doc_filled",
	"ai-doc_outlined",
	"ai-dodo",
	"ai-doge",
	"ai-dok_empty",
	"ai-dok_x",
	"ai-dots_grid",
	"ai-download",
	"ai-edc",
	"ai-edit_form",
	"ai-edit_outline",
	"ai-edit",
	"ai-email_filled",
	"ai-eos",
	"ai-error_circle",
	"ai-error_octagon_outline",
	"ai-error_outline",
	"ai-error_outlined",
	"ai-etc",
	"ai-eth",
	"ai-ethd",
	"ai-etz",
	"ai-eur",
	"ai-evn",
	"ai-exchange",
	"ai-eye_disabled",
	"ai-eye",
	"ai-facebook",
	"ai-faq",
	"ai-fees",
	"ai-file_minus",
	"ai-file_plus",
	"ai-file_text",
	"ai-file-02",
	"ai-file-check-02",
	"ai-file-x-02",
	"ai-filter",
	"ai-fingerprint-04",
	"ai-fire",
	"ai-flag_filled-221",
	"ai-flag_filled-245",
	"ai-flash",
	"ai-forth",
	"ai-gbp",
	"ai-gene",
	"ai-gera",
	"ai-globe",
	"ai-gmt",
	"ai-gpc",
	"ai-grid",
	"ai-grn",
	"ai-grt",
	"ai-hamburger",
	"ai-happy",
	"ai-highlight",
	"ai-hint_circle_outline",
	"ai-hint_down",
	"ai-hint_left",
	"ai-hint_outline_new",
	"ai-hint_right",
	"ai-hint_up",
	"ai-hint",
	"ai-history",
	"ai-hkd",
	"ai-hlix",
	"ai-hmr",
	"ai-home_outline",
	"ai-home",
	"ai-horizont",
	"ai-hqx",
	"ai-hwc",
	"ai-icx",
	"ai-idr",
	"ai-image",
	"ai-infinity",
	"ai-info_circle_outline",
	"ai-info_coin",
	"ai-info_filled",
	"ai-info_outlined",
	"ai-info-circle",
	"ai-info-octagon",
	"ai-inr",
	"ai-instagram",
	"ai-jwl",
	"ai-knc",
	"ai-krg",
	"ai-krm",
	"ai-krw",
	"ai-kvnt",
	"ai-kyc",
	"ai-kzc",
	"ai-kzt",
	"ai-layers",
	"ai-layout-grid-01",
	"ai-lend",
	"ai-lhcoin",
	"ai-like_filled",
	"ai-like_outline",
	"ai-linda",
	"ai-link_1",
	"ai-link_4",
	"ai-link_5",
	"ai-link-232",
	"ai-link",
	"ai-linkedin",
	"ai-lio",
	"ai-list",
	"ai-listing",
	"ai-lit",
	"ai-loading",
	"ai-lock",
	"ai-locked",
	"ai-log_in",
	"ai-log_out",
	"ai-log-out-01",
	"ai-logout",
	"ai-lrc",
	"ai-lrg",
	"ai-ltc",
	"ai-ltcd",
	"ai-mail_open",
	"ai-mail_outline_new",
	"ai-mail",
	"ai-map_pin",
	"ai-map",
	"ai-mdl",
	"ai-mediakit",
	"ai-medium",
	"ai-message_circle",
	"ai-message_square",
	"ai-message-question-square",
	"ai-messenger",
	"ai-min",
	"ai-mini_arrow_double",
	"ai-mini_down_left",
	"ai-mini_down_right",
	"ai-mini_down",
	"ai-mini_left",
	"ai-mini_right",
	"ai-mini_up_left",
	"ai-mini_up_right",
	"ai-mini_up",
	"ai-minus_mini",
	"ai-minus",
	"ai-mkr",
	"ai-mlc",
	"ai-mnc",
	"ai-mobile",
	"ai-monitor",
	"ai-moon",
	"ai-mrk",
	"ai-mrx",
	"ai-new_filled",
	"ai-new_outlined",
	"ai-news",
	"ai-nim",
	"ai-noah",
	"ai-notification_check",
	"ai-notification_off",
	"ai-notification_on",
	"ai-notify",
	"ai-npc",
	"ai-nsd",
	"ai-nvb",
	"ai-octagon_x",
	"ai-ohc",
	"ai-opti",
	"ai-orders_outline",
	"ai-orders",
	"ai-oton",
	"ai-ouro",
	"ai-paradice",
	"ai-pc",
	"ai-pct",
	"ai-pdf",
	"ai-person",
	"ai-pgc",
	"ai-pie_chart",
	"ai-pirl",
	"ai-play",
	"ai-pln",
	"ai-plus_mini",
	"ai-plus",
	"ai-pomz",
	"ai-pond",
	"ai-posket",
	"ai-price_mini",
	"ai-price",
	"ai-privacy_policy",
	"ai-profile",
	"ai-pti",
	"ai-purse",
	"ai-pzm",
	"ai-qbic",
	"ai-qdao",
	"ai-qoob",
	"ai-qr_code",
	"ai-qr-code-01",
	"ai-quotes",
	"ai-r",
	"ai-rch",
	"ai-reddit",
	"ai-reef",
	"ai-referral_program",
	"ai-referral",
	"ai-reload",
	"ai-rem",
	"ai-ren",
	"ai-reply_in",
	"ai-reply",
	"ai-rev",
	"ai-revain",
	"ai-rocket",
	"ai-rss",
	"ai-rub",
	"ai-rwc",
	"ai-search",
	"ai-security",
	"ai-sek",
	"ai-send",
	"ai-sentence",
	"ai-server",
	"ai-settings_2",
	"ai-settings_outline",
	"ai-settings-02",
	"ai-settings",
	"ai-sgd",
	"ai-share_outline",
	"ai-share",
	"ai-shield_off",
	"ai-shield_on",
	"ai-shield_transfer",
	"ai-shield-03",
	"ai-shield",
	"ai-skl",
	"ai-sld",
	"ai-smartphone",
	"ai-smile_alp",
	"ai-sms",
	"ai-snx",
	"ai-spa",
	"ai-spd",
	"ai-sput",
	"ai-srorj",
	"ai-star_filled",
	"ai-star_half",
	"ai-star_outlined",
	"ai-stn",
	"ai-storj",
	"ai-sun",
	"ai-sup",
	"ai-support_new",
	"ai-support",
	"ai-sushi",
	"ai-switch-horizontal-01",
	"ai-sxp",
	"ai-tablet",
	"ai-tag_outline",
	"ai-target-05",
	"ai-target",
	"ai-telegram",
	"ai-terms_of_use",
	"ai-test_new_filled",
	"ai-test_new_outlined",
	"ai-tft",
	"ai-tlc",
	"ai-tool",
	"ai-trade_new",
	"ai-trade_outline",
	"ai-trade-candle-01",
	"ai-Transf",
	"ai-transfer",
	"ai-translation",
	"ai-trash_filled",
	"ai-trash",
	"ai-trb-230",
	"ai-trb",
	"ai-trf",
	"ai-trx",
	"ai-try",
	"ai-ttc",
	"ai-twd",
	"ai-twitter",
	"ai-uah",
	"ai-ubex",
	"ai-ubt",
	"ai-ucash",
	"ai-ui_clock",
	"ai-umbrella",
	"ai-umi",
	"ai-uni",
	"ai-union",
	"ai-unlocked",
	"ai-upload",
	"ai-usd",
	"ai-usdq",
	"ai-usdt",
	"ai-usdtd",
	"ai-user_check",
	"ai-user",
	"ai-users-02",
	"ai-users",
	"ai-verification",
	"ai-vertikal",
	"ai-vest",
	"ai-vex",
	"ai-viber",
	"ai-vip",
	"ai-vk",
	"ai-vnd",
	"ai-vollar",
	"ai-volt",
	"ai-volume_demo",
	"ai-volume",
	"ai-wallet-01",
	"ai-wallet-03",
	"ai-wallet",
	"ai-warning",
	"ai-web_filled",
	"ai-web_link",
	"ai-web_outlined",
	"ai-wec",
	"ai-whatsapp",
	"ai-withdraw_down",
	"ai-withdraw",
	"ai-wix",
	"ai-wiz",
	"ai-wtp",
	"ai-x_close",
	"ai-xbv",
	"ai-xcg",
	"ai-xem",
	"ai-xmr",
	"ai-xrp",
	"ai-xrpd",
	"ai-xtz",
	"ai-yfi",
	"ai-yfii",
	"ai-youtube",
	"ai-zec",
	"ai-zhc",
	"ai-zks",
	"ai-zoom_in",
	"ai-zoom_out",
	"ai-zrx",
] as const
