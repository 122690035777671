import React, { useEffect, useMemo, useRef } from "react"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"
import cn from "classnames"
// eslint-disable-next-line import/no-extraneous-dependencies
import debounceRender from "react-debounce-render"

import { useMst } from "models/Root"
import styles from "../index.module.scss"
import Item from "./Item"

const List: React.FC<{
	isSell?: boolean
	list: any[]
	currentListType: string
}> = ({ isSell = false, list = [], currentListType }) => {
	const listRef = useRef<HTMLDivElement>(null)
	const { pair, tradeForm } = useMst().terminal
	const {
		isLoaded,
		currentPair: { baseCoin, quoteCoin },
		pairAmountPrecision,
		pairPricePrecision,
	} = useMst().bybit

	const maxDepth = useMemo(
		() => (list.length > 7 ? list[7]?.orderDepth : list[list.length - 1]?.orderDepth),
		[list],
	)

	const handleOrderClick = (item: any) => {
		if (item) {
			tradeForm.setClickedOrder(item)
		}
	}

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTop = 0
		}
	}, [currentListType])

	return (
		<div
			ref={listRef}
			className={cn(styles.orders__listWrapper, {
				[styles.orders__listWrapper___red]: isSell,
				[styles.orders__listWrapper___loader]: list.length === 0,
			})}
		>
			{!isLoaded ? (
				<>
					{Array.from({ length: 8 }).map((_, index) => (
						<div key={`${index}-order-skeleton`} className={styles.orders__skiletonWrapper}>
							<Skeleton width={300} height={24} borderRadius={0} />
						</div>
					))}
				</>
			) : list.length > 0 ? (
				<ul className={cn(styles.orders__list, { [styles.orders__list___red]: isSell })}>
					{list
						.map((item, index: number) => (
							<Item
								key={`${index}-item`}
								item={item}
								isSell={isSell}
								maxDepth={maxDepth}
								amount_precision={pairAmountPrecision}
								decimalPlaces={pairPricePrecision}
								quote_currency_code={quoteCoin}
								base_currency_code={baseCoin}
								handleOrderClick={handleOrderClick}
							/>
						))
						.reverse()}
				</ul>
			) : null}
		</div>
	)
}

export default debounceRender(observer(List), 500, { maxWait: 500, trailing: true })
