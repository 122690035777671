import React, { useState, useCallback } from "react"
import cn from "classnames"
import styles from "styles/pages/Wallets.module.scss"

interface ICollateralSwitchProps {
	isFixedCurrency: boolean
	onToggle: (newState: boolean) => void
	initialState: boolean
}

const CollateralSwitchToggle: React.FC<ICollateralSwitchProps> = ({
	isFixedCurrency,
	onToggle,
	initialState,
}) => {
	const [localSwitchState, setLocalSwitchState] = useState(initialState)

	const handleToggle = useCallback(() => {
		const newState = !localSwitchState
		setLocalSwitchState(newState)
		onToggle(newState)
	}, [localSwitchState, onToggle])

	return (
		<div
			className={cn(styles.toggle, {
				[styles.toggle_orange]: localSwitchState || isFixedCurrency,
				[styles.toggle_gray]: !localSwitchState && !isFixedCurrency,
				[styles.disabled_toggle]: isFixedCurrency,
			})}
			onClick={!isFixedCurrency ? handleToggle : undefined}
		>
			<div
				className={cn(styles.toggle_circle, {
					[styles.right]: localSwitchState || isFixedCurrency,
				})}
			/>
		</div>
	)
}

export default React.memo(CollateralSwitchToggle)
