import {
	applySnapshot,
	cast,
	flow,
	getParent,
	getSnapshot,
	Instance,
	types as t,
} from "mobx-state-tree"
import { toJS } from "mobx"

import { SearchSymbolResultItem } from "charting_library"
import config from "helpers/config"
import { getPrecisionMap } from "helpers/exchange"
import { TradeActionsEnum, TradeTypeEnum } from "types/exchange"
import {
	TBybitGetInstrumentsInfoSpotResponse,
	TBybitInstrumentSpot,
	TBybitTicker,
	TBybitTrade,
	TBybitWebSocketTrade,
	TBybitWebSocketWallet,
	TBybitWebSocketWalletCoin,
	TBybitWsCredentials,
} from "types/bybit"
import { AwaitedReturn } from "types/general"

import AccountService from "services/AccountService"
import BybitService from "services/BybitService"
import errorHandler from "utils/errorHandler"
import { MAX_PRICE_PRECISION } from "utils/constants"
import { getPrecision } from "utils/format"

import { IRecentTrade } from "./Terminal/recentTrades"
import { IRootStore } from "./Root"

export const ModelBybitTicker = t.model("BybitTicker", {
	symbol: t.string,
	lastPrice: t.number,
	highPrice24h: t.number,
	lowPrice24h: t.number,
	prevPrice24h: t.number,
	volume24h: t.number,
	turnover24h: t.number,
	price24hPcnt: t.number,
	usdIndexPrice: t.number,
})

interface IModelBybitTicker extends Instance<typeof ModelBybitTicker> {}

export const ModelBybitBalances = t.model("BybitBalances", {
	code: t.string,
	balance: t.number,
	reserve: t.number,
	available: t.number,
	converted: t.model({
		USD: t.number,
	}),
})

interface IModelBybitBalances extends Instance<typeof ModelBybitBalances> {}

const ModelBybitBorrowQuota = t.model("BybitBorrowQuota", {
	symbol: t.string,
	max_trade_qty: t.string,
	side: t.string,
	spot_max_trade_amount: t.string,
	max_trade_amount: t.string,
	borrow_coin: t.string,
	spot_max_trade_qty: t.string,
})

interface IModelBybitBorrowQuota extends Instance<typeof ModelBybitBorrowQuota> {}

export const ModelBybitRecentTrade = t.model("BybitRecentTrade", {
	id: t.number,
	price: t.number,
	amount: t.number,
	date: t.number,
	type: t.number,
	symbol: t.string,
})

interface IModelBybitRecentTrade extends Instance<typeof ModelBybitRecentTrade> {}

export const Bybit: any = t
	.model({
		// ticker
		ticker: t.maybe(ModelBybitTicker),

		// spot balances
		spotBalances: t.optional(t.array(ModelBybitBalances), []),

		// recent trades
		recentTrades: t.optional(t.array(ModelBybitRecentTrade), []),

		// current pair
		currentPair: t.optional(t.frozen<TBybitInstrumentSpot>(), {
			symbol: "",
			baseCoin: "",
			quoteCoin: "",
			innovation: "",
			status: "",
			marginTrading: "",
			lotSizeFilter: {
				basePrecision: "",
				quotePrecision: "",
				minOrderQty: "",
				maxOrderQty: "",
				minOrderAmt: "",
				maxOrderAmt: "",
			},
			priceFilter: {
				tickSize: "",
			},
			riskParameters: {
				limitParameter: "",
				marketParameter: "",
			},
		}),
		orderBookPrecision: t.optional(t.number, MAX_PRICE_PRECISION),
		isLoaded: t.optional(t.boolean, false),

		borrowQuota: t.optional(t.frozen<IModelBybitBorrowQuota>(), {
			symbol: "",
			max_trade_qty: "",
			side: "",
			spot_max_trade_amount: "",
			max_trade_amount: "",
			borrow_coin: "",
			spot_max_trade_qty: "",
		}),

		//pairs
		spotPairs: t.optional(t.array(t.frozen<TBybitInstrumentSpot>()), []),
		spotCategory: t.optional(t.string, ""),

		//private
		wsCredentials: t.maybeNull(t.frozen<TBybitWsCredentials>()),
		loanConditions: t.optional(t.array(t.frozen()), []),
	})
	.actions(self => ({
		resetState() {
			applySnapshot(self, getSnapshot(self))
		},
	}))
	.views((self: any) => ({
		get recentTradesList() {
			return toJS(self.recentTrades)
		},

		get recentTrade() {
			return self.recentTrades.length > 0 ? toJS(self.recentTrades[0]) : null
		},

		// current pair
		get pairAmountPrecision(): number {
			return self.currentPair.lotSizeFilter.basePrecision.split(".")[1]?.length || 8
		},
		get pairPricePrecision(): number {
			return self.currentPair.priceFilter.tickSize.split(".")[1]?.length || 8
		},
		get currentPrice() {
			return self.ticker?.lastPrice || 0
		},

		// spot balances
		get availableBalance() {
			const side = getParent<IRootStore>(self).tickers.filter.tradeAction
			const tradeType = getParent<IRootStore>(self).tickers.filter.tradeType

			if (tradeType === TradeTypeEnum.SPOT) {
				if (side === TradeActionsEnum.BUY)
					return self.borrowQuota.spot_max_trade_amount !== "" &&
						side.toLowerCase() === self.borrowQuota.side.toLowerCase()
						? self.borrowQuota.spot_max_trade_amount
						: 0

				if (side === TradeActionsEnum.SELL)
					return self.borrowQuota.spot_max_trade_qty !== "" &&
						side.toLowerCase() === self.borrowQuota.side.toLowerCase()
						? self.borrowQuota.spot_max_trade_qty
						: 0
			}

			if (side === TradeActionsEnum.BUY)
				return self.borrowQuota.max_trade_amount !== "" &&
					side.toLowerCase() === self.borrowQuota.side.toLowerCase()
					? self.borrowQuota.max_trade_amount
					: 0

			return self.borrowQuota.max_trade_qty !== "" &&
				side.toLowerCase() === self.borrowQuota.side.toLowerCase()
				? self.borrowQuota.max_trade_qty
				: 0
		},

		// pairs
		get chartCompareSymbols(): SearchSymbolResultItem[] {
			return self.spotPairs.map((t: TBybitInstrumentSpot) => ({
				symbol: t.symbol,
				full_name: t.symbol,
				description: t.symbol,
				exchange: config.department ?? "",
				ticker: t.symbol,
				type: "",
			}))
		},
		get currencyList() {
			const list = toJS(self.spotPairs)

			return Array.from(new Set(list.map((item: TBybitInstrumentSpot) => item.quoteCoin)))?.map(
				(item: unknown) => ({ label: item as string, name: item as string }),
			)
		},
		get currencyMarginList() {
			const list = toJS(self.spotPairs)

			return Array.from(
				new Set(
					list
						.filter((item: TBybitInstrumentSpot) => item.marginTrading !== "none")
						.map((item: TBybitInstrumentSpot) => item.quoteCoin),
				),
			)?.map((item: unknown) => ({ label: item as string, name: item as string }))
		},
		get formattedListSpotHeader() {
			const list = toJS(self.spotPairs)

			return list
				.filter((item: TBybitInstrumentSpot) => item.quoteCoin === self.spotCategory)
				.map((item: TBybitInstrumentSpot) => ({
					label: item.baseCoin as string,
					symbol: item.symbol as string,
					baseCoin: item.baseCoin as string,
					quoteCoin: item.quoteCoin as string,
					isMarginAccepted: item.marginTrading !== "none",
				}))
		},
		get formattedListMarginHeader() {
			const list = toJS(self.spotPairs)

			return list
				.filter(
					(item: TBybitInstrumentSpot) =>
						item.quoteCoin === self.spotCategory && item.marginTrading !== "none",
				)
				.map((item: TBybitInstrumentSpot) => ({
					label: item.baseCoin as string,
					symbol: item.symbol as string,
					baseCoin: item.baseCoin as string,
					quoteCoin: item.quoteCoin as string,
					isMarginAccepted: item.marginTrading !== "none",
				}))
		},
	}))
	.views(self => ({
		get precisionArray() {
			return self.ticker
				? Array.from(getPrecisionMap(self.pairPricePrecision, +self.ticker.lastPrice).entries())
				: []
		},

		get isMarginAvailable() {
			return self.currentPair.marginTrading !== "none" && self.currentPair.marginTrading !== ""
		},

		get funds() {
			return self.spotBalances
				.filter(
					(item: IModelBybitBalances) =>
						item.code.toLowerCase() === self.currentPair.baseCoin.toLowerCase() ||
						item.code.toLowerCase() === self.currentPair.quoteCoin.toLowerCase(),
				)
				.map((item: IModelBybitBalances) => ({
					currency: { name: item.code, url: item.code, symbol: item.code },
					available: item.available,
					balancePrecision:
						item.code.toLowerCase() === self.currentPair.baseCoin.toLowerCase()
							? self.pairAmountPrecision
							: self.pairPricePrecision,
				}))
		},
	}))
	.actions(self => ({
		// current pair
		setOrderBookPrecision(v: number) {
			self.orderBookPrecision = v
		},
		getCurrencyCodeBySide(side: TradeActionsEnum, isOpposite: boolean | undefined = false) {
			if (isOpposite) {
				return side === TradeActionsEnum.BUY
					? self.currentPair.baseCoin
					: self.currentPair.quoteCoin
			}

			return side === TradeActionsEnum.BUY ? self.currentPair.quoteCoin : self.currentPair.baseCoin
		},
	}))
	.actions(self => ({
		setTicker(data: TBybitTicker) {
			self.ticker = {
				symbol: data.symbol,
				lastPrice: +data.lastPrice,
				highPrice24h: +data.highPrice24h,
				lowPrice24h: +data.lowPrice24h,
				prevPrice24h: +data.prevPrice24h,
				volume24h: +data.volume24h,
				turnover24h: +data.turnover24h,
				price24hPcnt: +data.price24hPcnt,
				usdIndexPrice:
					+data.usdIndexPrice !== 0 ? +data.usdIndexPrice : self.ticker?.usdIndexPrice || 0,
			}
		},
		setRecentTrades(list: TBybitTrade[]) {
			self.recentTrades = cast(
				list.map<IModelBybitRecentTrade>(({ execId, price, size, time, side, symbol }) => ({
					id: +execId,
					price: +price,
					amount: +size,
					date: +time,
					type: side === "Buy" ? 2 : 1,
					symbol: symbol,
				})),
			)
		},
		patchRecentTrades(newTrades: TBybitWebSocketTrade[]) {
			const formattedTrades = newTrades.map<IRecentTrade>(trade => ({
				id: +trade.i,
				price: +trade.p,
				amount: +trade.v,
				date: +trade.T,
				type: trade.S === "Buy" ? 2 : 1,
				symbol: trade.s,
			}))

			const numberOfNewTrades = formattedTrades.length

			self.recentTrades.unshift(...formattedTrades)
			self.recentTrades.splice(-numberOfNewTrades, numberOfNewTrades)
		},

		// current pair
		setSpotCurrentPair(data: TBybitInstrumentSpot) {
			self.isLoaded = true
			self.currentPair = cast(data)
			self.setOrderBookPrecision(getPrecision(self.pairPricePrecision))
		},

		// spot balances
		setSpotBalances(data: IModelBybitBalances[] | any) {
			self.spotBalances = cast(data)
		},
		patchSpotBalances(data: TBybitWebSocketWallet) {
			const { coin: coins } = data
			const codes = coins.map(({ coin }) => coin)
			self.spotBalances = cast(
				self.spotBalances.map(balance => {
					if (!codes.includes(balance.code)) return balance
					const { coin, availableToWithdraw, usdValue, walletBalance, locked } = coins.find(
						({ coin }) => coin === balance.code,
					) as TBybitWebSocketWalletCoin
					return {
						...balance,
						code: coin,
						balance: +walletBalance,
						available: +availableToWithdraw,
						reserve: +locked,
						converted: {
							USD: +usdValue,
						},
					}
				}),
			)
		},

		// pairs
		setSpotPairs(data: TBybitInstrumentSpot[]) {
			self.spotPairs = cast(data)
		},
		setSpotCategory(data: string) {
			self.spotCategory = cast(data)
		},
		setBorrowQuota(data: IModelBybitBorrowQuota) {
			self.borrowQuota = cast(data)
		},
	}))

	.actions(self => ({
		loadSpotBalances: flow(function* () {
			try {
				const list: Awaited<ReturnType<typeof AccountService.getBalances>> =
					yield AccountService.getBalances({ account_type: "UNIFIED" })

				if (!Array.isArray(list)) {
					console.error("Invalid pairs data format", list)
					return
				}

				self.setSpotBalances(list)
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadBorrowQuota: flow(function* () {
			const side: TradeActionsEnum = getParent<IRootStore>(self).tickers.filter.tradeAction

			try {
				const borrowQuota: Awaited<ReturnType<typeof AccountService.getBorrowQuota>> =
					yield AccountService.getBorrowQuota({
						category: "spot",
						currency: self.currentPair.symbol,
						side: side,
					})

				self.setBorrowQuota(borrowQuota)
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadTicker: flow(function* (symbol: string) {
			try {
				const { list }: AwaitedReturn<typeof BybitService.getTickers> =
					yield BybitService.getTickers({
						category: "spot",
						symbol,
					})
				self.setTicker(list[0])
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadPairs: flow(function* () {
			try {
				const { list }: TBybitGetInstrumentsInfoSpotResponse =
					yield BybitService.getInstrumentsInfo({
						category: "spot",
					})

				if (!Array.isArray(list)) {
					console.error("Invalid pairs data format", list)
					return
				}

				self.setSpotPairs(list)
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadPair: flow(function* (symbol: string) {
			try {
				const { list }: TBybitGetInstrumentsInfoSpotResponse =
					yield BybitService.getInstrumentsInfo({
						category: "spot",
						symbol,
					})

				if (!Array.isArray(list)) {
					console.error("Invalid pairs data format", list)
					return
				}

				self.setSpotCurrentPair(list[0])
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadRecentTrades: flow(function* (symbol: string) {
			try {
				const { list }: AwaitedReturn<typeof BybitService.getRecentTrades> =
					yield BybitService.getRecentTrades({
						category: "spot",
						symbol,
					})

				if (!Array.isArray(list)) {
					console.error("Invalid recent trades data format", list)
					return
				}

				self.setRecentTrades(list)
			} catch (err) {
				console.error("Failed to load recent trades from Bybit API:", err)
			}
		}),
		loadWsCredentials: flow(function* () {
			try {
				self.wsCredentials = yield BybitService.getWsCredentials()
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadLoanConditions: flow(function* (currency: string) {
			try {
				const response = yield BybitService.getLoanConditions(currency)
				return response
			} catch (error) {
				console.error("Error fetching loan conditions:", error)
			}
			return undefined
		}),
	}))

export interface IBybit extends Instance<typeof Bybit> {}
