import { makeAutoObservable, runInAction } from "mobx"

import BybitService from "services/BybitService"
import errorHandler from "utils/errorHandler"

export type ordersType = [string, string][]

export class OrderBookStore {
	// bids orders
	bids: ordersType = []

	// asks orders
	asks: ordersType = []

	// is order book loaded
	isLoaded = false

	ordersBookPrecession = 0

	constructor() {
		makeAutoObservable(this)
	}

	async loadOrderBook(symbol: string) {
		try {
			const { b: bids, a: asks } = await BybitService.getOrderBook({
				category: "spot",
				symbol,
				limit: 200,
			})

			runInAction(() => {
				this.setOrderBook(bids, asks)
			})
		} catch (err) {
			errorHandler(err)
		}
	}

	setOrderBook(bids: ordersType, asks: ordersType) {
		this.bids = bids
		this.asks = asks
		this.isLoaded = true
	}

	setOrderBookPrecession(precession: number) {
		this.ordersBookPrecession = precession
	}
}
