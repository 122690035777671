import { queryVars } from "constants/query"
import { TradeActionsEnum } from "./exchange"

export enum AccountTypeEnum {
	SPOT = "spot",
	CROSS = "cross",
	CROSS_MARGIN = "cross",
	ISOLATED = "isolated",
}

export enum WalletTypeEnum {
	SPOT = "SPOT",
	CROSS_MARGIN = "CROSS_MARGIN",
	ISOLATED_MARGIN = "ISOLATED_MARGIN",
}

export enum EBybitWalletType {
	FUND = "FUND",
	UNIFIED = "UNIFIED",
}

export const getEBybitWalletTypeLabel = (walletType: EBybitWalletType, formatMessage: any) => {
	switch (walletType) {
		case EBybitWalletType.FUND:
			return formatMessage({
				id: "app.common.funding_wallet_new",
				defaultMessage: "Funding Wallet",
			})
		case EBybitWalletType.UNIFIED:
			return formatMessage({
				id: "app.common.unified_trading_wallet",
				defaultMessage: "Unified Trading Wallet",
			})
		default:
			return walletType
	}
}

export interface IGetMarginStatusParams {
	[queryVars.wallet_type]: number
	[queryVars.pair]?: string
}

export type TGetFinanceAccountTypesResponse = string[]

export interface IGetFinanceBalancesParams {
	account_type: string
}

export interface IGetBorrowQuotaParams {
	category: "spot"
	currency: string
	side: TradeActionsEnum
}

export type TGetFinanceBalancesResponse = {
	code: string
	balance: string
	reserve: string
	available: string
	converted: {
		USD: string
	}
}[]

export type TGetBorrowQuotaResponse = {
	symbol: string
	max_trade_qty: string
	side: string
	spot_max_trade_amount: string
	max_trade_amount: string
	borrow_coin: string
	spot_max_trade_qty: string
}

export type TGetAggregatedBalanceResponse = {
	eqv: string
	balance: number
}[]

export interface IBalanceWS {
	code: string
	type: string
	market: string
	balance: string
	reserve: string
}

export enum RateAlgoPlanEnum {
	CONSTANT = "constant",
	DIRECT = "direct",
	DIV = "div",
	MUL = "mul",
	REVERSED = "reversed",
	MUL_REVERSED = "mulReversed",
}

export interface IPostMarginTransferParams {
	from_account_type: string
	to_account_type: string
	currency: string
	amount: string
}

export enum EMarginTransferStatus {
	SUCCESS = "SUCCESS",
}

export type TPostMarginTransferResponse = {
	from_account_type: string
	to_account_type: string
	currency: string
	amount: string
	status: EMarginTransferStatus
	transfer_id: string
}

export interface ICollateralInfo {
	available_to_borrow: number
	borrow_amount: number
	borrow_usage_rate: number
	borrowable: boolean
	collateral_ratio: number
	collateral_switch: boolean
	currency: string
	free_borrow_amount: number
	free_borrowing_amount: string
	free_borrowing_limit: number
	hourly_borrow_rate: number
	margin_collateral: boolean
	max_borrowing_amount: number
}

export interface IBorrowHistoryItem {
	order_id: string
	status: number
	initial_collateral_amount: number
	initial_loan_amount: number
	collateral_currency: string
	loan_currency: string
	repaid_interest: number
	repaid_penalty_interest: number
	hourly_interest_rate: number
	borrow_time: string
	expiration_time: string | null
}

export interface IBorrowHistoryResponse {
	nextPageCursor?: string
	list: IBorrowHistoryItem[]
}

export interface IBorrowHistoryResult {
	retCode: number
	retMsg: string
	result: IBorrowHistoryResponse
	retExtInfo?: object
	time: number
}

export enum EMarginMode {
	ISOLATED = "ISOLATED_MARGIN",
	REGULAR = "REGULAR_MARGIN",
	PORTFOLIO = "PORTFOLIO_MARGIN",
}

export type TGetAccountInfoResponse = {
	isMaster_trader: boolean
	margin_mode: EMarginMode
	spot_hedging_status: boolean
	unified_margin_status: number
	updated_time: string
}

export type TPostMarginModeResponse = { margin_mode: EMarginMode }

export interface ICollateralSwitchParams {
	asset: string
	collateral_switch: "ON" | "OFF"
}

export type TPostCollateralSwitchResponse = {
	success: boolean
}
