import React, { FC } from "react"
import Skeleton from "react-loading-skeleton"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cn from "classnames"

import { useMst } from "models/Root"
import DebouncedValue from "components/UI/DebouncedValue"

import styles from "../index.module.scss"

const CurrentPrice: FC = () => {
	const { formatNumber } = useIntl()
	const {
		bybit: { ticker, isLoaded, pairPricePrecision },
		terminal: { recentTradeDiff },
	} = useMst()

	const lastPrice = formatNumber(ticker?.lastPrice || 0, {
		useGrouping: true,
		maximumFractionDigits: pairPricePrecision,
		minimumFractionDigits: pairPricePrecision,
	})

	const usdPrice = formatNumber(ticker?.usdIndexPrice || 0, {
		useGrouping: true,
		maximumFractionDigits: pairPricePrecision,
		minimumFractionDigits: pairPricePrecision,
	})

	return (
		<div className={styles.orders__currentPrice}>
			<span
				className={cn({
					[styles.orders__currentPriceText]: true,
					[styles.orders__currentPriceText___green]: recentTradeDiff > 0,
					[styles.orders__currentPriceText___red]: recentTradeDiff < 0,
				})}
			>
				{isLoaded ? (
					<DebouncedValue value={lastPrice} />
				) : (
					<Skeleton width={81} height={20} borderRadius={2} />
				)}
			</span>

			<span className={styles.orders__currentPriceText2}>
				{isLoaded ? (
					<DebouncedValue value={`≈ ${usdPrice} USD`} />
				) : (
					<Skeleton width={90} height={20} borderRadius={2} />
				)}
			</span>
		</div>
	)
}

export default observer(CurrentPrice)
