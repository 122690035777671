import React, { CSSProperties } from "react"
import cn from "classnames"
import styles from "styles/components/UI/Button.module.scss"
import InternalLink from "components/InternalLink"
import { ReactComponent as Loader } from "./loader.svg"

export interface IButton {
	className?: string
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
	onSubmit?: (body: any) => void
	disabled?: boolean
	variant?: "filled" | "text" | "outlined"
	color?: "primary" | "secondary" | "tertiary" | "quaternary" | "quinary" | "darkness"
	mini?: boolean
	defaultTerminalBtn?: boolean
	fullWidth?: boolean
	label: string | JSX.Element
	isLoading?: boolean
	iconCode?: string
	iconAlign?: "left" | "right" | "center"
	iconSize?: number
	type?: "submit" | "button"
	isInternalLink?: boolean
	to?: string
	children?: React.ReactNode
	fontVariant?: "bold" | "medium" | "normal"
}

interface IButtonsGroupProps {
	fullWidth?: boolean
	style?: React.CSSProperties
	children?: React.ReactNode
}

export const ButtonsGroup: React.FC<IButtonsGroupProps> = ({ fullWidth, style, children }) => (
	<div style={style} className={cn(styles.buttons_group, { [styles.fullWidth]: fullWidth })}>
		{children}
	</div>
)

const Button: React.FC<IButton> = ({
	className,
	label,
	onClick,
	onSubmit,
	disabled,
	variant = "filled",
	color = "primary",
	fullWidth,
	iconCode,
	iconAlign = "left",
	iconSize,
	isLoading,
	mini,
	defaultTerminalBtn,
	isInternalLink,
	to,
	children,
	type = "button",
	fontVariant = "normal",
}) => {
	const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (!disabled && !isLoading && onClick) {
			onClick(e)
		}
	}

	const handleSubmit = (body: any) => {
		if (!disabled && !isLoading && onSubmit) {
			onSubmit(body)
		}
	}

	const style = iconSize ? ({ "--icon": `${iconSize}px` } as CSSProperties) : undefined

	return isInternalLink ? (
		<InternalLink
			className={cn(
				styles.container,
				className,
				styles[fontVariant],
				styles[variant],
				styles[color],
				{
					[styles.disabled]: disabled,
					[styles.mini]: mini,
					[styles.defaultTerminalBtn]: defaultTerminalBtn,
					[styles.full_width]: fullWidth,
					[styles.loading]: isLoading,
				},
			)}
			to={to}
			style={style}
		>
			{isLoading && (
				<div className={styles.button_loader}>
					<Loader />
				</div>
			)}
			<div
				tabIndex={-1}
				className={cn(styles.button_content, {
					[styles.loading]: isLoading,
					[styles.mini]: mini,
				})}
			>
				{iconCode && iconAlign === "left" ? <i className={`ai ai-${iconCode}`} /> : null}
				{iconCode && iconAlign === "center" ? (
					<i className={`ai ai-${iconCode}`} />
				) : (
					<span>{label}</span>
				)}
				{iconCode && iconAlign === "right" ? <i className={`ai ai-${iconCode}`} /> : null}
			</div>
		</InternalLink>
	) : (
		<button
			className={cn(
				styles.container,
				className,
				styles[fontVariant],
				styles[variant],
				styles[color],
				{
					[styles.disabled]: disabled,
					[styles.mini]: mini,
					[styles.defaultTerminalBtn]: defaultTerminalBtn,
					[styles.full_width]: fullWidth,
					[styles.loading]: isLoading,
				},
			)}
			// eslint-disable-next-line react/button-has-type
			type={type}
			onClick={handleClick}
			onSubmit={handleSubmit}
			disabled={disabled}
			style={style}
		>
			{isLoading && (
				<div className={styles.button_loader}>
					<Loader />
				</div>
			)}
			<div
				tabIndex={-1}
				className={cn(styles.button_content, {
					[styles.loading]: isLoading,
					[styles.mini]: mini,
					[styles.iconRight]: iconAlign === "right",
				})}
			>
				{iconCode && iconAlign === "left" ? <i className={`ai ai-${iconCode}`} /> : null}
				{iconCode && iconAlign === "center" ? (
					<i className={`ai ai-${iconCode}`} />
				) : (
					<span>{label}</span>
				)}
				{iconCode && iconAlign === "right" ? <i className={`ai ai-${iconCode}`} /> : null}
			</div>
		</button>
	)
}

export default Button
