import React, { useRef } from "react"
import cn from "classnames"

import styles from "./input.module.scss"

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	prefixIcon?: React.ReactElement
	postfixIcon?: React.ReactElement
	prefixText?: string | React.ReactElement
	postfixText?: string | React.ReactElement
	onEnter?: () => void
	error?: string
	inputClassName?: string
}

export const Input: React.FC<IProps> = ({
	prefixIcon,
	postfixIcon,
	prefixText,
	onEnter,
	postfixText,
	error = "",
	className,
	inputClassName,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement>(null)

	const [isFocused, setIsFocused] = React.useState(false)

	const setValue = (text: string) => {
		const nodeInput = inputRef.current as HTMLInputElement
		// eslint-disable-next-line no-proto
		const setValue = Object.getOwnPropertyDescriptor((nodeInput as any).__proto__, "value")?.set
		if (setValue) setValue?.call(nodeInput, text)
		else nodeInput.value = text
		nodeInput.dispatchEvent(new Event("input", { bubbles: true }))
		nodeInput.dispatchEvent(new Event("change", { bubbles: true }))
	}

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter" && !!onEnter) onEnter()
		if (event.key === "Escape" && inputRef.current) setValue("")
	}

	const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true)
		props.onFocus?.(event)
	}

	const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(false)
		props.onBlur?.(event)
	}

	return (
		<>
			<div
				className={cn(
					styles.input,
					{
						[styles.input___focus]: isFocused,
						[styles.input___disabled]: props.disabled,
						[styles.input___errorInput]: error !== "",
					},
					className,
				)}
			>
				<div className={styles.input__prefixWrapper}>
					{!!prefixIcon && <div className={styles.input__prefixIcon}>{prefixIcon}</div>}
					{!!prefixText && <div className={styles.input__prefixText}>{prefixText}</div>}
				</div>

				<input
					ref={inputRef}
					autoComplete="off"
					onKeyDown={handleKeyPress}
					className={cn(styles.input__elem, inputClassName)}
					{...props}
					onFocus={onFocus}
					onBlur={onBlur}
				/>

				<div className={styles.input__postfixWrapper}>
					{!!postfixIcon && <div className={styles.input__postfixIcon}>{postfixIcon}</div>}
					{!!postfixText && <div className={styles.input__postfixText}>{postfixText}</div>}
				</div>
			</div>

			{!!error?.length && <p className={styles.input__errorText}>{error}</p>}
		</>
	)
}
