import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Terminal/Bottom/index.module.scss"
import { routes } from "../../../../../constants/routing"
import InternalLink from "../../../../InternalLink"
import commonMessages from "../../../../../messages/common"

const Header: React.FC<{
	openedOrdersCount: number
	isAuthenticated: boolean
	currentValue: string
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleChange: any
	isMargin: boolean
	isShowAll: boolean
	handleShowAll: (value: boolean) => void
}> = ({
	openedOrdersCount,
	isAuthenticated,
	currentValue,
	handleChange,
	isMargin,
	handleShowAll,
	isShowAll,
}) => {
	const { formatMessage } = useIntl()
	const handleChangeAction = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			if (ev.target.value !== currentValue) handleChange(ev.target.value)
		},
		[currentValue, handleChange],
	)

	const toggleShowAll = useCallback(() => handleShowAll(!isShowAll), [handleShowAll, isShowAll])

	return (
		<div className={styles.bottom__header}>
			<ul className={styles.bottom__headerTabList}>
				<li className={styles.bottom__headerTab}>
					<input
						className={`visually-hidden ${styles.bottom__tradingListTabRadio}`}
						type="radio"
						name="BottomTabs"
						id="OpenOrdersTab"
						value="OpenOrdersTab"
						checked={currentValue === "OpenOrdersTab"}
						onChange={handleChangeAction}
					/>

					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label className={styles.bottom__headerTabButton} htmlFor="OpenOrdersTab">
						<span>
							{formatMessage(commonMessages.openOrders)} ({openedOrdersCount})
						</span>
					</label>
				</li>

				{/*{isMargin ? (*/}
				{/*	<li className={styles.bottom__headerTab}>*/}
				{/*		<input*/}
				{/*			className={`visually-hidden ${styles.bottom__tradingListTabRadio}`}*/}
				{/*			type="radio"*/}
				{/*			name="BottomTabs"*/}
				{/*			id="PositionsTab"*/}
				{/*			value="PositionsTab"*/}
				{/*			checked={currentValue === "PositionsTab"}*/}
				{/*			onChange={() => handleChange("PositionsTab")}*/}
				{/*		/>*/}

				{/*		/!* eslint-disable-next-line jsx-a11y/label-has-associated-control *!/*/}
				{/*		<label className={styles.bottom__headerTabButton} htmlFor="PositionsTab">*/}
				{/*			<span>Positions</span>*/}
				{/*		</label>*/}
				{/*	</li>*/}
				{/*) : null}*/}

				<li className={styles.bottom__headerTab}>
					<input
						className={`visually-hidden ${styles.bottom__tradingListTabRadio}`}
						type="radio"
						name="BottomTabs"
						id="OrderHistoryTab"
						value="OrderHistoryTab"
						checked={currentValue === "OrderHistoryTab"}
						onChange={handleChangeAction}
					/>

					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label className={styles.bottom__headerTabButton} htmlFor="OrderHistoryTab">
						<span>{formatMessage(commonMessages.orderHistory)}</span>
					</label>
				</li>

				<li className={styles.bottom__headerTab}>
					<input
						className={`visually-hidden ${styles.bottom__tradingListTabRadio}`}
						type="radio"
						name="BottomTabs"
						id="TradeHistoryTab"
						value="TradeHistoryTab"
						checked={currentValue === "TradeHistoryTab"}
						onChange={handleChangeAction}
					/>

					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label className={styles.bottom__headerTabButton} htmlFor="TradeHistoryTab">
						<span>{formatMessage(commonMessages.tradeHistory)}</span>
					</label>
				</li>

				{isMargin ? (
					<li className={styles.bottom__headerTab}>
						<input
							className={`visually-hidden ${styles.bottom__tradingListTabRadio}`}
							type="radio"
							name="BottomTabs"
							id="BorrowsTab"
							value="BorrowsTab"
							checked={currentValue === "BorrowsTab"}
							onChange={handleChangeAction}
						/>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className={styles.bottom__headerTabButton} htmlFor="BorrowsTab">
							<span>{formatMessage(commonMessages.borrows)}</span>
						</label>
					</li>
				) : null}

				{isAuthenticated ? (
					<li className={styles.bottom__headerTab}>
						<input
							className={`visually-hidden ${styles.bottom__tradingListTabRadio}`}
							type="radio"
							name="BottomTabs"
							id="FundsTab"
							value="FundsTab"
							checked={currentValue === "FundsTab"}
							onChange={handleChangeAction}
						/>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

						<label className={styles.bottom__headerTabButton} htmlFor="FundsTab">
							<span>{formatMessage(commonMessages.funds)}</span>
						</label>
					</li>
				) : null}
			</ul>

			{isAuthenticated &&
			(currentValue === "TradeHistoryTab" ||
				currentValue === "OrderHistoryTab" ||
				currentValue === "OpenOrdersTab") ? (
				<>
					<div className={styles.bottom__headerCheckboxWrapper}>
						<input
							className={`visually-hidden ${styles.bottom__headerAllPairsCheckbox}`}
							type="checkbox"
							name="Show all trading pairs"
							id="ShowAllTradingPairs"
							checked={isShowAll}
							onChange={toggleShowAll}
						/>

						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className={styles.bottom__headerAllPairsLabel} htmlFor="ShowAllTradingPairs">
							{formatMessage(commonMessages.show_all_trading_pairs)}
						</label>
					</div>

					<InternalLink to={routes.history.activeOrders} className={styles.bottom__headerAllOrders}>
						{formatMessage(commonMessages.allOrders)}
					</InternalLink>
				</>
			) : null}
		</div>
	)
}

export default observer(Header)
