import { makeAutoObservable } from "mobx"

import { OrderBookStore } from "./orderBook"
import { CurrentPairStore } from "./currentPair"

export class TerminalStore {
	orderBook

	currentPair

	constructor() {
		this.orderBook = new OrderBookStore()
		this.currentPair = new CurrentPairStore()

		makeAutoObservable(this)
	}
}
