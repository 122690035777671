import React, { memo } from "react"

type TProps = {
	width?: number
	height?: number
	color?: string
	hideTM?: boolean
	className?: string
}

const Logo: React.FC<TProps> = ({
	width = 99,
	height = 64,
	color = "#7C59FF",
	hideTM,
	className,
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 99 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path d="M42.2526 64H0L39.1685 0H81.7295L42.2526 64Z" fill={color} />
		<ellipse cx="82.0372" cy="46.9126" rx="16.9627" ry="17.0874" fill={color} />
		{!hideTM && (
			<>
				<path
					d="M95.1624 0.0594482L96.4977 2.1722L97.8266 0.0594482H98.7966V4.55143H97.8896V1.45526L96.6866 3.39037H96.2772L95.0742 1.45526V4.55143H94.1736V0.0594482H95.1624Z"
					fill={color}
				/>
				<path
					d="M89.9561 0.877841V0.0593872H93.6658V0.877841H92.2675V4.55137H91.3543V0.877841H89.9561Z"
					fill={color}
				/>
			</>
		)}
	</svg>
)

export default memo(Logo)
