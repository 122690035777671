/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import cn from "classnames"

import { TWithdrawMethod } from "types/withdrawal"
import { routes } from "constants/routing"
import financeMessages from "messages/finance"
import { useMst } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import useModal from "hooks/useModal"
import WithdrawalService from "services/WithdrawalService"
import errorHandler from "utils/errorHandler"

import Stepper from "components/redesigned/Stepper"
import Select, { TSelectChangeEvent, TSelectOption } from "components/redesigned/Select"
import CurrencyIcon from "components/redesigned/CurrencyIcon"
import Field from "components/redesigned/Field"
import Button from "components/UI/Button"
import Input from "components/redesigned/Input"
import TextButton from "components/redesigned/TextButton"
import Textarea from "components/redesigned/Textarea"

import { ERROR_ADDRESS, ERROR_AMOUNT } from "./constants"
import ConfirmWithdrawal from "./ConfirmWithdrawal"

import styles from "./withdrawal.module.scss"

const MIN_ADDRESS_LENGTH = 1

type TProps = {
	currency: string
	updateHistory?: () => void
}

const WithdrawalForm: React.FC<TProps> = ({ currency, updateHistory }) => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { profileStatus },
		withdrawal: { currencies, loadCurrencies },
	} = useMst()
	const localeNavigate = useLocaleNavigate()

	const [step, setStep] = useState<number>(1)
	const [error, setError] = useState<string>()

	/* Coin */

	const { code = "", available = 0 } = currencies.find(({ code }) => code === currency) || {}

	useEffect(() => {
		!currencies?.length && loadCurrencies()
	}, [currencies])

	const currencyOptions = useMemo(
		() =>
			[...currencies]
				.filter(({ available }) => available)
				.sort((a, b) => a.code.localeCompare(b.code))
				.map<TSelectOption>(({ code, available }) => ({
					icon: <CurrencyIcon name={code} />,
					label: code,
					description: code,
					amount: formatNumber(+available, { minimumFractionDigits: 2, maximumFractionDigits: 8 }),
					value: code.toUpperCase(),
				})),
		[currencies.length],
	)

	const handleCurrencyChange: TSelectChangeEvent = value => {
		if (!value) {
			setStep(0)
			return
		}
		currency !== value && localeNavigate(routes.profile.getWithdrawCurrency(value))
		setStep(1)
	}

	/* Chain */

	const [methods, setMethods] = useState<TWithdrawMethod[]>([])
	const [methodId, setMethodId] = useState<string>("")

	const loadMethods = async () => {
		if (!currency) return
		try {
			const result = await WithdrawalService.getMethods({ currency })
			setMethods(result)
		} catch (error) {
			errorHandler(error, false)
		}
	}
	useEffect(() => {
		if (step < 1) {
			setMethods([])
			setMethodId("")
		} else if (currency && step === 1) {
			setMethods([])
			setMethodId("")
			loadMethods()
		}
	}, [currency])

	const methodOptions = useMemo(
		() =>
			methods.map<TSelectOption>(({ id, name, withdraw_fee_amount, is_withdraw_enabled }) => ({
				label: name,
				//TODO: translate
				amount: `Fee: ${formatNumber(+withdraw_fee_amount, {
					minimumFractionDigits: 2,
					maximumFractionDigits: 8,
				})}`,
				value: id,
				disabled: !is_withdraw_enabled,
			})),
		[methods],
	)

	/* Address */

	const [address, setAddress] = useState<string>("")
	const [tag, setTag] = useState<string>("")

	useEffect(() => {
		setError("")
		setAddress("")
		setTag("")
	}, [methodId])

	useEffect(() => {
		if (address.length < MIN_ADDRESS_LENGTH && step > 1) setStep(1)
		else if (address.length >= MIN_ADDRESS_LENGTH && step === 1) setStep(2)
		setAmount("")
		setNote("")
	}, [step, address])

	/* Amount */

	const method = methods.find(({ id }) => id === methodId)
	const {
		withdraw_fee_rate = 0,
		withdraw_fee_amount = 0,
		min_withdraw = 0,
		min_verification_level,
	} = method || {}

	const [amount, setAmount] = useState<string>("")

	const maximumAmount = available - +withdraw_fee_amount - available * +withdraw_fee_rate
	const minimumAmount = +min_withdraw

	const receivedAmount = Math.max(Math.min(+amount, maximumAmount), minimumAmount)
	const calculatedFee = +withdraw_fee_amount + receivedAmount * +withdraw_fee_rate

	const handleAllClick = () => setAmount(maximumAmount.toString())

	/* Note */

	const [noting, setNoting] = useState<boolean>(false)
	const [note, setNote] = useState<string>("")

	const toggleNoting = () => setNoting(val => !val)

	/* Confirm */

	const [openConfirmationModal, , , getConfirmationModalOpened] = useModal(ConfirmWithdrawal, {
		//TODO: translate
		title: "Confirm to Withdraw",
		width: 500,
		closeBack: false,
		closeEsc: false,
		closeButton: false,
		onClose: setError,
	})

	const handleConfirmClick = () => {
		setError(undefined)
		openConfirmationModal({
			currency,
			address,
			method,
			received: receivedAmount,
			fee: calculatedFee,
			tag,
			note,
			updateHistory,
		})
	}

	/* Resume */

	const [searchParams, setSearchParams] = useSearchParams()
	const slug = searchParams.get("slug")
	useEffect(() => {
		if (!slug || !currency || getConfirmationModalOpened()) return
		WithdrawalService.getWithdrawDetails(slug)
			.then(withdrawal => {
				openConfirmationModal({
					currency,
					address: withdrawal.address,
					received: +withdrawal.amount,
					fee: withdrawal.fee_amount,
					withdrawal,
					updateHistory,
				})
			})
			.catch(() => setSearchParams({}))
	}, [slug])

	/* Render */

	//TODO: render verification level if false
	const isEnoughVerificationLevel =
		(profileStatus?.verification_level ?? 0) >= (min_verification_level ?? 0)

	const isConfirmEnabled =
		isEnoughVerificationLevel &&
		step >= 2 &&
		!!amount &&
		+min_withdraw <= +amount &&
		+amount <= available

	return (
		<Stepper step={step} className={styles.form}>
			{/*TODO: translate*/}
			<Stepper.Item title="Select coin to withdraw" className={styles.step}>
				<Select
					options={currencyOptions}
					search
					value={currency}
					loading={!currencies?.length}
					onChange={handleCurrencyChange}
				/>
			</Stepper.Item>
			{/*TODO: translate*/}
			<Stepper.Item title="Withdraw to" className={styles.step}>
				<Field
					//TODO: translate
					fLabel="Chain Type"
					fComponent={Select}
					loading={!methods.length}
					options={methodOptions}
					value={methodId}
					onChange={setMethodId}
				/>
				<Field
					//TODO: translate
					fLabel="Wallet Address"
					fRequired
					fComponent={Input}
					value={address}
					disabled={!methodId}
					error={error === ERROR_ADDRESS}
					onChange={setAddress}
				/>
				<Field
					//TODO: translate
					fLabel={
						<>
							Tag <sup>(optional)</sup>
						</>
					}
					fComponent={Input}
					value={tag}
					disabled={!methodId}
					onChange={setTag}
				/>
			</Stepper.Item>
			{/*TODO: translate*/}
			<Stepper.Item
				title="Withdrawble Amount"
				subTitle={available ? `${available} ${currency}` : "0"}
			>
				<Field
					//TODO: translate
					fSubline={`Minimum: ${+min_withdraw}`}
					fComponent={Input}
					number
					min={minimumAmount}
					max={maximumAmount}
					//TODO: translate
					action="All"
					actionClick={handleAllClick}
					error={error === ERROR_AMOUNT}
					value={amount}
					onChange={setAmount}
				/>
				<div className={styles.note}>
					<TextButton
						caption={formatMessage(financeMessages.make_a_note)}
						icon={noting ? "ai-cancel_filled" : "ai-edit"}
						active={noting}
						onClick={toggleNoting}
					/>
					<Textarea
						placeholder={formatMessage(financeMessages.note_tooltip)}
						className={cn({ [styles.showed]: noting })}
						rows={3}
						value={note}
						onChange={setNote}
					/>
				</div>
			</Stepper.Item>
			<Stepper.Footer>
				<div className={styles.amounts}>
					<div>
						{/*TODO: translate*/}
						<span>Full Amount</span>
						<span>
							{receivedAmount + calculatedFee} {code}
						</span>
					</div>
					<div>
						{/*TODO: translate*/}
						<span>Amount Received</span>
						<span>
							{receivedAmount} {code}
						</span>
					</div>
					<div>
						{/*TODO: translate*/}
						<span>Transaction Fee</span>
						<span>
							{calculatedFee} {code}
						</span>
					</div>
				</div>
				<div className={styles.confirmation}>
					{/*TODO: translate*/}
					<p>Amount received is based on the deduction of Transaction Fee from Withdraw Amount</p>
					<Button
						//TODO: translate
						label="Confirm Withdraw"
						disabled={!isConfirmEnabled}
						onClick={handleConfirmClick}
					/>
				</div>
			</Stepper.Footer>
		</Stepper>
	)
}

export default observer(WithdrawalForm)
