import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"

import { queryVars } from "constants/query"
import ArrowSort from "assets/icons/terminal/ArrowSort"

import { TSort, TSortDirection } from "../index"
import styles from "../../index.module.scss"

type TProps = {
	name: string
	label: string
	sortName: string
	sortValue: TSortDirection
	handleFilterChange?: (name: TSort) => void
}

const Item: React.FC<TProps> = ({ name, label, handleFilterChange, sortValue, sortName }) => {
	const onClick = useCallback(
		() =>
			handleFilterChange?.(
				`${name}.${sortValue === queryVars.asc ? queryVars.desc : queryVars.asc}`,
			),
		[name, sortValue],
	)

	return (
		<button
			type="button"
			onClick={onClick}
			className={`${styles.search__coinsHeaderText} ${
				name === sortName ? styles.search__coinsHeaderText___current : ""
			} ${sortValue === queryVars.desc ? styles.search__coinsHeaderText___desc : ""}`}
		>
			{label}

			{name === sortName ? <ArrowSort /> : null}
		</button>
	)
}

export default observer(Item)
