import React, {
	CSSProperties,
	MouseEventHandler,
	useCallback,
	useEffect,
	useId,
	useRef,
	useState,
} from "react"
import cn from "classnames"

import styles from "./switcher.module.scss"

export type TSwitcherItem<Key extends string> = {
	key: Key
	label: string
	color?: string
	disabled?: boolean
	visible?: boolean
}

export type TSwitcherItems<Key extends string = string> = Array<TSwitcherItem<Key>>

export type TSwitcherClick<Key extends string = string> = (key: Key) => void

type TProps<Key extends string> = {
	items?: TSwitcherItems<Key>
	selected?: Key
	full?: boolean
	className?: string
	onClick?: TSwitcherClick<Key>
}

function Switcher<Key extends string>({
	items = [],
	selected,
	full,
	className,
	onClick,
}: TProps<Key>) {
	const id = useId()
	const rootRef = useRef<HTMLDivElement>(null)

	const [style, setStyle] = useState<CSSProperties>({})

	const updateSelector = useCallback(() => {
		const nodeRoot = rootRef.current as HTMLDivElement
		const nodeButton = nodeRoot.querySelector(`button[data-key="${selected}"]`) as HTMLButtonElement
		if (!nodeButton) {
			setStyle({ display: "none" })
			return
		}
		const item = items.find(({ key }) => key === selected)
		setStyle({
			width: nodeButton.offsetWidth,
			left: nodeButton.offsetLeft,
			backgroundColor: item?.color,
		})
	}, [items, selected])

	useEffect(() => {
		const nodeRoot = rootRef.current as HTMLDivElement
		const resizeObserver = new ResizeObserver(updateSelector)
		updateSelector()
		resizeObserver.observe(nodeRoot)
		return () => resizeObserver.unobserve(nodeRoot)
	}, [updateSelector])

	const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
		event.preventDefault()
		const { key } = event.currentTarget.dataset
		onClick?.(key as Key)
	}

	return (
		<div ref={rootRef} className={cn(styles.switcher, { [styles.full]: full }, className)}>
			<div style={style} className={styles.slider} />
			{items
				.filter(({ visible }) => visible !== false)
				.map(({ key, label, disabled, color }, index, array) => (
					<button
						key={`switcher-${id}-item-${key}`}
						type="button"
						data-key={key}
						className={cn({ [styles.selected]: selected === key })}
						disabled={disabled}
						onClick={handleClick}
					>
						<span>{label}</span>
					</button>
				))}
		</div>
	)
}

export default Switcher
